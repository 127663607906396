import React from 'react';
import { useTranslation } from 'react-i18next';

function SupplierCarbonIntensity({ partner }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-y-4 bg-white p-6 mb-4 border border-solid border-greySix rounded">
      <h2 className="text-greyOne font-moskauGrotesk text-base">
        {t('SUPPLIER_CARBON_INTENSITY.TITLE')}
      </h2>
      <div className="flex flex-col gap-y-1 p-4 bg-noteIntermediaire/10 rounded font-inter">
        <span className="font-bold text-sm text-noteIntermediaire">{t('SUPPLIER_CARBON_INTENSITY.STATUS_TITLE')}</span>
        <span className="text-sm text-greyOne leading-4">{t('SUPPLIER_CARBON_INTENSITY.STATUS_DESCRIPTION', { partnerName: partner.name })}</span>
      </div>
      <span className="font-moskauGrotesk font-medium text-sm text-primaryNewBlue leading-[14px] underline cursor-pointer">{t('SUPPLIER_CARBON_INTENSITY.ALL_LEVELS_LINK')}</span>
    </div>
  );
}

export default SupplierCarbonIntensity;

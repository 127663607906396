import { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import resolveConfig from 'tailwindcss/resolveConfig';
import { useUserLocale } from '../utils/getUserLocal';
import tailwindConfig from '../tailwind.config';
import getCompleteColorByScore from '../utils/getCompleteColorByScore';
import getOpenClimatComment from '../utils/getOpenClimatComment';
import maturityInfo from '../utils/maturityInfo';

/**
 * Custom hook to fetch and manage climate actions data for a given profile.
 *
 * @param {Object} profile - The profile object containing the profile details.
 * @param {number} profile.id - The ID of the profile.
 * @param {number} profile.type - The type of the profile.
 * @returns {Object} - An object containing climate actions data and related states.
 * @returns {string} openClimatComment - The comment related to the climate actions.
 * @returns {Object} scope12 - The scope 1 and 2 data.
 * @returns {number} scope12.percentDelta - The percent delta for scope 1 and 2.
 * @returns {string} scope12.reduceType - The reduction type for scope 1 and 2.
 * @returns {number} scope12.startYear - The start year for scope 1 and 2.
 * @returns {number} scope12.endYear - The end year for scope 1 and 2.
 * @returns {number} scope12.degreeScore - The degree score for scope 1 and 2.
 * @returns {boolean} scope12.dataUsable - Whether the data for scope 1 and 2 is usable.
 * @returns {boolean} scope12.dataNotComparable - Whether the data for scope 1 and 2 is not comparable.
 * @returns {Object} scope3 - The scope 3 data.
 * @returns {number} scope3.percentDelta - The percent delta for scope 3.
 * @returns {string} scope3.reduceType - The reduction type for scope 3.
 * @returns {number} scope3.startYear - The start year for scope 3.
 * @returns {number} scope3.endYear - The end year for scope 3.
 * @returns {number} scope3.degreeScore - The degree score for scope 3.
 * @returns {boolean} scope3.dataUsable - Whether the data for scope 3 is usable.
 * @returns {boolean} scope3.dataNotComparable - Whether the data for scope 3 is not comparable.
 * @returns {Object} flag - The flag data.
 * @returns {number} flag.percentDelta - The percent delta for the flag.
 * @returns {string} flag.reduceType - The reduction type for the flag.
 * @returns {number} flag.startYear - The start year for the flag.
 * @returns {number} flag.endYear - The end year for the flag.
 * @returns {number} flag.degreeScore - The degree score for the flag.
 * @returns {boolean} flag.dataUsable - Whether the data for the flag is usable.
 * @returns {boolean} flag.dataNotComparable - Whether the data for the flag is not comparable.
 * @returns {string} scope12Color - The color associated with scope 1 and 2 data.
 * @returns {string} scope3Color - The color associated with scope 3 data.
 * @returns {string} flagColor - The color associated with the flag data.
 * @returns {string} note - The note related to the climate actions.
 * @returns {string} flagPerimeter - The flag perimeter.
 * @returns {boolean} isNotMember - Indicates whether the profile is not a member.
 * @returns {Object} levelInfo - The information related to the maturity level.
 */
const useClimateActions = (profile) => {
  const [openClimatComment, setOpenCimatComment] = useState();
  const [scope12, setScope12] = useState();
  const [scope3, setScope3] = useState();
  const [flag, setFlag] = useState();
  const [scope12Color, setScope12Color] = useState();
  const [scope3Color, setScope3Color] = useState();
  const [flagColor, setFlagColor] = useState();
  const [score, setScore] = useState(null);
  const [note, setNote] = useState(null);
  const [flagPerimeter, setFlagPerimeter] = useState(null);
  const [reductionColPosition, setReductionColPosition] = useState();
  const [speedColPosition, setSpeedColPosition] = useState();
  const [showClimateAlignment, setShowClimateAlignment] = useState(true);

  const fullConfig = resolveConfig(tailwindConfig);

  const locale = useUserLocale();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profile.id}/climate-actions`);

  const getClimateActions = async () => {
    try {
      const data = await get();

      if (response.ok) {
        if (data && data.reporting && data.reporting.direct_score_data) {
          setScope12({
            percentDelta: data.reporting.direct_score_data.percent_delta,
            reduceType: data.reporting.direct_score_data.reduce_type,
            startYear: data.reporting.direct_score_data.start_year,
            endYear: data.reporting.direct_score_data.end_year,
            degreeScore: data.reporting.direct_score_data.degree_score,
            dataUsable: data.reporting.direct_data_usable,
            dataNotComparable: data.reporting.direct_data_not_comparable,
          });
        }
        if (data && data.reporting && data.reporting.complete_score_data) {
          setScope3({
            percentDelta: data.reporting.complete_score_data.percent_delta,
            reduceType: data.reporting.complete_score_data.reduce_type,
            startYear: data.reporting.complete_score_data.start_year,
            endYear: data.reporting.complete_score_data.end_year,
            degreeScore: data.reporting.complete_score_data.degree_score,
            dataUsable: data.reporting.complete_data_usable,
            dataNotComparable: data.reporting.complete_data_not_comparable,
          });
        }
        if (
          data
          && data.reporting
          && data.reporting.flag_score_data
          && data.reporting.flag_score_data.degree_score
          && data.reporting.flag_score_data.percent_delta
          && data.reporting.flag_score_data.reduce_type
          && data.reporting.flag_score_data.start_year
          && data.reporting.flag_score_data.end_year
        ) {
          setFlag({
            percentDelta: data.reporting.flag_score_data.percent_delta,
            reduceType: data.reporting.flag_score_data.reduce_type,
            startYear: data.reporting.flag_score_data.start_year,
            endYear: data.reporting.flag_score_data.end_year,
            degreeScore: data.reporting.flag_score_data.degree_score,
            dataUsable: data.reporting.flag_data_usable,
            dataNotComparable: data.reporting.flag_data_not_comparable,
          });
        } else {
          setFlag(null);
        }
        if (data && data.reporting) {
          setScope12Color(data.reporting.direct_data_usable || data.reporting.direct_data_not_comparable ? fullConfig.theme.colors[getCompleteColorByScore(1)] : fullConfig.theme.colors[getCompleteColorByScore(data.reporting.direct_score_data.degree_score)]);
          setScope3Color(data.reporting.complete_data_usable || data.reporting.complete_data_not_comparable ? fullConfig.theme.colors[getCompleteColorByScore(1)] : fullConfig.theme.colors[getCompleteColorByScore(data.reporting.complete_score_data.degree_score)]);
          setFlagColor(data.reporting.flag_data_usable || data.reporting.flag_data_not_comparable ? fullConfig.theme.colors[getCompleteColorByScore(1)] : fullConfig.theme.colors[getCompleteColorByScore(data.reporting.flag_score_data?.degree_score)]);
          if (data.reporting.note || data.reporting.note_en) {
            if (locale === 'en') {
              setNote(data.reporting.note_en);
            } else {
              setNote(data.reporting.note);
            }
          }
          if (data.reporting.flag_perimeter) {
            setFlagPerimeter(data.reporting.flag_perimeter);
          }
          if (data.reporting.complete_score_data?.degree_score === 0 || data.reporting.direct_score_data?.degree_score === 0 || data.reporting.flag_score_data?.degree_score === 0) {
            setShowClimateAlignment(false);
            setReductionColPosition('col-start-5 col-span-1');
            setSpeedColPosition('col-start-7 col-span-1');
          } else {
            setShowClimateAlignment(true);
            setReductionColPosition('col-start-3 col-span-1');
            setSpeedColPosition('col-start-5 col-span-1');
          }
        }
        if (profile.type !== 2) {
          const comment = getOpenClimatComment(data && data.score);

          const getCommentText = (comment) => {
            return comment;
          };

          setOpenCimatComment(getCommentText(comment));
          setScore(data && data.score);
        }

        return data;
      }

      return null;
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    getClimateActions();
  }, [profile.id, locale]);

  const blocInfo = maturityInfo.resultatsScope3;
  const levelInfo = blocInfo.levels[score < 1 ? 1 : Math.floor(score, 6)];

  return {
    openClimatComment,
    scope12,
    scope3,
    flag,
    scope12Color,
    scope3Color,
    flagColor,
    note,
    flagPerimeter,
    levelInfo,
    reductionColPosition,
    speedColPosition,
    showClimateAlignment,
  };
};

export default useClimateActions;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SBTI from '../../../../assets/ui/SBTI.svg';
import CDP from '../../../../assets/ui/CDP.svg';
import info from '../../../../assets/ui/i-info.svg';

import getLabelsClimat from '../../../../utils/getLabelsClimat';

function Content({ item, resultWording, index, data, t }) {
  let wording;

  if (item.title === 'TARGET_SBT_LABEL') {
    if (item.value !== '-') {
      wording = t(resultWording[item.value]);
    } else {
      wording = item.value;
    }
  } else {
    wording = item.value;
  }

  return (
    <div key={index} className="text-sm">
      <div className="flex items-center">
        <div className="flex items-center">
          {item.title === 'CDP_SCORE_LABEL' ? (
            <img
              src={CDP}
              className="pe-3"
              alt="cdp"
            />
          ) : (
            <img
              src={SBTI}
              className="pe-3"
              alt="sbti"
            />
          )}
        </div>
        <div className="flex flex-col justify-center">
          <div className="font-normal text-greyFour mb-1 flex items-center">
            <div>
              {t(item.title)}
            </div>
            <div className="flex items-center ml-1 relative group">
              <img src={info} alt="info" />
              <div className="absolute right-6 invisible group-hover:visible min-w-[300px] bg-greyThree py-1 px-2 rounded-sm text-sm text-white z-30">
                {t(item.tooltip)}
              </div>
            </div>
          </div>
          <div className="font-medium">
            {wording}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        {index < data.length - 1 && <hr className="w-1/2 border-t-0 border-x-0 border-greySeven my-4" />}
      </div>
    </div>
  );
}

function LabelsClimat({ profile }) {
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  // const netZeroLabel = [
  //   'RESULT_NET_ZERO_1',
  //   'RESULT_NET_ZERO_2',
  // ];

  const resultWording = [
    '-',
    'RESULT_WORDING_1',
    'RESULT_WORDING_2',
    'RESULT_WORDING_3',
    'RESULT_WORDING_4',
  ];

  useEffect(() => {
    getLabelsClimat(profile.id).then((response) => {
      const items = [
        {
          title: 'TARGET_SBT_LABEL',
          value: response.target_sbt ? response.target_sbt : '-',
          tooltip: 'TARGET_SBT_TOOLTIP',
        },
        {
          title: 'CDP_SCORE_LABEL',
          value: response.scope_cdp ? response.scope_cdp : '-',
          tooltip: 'CDP_SCORE_LABEL_TOOLTIP',
        },
      ];
      setData(items);
    });
  }, [profile]);

  return (
    <div className="bg-white mb-4 p-4 border border-solid border-greySix rounded">
      <h1 className="text-greyOne text-base flex justify-start mb-3">
        {t('MORE_INFO_TITLE')}
      </h1>
      <div className="flex flex-col">
        {data.map((item, index) => (
          <Content
            key={index}
            index={index}
            data={data}
            item={item}
            resultWording={resultWording}
            t={t}
          />
        ))}
      </div>
    </div>
  );
}

export default LabelsClimat;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@Openclimat/openclimat-component-library';
import { countries } from 'countries-list';

import exportWhiteIcon from '../../../assets/ui/export-white.svg';

import SbtiDistribution from './SbtiDistribution/SbtiDistribution';
import StatisticsFilter from './Filter/StatisticsFilter';
import useCategories from '../../../hooks/useCategories';
import verifyAuthorization from '../../../utils/verifyAuthorization';
import Features from '../../../features.json';
import SbtiDistributionEvolution from './SbtiDistributionEvolution/SbtiDistributionEvolution';
import EmissionsEvolution from './EmissionsEvolution/EmissionsEvolution';

function Statistics() {
  const [isOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedStatusEvolution, setSelectedStatusEvolution] = useState(null);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedWeight, setSelectedWeight] = useState(null);

  const { partner, session, features } = useOutletContext();

  const showDemoScreen = (features.includes(Features.mockedDemoScreens) && verifyAuthorization(session, ['ROLE_ADMIN']));

  const { t } = useTranslation();

  const {
    categories,
    selectedCategoryId,
    setSelectedCategoryId,
    getCategoryName,
    removeCategory,
  } = useCategories(partner.id);

  const sortedLevels = categories?.sort(
    (a, b) => (b.level?.[0]?.position || 0) - (a.level?.[0]?.position || 0),
  ) || [];

  // On format les tags pour qu'ils conviennent au format du Dropdown
  const formattedTags = partner?.tags?.map((tag) => ({
    name: tag.name,
    value: tag.id,
  })) || [];

  const formattedEuCountries = Object.values(countries)
    .filter((country) => country.continent === 'EU')
    .map((country) => ({
      name: country.name,
      value: country.name,
    })) || [];

  const formattedWeights = [
    {
      name: 'Nombre de fournisseurs',
      value: 1,
    },
    {
      name: "Chiffre d'affaires",
      value: 2,
    },
    {
      name: 'Poids carbone',
      value: 3,
    },
  ];

  return (
    <div className="w-full flex flex-col bg-greyEight">
      <div className="w-full h-full py-[42px] px-[50px]">
        <h2 className="text-2xl mb-[30px]">{t('PARTNER_DASHBOARD.STATISTICS.HEADER')}</h2>
        <div className="w-full flex flex-col lg:flex-row justify-between">
          <StatisticsFilter
            levels={sortedLevels}
            selectedCategoryId={selectedCategoryId}
            onCategoryChange={setSelectedCategoryId}
            getCategoryName={getCategoryName}
            onRemoveCategory={removeCategory}
            tags={formattedTags}
            selectedTag={selectedTagId}
            handleTagsFilter={setSelectedTagId}
            countries={formattedEuCountries}
            selectedCountry={selectedCountry}
            handleCountryFilter={setSelectedCountry}
            weights={formattedWeights}
            selectedWeight={selectedWeight}
            handleWeightFilter={setSelectedWeight}
            demoIsEnabled={showDemoScreen}
          />
          {showDemoScreen && (
            <Button
              styleType="primary"
              color="blue"
              className="max-h-16 lg:max-h-11 md:w-48 lg:min-w-60 text-sm mb-4"
              trailingPicto={exportWhiteIcon}
            >
              {t('PARTNER_DASHBOARD.STATISTICS.EXPORT')}
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-5 max-w-[1250px]">
          <div className="flex gap-5 flex-col xl:flex-row">
            {showDemoScreen && (
              <SbtiDistributionEvolution
                selectedCategoryId={selectedCategoryId}
                isOpen={isOpen}
                selectedStatus={selectedStatusEvolution}
                setSelectedStatus={setSelectedStatusEvolution}
                selectedTagId={selectedTagId}
                demoIsEnabled={showDemoScreen}
              />
            )}
            <SbtiDistribution
              selectedCategoryId={selectedCategoryId}
              getCategoryName={getCategoryName}
              isOpen={isOpen}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedTagId={selectedTagId}
              demoIsEnabled={showDemoScreen}
            />
          </div>
          {showDemoScreen && (
            <div className="flex gap-5 flex-col lg:flex-row">
              <EmissionsEvolution
                selectedCategoryId={selectedCategoryId}
                isOpen={isOpen}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                selectedTagId={selectedTagId}
                demoIsEnabled={showDemoScreen}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Statistics;

import React, { useState, useRef, useEffect } from 'react';

import dropdownArrow from '../../../../assets/ui/caret-down-s.svg';

function CategoryFilter({
  categories,
  filteredCategory,
  onCategoryChange,
  isFilterDisabled,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    if (!isFilterDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleCategorySelect = (category) => {
    onCategoryChange(category);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className="relative rounded-sm w-full mb-2">
      {/* Dropdown Button */}
      <button
        type="button"
        disabled={isFilterDisabled}
        className={`relative max-h-10 h-10 border border-solid border-greySix text-greyFour font-moskauGrotesk text-sm px-4 w-full flex justify-between items-center ${
          isOpen ? 'rounded-t-[4px] ring-2 ring-secondarySkyBlue ring-opacity-50' : 'rounded-[4px]'
        } bg-white`}
        onClick={handleToggle}
      >
        <div className="transition-all duration-600 flex flex-col items-start">
          <div className="transition-all duration-200 text-greyOne line-clamp-1 text-left">
            {filteredCategory ? filteredCategory.name : ''}
          </div>
        </div>
        {!isFilterDisabled && (
          <img
            src={dropdownArrow}
            className={`${isOpen ? 'rotate-180' : ''} duration-500 transition-all`}
            alt="Dropdown arrow"
          />
        )}
      </button>

      {/* Dropdown Content - Only render if not disabled */}
      {!isFilterDisabled && (
        <div
          className={`absolute top-[42px] -ml-[2px] -mr-[2px] w-[calc(100%+4px)] z-50 transition-all duration-200 overflow-hidden bg-white border border-t-0 border-solid border-greySix rounded-b-[4px] shadow-md ${
            isOpen ? 'visible max-h-[400px]' : 'invisible max-h-0'
          } pb-[5px] font-moskauGrotesk font-medium`}
        >
          <div className="flex flex-col text-xs">
            {categories.map((category, index) => (
              <button
                key={category.id}
                type="button"
                onClick={() => handleCategorySelect(category)}
                className={`px-4 py-3 w-full flex justify-between items-center text-xs text-left font-moskauGrotesk border-t-0 border-x-0 border-solid border-b-greySeven text-greyTwo bg-white hover:bg-greyEight ${
                  index === categories.length - 1 ? 'border-b-0' : 'border-b'
                }`}
              >
                <span className="text-sm text-primaryNewBlue font-medium line-clamp-1">
                  {category.name}
                </span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CategoryFilter;

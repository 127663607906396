import React from 'react';
import { useTranslation } from 'react-i18next';
import CampaignSummaryCard from './CampaignSummaryCard';
import suppliersList from './fakeSuppliersList';
import HeadphonesButton from './HeadphonesButton';

import check from '../../../assets/ui/check-gray-black.svg';
import hourglass from '../../../assets/ui/hourglass.svg';
import prohibit from '../../../assets/ui/prohibit.svg';

import './CampaignTable.scss';

function Campaign() {
  const { t } = useTranslation();

  const getStatusDisplay = (status) => {
    switch (status) {
      case 1:
        return (
          <span className="bg-noteTresForte/10 py-2 px-4 text-xs text-noteTresForte font-inter font-medium rounded-full">
            {t('PARTNER_DASHBOARD.CAMPAIGN.STATUS.QUESTIONNAIRE_ANSWERED')}
          </span>
        );
      case 2:
        return (
          <span className="bg-noteEncoreInsufissante/10 py-2 px-4 text-xs text-noteEncoreInsufissante font-inter font-medium rounded-full">
            {t('PARTNER_DASHBOARD.CAMPAIGN.STATUS.QUESTIONNAIRE_IN_PROGRESS')}
          </span>
        );
      case 3:
        return (
          <span className="bg-noteTresInsuffisante/10 py-2 px-4 text-xs text-noteTresInsuffisante font-inter font-medium rounded-full">
            {t('PARTNER_DASHBOARD.CAMPAIGN.STATUS.NOT_JOINED_OC')}
          </span>
        );
      default:
        return null;
    }
  };

  const getConnexionDisplay = (connexion) => {
    switch (connexion) {
      case 1:
        return t('PARTNER_DASHBOARD.CAMPAIGN.TABLE.YES');
      case 2:
        return t('PARTNER_DASHBOARD.CAMPAIGN.TABLE.NO');
      default:
        return null;
    }
  };

  const getActionsDisplay = (action) => {
    switch (action) {
      case 1:
        return t('PARTNER_DASHBOARD.CAMPAIGN.TABLE.SHOW_RESULTS');
      default:
        return null;
    }
  };

  return (
    <div className="w-full min-h-[calc(100vh-60px)] flex flex-col p-7 xl:p-10 bg-backgroundCleanBlue">
      <span className="font-inter text-xs text-greyFour">
        {t('PARTNER_DASHBOARD.CAMPAIGN.SUB_TITLE')}
      </span>
      <div className="flex items-end justify-between gap-x-3 mt-2.5">
        <div className="flex items-center gap-x-[6px]">
          <span className="text-3xl text-greyOne font-inter font-medium">
            {t('PARTNER_DASHBOARD.CAMPAIGN.TITLE')}
          </span>
          <span className="bg-noteTresForte/10 p-1.5 text-xs whitespace-nowrap text-noteTresForte font-inter font-medium rounded">
            {t('PARTNER_DASHBOARD.CAMPAIGN.IN_PROGRESS')}
          </span>
        </div>
        <div className="flex gap-x-8">
          <div className="flex flex-col items-center justify-between font-inter">
            <span className="text-xs text-center text-greyFour">
              {t('PARTNER_DASHBOARD.CAMPAIGN.START_DATE')}
            </span>
            <span className="text-greyOne font-bold">31/03/2024</span>
          </div>
          <div className="flex flex-col items-center justify-between font-inter">
            <span className="text-xs text-center text-greyFour">
              {t('PARTNER_DASHBOARD.CAMPAIGN.END_DATE')}
            </span>
            <span className="text-greyOne font-bold">31/05/2024</span>
          </div>
        </div>
      </div>
      <div className="flex items-stretch gap-x-3 font-inter mt-6">
        <CampaignSummaryCard
          percentage="45%"
          title={t('PARTNER_DASHBOARD.CAMPAIGN.COMPLETED_FORM')}
          icon={check}
        />
        <CampaignSummaryCard
          percentage="23%"
          title={t('PARTNER_DASHBOARD.CAMPAIGN.IN_PROGRESS_FORM')}
          icon={hourglass}
        />
        <CampaignSummaryCard
          percentage="32%"
          title={t('PARTNER_DASHBOARD.CAMPAIGN.NOT_JOINED_OC')}
          linkName={`${t(
            'PARTNER_DASHBOARD.CAMPAIGN.NEXT_REMINDER',
          )} 31/04/2024`}
          icon={prohibit}
        />
      </div>
      <div className="relative max-h-full bg-white border-[1px] border-solid border-greySeven rounded py-6 px-6 mt-3.5 font-inter shadow-sm">
        <div className="table-wrapper">
          <table className="full-width-table border-t border-greySeven">
            <thead>
              <tr className="bg-greyEight shadow-sm">
                <th className="text-xs text-greyOne font-normal border-r border-b border-greySeven first:border-l">
                  {t('PARTNER_DASHBOARD.CAMPAIGN.TABLE.SUPPLIER')}
                </th>
                <th className="text-xs text-greyOne font-normal border-r border-b border-greySeven">
                  {t('PARTNER_DASHBOARD.CAMPAIGN.TABLE.CONNECTION')}
                </th>
                <th className="text-xs text-greyOne font-normal border-r border-b border-greySeven">
                  {t('PARTNER_DASHBOARD.CAMPAIGN.TABLE.STATUS')}
                </th>
                <th className="text-xs text-greyOne font-normal border-r border-b border-greySeven">
                  {t('PARTNER_DASHBOARD.CAMPAIGN.TABLE.LAST_REMINDER_DATE')}
                </th>
                <th className="text-xs text-greyOne font-normal border-r border-b border-greySeven">
                  {t('PARTNER_DASHBOARD.CAMPAIGN.TABLE.ACTIONS')}
                </th>
              </tr>
            </thead>
            <tbody>
              {suppliersList.map((supplier) => (
                <tr key={supplier.id}>
                  <td className="border-r border-b border-greySeven first:border-l">
                    <div className="supplier-cell-content">
                      {supplier.image && (
                        <img
                          src={supplier.image}
                          alt={`Logo ${supplier.name}`}
                          className="supplier-image border border-solid border-greySeven rounded-full"
                        />
                      )}
                      <span className="supplier-name font-poppins text-sm text-greyOne">
                        {supplier.name}
                      </span>
                    </div>
                  </td>
                  <td className="border-r border-b border-greySeven font-poppins text-sm text-greyDarkHeavy">
                    {getConnexionDisplay(supplier.connexion)}
                  </td>
                  <td className="border-r border-b border-greySeven">
                    {getStatusDisplay(supplier.statut)}
                  </td>
                  <td className="border-r border-b border-greySeven font-poppins text-sm text-greyDarkHeavy">
                    {supplier.last_relaunch_date || '-'}
                  </td>
                  <td className="border-r border-b border-greySeven">
                    <button
                      type="button"
                      className="font-poppins text-xs font-medium text-primaryNewBlue underline"
                    >
                      {getActionsDisplay(supplier.actions)}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="absolute right-6 -bottom-4">
          <HeadphonesButton />
        </div>
      </div>
    </div>
  );
}

export default Campaign;

import React from 'react';
import { useTranslation } from 'react-i18next';
import tempGraph from '../../../../../assets/temp/graph-intensity.svg';

function SupplierPartnerCarbonMonitoring({ partner, profile }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col bg-white p-6 mb-4 border border-solid border-greySix rounded">
      <h2 className="text-greyOne font-moskauGrotesk text-base">
        {t('SUPPLIER_PARTNER_CARBON_MONITORING.TITLE', {
          profileName: profile.name,
          partnerName: partner.name,
        })}
      </h2>
      <span className="text-xs font-inter text-greyFour mb-7">
        {t('SUPPLIER_PARTNER_CARBON_MONITORING.DETAILS')}
      </span>
      <div className="flex max-[1239px]:flex-col max-[1239px]:gap-y-6">
        <div className="w-full min-[1240px]:w-3/5 max-[1239px]:border-b-[1px] max-[1239px]:pb-2 min-[1240px]:border-r-[1px] border-solid border-greySix">
          <img src={tempGraph} alt="graph" />
        </div>
        <div className="w-full min-[1240px]:w-2/5 flex flex-col gap-y-3.5 justify-center min-[1240px]:px-4">
          <div className="flex items-center gap-x-1.5">
            <div className="min-w-3.5 min-h-3.5 flex items-center justify-center rounded-full border-[1px] border-solid border-greySix">
              <div className="min-w-2 min-h-2 bg-[#223A96] rounded-full" />
            </div>
            <span className="text-xs font-inter text-greyOne">{t('SUPPLIER_PARTNER_CARBON_MONITORING.LEGEND.INTENSITY', { profileName: profile.name, partnerName: partner.name })}</span>
          </div>
          <div className="flex items-center gap-x-1.5">
            <div className="min-w-[18px] h-[1px] bg-[#F68F57]" />
            <span className="text-xs font-inter text-greyOne">{t('SUPPLIER_PARTNER_CARBON_MONITORING.LEGEND.TRAJECTORY')}</span>
          </div>
          <div className="flex items-center gap-x-1.5">
            <div className="min-w-[18px] border-t border-dashed border-[#F68F57]" />
            <span className="text-xs font-inter text-greyOne">{t('SUPPLIER_PARTNER_CARBON_MONITORING.LEGEND.OBJECTIVE')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierPartnerCarbonMonitoring;

import React, { useContext, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function CarbonWeightHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const SORT_NAME = 'partner_carbon_weight';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 px-3 font-medium cursor-pointer border-solid border-greySix border border-b-0" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="flex justify-center">
        <div className="flex flex-col justify-center">
          <div className="flex items-center">
            {t('CARBON_WEIGHT_AT')} {partner.name}
            {filter === SORT_NAME ? (
              <SortArrow sort={sort} />
            ) : (
              <span className="text-end ml-2.5 ">
                <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
              </span>
            )}
          </div>
        </div>
      </div>
    </th>
  );
}

function CarbonWeightBody({ profile, cellStyle, onClick }) {
  const { i18n } = useTranslation();

  return (
    <td className="h-[60px] border-solid border-greySix border" style={cellStyle}>
      <Link to={onClick(profile)} className="px-3 w-full h-full flex justify-center items-center no-underline text-greyOne">
        <div className="text-sm font-moskauGrotesk font-normal justify-center flex">
          <div>
            {profile.carbon_weight ? `${(profile.carbon_weight / 1000).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ktCO2e` : (
              <img src={minus} alt="minus" />
            )}
          </div>
        </div>
      </Link>
    </td>
  );
}

export { CarbonWeightHead, CarbonWeightBody };

import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import isProductionEnvironement from '../../../utils/isProductionEnvironement';

import pdfLogo from '../../../assets/ui/pdf-logo.svg';
import threeDots from '../../../assets/ui/three-dots.svg';

function ResourceCard({ resource, onDelete, onEdit, openMenuId, setOpenMenuId }) {
  const [searchParams] = useSearchParams();
  const cardRef = useRef(null);
  const menuRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !cardRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenMenuId]);

  const handleToggleMenu = (e) => {
    e.stopPropagation();
    setOpenMenuId(openMenuId === resource.id ? null : resource.id);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(resource.id, resource.attachment_id);
    setOpenMenuId(null);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(resource);
    setOpenMenuId(null);
  };

  const handleCardInteraction = (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && (e.key === 'Enter' || e.key === ' '))) {
      e.preventDefault();
      window.open(`/resource-preview/${resource.id}${!isProductionEnvironement() ? `?partner=${searchParams.get('partner')}` : ''}`, '_blank', 'rel=noopener noreferrer');
    }
  };

  return (
    <div
      ref={cardRef}
      className="relative w-40 min-h-[150px] bg-white flex flex-col items-center justify-between rounded border border-solid border-blueBorder p-4 cursor-pointer"
      onClick={handleCardInteraction}
      onKeyDown={handleCardInteraction}
      role="button"
      tabIndex="0"
      aria-label={`Open PDF: ${resource.file_name}`}
    >
      <button
        type="button"
        className="absolute top-1 right-0 cursor-pointer p-2 flex items-center justify-center border-none bg-white"
        onClick={handleToggleMenu}
        aria-label="Toggle menu"
      >
        <img src={threeDots} alt="" aria-hidden="true" />
      </button>
      {openMenuId === resource.id && (
      <div ref={menuRef} className="absolute -right-20 -top-2 z-50">
        <div className=" flex flex-col px-[3px] py-2 rounded shadow-resourcePdfShadow bg-white ">
          <button
            type="button"
            className="text-greyThree text-sm font-medium font-moskauGrotesk bg-white hover:bg-greyEight border-none text-left px-[7px] py-[2px] rounded-sm transition-transform duration-150 z-10"
            onClick={handleEdit}
          >
            {t('PARTNER_DASHBOARD.RESOURCES.EDIT_BUTTON')}
          </button>
          <button
            type="button"
            className="text-greyThree text-sm font-medium font-moskauGrotesk mt-2 bg-white hover:bg-greyEight border-none text-left px-[7px] py-[2px] rounded-sm transition-transform duration-150"
            onClick={handleDelete}
          >
            {t('PARTNER_DASHBOARD.RESOURCES.DELETE_BUTTON')}
          </button>
        </div>
        <div className="absolute -left-[6px] top-4 w-3 h-3 rotate-45 z-50 bg-white" />
      </div>
      )}
      <img src={pdfLogo} alt="" aria-hidden="true" />
      <span className="w-full text-xs text-greyTwo overflow-hidden text-ellipsis line-clamp-2 webkit-line-clamp-2 webkit-box mb-1" style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical' }}>
        {resource.file_name}
      </span>
      <span className="w-full text-[10px] text-greyFour overflow-hidden text-ellipsis whitespace-nowrap">
        {resource.category_name}
      </span>
    </div>
  );
}

export default ResourceCard;

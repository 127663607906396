import React from 'react';
import { useTranslation } from 'react-i18next';

import logoLeclerc from '../../../assets/ui/landingPartner/logo-leclerc.svg';
import logoAuchanRetail from '../../../assets/ui/landingPartner/logo-auchan-retail.svg';
import logoCasino from '../../../assets/ui/landingPartner/logo-casino.svg';
import logoLoreal from '../../../assets/ui/landingPartner/logo-loreal.svg';
import logoKellanova from '../../../assets/ui/landingPartner/logo-kellanova.svg';
import logoMcCormick from '../../../assets/ui/landingPartner/logo-mc-cormick.svg';
import logoNutritionSante from '../../../assets/ui/landingPartner/logo-nutrition-sante.svg';
import logoHenkel from '../../../assets/ui/landingPartner/logo-henkel.svg';
import logoBel from '../../../assets/ui/landingPartner/logo-bel.svg';
import logoJacquet from '../../../assets/ui/landingPartner/logo-jacquet.svg';
import logoMixBuffet from '../../../assets/ui/landingPartner/logo-mix-buffet.svg';
import logoBeiersdof from '../../../assets/ui/landingPartner/logo-beiersdof.svg';
import logoBarilla from '../../../assets/ui/landingPartner/logo-barilla.svg';
import logoLabeyrie from '../../../assets/ui/landingPartner/logo-labeyrie.svg';
import logoSodebo from '../../../assets/ui/landingPartner/logo-sodebo.svg';
import check from '../../../assets/ui/landingPartner/check.svg';

function About() {
  const { t } = useTranslation();

  return (
    <section className="bg-white">
      <div className="container mx-auto py-20 2xl:p-20 flex gap-20 flex-col 2xl:flex-row">
        <div className="w-full flex flex-col 2xl:flex-row order-2 2xl:order-none px-2.5 2xl:px-0">
          <div className="w-full 2xl:w-1/4 p-[30px] 2xl:px-4 2xl:py-[90px] text-center bg-[#FBFBFB]">
            <div className="text-greyFour text-xs">
              {t('LANDING_LECLERC.ABOUT.DISTRIBUTORS')}
            </div>
            <div className="flex items-center 2xl:gap-9 2xl:block mt-6 2xl:mt-0 justify-between">
              <div className="2xl:mt-[52px] justify-self-center">
                <img src={logoLeclerc} alt="Logo Leclerc" className="w-3/4 sm:w-max" />
              </div>
              <div className="2xl:mt-[58px] justify-self-center">
                <img src={logoAuchanRetail} alt="Logo Auchan Retail" className="w-3/4 sm:w-max" />
              </div>
              <div className="2xl:mt-[78px] justify-self-center">
                <img src={logoCasino} alt="Logo Casino" className="w-3/4 sm:w-max" />
              </div>
            </div>
          </div>
          <div className="w-full 2xl:w-3/4 mt-[30px] 2xl:mt-0">
            <div className="w-full 2xl:mt-[90px] px-9">
              <div className="text-greyFour text-xs text-center 2xl:text-left">
                {t('LANDING_LECLERC.ABOUT.SUPPLIERS')}
              </div>
            </div>
            <div className="flex w-full mt-9">
              <div className="w-full flex flex-col px-9">
                <div className="flex justify-center mt-2">
                  <img src={logoLoreal} alt="Logo Loréal" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-[60px]">
                  <img src={logoKellanova} alt="Logo Kellanova" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-[60px]">
                  <img src={logoMcCormick} alt="Logo McCormick" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-16">
                  <img src={logoNutritionSante} alt="Logo Nutrition & Santé" className="w-3/4 sm:w-max" />
                </div>
              </div>
              <div className="w-full flex flex-col px-9">
                <div className="flex justify-center">
                  <img src={logoHenkel} alt="Logo Henkel" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-[50px]">
                  <img src={logoBel} alt="Logo Bel" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-[50px]">
                  <img src={logoJacquet} alt="Logo Jacquet Brossard" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-[50px]">
                  <img src={logoMixBuffet} alt="Logo Mix Buffet" className="w-3/4 sm:w-max" />
                </div>
              </div>
              <div className="w-full flex flex-col px-9">
                <div className="flex justify-center mt-1">
                  <img src={logoBeiersdof} alt="Logo Beiersdof" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-[65px]">
                  <img src={logoBarilla} alt="Logo Barilla" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-[65px]">
                  <img src={logoLabeyrie} alt="Logo Labeyrie Fine Foods" className="w-3/4 sm:w-max" />
                </div>
                <div className="flex justify-center mt-[65px]">
                  <img src={logoSodebo} alt="Logo Sodebo" className="w-3/4 sm:w-max" />
                </div>
              </div>
            </div>
            <div className="text-greyThree text-[10px] mt-7 text-center">
              {t('LANDING_LECLERC.ABOUT.TEXT_H')}
            </div>
          </div>
        </div>
        <div className="w-full text-primaryNewBlue px-[30px] 2xl:px-0">
          <h5 className="text-3xl font-bold text-leclercOrange text-center 2xl:text-left">
            {t('LANDING_LECLERC.ABOUT.TITLE')}
          </h5>
          <div className="mt-10 text-2xl font-medium">
            {t('LANDING_LECLERC.ABOUT.TEXT_A')}
          </div>
          <div className="mt-5">
            {t('LANDING_LECLERC.ABOUT.TEXT_B')}
          </div>
          <div className="mt-5">
            <div className="flex gap-2.5">
              <img src={check} alt="Check" />
              <div className="pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_C')}
              </div>
            </div>
            <div className="flex gap-2.5 mt-2.5">
              <img src={check} alt="Check" />
              <div className="pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_D')}
              </div>
            </div>
            <div className="flex gap-2.5 mt-2.5">
              <img src={check} alt="Check" />
              <div className="pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_E')}
              </div>
            </div>
            <div className="flex gap-2.5 mt-2.5">
              <img src={check} alt="Check" />
              <div className="pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_F')}
              </div>
            </div>
            <div className="flex gap-2.5 mt-2.5">
              <img src={check} alt="Check" className="self-start mt-1" />
              <div className="pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_G')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

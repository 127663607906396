import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import Question from './Question';

function QuestionContainer({ profile, partner, questions, hasWriteAccess, fetchData, view, setData, setItemToDelete }) {
  const [checked, setChecked] = useState(false);

  const updatePublishing = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profile.id}/private-questions/publishing`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { t } = useTranslation();

  const handleCheck = async () => {
    setChecked(!checked);
    const data = {};
    data.questions = questions;
    data.check = !checked;
    await updatePublishing.post('', data);
    fetchData();
  };

  return (
    <section id={partner.name} className="question-container">
      <div className="p-4 xl:p-6 white-bg mb-6 rounded border border-greySix">
        {hasWriteAccess ? (
          <>
            <div className="flex justify-between h-full">
              {checked ? (
                <div>
                  <div className="text-sm grey-L-color">
                    {t('PRIVATE_ANSWERS.QUESTIONS.TITLE_PUBLISHED')}
                  </div>
                  <div className="text-xs grey-L-color">
                    {t('PRIVATE_ANSWERS.QUESTIONS.SUBTITLE_PUBLISHED')}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="text-sm0 grey-L-color">
                    {t('PRIVATE_ANSWERS.QUESTIONS.TITLE_NOT_PUBLISHED')}
                  </div>
                  <div className="text-xs grey-L-color">
                    {t('PRIVATE_ANSWERS.QUESTIONS.SUBTITLE_NOT_PUBLISHED')}
                  </div>
                </div>
              )}
              <div className="form-check form-switch ms-5 mb-0 flex items-center">
                {updatePublishing.loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className={`w-[50px] flex items-center ${
                      checked ? 'justify-end bg-secondarySkyBlue' : 'justify-start bg-greySix'
                    } p-[4px] rounded-[30px]`}
                    onClick={handleCheck}
                  >
                    <div className="w-[18px] h-[18px] bg-white rounded-full" />
                  </button>
                )}
              </div>
            </div>
            <hr className="mt-4 mb-2" />
          </>
        ) : (
          ''
        )}
        <h3 className="flex items-center gap-2">
          <img src={process.env.REACT_APP_FILES_DOMAIN + partner.logo_header} alt={`Logo ${partner.name}`} height={30} className="max-h-[30px]" />
          <span className="text-base">
            {t('PRIVATE_ANSWERS.QUESTIONS.FROM')} {partner.name}
          </span>
        </h3>
        <div className="flex flex-col gap-6">
          {questions.map((question, index) => (
            <Question
              key={index}
              index={index}
              question={question}
              profile={profile}
              partner={partner}
              hasWriteAccess={hasWriteAccess}
              setChecked={setChecked}
              view={view}
              fetchData={fetchData}
              setData={setData}
              setItemToDelete={setItemToDelete}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default QuestionContainer;

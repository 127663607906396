import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { useSearchParams } from 'react-router-dom';

import downArrow from '../../../assets/ui/arrow-down-white.svg';

import TableFeatures from '../../../tableFeatures.json';
import getPartnerTableFeatures from '../../../utils/getPartnerTableFeatures';
import MobileCard from './MobileCard';
import Table from './Table';
import { TableLoadingContext } from '../../../context/TableLoadingProvider';
import useViewport from '../../../hooks/useViewport';
import Status from '../../Dashboard/Suppliers/Filters/Status';
import Sbti from '../../Dashboard/Suppliers/Filters/Sbti';
import SbtiSimple from '../../Dashboard/Suppliers/Filters/SbtiSimple';
import CurrentProgress from '../../Dashboard/Suppliers/Filters/CurrentProgress';
import ActionPlanCompleted from '../../Dashboard/Suppliers/Filters/ActionPlanCompleted';
import Tags from '../../Dashboard/Suppliers/Filters/Tags';
import tableComponents from './tableComponents';
import DataExportButton from '../../Public/Profile/Pages/SpecificData/DataExportButton';
import Pagination from './Pagination';

import './Database.scss';

function Database({ partner, hasWriteAccess, filteredCategory, isDashboard, isDemoScreen }) {
  const [cellStyle, setCellStyle] = useState({ width: '200px', minWidth: '200px', maxWidth: '200px' });
  const [maxTableWidthStyle, setMaxTableWidthStyle] = useState('100%');
  const { loading, setLoading, setNavigationLoading } = useContext(TableLoadingContext);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [profileStatusList, setProfileStatusList] = useState();
  const [profiles, setProfiles] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sort, setSort] = useState(searchParams.get('sort'));
  const [filter, setFilter] = useState(searchParams.get('filter'));
  const [tagsFilter, setTagsFilter] = useState(searchParams.get('tags_filter'));
  const [sectorFilter, setSectorFilter] = useState(searchParams.get('sf'));
  const [categoryFilter, setCategoryFilter] = useState(searchParams.get('cf'));
  const [sbtiFilter, setSbtiFilter] = useState(searchParams.get('sbti_filter'));
  const [sbtiFilterSimple, setSbtiFilterSimple] = useState(searchParams.get('sbti_filter_simple'));
  const [statusFilter, setStatusFilter] = useState(searchParams.get('status_filter'));
  const [currentProgressFilter, setCurrentProgressFilter] = useState(searchParams.get('current_progress_filter'));
  const [actionPlanFilter, setActionPlanFilter] = useState(searchParams.get('action_plan_filter'));
  const [total, setTotal] = useState(0);
  const [tableComponent, setTableComponent] = useState();
  const [tableComponentHead, setTableComponentHead] = useState();
  const [edit, setEdit] = useState([]);
  const [features, setFeatures] = useState();
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const latestRequestIdRef = useRef(0);

  const { isMobile } = useViewport();

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: hasWriteAccess ? 'no-cache' : 'cache-first',
  });

  const getProfiles = async (localSort, localFilter, sectorFilter, categoryFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, localPage, showMore = false) => {
    const requestId = latestRequestIdRef.current + 1;
    latestRequestIdRef.current = requestId;

    try {
      if (searchParams.get('sort') && searchParams.get('filter')) {
        setLoading(true);

        await get(`/partner/${partner.id}/profiles-complete?page=${localPage}${localFilter ? `&filter=${filter}` : ''}${localSort ? `&sort=${sort}` : ''}${sectorFilter ? `&sector_filter=${sectorFilter}` : ''}${categoryFilter ? `&category_filter=${categoryFilter}` : ''}${sbtiFilter ? `&sbti_filter=${sbtiFilter}` : ''}${sbtiFilterSimple ? `&sbti_filter_simple=${sbtiFilterSimple}` : ''}${statusFilter ? `&status_filter=${statusFilter}` : ''}${currentProgressFilter ? `&current_progress_filter=${currentProgressFilter}` : ''}${actionPlanFilter ? `&action_plan_filter=${actionPlanFilter}` : ''}${tagsFilter ? `&tags_filter=${tagsFilter}` : ''}&domain=${partner.domain}`);

        if (requestId === latestRequestIdRef.current) {
          if (response.ok && response.data && response.data.profiles) {
            setTotal(response.data.total);
            const editArray = [];
            response.data.profiles.forEach((profile) => {
              editArray.push({ id: profile.id, edit: false });
            });

            setTotalPages(Math.ceil(response.data.total / process.env.REACT_APP_RESULT_PER_PAGE));

            setProfiles(response.data.profiles);
            setEdit(editArray);

            if (showMore) {
              setProfiles([...profiles, ...response.data.profiles]);
              setEdit([...edit, ...editArray]);
            } else {
              setProfiles(response.data.profiles);
              setEdit(editArray);
            }
          } else {
            setProfiles([]);
            setTotal(0);
            setEdit([]);
          }

          setLoading(false);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const getProfileStatusList = async () => {
    try {
      await get(`/partner/${partner.id}/profile-status`);
      if (response.ok) {
        setProfileStatusList(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const togglePage = (page) => {
    searchParams.set('p', page);
    setSearchParams(searchParams);
    document.getElementById('table-container').scrollIntoView();
    const tableContainer = document.getElementById('table-container');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
  };

  const showMore = () => {
    const localPage = page + 1;
    setPage(localPage);
    getProfiles(sort, filter, sectorFilter, categoryFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, localPage, true);
  };

  useEffect(() => {
    const localPage = searchParams.get('p') || 0;
    setPage(parseInt(localPage, 10));
    setSectorFilter(searchParams.get('sf'));
    setCategoryFilter(searchParams.get('cf'));
    setSbtiFilter(searchParams.get('sbti_filter'));
    setSbtiFilterSimple(searchParams.get('sbti_filter_simple'));
    setStatusFilter(searchParams.get('status_filter'));
    setCurrentProgressFilter(searchParams.get('current_progress_filter'));
    setActionPlanFilter(searchParams.get('action_plan_filter'));
    setTagsFilter(searchParams.get('tags_filter'));
    getProfiles(sort, filter, searchParams.get('sf'), searchParams.get('cf'), searchParams.get('sbti_filter'), searchParams.get('sbti_filter_simple'), searchParams.get('status_filter'), searchParams.get('current_progress_filter'), searchParams.get('action_plan_filter'), searchParams.get('tags_filter'), localPage);
    getProfileStatusList();
  }, [searchParams.get('tags_filter'), searchParams.get('filter'), searchParams.get('sort'), searchParams.get('sf'), searchParams.get('cf'), searchParams.get('sbti_filter'), searchParams.get('sbti_filter_simple'), searchParams.get('status_filter'), searchParams.get('current_progress_filter'), searchParams.get('action_plan_filter'), searchParams.get('p')]);

  const containerTableRef = useRef(null);
  const tableRef = useRef(null);

  const getCellStyle = (length, containerWidth) => {
    if (windowDimensions.width < 1400) {
      return { width: '180px', minWidth: '180px', maxWidth: '180px' };
    }

    switch (length) {
      case 1:
        return { width: (containerWidth / 2) - 2, minWidth: (containerWidth / 2) - 2, maxWidth: (containerWidth / 2) - 2 };

      case 2:
        return { width: (containerWidth / 3) - 2, minWidth: (containerWidth / 3) - 2, maxWidth: (containerWidth / 3) - 2 };

      case 3:
        return { width: (containerWidth / 4) - 2, minWidth: (containerWidth / 4) - 2, maxWidth: (containerWidth / 4) - 2 };

      case 4:
        return { width: (containerWidth / 5) - 2, minWidth: (containerWidth / 5) - 2, maxWidth: (containerWidth / 5) - 2 };

      case 5:
        return { width: (containerWidth / 6) - 2, minWidth: (containerWidth / 6) - 2, maxWidth: (containerWidth / 6) - 2 };

      default:
        return { width: '200px', minWidth: '200px', maxWidth: '200px' };
    }
  };

  useEffect(() => {
    if (features) {
      setCellStyle(getCellStyle(features.length, containerTableRef?.current?.offsetWidth));
    }
    setMaxTableWidthStyle(`${tableRef?.current?.offsetWidth}px`);
  }, [features, windowDimensions, tableComponentHead]);

  useEffect(() => {
    const componentsArray = tableComponents(filter, setFilter, sort, setSort, cellStyle, partner);

    if (features) {
      const tableComponent = [];
      features?.forEach((feature) => {
        const component = componentsArray.find((component) => component.id === feature);
        if (component) {
          tableComponent.push(component.componentHead);
        }
      });

      setTableComponentHead(tableComponent);
    }
  }, [filter, sort, partner, cellStyle, features]);

  useEffect(() => {
    const getTableFeatures = async () => {
      try {
        const features = await getPartnerTableFeatures(partner.id);
        if (features.length > 0) {
          setFeatures(features);

          setTableComponent({
            tags: features.includes(TableFeatures.tags),
            turnover: features.includes(TableFeatures.turnover),
            sector: features.includes(TableFeatures.sector),
            category: features.includes(TableFeatures.category),
            climateAction: features.includes(TableFeatures.climateAction),
            scope12: features.includes(TableFeatures.scope12),
            scope3: features.includes(TableFeatures.scope3),
            objScope12: features.includes(TableFeatures.objScope12),
            objScope3: features.includes(TableFeatures.objScope3),
            targetSbt: features.includes(TableFeatures.targetSbt),
            targetSbtSimple: features.includes(TableFeatures.targetSbtSimple),
            currentProgress: features.includes(TableFeatures.currentProgress),
            scoreCdp: features.includes(TableFeatures.scoreCdp),
            status: features.includes(TableFeatures.status),
            profileRating: features.includes(TableFeatures.profileRating),
            profileGroup: features.includes(TableFeatures.profileGroup),
            actionsCompleted: features.includes(TableFeatures.actionsCompleted),
            profileTurnover: features.includes(TableFeatures.profileTurnover),
            profileCarbonWeight: features.includes(TableFeatures.profileCarbonWeight),
            scope12Real: features.includes(TableFeatures.scope12Real),
            scope3Real: features.includes(TableFeatures.scope3Real),
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    getTableFeatures();
    setNavigationLoading(true);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderContent = () => {
    if (isDashboard) {
      return (
        <Table
          profiles={profiles}
          partner={partner}
          profileStatusList={profileStatusList}
          tableComponent={tableComponent}
          filter={filter}
          setFilter={setFilter}
          sort={sort}
          setSort={setSort}
          categoryFilter={categoryFilter}
          hasWriteAccess={hasWriteAccess}
          edit={edit}
          setEdit={setEdit}
          isDashboard={isDashboard}
          loading={loading}
          total={total}
          features={features}
          getProfiles={getProfiles}
          sectorFilter={sectorFilter}
          sbtiFilter={sbtiFilter}
          sbtiFilterSimple={sbtiFilterSimple}
          statusFilter={statusFilter}
          currentProgressFilter={currentProgressFilter}
          actionPlanFilter={actionPlanFilter}
          tagsFilter={tagsFilter}
          cellStyle={cellStyle}
          containerTableRef={containerTableRef}
          tableRef={tableRef}
          tableComponentHead={tableComponentHead}
        />
      );
    }

    if (!isMobile) {
      return (
        <Table
          profiles={profiles}
          partner={partner}
          profileStatusList={profileStatusList}
          tableComponent={tableComponent}
          filter={filter}
          setFilter={setFilter}
          sort={sort}
          setSort={setSort}
          categoryFilter={categoryFilter}
          hasWriteAccess={hasWriteAccess}
          edit={edit}
          setEdit={setEdit}
          isDashboard={isDashboard}
          loading={loading}
          total={total}
          features={features}
          getProfiles={getProfiles}
          sectorFilter={sectorFilter}
          sbtiFilter={sbtiFilter}
          sbtiFilterSimple={sbtiFilterSimple}
          statusFilter={statusFilter}
          currentProgressFilter={currentProgressFilter}
          actionPlanFilter={actionPlanFilter}
          tagsFilter={tagsFilter}
          cellStyle={cellStyle}
          containerTableRef={containerTableRef}
          tableRef={tableRef}
          tableComponentHead={tableComponentHead}
        />
      );
    }

    return (
      <div className="mb-5">
        {profiles.map((profile, index) => (
          <MobileCard
            key={index}
            profile={profile}
            index={index}
            tableComponent={tableComponent}
          />
        ))}
        <nav
          id="more-items"
          className={`mx-auto w-full my-5 text-center ${(loading || (total === profiles.length) || total <= 10) ? 'hidden' : ''}`}
          aria-label="Page navigation"
        >
          <button
            type="button"
            className="flex items-center px-4 py-2 rounded text-white mx-auto"
            style={{ background: partner.primary_color }}
            onClick={showMore}
          >
            <img
              className="mr-2"
              src={downArrow}
              alt="arrow down"
            />
            {t('SHOW_MORE')}
          </button>
        </nav>
      </div>
    );
  };

  return (
    <>
      <div className="w-full flex items-end justify-between mb-[12px] 2xl:mb-[20px]">
        <h2 className="text-newBrand text-2xl font-semibold ">
          {t('SUPPLIERS')}
          <span className="text-greyHeavy text-xs ml-[10px] font-medium">
            ({total} {t('SUPPLIER')}{total > 1 && 's'} {filteredCategory && `- ${t('CATEGORY')} ${filteredCategory}`})
          </span>
        </h2>
        {isDemoScreen ? (
          <div>
            <DataExportButton />
          </div>
        ) : null}
      </div>
      {isDashboard && (tableComponent?.status || tableComponent?.targetSbt || tableComponent?.targetSbtSimple || tableComponent?.currentProgress || tableComponent?.actionsCompleted || tableComponent?.tags) ? (
        <div className="border-solid border-greySix border-b-0 border bg-backgroundCleanBlue py-2 px-3 flex flex-wrap gap-2" style={{ maxWidth: maxTableWidthStyle }}>
          <div className="flex gap-2 flex-wrap">
            {tableComponent?.status ? <Status partner={partner} /> : null}
            {tableComponent?.targetSbt ? <Sbti /> : null}
            {tableComponent?.targetSbtSimple ? <SbtiSimple /> : null}
            {tableComponent?.currentProgress ? <CurrentProgress /> : null}
            {tableComponent?.actionsCompleted ? <ActionPlanCompleted /> : null}
            {tableComponent?.tags ? <Tags tags={partner.tags} /> : null}
          </div>
        </div>
      ) : null}

      { renderContent() }
      <div className="w-full" style={{ maxWidth: maxTableWidthStyle }}>
        <Pagination
          page={page}
          togglePage={togglePage}
          totalPages={totalPages}
        />
      </div>

    </>
  );
}

export default Database;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CarbonFootprintDistributionPie({ scope12, scope3, totalCarbonFootprintValue, formattedTotalCarbonFootprintValue, data, carbonFootprintYear }) {
  const { t } = useTranslation();

  const tooltip = (e) => (
    <div
      style={{
        display: 'flex',
        background: 'white',
        alignItems: 'center',
        borderRadius: '2px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
        padding: '5px 9px',
        flexWrap: 'nowrap',
      }}
    >
      <span
        style={{
          width: 12,
          minWidth: 12,
          height: 12,
          minHeight: 12,
          backgroundColor: e.datum.color,
          display: 'block',
          marginRight: 7,
        }}
      />
      <span style={{ whiteSpace: 'nowrap' }}>
        {e.datum.id} : <strong>{e.datum.value}%</strong>
      </span>
    </div>
  );

  function CenteredText({ centerX, centerY }) {
    return (
      <svg>
        <text
          x={centerX}
          y={centerY}
        >
          {totalCarbonFootprintValue === 0
            ? (
              <>
                <tspan
                  x="50%"
                  textAnchor="middle"
                  fill="#00051B"
                  style={{
                    fontWeight: '600',
                    fontSize: '22px',
                  }}
                >
                  -- kt
                </tspan>
                <tspan
                  x="50%"
                  textAnchor="middle"
                  dy="1.2em"
                  fill="#9E9E9E"
                  style={{
                    fontWeight: '500',
                    fontSize: '12px',
                  }}
                >
                  CO2e {carbonFootprintYear || ''}
                </tspan>
              </>
            )
            : (
              <>
                <tspan
                  x="50%"
                  textAnchor="middle"
                  fill={(scope12 && scope3) ? '#00051B' : (scope12 || scope3) ? scope12 ? '#21AFFF' : '#000D85' : '#00051B'}
                  style={{
                    fontWeight: '600',
                    fontSize: '22px',
                  }}
                >
                  {formattedTotalCarbonFootprintValue}
                </tspan>
                <tspan
                  x="50%"
                  textAnchor="middle"
                  dy="1.6em"
                  fill="#9E9E9E"
                  style={{
                    fontWeight: '500',
                    fontSize: '12px',
                  }}
                >{carbonFootprintYear ? `${t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_18')} ${carbonFootprintYear}` : totalCarbonFootprintValue ? 'CO2e' : ''}
                </tspan>
              </>
            )}
        </text>
      </svg>
    );
  }

  return (
    <ResponsivePie
      data={data}
      layers={['arcs', 'arcLabels', CenteredText]}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors={(data) => data.data.color}
      innerRadius={0.6}
      padAngle={1}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLabel={(d) => `${d.value}%`}
      enableArcLinkLabels={false}
      enableArcLabels={
        data && data[0]
          ? !(data[0].id === 'Données non disponibles' || data[0].id === 'Data not available')
          : false
      }
      isInteractive={
        data && data[0]
          ? !(data[0].id === 'Données non disponibles' || data[0].id === 'Data not available')
          : false
      }
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={data.length <= 1 ? '#333333' : ({ data }) => (data.id === 'Industriel' ? '#000000' : '#ffffff')}
      theme={{
        labels: {
          text: {
            fontSize: 14,
            fontWeight: 500,
          },
        },
      }}
      tooltip={(e) => tooltip(e)}
    />
  );
}

export default CarbonFootprintDistributionPie;

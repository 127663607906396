import React, { useEffect, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function PartnerTurnoverHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const SORT_NAME = 'partner_turnover';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 px-3 font-medium cursor-pointer border-solid border-greySix border border-b-0" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="flex justify-center items-center">
        <div className="flex items-center">
          {t('TURNOVER_WITH')} {partner.name}
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end ml-2.5 self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
            </span>
          )}
        </div>
      </div>
    </th>
  );
}

function PartnerTurnoverBody({ profile, cellStyle, onClick }) {
  const { t, i18n } = useTranslation();

  return (
    <td className="h-[60px] border-solid border-greySix border" style={cellStyle}>
      <Link to={onClick(profile)} className="px-3 flex w-full h-full items-center justify-center no-underline text-greyOne">
        <div className="text-sm font-moskauGrotesk font-normal flex justify-center">
          <div>
            {profile.turnover_amount_categories ? `${(profile.turnover_amount_categories / 1000000).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${t('MILLION_€')}` : (
              <img src={minus} alt="minus" />
            )}
          </div>
        </div>
      </Link>
    </td>
  );
}

export { PartnerTurnoverHead, PartnerTurnoverBody };

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { useOutletContext } from 'react-router-dom';

import handleResponse from '../../../../utils/handleResponse';
import SuppliersList from './SuppliersList';
import MainChart from './MainChart';
import BarChartLegend from '../BarChartLegend';

function SbtiDistribution({
  selectedCategoryId,
  getCategoryName,
  isOpen,
  selectedStatus,
  setSelectedStatus,
  selectedTagId,
  demoIsEnabled,
}) {
  const [sbtiPercentageByStatus, setSbtiPercentageByStatus] = useState();
  const [totalProfileCount, setTotalProfileCount] = useState(0);
  const [profileCountsBySbtiStatus, setProfileCountsBySbtiStatus] = useState();
  const [colorsObjects, setColorsObjects] = useState();
  const [legend, setLegend] = useState();
  const [profiles, setProfiles] = useState();

  const { partner } = useOutletContext();

  const { t } = useTranslation();

  const baseUrl = `${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/sbti-stats`;
  const categoryParam = selectedCategoryId
    ? `categoryId=${selectedCategoryId}`
    : '';
  const tagParam = selectedTagId ? `tagId=${selectedTagId}` : '';
  const queryParams = [categoryParam, tagParam].filter(Boolean).join('&');
  const url = `${baseUrl}?${queryParams}`;

  const { get, response } = useFetch(url, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  const getStats = async () => {
    try {
      const result = await get();
      if (response.ok) {
        // Ajuster les pourcentages si demoIsEnabled est vrai
        const percentageByStatus = demoIsEnabled
          ? {
            ...result?.percentage_by_sbti_status,
            'Pas engagé': (result?.percentage_by_sbti_status['Pas engagé'] || 0) + (result?.percentage_by_sbti_status['Sans statut'] || 0),
            'Sans statut': 0,
          }
          : result?.percentage_by_sbti_status;

        setSbtiPercentageByStatus(percentageByStatus);
        setTotalProfileCount(result?.profile_count);
        setProfileCountsBySbtiStatus(result?.profile_count_by_sbti_status);
        setColorsObjects(result?.colors);

        // Filtrer les couleurs pour exclure "Sans statut" si demoIsEnabled est vrai
        const legendColors = demoIsEnabled
          ? Object.fromEntries(
            Object.entries(result?.colors).filter(
              ([key]) => key !== 'Sans statut',
            ),
          )
          : result?.colors;

        setLegend(legendColors);

        // Filtrer les profils ayant "Sans statut" et les remplacer par "Pas engagé" si demoIsEnabled est vrai
        const updatedProfiles = demoIsEnabled
          ? result?.profiles.map((profile) => {
            return profile.sbti_status === 'Sans statut'
              ? { ...profile, sbti_status: 'Pas engagé' }
              : profile;
          })
          : result?.profiles;

        setProfiles(updatedProfiles);
      }
    } catch (error) {
      handleResponse(error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    getStats();
  }, [selectedCategoryId, selectedTagId]);

  const filteredProfiles = useMemo(() => {
    return selectedStatus
      ? profiles?.filter((profile) => profile.sbti_status === selectedStatus)
      : profiles;
  }, [profiles, selectedStatus]);

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  const supplierCount = selectedStatus
    ? profileCountsBySbtiStatus[selectedStatus]
    : totalProfileCount;
  const supplierText = `${supplierCount} ${t(
    'PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.SUPPLIERS',
  )}${supplierCount > 1 ? 's' : ''}`;

  return (
    <div className="lg:min-w-[530px] lg:max-w-[650px] w-full flex flex-col bg-white border border-solid border-greySix rounded-md p-5 pl-5 max-[903px]:pr-5 pr-11">
      <div className="text-base font-inter font-bold text-greyOne">
        {t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.TITLE')}
        <span className="text-sm font-normal text-greyFour ml-5">
          {selectedCategoryId
            ? getCategoryName(selectedCategoryId)
            : t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.CATEGORY')}
        </span>
      </div>
      <div className="w-full flex items-center gap-x-5 mt-1">
        <div className="w-[159px] text-base text-greyThree">{supplierText}</div>
      </div>

      <div className="w-full flex max-[903px]:flex-col max-[903px]:items-center items-stretch gap-x-5 max-[903px]:gap-y-5 mt-[14px]">
        <div className="w-[200px] h-[272px]">
          <MainChart
            sbtiPercentageByStatus={sbtiPercentageByStatus}
            profileCountsBySbtiStatus={profileCountsBySbtiStatus}
            colorsObjects={colorsObjects}
            onStatusSelect={handleStatusSelect}
            selectedCategoryId={selectedCategoryId}
            selectedStatus={selectedStatus}
            isOpen={isOpen}
          />
        </div>
        <SuppliersList profilesList={filteredProfiles} />
      </div>
      <BarChartLegend
        legend={legend}
        selectedStatus={selectedStatus}
        onStatusSelect={handleStatusSelect}
        className="justify-center"
        demoIsEnabled
      />
    </div>
  );
}

export default SbtiDistribution;

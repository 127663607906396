import React, { useEffect, useState } from 'react';

import pen from '../../../../../assets/ui/pen.svg';
import trashBin from '../../../../../assets/ui/trash-bin-m.svg';

import QuestionTypeQualitative from './QuestionTypeQualitative/QuestionTypeQualitative';
import QuestionTypeCarbonDistribution from './QuestionTypeCarbonDistribution/QuestionTypeCarbonDistribution';
import QuestiontTypeQuantitative from './QuestionTypeQuantitative/QuestiontTypeQuantitative';
import useModal from '../../../../../hooks/useModal';

function Question({ profile, partner, question, isPublished, hasWriteAccess, fetchData, setShowOptional, setData, setItemToDelete }) {
  const [answer, setAnswer] = useState();
  const [noAnswerText, setNoAnswerText] = useState();
  const [show, setShow] = useState(false);

  const { open } = useModal();

  useEffect(() => {
    if (question.answer) {
      setAnswer(question.answer);
    }
    if (setShowOptional) {
      setShowOptional(true);
    }

    if (setShowOptional && hasWriteAccess === true) {
      setShowOptional(true);
    }

    setShow(true);

    if ((!isPublished || !hasWriteAccess) && partner) {
      setNoAnswerText('PROFILE_APPROACH_TEXT_2');
    } else if (profile.is_managed) {
      setNoAnswerText('PROFILE_APPROACH_TEXT_1');
    } else {
      setNoAnswerText('PROFILE_APPROACH_TEXT_2');
    }
  }, [hasWriteAccess, fetchData]);

  return (
    ((answer && answer.answer) || question.is_required || hasWriteAccess) && show ? (
      <section id={question.name} className="relative">
        <div className="border border-solid border-greySix p-3.5 bg-backgroundCleanBlue mt-7 rounded-sm text-greyOne font-medium text-sm flex justify-between gap-6">
          <span>
            {question.info} {!question.is_required && hasWriteAccess ? <span className="text-greyFour text-sm">(optionnel)</span> : ''}
          </span>
          {hasWriteAccess ? (
            <div className="flex gap-5">
              <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                <button
                  type="button"
                  onClick={() => {
                    setData(question);
                    if (question.type === 1 || question.type === null) {
                      open('question-qualitative-modal');
                    } else if (question.type === 2) {
                      open('question-quantitative-modal');
                    } else if (question.type === 3) {
                      open('question-carbon-distribution-modal');
                    }
                  }}
                >
                  <img src={pen} alt="Pen" />
                </button>
              </div>
              {question.answer?.answer ? (
                <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                  <button
                    type="button"
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setItemToDelete(question.answer?.id);
                      open('delete-confirmation-modal-questions');
                    }}
                  >
                    <img src={trashBin} alt="Trash bin" />
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="mt-6 text-sm text-greyTwo">
          {question.type === null || question.type === 1 ? (
            <QuestionTypeQualitative
              question={question}
              isPublished={isPublished}
              hasWriteAccess={hasWriteAccess}
              answer={answer}
              noAnswerText={noAnswerText}
              partner={partner}
            />
          ) : ('')}
          {question.type === 2 ? (
            <QuestiontTypeQuantitative
              question={question}
              isPublished={isPublished}
              hasWriteAccess={hasWriteAccess}
              answer={answer}
              noAnswerText={noAnswerText}
              partner={partner}
            />
          ) : ('')}
          {question.type === 3 ? (
            <QuestionTypeCarbonDistribution
              question={question}
              isPublished={isPublished}
              hasWriteAccess={hasWriteAccess}
              answer={answer}
              noAnswerText={noAnswerText}
              partner={partner}
            />
          ) : ('')}
        </div>
      </section>
    ) : ('')
  );
}

export default Question;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Content from '../Template/TableOfContents/Content';

function TableOfContents({ hasWriteAccess, progressionBar, numberOfQuestions, numberOfAnsweredQuestions, summary, advancedSummary, isPublished, showOptional }) {
  const { t } = useTranslation();

  return (
    <div className="top-6 sticky rounded border border-solid border-greySix px-7 py-6 mb-6 sm:mb-0">
      <h3 className="text-base pb-3 border-b border-greySix">
        {t('TABLE_OF_CONTENTS_TITLE')}
      </h3>
      <div className="mt-4">
        {hasWriteAccess && (
          <div className="flex justify-between">
            <div className="h-2 w-3/4 relative self-center rounded">
              <div className="w-full absolute rounded h-2 bg-backgroundCleanBlue" />
              <div className="bg-primaryNewBlue h-2 rounded absolute" style={{ width: `${progressionBar && progressionBar}%` }} />
            </div>
            <div className="fw-400 text-xs">
              {numberOfAnsweredQuestions} / {numberOfQuestions} {t('TABLE_OF_CONTENTS_TEXT_1')}
            </div>
          </div>
        )}
        <h4 className="text-sm font-bold mt-4 mb-3">
          {t('TABLE_OF_CONTENTS_TEXT_2')}
        </h4>
        <div className="flex flex-col">
          {summary && summary.map((category, index) => (
            <Content
              key={index}
              category={category}
              hasWriteAccess={hasWriteAccess}
              isPublished={isPublished}
            />
          ))}
        </div>
      </div>
      {advancedSummary.length > 0 && advancedSummary && showOptional === true ? (
        <div className="mt-3">
          <h4 className="text-sm font-bold mt-4 mb-3 flex justify-between items-center">
            <span>{t('TABLE_OF_CONTENTS_TEXT_3')}</span>
            {hasWriteAccess && <span className="text-xs font-normal">{t('TABLE_OF_CONTENTS_TEXT_6')}</span>}
          </h4>
          {advancedSummary && advancedSummary.map((category, index) => (
            <Content
              key={index}
              category={category}
              hasWriteAccess={hasWriteAccess}
              isPublished={isPublished}
            />
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default TableOfContents;

import React from 'react';
import { useTranslation } from 'react-i18next';

import handshake from '../../../assets/ui/landingPartner/handshake.svg';
import medal from '../../../assets/ui/landingPartner/medal-military.svg';
import cornersIn from '../../../assets/ui/landingPartner/corners-in.svg';
import chat from '../../../assets/ui/landingPartner/double-chat.svg';

import Card from './Card';

function Share() {
  const { t } = useTranslation();

  const cards = [
    {
      icon: medal,
      iconName: 'handshake',
      textOne: t('LANDING_LECLERC.SHARE.CARD_A.TEXT_PART_ONE'),
      textTwo: t('LANDING_LECLERC.SHARE.CARD_A.TEXT_PART_TWO'),
    },
    {
      icon: chat,
      iconName: 'medal',
      textOne: t('LANDING_LECLERC.SHARE.CARD_B.TEXT_PART_ONE'),
      textTwo: t('LANDING_LECLERC.SHARE.CARD_B.TEXT_PART_TWO'),
    },
    {
      icon: handshake,
      iconName: 'corners-in',
      textOne: t('LANDING_LECLERC.SHARE.CARD_C.TEXT_PART_ONE'),
      textTwo: t('LANDING_LECLERC.SHARE.CARD_C.TEXT_PART_TWO'),
    },
    {
      icon: cornersIn,
      iconName: 'git-diff',
      textOne: t('LANDING_LECLERC.SHARE.CARD_D.TEXT_PART_ONE'),
      textTwo: t('LANDING_LECLERC.SHARE.CARD_D.TEXT_PART_TWO'),
    },
  ];

  return (
    <section className="bg-[#FBFBFB]">
      <div className="container flex flex-col items-center px-[30px] xl:px-20 pt-20 pb-[50px] mx-auto">
        <h4 className="font-bold text-leclercOrange text-[42px] xl:text-3xl text-center">
          {t('LANDING_LECLERC.SHARE.TITLE')}
        </h4>
        <div className="text-primaryNewBlue mt-10 font-medium text-lg xl:text-center xl:px-40">
          {t('LANDING_LECLERC.SHARE.TEXT_A')}
        </div>
        <div className="w-full lg:w-4/5 xl:w-3/4 2xl:w-full">
          <div className="mt-[60px] xl:mt-20 gap-[30px] grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 justify-items-center ">

            {cards.map((card, index) => (
              <Card key={index} card={card} />
            ))}

          </div>
        </div>

        <div className="text-center mt-14 flex justify-center">
          <a href="#contact-form" className="bg-primaryNewBlue block rounded-md px-7 py-2.5 text-white no-underline text-sm hover:bg-primaryNewBlueHover">
            {t('LANDING_LECLERC.SHARE.CTA')}
          </a>
        </div>
      </div>
    </section>
  );
}

export default Share;

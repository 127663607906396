import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import crossModal from '../../../../../assets/ui/cross-modal.svg';

function DeleteTagConfirmationModal({ isOpen, onClose, onConfirm, title, message = null }) {
  const { t } = useTranslation();
  const modalRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-greyOne/50 flex items-center justify-center z-50">
      <div ref={modalRef} className="max-w-[375px] bg-white rounded-lg shadow-lg p-7">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-base text-greyOne font-medium m-0">{title}</h2>
          <button type="button" className="flex flex-col justify-start border-none bg-white" onClick={onClose}>
            <img src={crossModal} alt="close icon" />
          </button>
        </div>
        {message && <p className="text-sm text-greyThree font-moskauGrotesk mt-4">{message}</p>}
        <div className="flex items-center gap-x-3 mt-9">
          <button
            type="button"
            className="py-[13px] px-7 bg-primaryCorail hover:bg-primaryCorailHover text-sm text-white font-moskauGrotesk font-medium border-none rounded-md"
            onClick={onConfirm}
          >
            {t('DELETE_TAG')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteTagConfirmationModal;

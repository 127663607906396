import React from 'react';
import { useTranslation } from 'react-i18next';

import './NotFound.scss';

import getLocalLink from '../../utils/getLocalLink';

function NotFound() {
  const { t } = useTranslation();
  return (
    <section className="flex w-full justify-center items-center text-center h-screen" id="error_page">
      <div>
        <h1>{t('PAGE_NOT_FOUND')}</h1>
        <h2>{t('LINK_IS_NOT_WORKING')}</h2>
        <p>
          {t('CHECK_URL')} <a href={getLocalLink('/')}>{t('GO_TO_HOMEPAGE')}</a>.
        </p>
      </div>
    </section>
  );
}

export default NotFound;

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MDXEditor, markdownShortcutPlugin, headingsPlugin, toolbarPlugin, BoldItalicUnderlineToggles, listsPlugin } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import './Form.scss';

import caretRight from '../../../../assets/ui/caret-right.svg';
import handleResponse from '../../../../utils/handleResponse';

function Form({ handleShowForm, post, put, response, selectedCategory, getNotes, setShowForm, editedNote, setIsDisabled }) {
  const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({});
  const { t } = useTranslation();

  register('note', { required: true });

  const onSubmit = async (data) => {
    try {
      if (editedNote && editedNote.id) {
        await put(`categories/${selectedCategory.id}/notes/${editedNote.id}`, data);
      } else {
        await post(`categories/${selectedCategory.id}/notes`, data);
      }

      if (response.ok) {
        setShowForm(false);
        reset();
        if (selectedCategory) {
          try {
            getNotes(selectedCategory.id);
          } catch (error) {
            throw new Error(error);
          }
        }
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  const getToolbarMardownEditor = () => <BoldItalicUnderlineToggles />;

  useEffect(() => {
    watch((value) => {
      if (!value.title || !value.note) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    });
    if (!editedNote) {
      setIsDisabled(true);
    }
  }, [watch, editedNote]);

  return (
    <>
      <button
        type="button"
        className="text-greyFour text-sm font-moskauGrotesk flex gap-1 items-center bg-white border-0 px-0 hover:text-greyThree"
        onClick={(e) => handleShowForm(e)}
      >
        <img src={caretRight} alt="Caret right" />
        <span className="pt-0.5">
          {editedNote ? t('NOTES.EDIT') : t('NOTES.WRITE')} {t('NOTES.A_NOTE')}
        </span>
      </button>
      <form
        id="note-form"
        onSubmit={handleSubmit(onSubmit)}
        className="mt-[30px] flex flex-col h-full"
      >
        <div>
          <input
            type="text"
            className={`w-full border-greySeven border-solid border rounded p-3 text-sm font-moskauGrotesk text-greyOne ${errors.title ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
            defaultValue={editedNote ? editedNote.title : ''}
            placeholder={t('NOTES.PLACEHOLDER_TITLE')}
            {...register('title', {
              maxLength: 255,
              required: t('NOTES.REQUIRED'),
            })}
          />
          {errors.title && (
          <div className="mt-1.5 text-redError text-sm">{errors.title.message}</div>
          )}
        </div>
        <div className="flex flex-col flex-grow mt-3">
          <MDXEditor
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              markdownShortcutPlugin(),
              toolbarPlugin({
                toolbarContents: getToolbarMardownEditor,
              }),
            ]}
            className={`w-full border-greySeven border-solid border rounded  text-sm font-moskauGrotesk text-greyOne flex-grow ${errors.note ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
            placeholder={t('NOTES.PLACEHOLDER_NOTE')}
            markdown={editedNote ? editedNote.note : ''}
            onChange={(value) => setValue('note', value)}
          />
          {errors.note && (
          <div className="mt-1.5 text-redError text-sm font-moskauGrotesk">{errors.note.message}</div>
          )}
        </div>
      </form>
    </>
  );
}

export default Form;

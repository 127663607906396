import React from 'react';
import { useOutletContext } from 'react-router-dom';
import useViewport from '../../../../../hooks/useViewport';

import Header from '../../Header/Header';
import SupplierCarbonIntensity from './SupplierCarbonIntensity';
import DataExportButton from './DataExportButton';
import AllocationMethod from './AllocationMethod';
import SupplierPartnerEmissions from './SupplierPartnerEmissions';
import SupplierPartnerCarbonFootprint from './SupplierPartnerCarbonFootprint';
import SupplierPartnerCarbonMonitoring from './SupplierPartnerCarbonMonitoring';

function SpecificData({ view }) {
  const { partner, profile } = useOutletContext();
  const { isMobile } = useViewport(984);

  return (
    <div id="profile-page">
      <Header view={view} />
      <div className="container mx-auto px-5">
        {!isMobile ? (
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-8 col-start-1">
              <SupplierCarbonIntensity partner={partner} />
              <SupplierPartnerCarbonMonitoring partner={partner} profile={profile} />
              <SupplierPartnerCarbonFootprint partner={partner} profile={profile} />
            </div>
            <div className="col-span-4 col-start-9">
              <DataExportButton />
              <AllocationMethod />
              <SupplierPartnerEmissions partner={partner} profile={profile} />
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center flex-wrap">
            <SupplierCarbonIntensity partner={partner} />
            <SupplierPartnerCarbonMonitoring partner={partner} profile={profile} />
            <SupplierPartnerCarbonFootprint partner={partner} profile={profile} />
            <AllocationMethod />
            <SupplierPartnerEmissions partner={partner} profile={profile} />
            <DataExportButton />
          </div>
        )}
      </div>

    </div>
  );
}

export default SpecificData;

import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import crossModal from '../../../../assets/ui/cross-modal.svg';

function DrawerTemplate(props) {
  const { title, drawerVisibility, setDrawerVisibility, className, children, onSubmit, handleSubmit } = props;
  const modalRef = useRef(null);

  const { t } = useTranslation();

  const closeModal = () => {
    setDrawerVisibility(false);
  };

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    document.body.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (drawerVisibility) {
      document.body.classList.add('overflow-hidden');
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleClickOutside);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [drawerVisibility]);

  return (
    <td className={className} onClick={(e) => e.stopPropagation()}>
      <div className="fixed top-0 left-0 w-full h-full backdrop-blur-sm bg-backgroundBlur z-40" />

      <div id="category-modal" className="h-full w-full right-0 fixed flex z-40 top-0 overflow-y-auto flex-row-reverse">
        <div ref={modalRef} className="w-full lg:w-1/2 bg-white relative">
          <div className=" h-20 flex justify-between items-center py-7 px-6 border-solid border-0 border-b border-greyBorderLight sticky top-0 bg-white z-40">
            <div>
              <div className="text-xl font-moskauGrotesk font-semibold">
                {title}
              </div>
            </div>
            <button
              type="button"
              className="border-0 bg-white z-50"
              onClick={closeModal}
            >
              <img src={crossModal} alt="Cross" />
            </button>
          </div>
          <div className="flex flex-col pb-12  bg-white">
            <form
              id="labels-modal-form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="flex flex-col  px-10 py-11">
                {children}
              </div>
              <div className="z-20 w-full lg:w-1/2 h-20 px-6 border-solid border-0 border-t border-greyBorderLight flex justify-end fixed  bottom-0 bg-white py-4">
                <button
                  type="button"
                  className="py-3 px-12 text-primaryNewBlue bg-white border-solid border border-primaryNewBlue rounded-[100px]"
                  onClick={closeModal}
                >
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.CANCEL')}
                </button>
                <button
                  type="submit"
                  className="py-3 px-12 text-white bg-primaryNewBlue border-0 rounded-[100px] ml-6"
                >
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBMIT')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </td>
  );
}

export default DrawerTemplate;

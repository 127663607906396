import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import tailwindConfig from '../../../../tailwind.config';

function TagsHead({ cellStyle }) {
  const { t } = useTranslation();

  return (
    <th
      className="font-12 px-3 font-medium cursor-pointer border-solid border-greySix border border-b-0"
      style={cellStyle}
    >
      <div className="flex justify-center">
        <span className="self-center">
          {t('TAGS')}
        </span>
      </div>
    </th>
  );
}

function TagsBody({ profile, cellStyle, onClick }) {
  return (
    <td className="h-[60px]" style={cellStyle}>
      <Link to={onClick(profile)} className="px-3 flex w-full h-full items-center no-underline text-greyOne">
        <div className="flex items-center gap-1 flex-wrap py-3">
          {profile.tags && profile.tags.map((tag) => (
            <div key={tag.id} className="ml-4 flex items-center rounded py-[6px] px-3" style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}>
              <span className="text-sm font-moskauGrotesk font-medium text-greyOne -mb-[4px]">{tag.name}</span>
            </div>
          ))}
        </div>
      </Link>
    </td>
  );
}

export { TagsHead, TagsBody };

import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <footer className="bg-[#000C23]">
      <div className="container mx-auto px-[30px] md:px-[60px] py-5 flex gap-[60px] no-underline text-white">
        <a href="https://www.openclimat.com" className="hover:text-greySeven">
          OpenClimat
        </a>
        <a href={`https://www.openclimat.com/${i18n.language}/contact`} className="hover:text-greySeven">
          {t('LANDING_LECLERC.FOOTER.CONTACT')}
        </a>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import { useTranslation } from 'react-i18next';

import pen from '../../../../assets/ui/pen.svg';

function ActionsHead({ cellStyle }) {
  const { t } = useTranslation();

  return (
    <th className="font-12 px-3 font-medium cursor-pointer border-solid border-greySix border border-b-0" style={cellStyle}>
      <div className="flex justify-center">
        {t('ACTIONS')}
      </div>
    </th>
  );
}

function ActionsBody({ cellStyle, setDrawerVisibility }) {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.stopPropagation();
    setDrawerVisibility(true);
  };

  return (
    <td className="px-3 border-solid border-greySix border" style={cellStyle}>
      <div className="flex justify-center">
        <button type="button" className="flex mt-1 table-status-edit border-0" onClick={(e) => handleClick(e)}>
          <span className="self-center me-2 decoration-primaryNewBlue text-primaryNewBlue">
            {t('ACTION_EDIT')}
          </span>
          <div className="action-svg self-center" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})`, height: 12, width: 12 }} />
        </button>
      </div>
    </td>
  );
}

export { ActionsHead, ActionsBody };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import cross from '../../../../assets/ui/cross-modal.svg';

function ModalText({ deleteInfo, t }) {
  const [text, setText] = useState('');

  useEffect(() => {
    if (deleteInfo?.canDelete.canDelete === true) {
      if (deleteInfo?.subCategoriesNumber) {
        setText(
          <div className="text-sm text-greyDark mt-4">
            {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE_2.PART_A')} {deleteInfo?.subCategoriesNumber} {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE_2.PART_B')}{deleteInfo?.subCategoriesNumber > 1 ? 's' : ''}. {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE_2.PART_C')}
          </div>,
        );
      } else {
        setText(
          <div className="text-sm text-greyDark mt-4">
            {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE.PART_A')}{deleteInfo?.number}{t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE.PART_B')}{deleteInfo?.number > 1 ? 's' : ''}. {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE.PART_C')}
          </div>,
        );
      }
    } else {
      setText(
        <div className="text-sm text-greyDark mt-4">
          {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.SUBTITLE_3')} "{deleteInfo?.canDelete.parentCategoryName}".
        </div>,
      );
    }
  }, [deleteInfo]);

  return (
    text
  );
}

function DeleteConfirmationModal({ deleteModalRef, deleteInfo, setDeleteInfo, toggleModal, backgroundDeleteModalRef }) {
  const { t } = useTranslation();

  const handleClickOutside = (event) => {
    if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
      const deleteModal = document.getElementById('delete-modal');
      if (!deleteModal.classList.contains('hidden')) {
        deleteModal.classList.add('hidden');
        setDeleteInfo(null);
      }
    }
  };

  const handleCancel = () => {
    setDeleteInfo(null);
    toggleModal();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      id="delete-modal"
      className="fixed z-40 overflow-y-auto top-0 w-full left-0 hidden"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div ref={backgroundDeleteModalRef} className="absolute inset-0 backdrop-blur-sm bg-greyBlur" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <div ref={deleteModalRef} className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full p-7 justify-self-end right-full md:mx-10 md:mt-20" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div className="bg-white text-xl">
            <div className="flex justify-between">
              <div>
                {deleteInfo?.canDelete.canDelete === true ? (
                  t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.TITLE')
                ) : (
                  t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.TITLE_ALT')
                )}
              </div>
              <button type="button" className="bg-white border-0 flex" onClick={() => { setDeleteInfo(null); toggleModal(); }}>
                <img src={cross} alt="cross" className="float-right cursor-pointer" />
              </button>
            </div>
            <ModalText deleteInfo={deleteInfo} t={t} />
          </div>
          <div className="mt-9">
            <button
              type="button"
              className="py-2 px-8 bg-white mr-3.5 border-primaryNewBlue rounded-[100px]"
              onClick={() => handleCancel()}
            >
              {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.CTA.CANCEL')}
            </button>
            {deleteInfo?.canDelete.canDelete === true ? (
              <button
                type="button"
                className="py-2 px-12 bg-primaryNewBlue border-0 text-white rounded-[100px] font-medium hover:bg-blue-700 mr-2 transition duration-500"
                onClick={() => toggleModal(true)}
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.DELETE_MODAL.CTA.DELETE')}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;

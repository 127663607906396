import React from 'react';
import { useTranslation } from 'react-i18next';

import download from '../../../../../assets/ui/download.svg';

function DataExportButton() {
  const { t } = useTranslation();

  return (
    <button type="button" className="w-full flex items-center justify-center text-white font-poppins text-sm font-medium text-center py-3 px-7 rounded-md border-none bg-primaryNewBlue hover:bg-primaryNewBlueHover">
      {t('DATA_EXPORT_BUTTON')}
      <img src={download} alt="download" className="ml-1" />
    </button>
  );
}

export default DataExportButton;

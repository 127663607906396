import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import useFetch from 'use-http';

import spin from '../../../../assets/ui/spin.svg';

import handleResponse from '../../../../utils/handleResponse';

function ForgotPasswordForm() {
  const [submited, setSubmited] = useState(false);
  const [email, setEmail] = useState('');
  const { partner } = useOutletContext();
  const { post, response, loading } = useFetch(process.env.REACT_APP_PARTNER_API);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const onSubmit = async (formData) => {
    try {
      await post('/login/forgot', {
        domain: partner.domain,
        email: formData.email,
      });
      if (response.ok) {
        setSubmited(true);
        setEmail(formData.email);
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  return (
    <>
      <h1 className="text-3xl text-greyOne text-center mt-10">
        {t('LOGIN_FORM.FORGOTTEN_PASSWORD')}
      </h1>
      {submited ? `${t('PARTNER_LOGIN_TEXT_8')} ${email} ${t('PARTNER_LOGIN_TEXT_9')}` : (
        <form
          id="partner-forgot-form"
          className="mt-[30px]"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <label className="mb-2.5 font-medium" htmlFor="inputEmail">
            Email *
          </label>
          <input
            type="email"
            {...register('email', {
              required: t('FORM_CONTACT_COMPANY_EMAIL_MISSING'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('FORM_CONTACT_COMPANY_EMAIL_BAD_FORMAT'),
              },
            })}
            name="email"
            id="inputEmail"
            className={`flex w-full border-greySix border border-solid rounded-md py-3 px-5 text-greyFour ${
              errors.email
                ? 'focus:outline-redError'
                : 'focus:outline-secondarySkyBlue'
            }`}
            autoComplete="email"
            placeholder={t('LOGIN_FORM.PASSWORD.PLACEHOLDER_EMAIL')}
          />
          {errors.email && (
            <div className="mt-1 text-sm text-redError">{errors.email.message}</div>
          )}
          <button
            type="submit"
            className="w-full mt-[30px] min-h-[48px] py-3 bg-primaryNewBlue border-0 rounded text-white justify-center flex items-center"
            disabled={loading}
          >
            {loading ? (
              <img src={spin} alt="spin" />
            ) : (
              t('PARTNER_LOGIN_TEXT_15')
            )}
          </button>
        </form>
      )}

    </>
  );
}

export default ForgotPasswordForm;

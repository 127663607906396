import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function CategoryHead({ categoryLevel0, cellStyle }) {
  const { t } = useTranslation();

  return (
    <th className="font-12 px-3 font-medium cursor-pointer border-solid border-greySix border border-b-0" style={cellStyle}>
      <div className="flex justify-center">
        {categoryLevel0 ? (
          categoryLevel0.name
        ) : (
          t('CATEGORY_LEVEL_0')
        )}
      </div>
    </th>
  );
}

function CategoryBody({ profile, cellStyle, onClick }) {
  const [categories, setCategories] = useState('');
  const [categoriesFull, setCategoriesFull] = useState('');

  useEffect(() => {
    if (profile && profile.categories) {
      let categories = '';

      const categoriesSorted = profile?.categories.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      categoriesSorted.forEach((element, index) => {
        categories += `${element.name}${index + 1 < profile.categories.length ? ', ' : ''}`;
      });

      setCategoriesFull(categories);
      if (categories.length > 40) {
        categories = `${categories.substring(0, 40)}...`;
      }
      setCategories(categories);
    } else {
      setCategories('--');
    }
  }, [profile]);

  return (
    <td className="group border-solid border-greySix border relative h-[60px]" style={cellStyle}>
      <Link to={onClick(profile)} className="px-3 h-full w-full flex items-center no-underline text-greyOne">
        {categories}
        {categoriesFull.length > 40 ? (
          <span className=" max-w-96 z-10 absolute hidden group-hover:block p-2 bg-greyTwo text-white  bottom-3/4 animate-fadeIn text-xs rounded-sm">
            {categoriesFull}
          </span>
        ) : ('')}
      </Link>
    </td>
  );
}

export { CategoryHead, CategoryBody };

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';

import toggleSort from '../../../../utils/toggleSort';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function CdpHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'cdp';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 px-3 text-center font-medium cursor-pointer border-solid border-greySix border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="flex justify-center">
        {t('CDP_SCORE')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end ml-2.5 self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function CdpBody({ profile, cellStyle, onClick }) {
  return (
    <td className="border-solid border-greySix border h-[60px]" style={cellStyle}>
      <Link to={onClick(profile)} className="px-3 flex w-full h-full text-greyOne no-underline items-center justify-center">
        {profile.scope_cdp}
      </Link>
    </td>
  );
}

export { CdpHead, CdpBody };

import React, { useEffect, useRef } from 'react';
import cross from '../../assets/ui/cross-modal.svg';
import useModal from '../../hooks/useModal';

export default function TemplateModal({ children, modalId, title }) {
  const { openModal, close } = useModal();
  const isModalOpen = openModal === modalId;
  const modalRef = useRef(null);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        const modalContent = document.getElementById(`modal-content-${modalId}`);
        if (modalContent) {
          modalContent.style.opacity = '1';
          modalContent.style.transform = 'translateY(0)';
          modalContent.style.transition = 'transform 0.5s ease-out, opacity 0.5s ease-out';
        }
      }, 50);
    } else {
      const modalContent = document.getElementById(`modal-content-${modalId}`);
      if (modalContent) {
        modalContent.style.opacity = '0';
        modalContent.style.transform = 'translateY(-20px)';
        modalContent.style.transition = 'none';
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        close();
      }
    };

    const handleBodyScroll = () => {
      if (isModalOpen) {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.style.paddingRight = '';
        document.body.classList.remove('overflow-hidden');
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      handleBodyScroll();
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      handleBodyScroll();
    }

    return () => {
      document.body.style.paddingRight = '';
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  return (
    <div
      id={modalId}
      tabIndex="-1"
      aria-hidden={!isModalOpen ? 'true' : undefined}
      inert={!isModalOpen ? 'true' : undefined}
      className={`flex ${isModalOpen ? 'visible' : 'hidden'} overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-[50000] justify-center items-center w-full bg-greyOne/50 h-full max-h-full`}
    >
      <div
        id={`modal-content-${modalId}`}
        ref={modalRef}
        className={`relative my-4 w-full max-w-2xl h-auto transition-all transform ${isModalOpen ? '' : ''} duration-1000 ease-in-out mx-5`}
      >
        <div className="relative bg-white rounded-lg shadow overflow-y-auto max-h-screen">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-greySix sticky top-0 bg-white border-solid border-t-0 border-x-0">
            <h3 className="text-xl font-semibold text-gray-900 mb-0">
              {title}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center border-0"
              data-modal-hide="default-modal"
              onClick={() => close()}
            >
              <img src={cross} alt="cross" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

import React from 'react';

import checkToast from '../../../assets/ui/check-toast.svg';
import exclamationMark from '../../../assets/ui/exclamation-mark-toast.svg';

export default function ToastMessage({ text, warning = false }) {
  return (
    <div className="flex items-center text-primaryNewBlue text-sm font-moskauGrotesk font-normal">
      <img src={warning ? exclamationMark : checkToast} alt="Icon" className=" h-full mr-2" />
      <div>{text}</div>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

function EmployeesInformations({ profile }) {
  const { t } = useTranslation();

  const employeesCountLabel = {
    49: 'EMPLOYEES_COUNT_50',
    99: 'EMPLOYEES_COUNT_100',
    199: 'EMPLOYEES_COUNT_200',
    499: 'EMPLOYEES_COUNT_500',
    999: 'EMPLOYEES_COUNT_1000',
    4999: 'EMPLOYEES_COUNT_5000',
    9999: 'EMPLOYEES_COUNT_10000',
    10000: 'EMPLOYEES_COUNT_10001',
  };

  return (
    <div className="flex flex-col max-w-[33%]">
      <div className="text-xs text-greyFour mb-2">
        {t('HEADER_LABEL_SIZE')}
      </div>
      <div className="text-sm text-greyTwo">
        {t(employeesCountLabel[profile?.employees_count])}
      </div>
    </div>
  );
}

export default EmployeesInformations;

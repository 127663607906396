import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import { NameHead } from './Columns/Name';
import TableRow from './TableRow';
import { ActionsHead } from './Columns/Actions';

function Table({ profiles, partner, profileStatusList, tableComponent, filter, setFilter, sort, setSort, categoryFilter, hasWriteAccess, edit, isDashboard, loading, features, getProfiles, sectorFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, cellStyle, containterTableRef, tableRef, tableComponentHead }) {
  const [partnerTags, setPartnerTags] = useState([]);

  const { t } = useTranslation();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/tags`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const getPartnerTags = async () => {
    try {
      await get();
      if (response.ok) {
        setPartnerTags(response.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    getPartnerTags();
  }, [partner]);

  const trueCount = tableComponent ? Object.keys(tableComponent).filter((key) => tableComponent[key] === true).length : 0;

  const adjustedTrueCount = hasWriteAccess ? trueCount + 2 : trueCount + 1;

  const skeletonTdElements = Array.from({ length: adjustedTrueCount }, (_, index) => (
    <td key={index} className="px-3 border-solid border-greySix border text-center" style={cellStyle}>
      <div className="bg-greySeven animate-pulse h-8 rounded m-3" />
    </td>
  ));

  const skeletonTrElements = Array.from({ length: process.env.REACT_APP_RESULT_PER_PAGE }, (_, index) => (
    <tr key={index} className="border-solid border-greySix border align-middle table-row-no-hover">
      {skeletonTdElements}
    </tr>
  ));

  return (
    <div ref={containterTableRef} id="table-container" className="d-lg-block false flex-grow overflow-auto relative">
      <table ref={tableRef} className="dashboard-table">
        <thead className="align-middle table-header text-greyDarkHeavy">
          <tr>
            <NameHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />
            {tableComponentHead && tableComponentHead.map((component, index) => (
              <React.Fragment key={index}>
                {React.cloneElement(component, { cellStyle })}
              </React.Fragment>
            ))}
            {(hasWriteAccess && (tableComponent?.status || tableComponent?.profileRating || tableComponent?.profileTurnover || tableComponent?.profileCarbonWeight)) && <ActionsHead cellStyle={cellStyle} />}
          </tr>
        </thead>
        <tbody className="bg-white text-greyHeavy overflow-hidden">
          {profiles && profiles.length <= 0 && !loading && (
            <tr className="font-12 py-5 font-medium border-solid border-greySix border flex justify-center">
              <td>
                {t('NO_RESULT')}
              </td>
            </tr>
          )}
          {!loading ? (
            profiles.map((profile, index) => (
              <TableRow
                key={profile.id + index}
                category={categoryFilter}
                partner={partner}
                partnerTags={partnerTags}
                getPartnerTags={getPartnerTags}
                profile={profile}
                profileStatusList={profileStatusList}
                tableComponent={tableComponent}
                hasWriteAccess={hasWriteAccess}
                edit={edit}
                isDashboard={isDashboard}
                cellStyle={cellStyle}
                getProfiles={getProfiles}
                categoryFilter={categoryFilter}
                sectorFilter={sectorFilter}
                sbtiFilter={sbtiFilter}
                sbtiFilterSimple={sbtiFilterSimple}
                statusFilter={statusFilter}
                currentProgressFilter={currentProgressFilter}
                actionPlanFilter={actionPlanFilter}
                tagsFilter={tagsFilter}
                sort={sort}
                filter={filter}
                tableFeatures={features}
              />
            ))
          ) : (
            skeletonTrElements
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;

import React from 'react';
import { useTranslation } from 'react-i18next';

import getClimateAlignementInfos from '../../../../utils/getClimateAlignementInfos';

function Widget({ getColorByScore, getHexColorWithOpacity, degreeScore, color }) {
  const { t } = useTranslation();

  return (
    <div className={`text-center flex justify-center w-full ${degreeScore >= 2 ? 'col-span-1 col-start-7' : 'col-span-1 col-start-7'}`}>
      {degreeScore >= 4 ? (
        <div className={`self-center py-1 px-7 font-medium text-sm text-greyThree font-moskauGrotesk border border-solid rounded-[100px] text-${getColorByScore(degreeScore)}`} style={{ backgroundColor: `${getHexColorWithOpacity(color, 0.1)}` }}>
          {t(getClimateAlignementInfos(degreeScore).text)}
        </div>
      ) : (
        <div className="text-xs self-center font-moskauGrotesk text-center">
          <div className="text-greyThree">
            {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
          </div>
          <div className={`text-${getColorByScore(degreeScore)} font-semibold`}>
            {t(getClimateAlignementInfos(degreeScore).text)}
          </div>
        </div>
      )}
    </div>
  );
}

export default Widget;

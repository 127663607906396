import React, { useEffect } from 'react';

import blueCheckmark from '../../../../../assets/ui/blue-checkmark.svg';
import emptyCheckmark from '../../../../../assets/ui/empty-checkmark.svg';

function Content({ category, hasWriteAccess, isPublished, isMainQuestions }) {
  let allAnswered = true;
  let show = false;

  category.individualQuestions.forEach((question) => {
    if (question.type === 2) {
      if (question.answer?.answer) {
        const parsedAnswer = JSON.parse(question.answer?.answer);

        if ((parsedAnswer.amount === '' && parsedAnswer.year === '') || (!parsedAnswer.amount && !parsedAnswer.year)) {
          allAnswered = false;
        }
      }
    } else if (question.type === 1 || question.type === 3 || question.type === null) {
      if (!question.answer?.answer) {
        allAnswered = false;
      }
    }

    if (question.answer?.answer || question.is_required || hasWriteAccess) {
      show = true;
    }
  });

  useEffect(() => {
    const targets = document.getElementsByClassName('category-test');
    if (targets.length === 0) return undefined;

    const observer = new IntersectionObserver((entries) => {
      let closestId = null;
      let smallestDistance = Infinity;

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const distance = Math.abs(entry.boundingClientRect.top);
          if (distance < smallestDistance) {
            smallestDistance = distance;
            closestId = entry.target.id;
          }
        }
      });

      if (closestId !== null) {
        const test = document.getElementById(`table-contents-${closestId}`);
        // Reset background color for all targets
        Array.from(targets).forEach((target) => {
          const potentialTest = document.getElementById(`table-contents-${target.id}`);
          if (potentialTest) potentialTest.style.backgroundColor = ''; // Reset background color
        });
        // Highlight the closest element
        if (test) {
          test.style.backgroundColor = '#FAFAFB';
        }
      }
    }, {
      threshold: 0.7,
    });

    Array.from(targets).forEach((target) => observer.observe(target));

    return () => observer.disconnect();
  }, []);

  return (
    show && (
      hasWriteAccess ? (
        <a id={`table-contents-${category.id}`} href={`#${category.id}`} className={`p-2 flex hover:bg-greyEight rounded-sm justify-between no-underline gap-4 items-center text-sm ${allAnswered ? 'font-medium text-greyOne' : 'font-normal text-greyFour'}`}>
          <div className="flex gap-4 items-center">
            {isMainQuestions ? (
              <div className="text-[13px] font-medium px-1 flex justify-center items-center text-primaryNewBlue w-9 h-6 rounded-2xl bg-primaryNewBlue/[0.05]">
                <span className="mt-0.5">
                  {category.distribution[0]?.percentage ? category.distribution[0].percentage : '--'}%
                </span>
              </div>
            ) : null}
            <div>
              {category.name}
            </div>
          </div>
          {allAnswered ? (
            <div className="flex content-center min-h-5 min-w-5 mr-2">
              <img className="flex self-center" height={20} width={20} src={blueCheckmark} alt="blue checkmark" />
            </div>
          ) : (
            <div className="flex content-center min-h-5 min-w-5 mr-2">
              <img className="flex self-center" height={20} width={20} src={emptyCheckmark} alt="empty checkmark" />
            </div>
          )}
        </a>
      ) : (
        <a id={`table-contents-${category.id}`} href={`#${category.id}`} className={`p-2 flex gap-4 items-center hover:bg-greyEight rounded-sm text-sm no-underline ${!allAnswered || !isPublished ? 'font-normal text-greyFour' : 'font-medium text-greyOne'}`}>
          <div className="text-[13px] font-medium px-1 flex justify-center items-center text-primaryNewBlue w-9 h-6 rounded-2xl bg-primaryNewBlue/[0.05]">
            <span className="mt-0.5">
              {category.distribution[0]?.percentage ? category.distribution[0].percentage : '--'}%
            </span>
          </div>
          <div>
            {category.name}
          </div>
        </a>
      )
    )
  );
}

export default Content;

import { useState, useCallback } from 'react';
import getCompetingCompanies from '../utils/getCompetingCompanies';

/**
 * Custom hook to fetch and manage competing companies.
 *
 * @param {string} profileId - The profile ID.
 * @param {string} partnerId - The partner ID.
 * @param {boolean} status - Flag to sort companies by status.
 * @param {boolean} climateAction - Flag to sort companies by climate action.
 * @returns {Object} - An object containing the companies array and fetchCompanies function.
 */
const useCompetingCompanies = (profileId, partnerId, status, climateAction) => {
  const [companies, setCompanies] = useState([]);

  /**
   * Fetches competing companies based on the provided category ID.
   *
   * @param {string} categoryId - The category ID.
   */
  const fetchCompanies = useCallback(async (categoryId) => {
    const fetchedCompanies = await getCompetingCompanies(profileId, partnerId, categoryId);

    const companiesArray = fetchedCompanies || [];

    if (companiesArray.length > 0) {
      if (status) {
        companiesArray.sort((a, b) => {
          const statusDiff = b.status_position - a.status_position;

          if (statusDiff === 0) {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
          }

          return statusDiff;
        });
      } else if (climateAction) {
        companiesArray.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      }
    }

    setCompanies(companiesArray);
  }, [profileId, partnerId, status, climateAction]);

  return { companies, fetchCompanies };
};

export default useCompetingCompanies;

import React from 'react';
import { useTranslation } from 'react-i18next';

function BarChartLegend({
  legend,
  selectedStatus,
  onStatusSelect,
  className,
  isVertical = false,
  isDisable = false,
  demoIsEnabled = false,
}) {
  const { t } = useTranslation();

  const noLegendObject = {
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.VERY_STRONG')]: '#0EBF76',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.STRONG')]: '#6CB14A',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.INTERMEDIATE')]: '#F8D55B',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.INSUFFICIENT')]: '#F68F57',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.VERY_INSUFFICIENT')]: '#E63756',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.NO_REPORTING')]: '#B41A35',
  };

  const legendToUse = legend || noLegendObject;

  const getOpacity = (status) => {
    if (!selectedStatus) return 1;
    return status === selectedStatus ? 1 : 0.2;
  };

  const handleSelection = (status) => {
    onStatusSelect(selectedStatus === status ? null : status);
    const supliersListDiv = document.getElementById('suppliers-list');
    if (supliersListDiv) {
      supliersListDiv.scrollTop = 0;
    }
  };

  return (
    <div className={`w-full flex ${isVertical ? 'flex-col gap-y-[14px] justify-center items-start' : 'flex-wrap items-center'} gap-x-[15px] gap-y-2 mt-5 ${className}`}>
      {Object.entries(legendToUse).map((item, index) => {
        const opacity = getOpacity(item[0]);
        const displayName = demoIsEnabled && item[0] === 'Engagé et validé SBTi' ? 'Engagé SBTi et en ligne' : item[0];
        return (
          <button
            key={index}
            type="button"
            tabIndex={0}
            onClick={() => handleSelection(item[0])}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleSelection(item[0]);
              }
            }}
            className={`chart-legend flex items-center hover:opacity-80 border-none bg-transparent ${!isDisable ? 'cursor-pointer' : ''}`}
            style={!isDisable ? { opacity } : {}}
            disabled={isDisable}
          >
            <div
              className="w-[14px] h-[14px] rounded-full p-[3px]"
              style={{ backgroundColor: `${item[1]}10` }}
            >
              <div
                className="w-full h-full rounded-full"
                style={{ backgroundColor: item[1] }}
              />
            </div>
            <span className="text-xs text-greyOne font-moskauGrotesk ml-1">
              {displayName}
            </span>
          </button>
        );
      })}
    </div>
  );
}

export default BarChartLegend;

import React from 'react';

import Status from './Status';

import certified from '../../../../assets/ui/managed.svg';

function CompanyInfo({ company, climateAction, status, t }) {
  return (
    <div className="ms-2">
      <div className="flex items-center font-medium text-greyOne text-sm">
        {company.name}
        <div className="flex items-center">
          {company.isManagedSort ? (
            <img
              src={certified}
              alt="Certified"
              className="w-[20px] ml-[4px] -mt-1"
            />
          ) : null}
        </div>
      </div>
      <span className="text-xs font-medium text-greyFour font-moskauGrotesk mt-2">
        {climateAction ? (
          <div className="mt-2">
            {company.isManagedSort
              ? t('COMPETING_COMPANIES_MEMBER')
              : t('COMPETING_COMPANIES_NOT_MEMBER')}
          </div>
        ) : null }
      </span>
      {status && <Status profile={company} />}
    </div>
  );
}

export default CompanyInfo;

import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@Openclimat/openclimat-component-library';

import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function CurrentProgress() {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const options = [
    {
      name: t('CURRENT_PROGRESS_IN_LINE'),
      value: 'commited',
    },
    {
      name: t('CURRENT_PROGRESS_NOT_IN_LINE'),
      value: 'not_in_line',
    },
    {
      name: t('NO_COMMITMENT'),
      value: 'not_commited',
    },
  ];

  const handleCurrentProgressFilter = (value) => {
    setLoading(true);
    if (value === null) {
      searchParams.delete('current_progress_filter');
      searchParams.set('p', 0);
    } else {
      searchParams.set('current_progress_filter', value);
      searchParams.set('p', 0);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('current_progress_filter')) {
      setValue(searchParams.get('current_progress_filter'));
    } else {
      setValue('');
    }
  }, [searchParams]);

  return (
    <Dropdown
      name={t('CURRENT_PROGRESS')}
      handleChange={handleCurrentProgressFilter}
      options={options}
      value={value}
    />
  );
}

export default CurrentProgress;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import chevronRightM from '../../../assets/ui/chevron-right-m.svg';

import formatEmissionsPercentage from '../../../utils/formatEmissionsPercentage';
import formatTurnover from '../../../utils/formatTurnover';
import formatTargetSBT from '../../../utils/formatTargetSbt';
import getWebUiElements from '../../../utils/getWebUiElements';
import getLocalLink from '../../../utils/getLocalLink';
import getHexColorWithOpacity from '../../../utils/getHexColorWithOpacity';

function RowTemplate({ leftCol, rightCol }) {
  return (
    <div className="flex mt-5 text-xs">
      <div className="w-1/2 font-medium flex pr-1">
        <div className="self-center">
          {leftCol}
        </div>
      </div>
      <div className="w-1/2 font-normal flex pl-1">
        {rightCol}
      </div>
    </div>
  );
}

function MobileCard({ profile, index, tableComponent }) {
  const [status, setStatus] = useState();
  const [statusColor, setStatusColor] = useState();
  const [dateTextScope12, setDateTextScope12] = useState('');
  const [dateTextScope3, setDateTextScope3] = useState('');
  const [dateTextObjScope12, setDateTextObjScope12] = useState('');
  const [dateTextObjScope3, setDateTextObjScope3] = useState('');
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setStatus(profile.status_name);
    setStatusColor(profile.status_color);

    if (profile.direct_percentage && profile.direct_percentage !== 0) {
      if (profile.direct_final_year < currentYear) {
        setDateTextScope12(` ${t('SINCE')} ${profile.direct_initial_year}`);
      } else {
        setDateTextScope12(`${t('BETWEEN').toLowerCase()} ${profile.direct_initial_year} ${t('AND').toLowerCase()} ${profile.direct_final_year}`);
      }
    }

    if (profile.complete_percentage && profile.complete_percentage !== 0) {
      if (profile.complete_final_year < currentYear) {
        setDateTextScope3(` ${t('SINCE')} ${profile.complete_initial_year}`);
      } else {
        setDateTextScope3(`${t('BETWEEN').toLowerCase()} ${profile.complete_initial_year} ${t('AND').toLowerCase()} ${profile.complete_final_year}`);
      }
    }

    if (profile.obj_direct_percentage && profile.obj_direct_percentage !== 0) {
      if (profile.obj_scope_1e2_direct_final_year < currentYear) {
        setDateTextObjScope12(` ${t('SINCE')} ${profile.obj_scope_1e2_direct_initial_year}`);
      } else {
        setDateTextObjScope12(` ${t('BETWEEN').toLowerCase()} ${profile.obj_scope_1e2_direct_initial_year} ${t('AND').toLowerCase()} ${profile.obj_scope_1e2_direct_final_year}`);
      }
    }

    if (profile.obj_scope_3_n_1_complete_percentage && profile.obj_scope_3_n_1_complete_percentage !== 0) {
      if (profile.obj_scope_3_n_1_complete_final_year < currentYear) {
        setDateTextObjScope3(` ${t('SINCE')} ${profile.obj_scope_3_n_1_complete_initial_year}`);
      } else {
        setDateTextObjScope3(` ${t('BETWEEN').toLowerCase()} ${profile.obj_scope_3_n_1_complete_initial_year} ${t('AND').toLowerCase()} ${profile.obj_scope_3_n_1_complete_final_year}`);
      }
    }
  }, [currentYear, profile, t]);

  return (
    <a
      href={getLocalLink(`${window.origin}/profile/${profile.slug}`)}
      className={`block bg-white text-greyOne no-underline rounded-3xl p-3 ${index > 0 ? 'mt-4' : ''}`}
    >
      {/* Header */}
      <div className="flex items-center border-b-[1px] border-solid border-greySix pb-3">
        <div className="w-12 h-12 flex-shrink-0">
          <img
            src={`${process.env.REACT_APP_FILES_DOMAIN}${profile.logo_url}`}
            className="w-full h-full object-contain"
            alt={profile.name}
            loading="lazy"
          />
        </div>
        <div className="ml-3 flex-grow">
          <div className="text-sm font-medium">
            {profile.name}
          </div>
          <div className="text-xs text-greyFour">
            {profile.sector_name}
          </div>
        </div>
        <div className="self-center ml-auto">
          <img src={chevronRightM} alt="Chevron right" />
        </div>
      </div>

      {/* Status */}
      {profile.status_name && profile.status_color && (
        <div
          className="mt-4 text-center font-medium text-sm p-2 rounded-full"
          style={{
            color: statusColor,
            backgroundColor: getHexColorWithOpacity(statusColor, 0.1),
          }}
        >
          {t(status)}
        </div>
      )}

      {/* Turnover */}
      {tableComponent?.turnover && (
        <RowTemplate
          leftCol={t('TURNOVER')}
          rightCol={formatTurnover(profile.turnover_amount, profile.turnover_type, t)}
        />
      )}

      {/* Climate Action */}
      {tableComponent?.climateAction && (
        <RowTemplate
          leftCol={t('CLIMATE_ACTION')}
          rightCol={t(profile.score_label)}
        />
      )}

      {/* Scope 1 & 2 */}
      {tableComponent?.scope12 && (
        <RowTemplate
          leftCol={t('CO2_SCOPE_1_2_MOBILE_CARD')}
          rightCol={(
            <div className="flex items-center space-x-2">
              <img
                src={`${getWebUiElements(Math.floor(profile.direct_level)).co2Arrow}`}
                alt="co2 arrow course"
                className="w-4 h-4"
              />
              <div className="text-xs text-greyFour">
                {profile.direct && profile.direct_percentage ? (
                  <>{formatEmissionsPercentage(t, profile.direct_percentage, profile.direct_reduce_type, profile.direct)}{dateTextScope12}</>
                ) : (
                  <div>
                    n.a. <span className="text-greyThree">{t(profile.direct_details)}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        />
      )}

      {/* Scope 3 */}
      {tableComponent?.scope3 && (
        <RowTemplate
          leftCol={t('CO2_SCOPE_3_MOBILE_CARD')}
          rightCol={(
            <div className="flex items-center space-x-2">
              <img
                src={`${getWebUiElements(Math.floor(profile.complete_level)).co2Arrow}`}
                alt="co2 arrow course"
                className="w-4 h-4"
              />
              <div className="text-xs text-greyThree">
                {profile.complete && profile.complete_percentage ? (
                  <>{formatEmissionsPercentage(t, profile.complete_percentage, profile.complete_reduce_type, profile.complete)}{dateTextScope3}</>
                ) : (
                  <div>
                    n.a. <span className="text-greyThree">{t(profile.complete_details)}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        />
      )}

      {/* Objective Scope 1 & 2 */}
      {tableComponent?.objScope12 && (
        <RowTemplate
          leftCol={t('OBJ_CO2_SCOPE_1_2_MOBILE_CARD')}
          rightCol={(
            <div className="flex items-center space-x-2">
              <img
                src={`${getWebUiElements(Math.floor(profile.obj_scope_1e2_manual_degree_position)).co2Arrow}`}
                alt="co2 arrow course"
                className="w-4 h-4"
              />
              <div className="text-xs text-greyThree">
                {profile.direct ? (
                  <>{formatEmissionsPercentage(t, profile.obj_direct_percentage, profile.obj_scope_1e2_direct_value_type, profile.obj_direct)}{dateTextObjScope12}</>
                ) : (
                  <div>
                    n.a. <span className="text-greyThree">({t('ACTIONS_SUBTEXT_1')})</span>
                  </div>
                )}
              </div>
            </div>
          )}
        />
      )}

      {/* Objective Scope 3 */}
      {tableComponent?.objScope3 && (
        <RowTemplate
          leftCol={t('OBJ_CO2_SCOPE_3_MOBILE_CARD')}
          rightCol={(
            <div className="flex items-center space-x-2">
              <img
                src={`${getWebUiElements(Math.floor(profile.obj_scope_3_n_1_manual_degree_position)).co2Arrow}`}
                alt="co2 arrow course"
                className="w-4 h-4"
              />
              <div className="text-xs text-greyThree">
                {profile.obj_scope_3_n_1_complete_percentage ? (
                  <>{formatEmissionsPercentage(t, profile.obj_scope_3_n_1_complete_percentage, profile.obj_scope_3_n_1_direct_value_type, profile.obj_complete)}{dateTextObjScope3}</>
                ) : (
                  <div>
                    n.a. <span className="text-greyThree">({t('ACTIONS_SUBTEXT_1')})</span>
                  </div>
                )}
              </div>
            </div>
          )}
        />
      )}

      {/* SBT Target */}
      {tableComponent?.targetSbt && (
        <RowTemplate
          leftCol={t('SBT_TARGET')}
          rightCol={t(formatTargetSBT(profile.target_sbt))}
        />
      )}

      {/* CDP Score */}
      {tableComponent?.scoreCdp && (
        <RowTemplate
          leftCol={t('CDP_SCORE')}
          rightCol={profile.scope_cdp}
        />
      )}
    </a>
  );
}

export default MobileCard;

import React, { useEffect, useState } from 'react';

import pen from '../../../../../assets/ui/pen.svg';
import trashBin from '../../../../../assets/ui/trash-bin-m.svg';

import QuestionTypeActions from './QuestionTypeActions';
import useModal from '../../../../../hooks/useModal';

export default function Question({ question, isPublished, setShowOptional, hasWriteAccess, profile, fetchData, setData, setItemToDelete }) {
  const [noAnswerText, setNoAnswerText] = useState();
  const [show, setShow] = useState(false);

  const { open } = useModal();

  useEffect(() => {
    if (setShowOptional) {
      setShowOptional(true);
    }

    if (setShowOptional && hasWriteAccess === true) {
      setShowOptional(true);
    }

    setShow(true);

    if ((!isPublished || !hasWriteAccess)) {
      setNoAnswerText('TABLE_OF_CONTENTS.TEXT_10');
    } else if (profile.is_managed) {
      setNoAnswerText('TABLE_OF_CONTENTS.TEXT_9');
    } else {
      setNoAnswerText('TABLE_OF_CONTENTS.TEXT_10');
    }
  }, [hasWriteAccess, fetchData]);

  return (
    ((question.answer && question.answer.answer) || question.is_required || hasWriteAccess) && show ? (
      <div>
        <div className="border border-solid border-greySix p-3.5 bg-backgroundCleanBlue mt-7 rounded-sm text-greyOne font-medium text-sm flex justify-between gap-6">
          <span className="self-center">
            {question.info} {!question.is_required && hasWriteAccess ? <span className="text-greyFour text-sm">(optionnel)</span> : ''}
          </span>
          {hasWriteAccess ? (
            <div className="flex gap-5">
              <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                <button
                  type="button"
                  onClick={() => {
                    setData(question);
                    open('question-actions-modal');
                  }}
                >
                  <img src={pen} alt="Pen" />
                </button>
              </div>
              {question.answer?.answer ? (
                <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                  <button
                    type="button"
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setItemToDelete({ id: question.answer?.id, type: 'contentValue' });
                      open('delete-confirmation-modal-questions');
                    }}
                  >
                    <img src={trashBin} alt="Trash bin" />
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="mt-6 text-sm text-greyTwo">
          {question.type === 1 || question.type === null ? (
            <QuestionTypeActions
              isPublished={isPublished}
              hasWriteAccess={hasWriteAccess}
              answer={question.answer}
              noAnswerText={noAnswerText}
              setData={setData}
              question={question}
              setItemToDelete={setItemToDelete}
            />
          ) : null}
        </div>
      </div>
    ) : ('')
  );
}

import tailwindConfig from '../tailwind.config';

const carbonFootprintFlagChartBuilder = (totalcarbonFootprintValue, emissionsFlag, t) => {
  const greenFlag = tailwindConfig?.theme?.colors?.greenDark;
  const flagPercentage = (emissionsFlag / totalcarbonFootprintValue) * 100;
  const notFlagPercentage = 100 - flagPercentage;

  const data = [
    {
      value: flagPercentage.toFixed(0),
      color: greenFlag,
      id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_25'),
      scope: 12,
    },
    {
      value: notFlagPercentage.toFixed(0),
      color: '#E1E3EB',
      id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_26'),
      scope: 3,
    },
  ];

  return data;
};

export default carbonFootprintFlagChartBuilder;

import React from 'react';
import { useTranslation } from 'react-i18next';

import StatusTemplate from './StatusTemplate';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';
import TemplateModal from '../../../Modal/TemplateModal';

import './Status.scss';

function StatusListModal({ statusList }) {
  const { t } = useTranslation();

  return (
    <TemplateModal
      modalId="status-list-modal"
      title={t('STATUSES')}
    >
      <div className="p-4">
        {statusList && statusList.sort((a, b) => a.position - b.position).map((element, index) => (
          <StatusTemplate
            key={index}
            title={element.name}
            description={element.description}
            color={element.color}
            backgroundColor={getHexColorWithOpacity(element.color, 0.1)}
            additionalClass={statusList.length !== index + 1 ? 'mb-4' : ''}
          />
        ))}
      </div>
    </TemplateModal>
  );
}

export default StatusListModal;

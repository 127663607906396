import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { useUserLocale } from '../../../../utils/getUserLocal';

function Audit({ profile }) {
  const [carbonProviderData, setCarbonProviderData] = useState();
  const [auditorData, setAuditorData] = useState();

  const locale = useUserLocale();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profile.id}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { t } = useTranslation();

  const fetchAuditData = async () => {
    try {
      await get(`/audit?locale=${locale}`);
      if (response.ok) {
        setAuditorData(response.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchCarbonProviderData = async () => {
    try {
      await get('carbon-service-provider');
      if (response.ok) {
        setCarbonProviderData(response.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchCarbonProviderData();
    fetchAuditData();
  }, [profile, locale]);

  return (
    carbonProviderData?.id || auditorData ? (
      <div className="p-5 white-bg mb-4 border border-solid border-greySix rounded">
        <div className="text-base font-semibold text-black flex items-middle justify-between mb-3.5">
          <h4 className="text-greyOne text-base">
            {t('AUDIT_AND_CARBON_PROVIDER')}
          </h4>
        </div>
        {auditorData?.name && auditorData?.url ? (
          <div>
            <p className="text-sm text-greyOne">
              {t('CARBON_AUDITOR')}
            </p>
            <p className="text-sm font-semibold text-greyOne mt-1">
              {auditorData && auditorData?.name}
            </p>
            {auditorData && auditorData?.url && (
            <a href={auditorData && auditorData?.url} className="text-sm text-greyFour hover:text-greyThree block underline mt-3.5" target="_blank" rel="noreferrer">
              {t('AUDIT_AND_CONSEIL_TEXT_3')}
            </a>
            )}
          </div>
        ) : null}
        {carbonProviderData?.carbon_service_provider_id ? (
          <div className="flex justify-between mt-3.5">
            <div className="self-center flex flex-col">
              <p className="text-sm text-greyOne">
                { t('Prestataire Carbone')}
              </p>
              <p className="text-sm font-semibold text-greyOne mt-1">
                {carbonProviderData?.carbon_service_provider_name}
              </p>
            </div>
            {carbonProviderData?.carbon_service_provider_id && carbonProviderData.carbon_service_provider_is_partner ? (
              <div className="mr-3 flex items-center">
                {(carbonProviderData.carbon_service_provider_is_published === 0) ? (
                  <img
                    src={carbonProviderData.imageSrc}
                    alt={carbonProviderData.carbon_service_provider_name}
                    className="max-h-12 max-w-12"
                    width={48}
                    height={48}
                  />
                ) : (
                  <a
                    href={`/${locale}/carbon-service-provider/${carbonProviderData.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src={carbonProviderData.imageSrc}
                      alt={carbonProviderData.carbon_service_provider_name}
                      className="max-h-12 max-w-12"
                      width={48}
                      height={48}
                    />
                  </a>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
        {carbonProviderData?.carbon_service_provider_is_partner && carbonProviderData.services_provided.length > 0 ? (
          <div className="mt-3.5">
            <div className="text-sm text-greyThree">
              { t('SERVICES_PROVIDED')}
            </div>
            <div className="mt-1 flex itms-center gap-[5px] flex-wrap">
              {carbonProviderData.services_provided.map((prestation) => (
                <div className="text-greyTwo text-xs border border-greySix rounded-[40px] bg-backgroundCleanBlue px-4 py-1.5">{prestation.name}</div>
              ))}
            </div>
          </div>
        ) : ('')}
        {carbonProviderData && carbonProviderData.carbon_service_provider_is_partner ? (
          <div className="mt-3.5">
            <a href={`/${locale}/carbon-service-provider/${carbonProviderData.slug}`} target="_blank" rel="noopener noreferrer" className="text-sm text-greyFour underline">
              {t('DISCOVER_CARBON_PROVIDER_PAGE')}
            </a>
          </div>
        ) : ('')}
      </div>
    ) : null
  );
}

export default Audit;

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function CurrentProgressHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'current_progress';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 px-3 font-medium cursor-pointer border-solid border-greySix border border-b-0" style={cellStyle} onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)}>
      <div className="flex justify-center">
        <div className="flex items-center">
          <div className="flex flex-col justify-center">
            <span>
              {t('CURRENT_PROGRESS')}
            </span>
            <span id="climate-action-subtitle" className="font-10 text-muted">
              {t('COMPARED_TO_COMMITMENTS')}
            </span>
          </div>
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end ml-2.5 self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="current progress" />
            </span>
          )}
        </div>
      </div>
    </th>
  );
}

function CurrentProgressBody({ profile, cellStyle, onClick }) {
  const [isSbtiTarget, setIsSbtiTarget] = useState();
  const { t } = useTranslation();

  // Vérifier si il y a un engagement Sbti
  useEffect(() => {
    if (profile.target_sbt === 1 || profile.target_sbt === 2 || profile.target_sbt === 3) {
      setIsSbtiTarget(true);
    } else {
      setIsSbtiTarget(false);
    }
  }, [profile]);

  // Indiquer l'avancement en fonction des résultats de décarbonation scope 3
  const getCurrentProgressInfos = (degreePosition) => {
    const roundedLevel = Math.floor(degreePosition);
    return roundedLevel >= 5 ? t('CURRENT_PROGRESS_IN_LINE') : t('CURRENT_PROGRESS_NOT_IN_LINE');
  };

  return (
    <td className="border-solid border-greySix border text-center text-sm h-[60px]" style={cellStyle}>
      <Link to={onClick(profile)} className="px-3 no-underline flex w-full h-full items-center justify-center text-greyOne">
        {!isSbtiTarget ? <img src={minus} alt="minus" /> : getCurrentProgressInfos(profile.complete_degree_position)}
      </Link>
    </td>
  );
}

export { CurrentProgressBody, CurrentProgressHead };

import React from 'react';
import { useTranslation } from 'react-i18next';

import spin from '../../../assets/ui/spin.svg';

import getLocalLink from '../../../utils/getLocalLink';

function GetRecontactedForm({ handleSubmit, onSubmitSecondForm, register, errors, loading, setGetRecontacted }) {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onSubmitSecondForm)}
      className="bg-white pt-[30px] xl:w-[640px] xl:right-[80px] xl:absolute rounded-lg xl:rounded-xl xl:border xl:border-solid xl:border-greySix xl:px-10 xl:pt-[30px] xl:pb-[30px] text-greyOne px-[30px] pb-[30px] mb-[60px] xl:mb-0"
    >
      <h5 className="text-xl font-normal text-center text-greyOne">
        {t('LANDING_LECLERC.FORM.TEXT_L')}
      </h5>
      <div className="flex gap-2.5 mt-[30px] flex-col xl:flex-row">
        <div className="xl:w-1/2 text-sm">
          <label htmlFor="firstname" className="text-greyOne">
            {t('LANDING_LECLERC.FORM.INPUT.FIRSTNAME.LABEL')}
          </label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            className={`w-full rounded-md border border-solid px-3.5 py-3 mt-1 text-greyThree bg-greySeven ${errors.firstname ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
            {...register('firstname', {
              required: t('LANDING_LECLERC.FORM.INPUT.FIRSTNAME.REQUIRED'),
            })}
          />
          {errors && errors.firstname && (
          <div className="mt-1.5 text-sm text-redError">{errors.firstname?.message}</div>
          )}
        </div>
        <div className="xl:w-1/2 text-sm">
          <label htmlFor="lastname" className="text-greyOne">
            {t('LANDING_LECLERC.FORM.INPUT.LASTNAME.LABEL')}
          </label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            className={`w-full rounded-md border border-solid px-3.5 py-3 mt-1 text-greyThree bg-greySeven ${errors.lastname ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
            {...register('lastname', {
              required: t('LANDING_LECLERC.FORM.INPUT.LASTNAME.REQUIRED'),
            })}
          />
          {errors && errors.lastname && (
          <div className="mt-1.5 text-sm text-redError">{errors.lastname?.message}</div>
          )}
        </div>
      </div>
      <div className="flex gap-2.5 mt-6 flex-col xl:flex-row">
        <div className="xl:w-1/2 text-sm">
          <label htmlFor="job" className="text-greyOne">
            {t('LANDING_LECLERC.FORM.INPUT.JOB.LABEL')}
          </label>
          <input
            type="text"
            id="job"
            name="job"
            className={`w-full rounded-md border border-solid  px-3.5 py-3 mt-1 text-greyThree bg-greySeven ${errors.job ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
            {...register('job', {
              required: t('LANDING_LECLERC.FORM.INPUT.JOB.REQUIRED'),
            })}
          />
          {errors && errors.job && (
          <div className="mt-1.5 text-sm text-redError">{errors.job?.message}</div>
          )}
        </div>
        <div className="xl:w-1/2 text-sm">
          <label htmlFor="phone" className="text-greyOne">
            {t('LANDING_LECLERC.FORM.INPUT.PHONE.LABEL')}<span className="text-[10px] text-greyThree">{t('LANDING_LECLERC.FORM.OPTIONAL')}</span>
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className={`w-full rounded-md border border-solid  px-3.5 py-3 mt-1 text-greyThree bg-greySeven ${errors.phone ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
            {...register('phone', {
              required: false,
              pattern: {
                value: /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
                message: t('LANDING_LECLERC.FORM.INPUT.PHONE.INVALID'),
              },
            })}
          />
          {errors && errors.phone && (
          <div className="mt-1.5 text-sm text-redError">{errors.phone?.message}</div>
          )}
        </div>
      </div>
      <div className="mt-5">
        <label htmlFor="message" className="text-greyOne">
          {t('LANDING_LECLERC.FORM.INPUT.MESSAGE.LABEL')}<span className="text-[10px] text-greyThree">{t('LANDING_LECLERC.FORM.OPTIONAL')}</span>
        </label>
        <div>
          <textarea
            name="message"
            id="message"
            className={`w-full rounded-md border border-solid  px-3.5 py-3 mt-1 text-greyThree bg-greySeven ${errors.message ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
            rows={5}
            {...register('message', {
              required: false,
            })}
          />
          {errors && errors.message && (
          <div className="mt-1.5 text-sm text-redError">{errors.message?.message}</div>
          )}
        </div>
      </div>
      <div className="mt-3 text-xs text-greyOne">
        {t('LANDING_LECLERC.FORM.TEXT_H')}
        <a href={`${getLocalLink('/invitation-adhesion-fournisseurs/politique-de-confidentialite')}`} className="no-underline font-medium text-secondarySkyBlue" rel="nofollow noreferrer" target="blank">
          {t('LANDING_LECLERC.FORM.TEXT_I')}
        </a>
      </div>
      <div className="flex justify-between items-center gap-5 mt-7">
        <div>
          <button type="button" className="border-0 bg-transparent px-0" onClick={() => setGetRecontacted(false)}>
            {t('LANDING_LECLERC.FORM.BACK')}
          </button>
        </div>
        <div className="">
          <button type="submit" disabled={loading} className={`w-full py-3 font-medium bg-primaryNewBlue text-white border-0 rounded-md px-8 hover:bg-primaryNewBlueHover items-center justify-center ${loading ? 'disabled' : ''}`}>
            {loading ? <img src={spin} alt="spin" /> : t('LANDING_LECLERC.FORM.SEND')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default GetRecontactedForm;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { CKEditor } from 'ckeditor4-react';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';
import { useUserLocale } from '../../../../../utils/getUserLocal';
import handleResponse from '../../../../../utils/handleResponse';

export default function QuestionQuantitativeFormModal({ data, profileId, fetchData }) {
  const [quantitativeData, setQuantitativeData] = useState();
  const [editor, setEditor] = useState(null);
  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({});
  const { close } = useModal();
  const locale = useUserLocale();

  const { post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions/${data?.id}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const amount = watch('amount');
  const year = watch('year');

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  useEffect(() => {
    if (editor) {
      editor.setData(quantitativeData ? quantitativeData.more : null);
    }
    register('more', {
      required: false,
    });
  }, [data]);

  const onSubmit = async (formData) => {
    try {
      const finalData = {
        question_type: data.type,
        answer: {
          amount: formData.amount,
          year: formData.year,
          more: formData.more,
        },
        locale,
        type: 'TYPE_CARBON_DATA_AUDIT',
      };
      if (data?.answer?.id) {
        await put(`answer/${data.answer.id}`, finalData);
      } else {
        await post('answer', finalData);
      }
      if (response.ok) {
        close();
        fetchData();
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (data && data.answer && data.type === 2 && data.answer.answer !== '') {
      setQuantitativeData(JSON.parse(data.answer.answer));
    }
  }, [data]);

  return (
    <TemplateFormModal
      modalId="question-quantitative-modal"
      formId="question-quantitative-form"
      title="Éditer une réponse"
    >
      <form
        id="question-quantitative-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="p-4 md:p-5 space-y-4">
          <div className="flex gap-6 mb-4">
            <div>
              <label htmlFor="url" className="text-base">
                Montant
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  id="amount"
                  required
                  className={`border border-greySix rounded-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors.amount ? 'focus:outline-redError' : 'focus:outline-secondarySkyBlue'}`}
                  defaultValue={quantitativeData?.amount}
                  {...register('amount', {
                    required: amount ? 'Le montant est requis' : false,
                  })}
                />
                {errors.amount && (
                  <div className="text-sm text-redError">{errors.amount.message}</div>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="url" className="text-base">
                Unité
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="unit"
                  required
                  className="border border-greySix rounded-md text-greyFour px-5 py-2.5 font-normal text-base w-full"
                  defaultValue={data?.unit_name}
                  readOnly
                />
              </div>
            </div>
            <div>
              <label htmlFor="url" className="text-base">
                Année de mesure
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  id="year"
                  min="1970"
                  max={(new Date()).getFullYear() + 5}
                  required
                  className={`border border-greySix rounded-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors.year ? 'focus:outline-redError' : 'focus:outline-secondarySkyBlue'}`}
                  defaultValue={quantitativeData?.year}
                  {...register('year', {
                    required: year ? 'L\'année est requise' : false,
                  })}
                />
                {errors.year && (
                  <div className="text-sm text-redError">{errors.year.message}</div>
                )}
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="name" className="text-base">
              {data?.info}
            </label>
            <div className="mt-2">
              <CKEditor
                initData={quantitativeData?.more}
                onLoaded={onBeforeLoad}
                onChange={(value) => setValue('more', value.editor.getData().replace(/(\r\n|\n|\r)/gm, ''))}
                required={false}
                config={{
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', 'Strike'],
                    ['NumberedList', 'BulletedList'],
                  ],
                  versionCheck: false,
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}

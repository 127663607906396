import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import getLocalLink from '../../../utils/getLocalLink';
import getLogout from '../../../utils/getLogout';
import Dashboard from '../../../assets/ui/dashboard.svg';
import Logout from '../../../assets/ui/logout.svg';
import arrow from '../../../assets/ui/caret-right.svg';
import verifyAuthorization from '../../../utils/verifyAuthorization';

function Authenticated({ session, partner }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const initials = `${session.firstname[0]}${session.lastname[0]}`;

  return (
    <div ref={dropdownRef} className="relative">
      <button
        type="button"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center space-x-2 p-2 rounded-lg hover:bg-greyEight transition-colors"
      >
        <span
          className="flex items-center justify-center w-8 h-8 rounded-xl border-2 border-greyEight text-white text-xs"
          style={{ background: partner.primary_color }}
        >
          {initials}
        </span>
        <span className="text-sm text-greyOne">
          {session.firstname} {session.lastname}
        </span>
        <img src={arrow} width={15} height={15} alt="open menu" className="-rotate-90" />
      </button>

      {/* Dropdown menu */}
      <div
        className={`absolute left-0 mt-1 w-48 bg-white rounded-lg shadow-lg border border-greySix py-1 transition-all transform origin-top
          ${dropdownOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0 pointer-events-none'}
        `}
      >
        {verifyAuthorization(session, ['ROLE_ADMIN', 'ROLE_PARTNER_ADMIN']) && (
          <Link
            to={getLocalLink('/dashboard/home')}
            className="flex items-center px-4 py-2 text-sm text-greyOne hover:bg-greyEight transition-colors"
          >
            <img src={Dashboard} alt="" className="w-4 h-4 mr-2" />
            {t('DASHBOARD')}
          </Link>
        )}

        <button
          type="button"
          onClick={getLogout}
          className="w-full flex items-center px-4 py-2 text-sm text-greyOne hover:bg-greyEight transition-colors"
        >
          <img src={Logout} alt="" className="w-4 h-4 mr-2" />
          {t('DISCONNECT')}
        </button>
      </div>
    </div>
  );
}

export default Authenticated;

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dropdown,
  MultiLevelDropdown,
  SelectedCategories,
} from '@Openclimat/openclimat-component-library';

function StatisticsFilter({
  levels,
  selectedCategoryId,
  onCategoryChange,
  getCategoryName,
  onRemoveCategory,
  tags,
  selectedTag,
  handleTagsFilter,
  countries,
  selectedCountry,
  handleCountryFilter,
  weights,
  selectedWeight,
  handleWeightFilter,
  demoIsEnabled,
}) {
  const { t } = useTranslation();

  return (
    <div className="mb-[18px]">
      <div className="flex gap-2 flex-wrap">
        <MultiLevelDropdown
          name={t('CATEGORIES')}
          selectAll={t('PARTNER_DASHBOARD.STATISTICS.FILTER.ENTIRE_CATEGORY')}
          choicePlaceholder={t('PARTNER_DASHBOARD.STATISTICS.FILTER.CATEGORY_CHOOSE')}
          levels={levels}
          selectedCategoryId={selectedCategoryId}
          onChange={onCategoryChange}
        />
        {tags && tags.length > 0 && (
          <Dropdown
            name={t('TAGS')}
            handleChange={handleTagsFilter}
            value={selectedTag}
            options={tags}
          />
        )}
        {demoIsEnabled && (
          <>
            <Dropdown
              name={t('COUNTRIES')}
              handleChange={handleCountryFilter}
              value={selectedCountry}
              options={countries}
            />
            <Dropdown
              name="Pondération"
              handleChange={handleWeightFilter}
              value={selectedWeight}
              options={weights}
            />
          </>
        )}
      </div>
      {selectedCategoryId && (
        <SelectedCategories
          categoryId={selectedCategoryId}
          getCategoryName={getCategoryName}
          onRemove={onRemoveCategory}
        />
      )}
    </div>
  );
}

export default StatisticsFilter;

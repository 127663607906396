import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';
import handleResponse from '../../../../../utils/handleResponse';

export default function QuestionActionDistributionFormModal({ data, profileId, fetchData }) {
  const [values, setValues] = useState({});
  const [total, setTotal] = useState(0);
  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({});

  const { post, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions-distributions`, {
    credentials: 'include',
  });

  const { close } = useModal();

  const updateTotal = (value) => {
    const newTotal = Object.values(value)
      .filter((value) => value !== '' && value !== null)
      .map((value) => parseInt(value, 10))
      .reduce((acc, value) => acc + value, 0);
    setTotal(newTotal);
  };

  useEffect(() => {
    if (data) {
      const defaultValues = {};

      data.forEach((category) => {
        defaultValues[category.individualQuestions[0].questionId] = category.distribution[0] ? category.distribution[0].percentage : '';
      });

      reset(defaultValues);
      setValues(defaultValues);
    }
  }, [data]);

  const handleSelectChange = (questionId, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [questionId]: value,
    }));
  };

  const onSubmit = async (formData) => {
    try {
      const submittedData = {};

      const inputsData = Object.entries(formData).map((obj) => ({
        id: obj[0],
        value: obj[1],
      }));

      submittedData.data = inputsData;

      if (total <= 100) {
        await post(submittedData);

        if (response.ok) {
          fetchData();
          close();
        }
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  useEffect(() => {
    watch((value) => {
      updateTotal(value);
    });
  }, [watch]);

  const selectOptions = [
    {
      value: '',
      text: '--',
    },
    {
      value: 0,
      text: '<1',
    },
    {
      value: 2,
      text: '<5',
    },
  ];

  for (let value = 5; value <= 100; value += 5) {
    selectOptions.push({
      value,
      text: value,
    });
  }

  return (
    <TemplateFormModal
      modalId="question-actions-distributions-modal"
      formId="question-actions-distributions-form"
      title="Éditer le sommaire du plan d'action"
    >
      <form
        id="question-actions-distributions-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="p-4">
          <p className="mb-4">
            Renseignez la répartition en % de vos principales émissions de CO2.
          </p>
          {data && data.map((category, index) => (
            <div key={index} className="mb-3 flex justify-between">
              <label htmlFor="title" className="">
                {category.name}
              </label>
              <div className="">
                <select
                  className={`border py-1 px-2 rounded-sm ${errors[`${category.individualQuestions[0].questionId}`] ? 'border-redError focus:outline-redError' : 'border-greySix'} ${(values[category.individualQuestions[0].questionId] && (total > 100)) ? 'border-redError focus:outline-redError' : 'border-greySix'}`}
                  {...register(`${category.individualQuestions[0].questionId}`, {
                    onChange: (e) => handleSelectChange(category.individualQuestions[0].questionId, e.target.value),
                  })}
                >
                  {selectOptions.map((option, index) => (
                    <option key={index} value={option.value}>{option.text}</option>
                  ))}
                </select>
                {errors[`${category.individualQuestions[0].id}`] && (
                <div className="text-sm">{errors[`${category.individualQuestions[0].questionId}`].message}</div>
                )}
              </div>
            </div>
          ))}
          <div className="flex justify-between text-lg pt-3" style={{ borderTop: '1px solid #dee2e6' }}>
            <div>
              Total :
            </div>
            <div className={`font-medium ${total > 100 ? 'text-redError' : ''}`}>
              {total}%
            </div>
          </div>
          {(total > 100) && (
          <div className="text-redError text-sm">Le total de vos émissions ne peut pas être supérieur à 100%</div>
          )}
        </div>
      </form>
    </TemplateFormModal>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../../../../hooks/useNotifications';

import NotificationPoint from '../NotificationPoint';

import caretRight from '../../../../../assets/ui/caret-right.svg';
import dotsThree from '../../../../../assets/ui/dots-three.svg';

function Note({ profileId, setShowCategoryNotes, setNotes, selectedCategory, notes, handleClickShowSelectedNote, loading, session, del, response, getNotes, setEditedNote, setShowForm, categories }) {
  const [visibleOptions, setVisibleOptions] = useState(null);
  const { hasUnreadNotifications, markNoteAsRead } = useNotifications(profileId);

  const optionsRef = useRef(null);

  const { t } = useTranslation();

  const handleToggleOptions = (e, id) => {
    e.stopPropagation();
    setVisibleOptions(visibleOptions === id ? null : id);
  };

  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setVisibleOptions(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDeleteNote = async (e, id, categoryId) => {
    e.stopPropagation();
    await del(`/categories/${categoryId}/notes/${id}`);
    if (response.ok) {
      getNotes(selectedCategory.id);
    }
  };

  const handleEditNote = async (e, note) => {
    e.stopPropagation();
    setEditedNote(note);
    setShowForm(true);
  };

  const handleClickBackButton = () => {
    setShowCategoryNotes(false);
    setNotes([]);
  };

  const handleNoteClick = (note) => {
    markNoteAsRead(note.id);

    handleClickShowSelectedNote(note);
  };

  return (
    <>
      <button
        type="button"
        disabled={categories.length <= 1}
        className={`text-greyFour text-sm font-moskauGrotesk flex gap-1 items-center bg-white border-0 text-left px-0 ${categories.length > 1 ? 'cursor-pointer hover:text-greyThree' : 'cursor-default'}`}
        onClick={() => (categories.length > 1 ? handleClickBackButton() : null)}
      >
        {categories.length > 1 && (
          <img src={caretRight} alt="Caret right" />
        )}
        <span className="pt-0.5">
          {selectedCategory?.category_name}
        </span>
      </button>
      {loading && (
        <div className="bg-[#FDFDFD] border border-solid border-greySeven p-[14px] rounded mt-6">
          <div className="bg-greySeven animate-pulse h-12 rounded" />
        </div>
      )}
      {notes && !loading && notes?.map((note) => (
        <button
          type="button"
          key={note.id}
          className={`relative flex justify-between items-center gap-2.5 mt-2.5 border border-solid border-greySeven bg-[#FDFDFD] ${note.user_id === session.id ? 'py-6' : 'py-[16px]'} px-[13px] rounded w-full text-left font-moskauGrotesk hover:border-secondarySkyBlue relative`}
          onClick={() => handleNoteClick(note)}
        >
          {note.user_id === session.id && (
            <button
              type="button"
              className="absolute top-1.5 right-1.5 border-0 bg-[#FDFDFD]"
              onClick={(e) => handleToggleOptions(e, note.id)}
            >
              <img src={dotsThree} alt="Dots three" />
            </button>
          )}
          {visibleOptions === note.id && (
            <div
              ref={optionsRef}
              className="z-10 absolute top-8 right-0 p-1 bg-white rounded-[4px] flex flex-col gap-1"
              style={{ boxShadow: '0px 5px 10px -3.89px rgba(0, 0, 0, 0.1)' }}
            >
              <button
                type="button"
                className="mb-0 text-greyThree font-moskauGrotesk text-sm border-0 bg-white py-0.5 px-1.5 text-left hover:bg-greyEight rounded-sm"
                onClick={(e) => handleEditNote(e, note)}
              >
                {t('NOTES.EDIT')}
              </button>
              <button
                type="button"
                className="mb-0 text-greyThree text-sm font-moskauGrotesk border-0 bg-white py-0.5 px-1.5 text-left hover:bg-greyEight rounded-sm"
                onClick={(e) => handleDeleteNote(e, note.id, note.partner_category_as_profile_id)}
              >
                {t('NOTES.DELETE')}
              </button>
            </div>
          )}
          <div>
            <div className="text-greyThree text-xs">
              {note.firstname} {note.lastname}
            </div>
            <div className="text-primaryNewBlue text-sm font-semibold mt-2.5">
              {note.title}
            </div>
          </div>
          <div className="text-greyFour text-xs">
            {note ? new Date(note.created_at).toLocaleDateString() : null}
          </div>
          {hasUnreadNotifications(null, note.id) && (
            <div className="absolute right-[6px] top-[6px]">
              <NotificationPoint />
            </div>
          )}
        </button>
      ))}
    </>
  );
}

export default Note;

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';

function PartnerProfileRatingForm({ formContext }) {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({});
  const { action, modalTitle, title, profileId, partnerId, maxRating, data } = formContext;

  const { t } = useTranslation();

  const { close } = useModal();

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/profiles/${profileId}/rating`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  useEffect(() => {
    if (formContext.data) {
      reset(formContext.data);
    }
    register('profileComment', {
      required: false,
    });
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    if (action === 'create') {
      await api.post('', formData);
    } else if (action === 'edit') {
      await api.put(data.profileRatingId, formData);
    }
    if (api.response.ok) {
      data.getProfileRating();
      close();
    }
  };

  return (
    <TemplateFormModal
      modalId="partner-profile-rating-modal"
      formId="partner-profile-rating-form"
      title={modalTitle}
    >
      <form
        id="partner-profile-rating-form"
        onSubmit={handleSubmit(onSubmit)}
        className="needs-validation"
        noValidate
      >
        <div className="p-4">
          <div className="mb-3">
            <label htmlFor="rating" className="form-label">
              {title}
            </label>
            <div className="has-validation">
              <div className={`${errors.rating ? 'is-invalid' : ''}`}>
                <input
                  type="number"
                  className={`flex w-full border-greySix border border-solid rounded-md py-3 px-5 text-greyFour ${
                    errors.email
                      ? 'focus:outline-redError'
                      : 'focus:outline-secondarySkyBlue'
                  }`}
                  min={0}
                  max={maxRating}
                  defaultValue={formContext.data ? formContext.data.profileRating : null}
                  {...register('rating', {
                    required: false,
                    min: {
                      value: 0,
                      message: t('RATING_ERROR_1'),
                    },
                    max: {
                      value: maxRating,
                      message: t('RATING_ERROR_2'),
                    },
                  })}
                />
              </div>
              {errors.rating && (
              <div className="mt-1 text-sm text-redError">{errors.rating.message}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}

export default PartnerProfileRatingForm;

import React from 'react';
import { useTranslation } from 'react-i18next';

import RenderTagOptions from './RenderTagOptions';

function RenderTagDropdown({ availableTags, setHoveredTagId, hoveredTagId, handleTagSelection, handleCreateTag, tailwindConfig, handleToggleMenu, openMenuTagId, handleEditTag, handleDeleteTag }) {
  const { t } = useTranslation();

  return (
    <div className="z-10 w-full bg-white border border-t-0 border-solid border-greySix rounded-b-md shadow-companyCard max-h-60 overflow-auto">
      {availableTags.length === 0 ? (
        <div className="text-sm text-greyThree font-moskauGrotesk p-[14px] border-b border-greyNine">
          {t('NO_TAG_AVAILABLE')}
        </div>
      ) : (
        <div className="flex flex-col gap-3 py-[14px] px-6">
          {availableTags.map((tag) => (
            <div
              key={`available-${tag.id}`}
              className="flex items-center"
              onMouseEnter={() => setHoveredTagId(tag.id)}
              onMouseLeave={() => setHoveredTagId(null)}
            >
              <button
                type="button"
                onClick={() => handleTagSelection(tag)}
                className="py-[6px] px-3 rounded text-sm font-moskauGrotesk font-medium text-greyOne border-none cursor-pointer"
                style={{ backgroundColor: tag.color || tailwindConfig.theme.colors.tagsGrey }}
              >
                {tag.name}
              </button>
              {hoveredTagId === tag.id && (
                <RenderTagOptions
                  tag={tag}
                  handleToggleMenu={handleToggleMenu}
                  openMenuTagId={openMenuTagId}
                  handleEditTag={handleEditTag}
                  handleDeleteTag={handleDeleteTag}
                />
              )}
            </div>
          ))}
        </div>
      )}
      <button
        type="button"
        onClick={handleCreateTag}
        className="w-full bg-greyEight text-sm text-primaryNewBlue border border-t border-x-0 border-b-0 border-solid border-greySix p-[14px] text-start"
      >
        {t('CREATE_NEW_TAG')}
      </button>
    </div>
  );
}

export default RenderTagDropdown;

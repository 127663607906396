import React from 'react';
import { useTranslation } from 'react-i18next';

import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

function Status({ profile }) {
  const { t } = useTranslation();

  return (
    <div className="mt-2 font-medium text-xs inline-flex py-1 px-2 rounded-full" style={{ color: profile.status_color, backgroundColor: getHexColorWithOpacity(profile.status_color, 0.1) }}>
      {t(profile.status_name)}
    </div>
  );
}

export default Status;

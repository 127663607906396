import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainChart from './MainChart';
import BarChartLegend from './BarChartLegend';

import percentageByStatuts from '../DataSample/percentageBySbtiStatus.json';
import profileCountBySbtiStatus from '../DataSample/profileCountBySbtiStatus.json';

function SbtiDistributionEvolution({
  selectedCategoryId,
  isOpen,
  selectedStatus,
  setSelectedStatus,
  selectedTagId,
  demoIsEnabled,
}) {
  const [sbtiPercentageByStatus, setSbtiPercentageByStatus] = useState();
  const [profileCountsBySbtiStatus, setProfileCountsBySbtiStatus] = useState();
  const [colorsObjects, setColorsObjects] = useState();
  const [legend, setLegend] = useState();

  const { t } = useTranslation();

  const getStats = async () => {
    // FAKE DATA
    setSbtiPercentageByStatus(percentageByStatuts);
    setProfileCountsBySbtiStatus(profileCountBySbtiStatus);

    setColorsObjects({
      'Pas engagé*': ['#E63756', '#DE3855'],
      'Engagé hors SBTi*': ['#F68F57', '#ED8045'],
      'Engagé SBTi*': ['#F8D55B', '#F6E7B0'],
      'Engagé et validé SBTi*': ['#0EBF76', '#86DBB8'],
    });
    setLegend({
      'Pas engagé': '#E63756',
      'Engagé hors SBTi': '#F68F57',
      'Engagé SBTi': '#F8D55B',
      'Engagé et validé SBTi': '#0EBF76',
    });
  };

  useEffect(() => {
    getStats();
  }, [selectedCategoryId, selectedTagId]);

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  return (
    <div className="w-full flex flex-col justify-between bg-white border border-solid border-greySix rounded-md p-5">
      <div className="text-base font-inter font-bold text-greyOne">
        {t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION_EVOLUTION.TITLE')}
      </div>

      <div className="w-full flex max-[903px]:flex-col max-[903px]:items-center items-stretch gap-x-5 max-[903px]:gap-y-5 mt-[14px]">
        <div className="w-full h-[300px]">
          <MainChart
            sbtiPercentageByStatus={sbtiPercentageByStatus}
            profileCountsBySbtiStatus={profileCountsBySbtiStatus}
            colorsObjects={colorsObjects}
            onStatusSelect={handleStatusSelect}
            selectedCategoryId={selectedCategoryId}
            selectedStatus={selectedStatus}
            isOpen={isOpen}
          />
        </div>
      </div>
      <BarChartLegend
        legend={legend}
        selectedStatus={selectedStatus}
        onStatusSelect={handleStatusSelect}
        className="justify-center"
        demoIsEnabled={demoIsEnabled}
      />
    </div>
  );
}

export default SbtiDistributionEvolution;

import React from 'react';

function CompanyLogo({ company }) {
  return (
    <div>
      <img
        src={`${process.env.REACT_APP_FILES_DOMAIN}${company.logo_path}${company.logo_name}`}
        alt={company.name}
        width={50}
        height={50}
        loading="lazy"
      />
    </div>
  );
}

export default CompanyLogo;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { LocaleProvider } from './context/LocaleContext';

import router from './router';
import i18n from './i18n/i18n'; // eslint-disable-line no-unused-vars
import StoreProvider from './context/StoreProvider';
import isProductionEnvironement from './utils/isProductionEnvironement';

Sentry.init({
  enabled: isProductionEnvironement(),
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION,
  dsn: 'https://d224d5a23f87fc812c7d99021c75ff39@o4506666201710592.ingest.sentry.io/4506666339336192',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost'],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <LocaleProvider>
      <StoreProvider>
        <RouterProvider router={router} />
      </StoreProvider>
    </LocaleProvider>
  </React.StrictMode>,
);

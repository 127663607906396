import React from 'react';

import Question from './Question';

function Category({ category, profile, partner, isPublished, hasWriteAccess, view, fetchData, setShowOptional, setData, setItemToDelete }) {
  return (
    <section id={category.id} className="category-test -mt-24 pt-24">
      <div className="rounded border border-greySix border-solid">
        <div className="p-6">
          <h2 className="flex">
            {category.name}
          </h2>
          <div className="flex flex-col gap-6">
            {category.questions.map((question, index) => (
              <Question
                key={index}
                profile={profile}
                question={question}
                isPublished={isPublished}
                partner={partner}
                view={view}
                hasWriteAccess={hasWriteAccess}
                fetchData={fetchData}
                setShowOptional={setShowOptional}
                setData={setData}
                setItemToDelete={setItemToDelete}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category;

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import chevronDown from '../../../../assets/ui/chevron-down-grey.svg';
import crossModal from '../../../../assets/ui/cross-modal.svg';
import exclamationMark from '../../../../assets/ui/exclamation-mark.svg';

export default function SubCategorySelector({
  subCategories,
  selectedSubCategories,
  setSelectedSubCategories,
  subCategoriesError,
  setSubCategoriesError,
  subCategoriesRef,
  subCategoriesLevelName,
  editedCategoryId,
}) {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [tooltipId, setTooltipId] = useState(null);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleCheckboxChange = (subCategory) => {
    setSubCategoriesError('');
    if (selectedSubCategories.some((sub) => sub.id === subCategory.id)) {
      setSelectedSubCategories(selectedSubCategories.filter((sub) => sub.id !== subCategory.id));
    } else {
      setSelectedSubCategories([...selectedSubCategories, subCategory]);
    }
  };

  const removeSubCategory = (subCategoryId) => {
    setSelectedSubCategories(selectedSubCategories.filter((item) => item.id !== subCategoryId));
  };

  // affichage tooltip au hover si sous catégorie déjà sélectionnée
  const handleMouseEnter = (subCategory) => {
    if (subCategory.parent_category_id !== null && subCategory.parent_category_id !== editedCategoryId) {
      setTooltipId(subCategory.id);
      setTooltipMessage(t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.SUBCATEGORIES_TOOLTIP'));
    }
  };

  const handleMouseLeave = () => {
    setTooltipId(null);
  };

  return (
    <div ref={dropdownRef}>
      <h2 className="text-base mb-2">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_E')}
      </h2>
      <div className="relative mb-9">
        <button
          type="button"
          ref={subCategoriesRef}
          className={`h-11 w-full  text-left text-sm font-moskauGrotesk font-normal text-greyHeavy border border-solid ${subCategoriesError ? 'border-2 border-red bg-red/5 focus:border-red focus:border' : 'border-greyLight bg-white'}  focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-md p-2 flex items-center justify-between`}
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={showDropdown}
        >
          <span className="flex items-center grow">
            {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.SUBCATEGORY_PLACEHOLDER')}
          </span>
          <img
            src={chevronDown}
            alt="Toggle dropdown"
            className="ml-4 h-5 w-5"
          />
        </button>
        <div className="flex flex-wrap gap-2 list-none p-0 mt-4">
          {selectedSubCategories.map((category) => (
            <div key={category.id} className="py-1 px-4 bg-greyLight border border-solid border-primaryNewBlue/30 text-sm text-greyHeavy font-moskauGrotesk font-normal rounded-full flex items-center">
              {category.name}
              <button
                type="button"
                onClick={() => removeSubCategory(category.id)}
                className="border-none p-0 ml-2"
              >
                <img
                  src={crossModal}
                  alt="delete resource"
                  width="16px"
                  className="mb-[2px]"
                />
              </button>
            </div>
          ))}
        </div>
        {subCategoriesError && (
          <span className=" text-sm font-moskauGrotesk font-normal text-red mb-4">
            <img
              src={exclamationMark}
              alt="exclamation mark"
              className=" mr-1 -mt-[3px]"
            />
            {subCategoriesError}
          </span>
        )}
        {showDropdown && (
          <div
            className="absolute top-11 z-10 w-full max-h-72 flex flex-col overflow-auto rounded-md bg-white py-4 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm border border-solid border-greyLight shadow-customDropdown"
            role="listbox"
            aria-label="Subcategories"
          >
            <p className="text-sm text-greyFive font-moskauGrotesk font-normal border-b border-solid border-x-0 border-t-0 border-greyLight pl-4 pb-2 mb-0">
              {subCategoriesLevelName}
            </p>
            {subCategories && subCategories.map((subCategory) => {
              const isDisabled = subCategory.parent_category_id !== null && subCategory.parent_category_id !== editedCategoryId;
              return (
                <div
                  key={subCategory.id}
                  className="relative border-b border-greyLight"
                  onMouseEnter={() => handleMouseEnter(subCategory)}
                  onMouseLeave={handleMouseLeave}
                >
                  <label
                    className={`flex items-center hover:bg-backgroundCleanBlue cursor-pointer text-greyTwo font-moskauGrotesk font-normal rounded ${isDisabled ? 'opacity-50' : ''}  pl-4 py-3`}
                  >
                    <input
                      type="checkbox"
                      className="mr-2 accent-primaryNewBlue"
                      checked={subCategory.parent_category_id}
                      onChange={() => handleCheckboxChange(subCategory)}
                      disabled={isDisabled}
                    />
                    {subCategory.name}
                  </label>
                  {tooltipId === subCategory.id && (
                    <div className="absolute left-32 xl:left-40 top-0 ml-2 w-64 p-2 text-xs text-white bg-greyTwo rounded-sm shadow-lg">
                      {tooltipMessage}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * CampaignSummaryCard component displays a summary card with a percentage, title, optional link, and an icon.
 *
 * @param {Object} props - The component props.
 * @param {string} props.percentage - The percentage to display.
 * @param {string} props.title - The title of the campaign.
 * @param {string} [props.linkName=null] - The optional link name to display.
 * @param {string} props.icon - The URL of the icon to display.
 * @returns {JSX.Element} The rendered CampaignSummaryCard component.
 */
import React from 'react';

function CampaignSummaryCard({ percentage, title, linkName = null, icon }) {
  return (
    <div className="relative flex-1 flex items-center  px-5 py-3 bg-white border-[1px] border-solid border-greySeven rounded-md">
      <div className="flex flex-col justify-between">
        <span className="text-2xl text-primaryNewBlue font-extrabold">{percentage}</span>
        <span className="text-sm text-greyFour font-poppins">{title}</span>
        {linkName ? (
          <span className="text-primaryCorail text-xs font-medium">{linkName}</span>
        ) : null}
      </div>
      <img src={icon} alt="icon" className="absolute right-5 top-2 w-[30px] h-[30px]" />
    </div>
  );
}

export default CampaignSummaryCard;

import React from 'react';
import { useTranslation } from 'react-i18next';

function NoCimateActions({ dataUsable, dataNotComparable }) {
  const { t } = useTranslation();

  return (
    <div className="w-full lg:col-span-3 lg:col-start-7 text-xs text-center">
      <div className="text-greyThree font-moskauGrotesk font-normal">
        {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
      </div>
      <div className="text-noteTresInsuffisante mt-1 font-semibold font-moskauGrotesk">
        {dataUsable ? t('CLIMATE_ACTIONS.RECENT_MESSURES') : ''}
        {!dataUsable && dataNotComparable ? t('CLIMATE_ACTIONS.NOT_COMPARABLE') : ''}
      </div>
    </div>
  );
}

export default NoCimateActions;

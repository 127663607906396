/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import circleQuestion from '../../../../../../assets/ui/circle-question.svg';
import circleQuestionScope3 from '../../../../../../assets/ui/circle-question-scope-3.svg';

import NoAnswer from '../../../NoAnswer';
import CarbonFootprintDistributionPie from './Chart';
import chartDataBuilder from '../../../../../../utils/getChartData';

function QuestionTypeCarbonDistribution({ isPublished, hasWriteAccess, answer, noAnswerText, question }) {
  const [answeredModel, setAnsweredModel] = useState();
  const [parsedAnswer, setParsedAnswer] = useState();
  const [data, setData] = useState();
  const [dataFiltered, setDataFiltered] = useState();
  const [displayTable, setDisplayTable] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (answer && answer.distributions?.length > 0) {
      const placeholderAnsweredModel = question.carbonModel;

      placeholderAnsweredModel.forEach((element) => {
        answer.distributions.forEach((item) => {
          if (element.id === item.id) {
            element.percent = item.percent;
            element.id = item.answer_id;
          }
        });
      });

      setAnsweredModel(placeholderAnsweredModel);
      setParsedAnswer(answer.answer && JSON.parse(answer.answer));

      const [data, dataFiltered] = chartDataBuilder(placeholderAnsweredModel, t);
      setData(data);
      setDataFiltered(dataFiltered);
    }
  }, [answer]);

  const toggleTable = (e) => {
    e.preventDefault();
    setDisplayTable(!displayTable);
  };

  return (
    <>
      {(isPublished || hasWriteAccess) && (
        (answeredModel)
          && (
            <>
              <div className="xl:grid grid-cols-12 mt-4">
                <div className="col-span-4">
                  <div className="carbon-footprint-chart-container h-64 overflow-visible">
                    <CarbonFootprintDistributionPie
                      totalCarbonFootprintValue={parsedAnswer ? parseFloat(parsedAnswer.emissionsScope12) + parseFloat(parsedAnswer.emissionsScope3) : 0}
                      data={dataFiltered}
                      carbonFootprintYear={parsedAnswer ? parsedAnswer.carbonMixBalanceYear : null}
                    />
                  </div>
                </div>
                <div className="col-span-8 my-auto xl:grid grid-cols-12 xl:ps-5 pe-0 text-sm">
                  <div className="flex mt-4 xl:hidden gap-1">
                    <div className="xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 flex-nowrap bg-[#00E0FF0D] text-[#0ECAE3] rounded-full">
                      <div>
                        Scope 1 & 2
                      </div>
                      <img className="ms-1" src={circleQuestion} alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
                    </div>
                    <div className="xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 bg-[#000D850D] text-[#000D85AD] rounded-full">
                      <div>
                        Scope 3
                      </div>
                      <img className="ms-1" src={circleQuestionScope3} alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
                    </div>
                  </div>
                  <div className="col-span-8 xl:px-0 flex mt-4 xl:mt-0">
                    <div className="self-center h-7 w-7 min-h-7 min-w-7 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: '#00E0FF' }}>
                      <span className="self-center mx-auto mt-0.5">1</span>
                    </div>
                    <div className="self-center ms-3">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10')}
                    </div>
                  </div>
                  <div className="col-span-4 xl:flex hidden border-l-2 border-greySix">
                    <div className="ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex flex-nowrap bg-[#00E0FF0D] text-[#0ECAE3] rounded-full">
                      <div>
                        Scope 1 & 2
                      </div>
                      <img className="ms-1 mb-1" src={circleQuestion} alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="carbon-footprint-tooltip" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
                    </div>
                  </div>
                  <div className="col-span-8 pe-0 md:ps-0">
                    {(dataFiltered) ? (
                      dataFiltered.filter((item) => item.scope === 3).map((item, index) => (
                        <div key={index} className="flex mt-4">
                          <div className="self-center h-7 w-7 min-h-7 min-w-7 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: item.color }}>
                            <span className="self-center mx-auto mt-0.5">{index + 2}</span>
                          </div>
                          <div className="self-center ms-3">
                            {t(item.id)}
                          </div>
                        </div>
                      ))
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-span-4 xl:flex hidden mt-4 pe-0 border-l-2 border-greySix">
                    <div className="ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex bg-[#000D850D] text-[#000D85AD] rounded-full">
                      <div>
                        Scope 3
                      </div>
                      <img className="ms-1 mb-1" src={circleQuestionScope3} alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="carbon-footprint-tooltip" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
                    </div>
                  </div>
                </div>
              </div>
              {answeredModel.length > 0 ? (
                <>
                  <button type="button" onClick={toggleTable} className="text-greyFour hover:text-greyThree underline text-sm mt-6 bg-white border-0">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_15')}
                  </button>
                  <div style={{ maxHeight: (displayTable) ? '999px' : '0px', overflow: 'hidden' }} className="transition-all duration-1000 me-0 me-md-4">
                    {data.sort((a, b) => b.value - a.value).map((item, index) => (
                      item.value ? (
                        <div key={index} className="mt-3 md:grid grid-cols-12">
                          <div className="text-sm md:col-span-6 text-greyOne">
                            {t(item.id)}
                          </div>
                          <div className="flex md:col-span-6 mt-1 md:mt-0">
                            <div style={{ minHeight: '21px', maxHeight: '21px', width: `${item.value ? item.value : 0}%`, backgroundColor: item.color, borderRadius: '6px' }} />
                            <div className={`text-sm font-bold ${item.value ? 'ms-2' : ''}`}>
                              {item.value ? `${parseFloat(item.value).toFixed(1)}%` : t('NOT_COMMUNICATED')}
                            </div>
                          </div>
                        </div>
                      ) : ('')
                    ))}
                  </div>
                </>
              ) : (
                ''
              )}
            </>
          )
      )}
      {(!hasWriteAccess && !answeredModel) && (
        <div className="mt-2">
          <NoAnswer text={t(noAnswerText)} />
        </div>
      )}
    </>
  );
}

export default QuestionTypeCarbonDistribution;

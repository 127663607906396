import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';

import eye from '../../../../assets/ui/eye.svg';
import eyeSlash from '../../../../assets/ui/eye-slash.svg';

function PublishingSwitch({ isViewPublishedInThisLocale, view, publishing, locale, profilId }) {
  const [published, setPublished] = useState(false);
  const [newPublishing, setNewPublishing] = useState(publishing);
  const [checked, setChecked] = useState(false);

  const { t } = useTranslation();

  const updatePublishing = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profilId}/publishing`, {
    credentials: 'include',
  });

  const handleCheck = async () => {
    const tmpPublishing = newPublishing;

    if (newPublishing[view].indexOf(locale) < 0) {
      newPublishing[view].push(locale);
    } else if (newPublishing[view].indexOf(locale) === 0) {
      newPublishing[view] = newPublishing[view].slice(1);
    } else {
      newPublishing[view] = newPublishing[view].slice(0, newPublishing[view].indexOf(locale));
    }

    setNewPublishing(tmpPublishing);
    setChecked(!checked);

    try {
      await updatePublishing.put({
        publishing: newPublishing,
      });

      if (updatePublishing.error) {
        // TODO pop toaster + erreur
        throw new Error(updatePublishing.error);
      } else {
        setPublished(!published);
        return true;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (isViewPublishedInThisLocale) {
      setChecked(isViewPublishedInThisLocale);
      setPublished(isViewPublishedInThisLocale);
    }
  }, [isViewPublishedInThisLocale]);

  return (
    <div className="container mx-auto px-5">
      <div className="bg-white relative text-greyThree text-sm py-3 px-4 flex sm:justify-between flex-wrap sm:flex-nowrap rounded border border-greySix z-10">
        <div className="flex items-center">
          <div className="bg-backgroundGrey inline-block rounded me-3 p-4">
            <img src={published ? eye : eyeSlash} className="eye-slash" alt={published ? 'eye' : 'eye-slash'} />
          </div>
          {published ? <span>{t('SWITCH_MESSAGE_PUBLISHING_1')}<br />{t('SWITCH_MESSAGE_PUBLISHING_2')}</span>
            : <span>{t('SWITCH_MESSAGE_PUBLISHING_3')}<br />{t('SWITCH_MESSAGE_PUBLISHING_4')}</span>}
        </div>
        <div className="bg-backgroundGrey py-2 px-4 rounded mt-3 md:mt-0">
          <div className="flex h-full">
            <div className="flex flex-col h-full justify-center">
              <span className="font-semibold text-base text-greyOne">{t('SWITCH_MESSAGE_PUBLISHING_8')} {published ? '' : t('SWITCH_MESSAGE_PUBLISHING_7') } {t('SWITCH_MESSAGE_PUBLISHING_6')}</span>
              {t('SWITCH_MESSAGE_PUBLISHING_5')}
            </div>
            <div className="form-check form-switch ms-5 mb-0 flex items-center">
              {updatePublishing.loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <button
                  type="button"
                  className={`w-[50px] flex items-center ${
                    checked ? 'justify-end bg-secondarySkyBlue' : 'justify-start bg-greySix'
                  } p-[4px] rounded-[30px]`}
                  onClick={handleCheck}
                >
                  <div className="w-[18px] h-[18px] bg-white rounded-full" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishingSwitch;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from './Widget';
import unitText from '../../../../utils/unitText';

function Details({ scope, scopeColor, getColorByScore, getHexColorWithOpacity, flag, reductionColPosition, speedColPosition, showClimateAlignment }) {
  const { t } = useTranslation();

  return (
    <>
      {((scope?.degreeScore === 0) || (scope?.degreeScore >= 2)) && (flag ? scope?.degreeScore >= 3 : true) ? (
        <>
          <div className={`${reductionColPosition} text-center`}>
            <div className="text-lg text-greyOne font-moskauGrotesk font-semibold">
              {scope?.percentDelta > 0 ? '+' : ''}{scope?.percentDelta}%
            </div>
            <div className="text-xs text-greyTwo">
              {t(unitText[scope?.reduceType])}
            </div>
            <div className="text-xs text-greyTwo">
              {t('CLIMATE_ACTIONS.BETWEEN')} {scope?.startYear} {t('CLIMATE_ACTIONS.AND')} {scope?.endYear}
            </div>
          </div>
          <div className={`${speedColPosition} text-center text-greyOne text-lg flex flex-col justify-center`}>
            <div className="self-center font-moskauGrotesk font-semibold">
              {(scope && scope.percentDelta && scope?.startYear && scope.endYear) && (scope.percentDelta / (scope.endYear - scope.startYear)).toFixed(2) > 0 ? '+' : ''}{(scope && scope.percentDelta && scope?.startYear && scope.endYear) && (scope.percentDelta / (scope.endYear - scope.startYear)).toFixed(2)}% / {t('CLIMATE_ACTIONS.YEAR')}
            </div>
            <div className="text-xs text-greyTwo">
              {t(unitText[scope?.reduceType])}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {scope?.degreeScore !== 0 && showClimateAlignment ? (
        <Widget
          getColorByScore={getColorByScore}
          getHexColorWithOpacity={getHexColorWithOpacity}
          degreeScore={scope?.degreeScore}
          color={scopeColor}
        />
      ) : null}
    </>
  );
}

export default Details;

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../context/LocaleContext';
import dropdownArrow from '../../../assets/ui/caret-down-s.svg';

export default function LanguageSwitcher({
  isSidebarOpen,
  languages,
  dropdownRef,
  contentRef,
  isOpen,
  setIsOpen,
}) {
  const { t } = useTranslation();
  const { locale, changeLocale } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickLanguage = (lang) => {
    changeLocale(lang);

    // Rechargement des données du router sans recharger la page
    navigate(`${location.pathname}${location.search}`, { replace: true });

    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative w-full rounded-sm">
      <button
        type="button"
        className="bg-greyEight group p-3 w-full flex justify-between cursor-pointer text-greyThree border-0 hover:text-greyOne hover:font-medium"
        onClick={handleClickDropdown}
      >
        <div className="text-xs flex gap-2.5 whitespace-nowrap">
          <img
            src={languages.find((item) => item.shorthand === locale)?.flag || languages[0].flag}
            alt={`Flag ${languages.find((item) => item.shorthand === locale)?.shorthand || languages[0].shorthand}`}
          />
          {isSidebarOpen && t('PARTNER_DASHBOARD.SIDEBAR.CHANGE_LANGUAGE')}
        </div>
        {isSidebarOpen && (
          <img
            src={dropdownArrow}
            className={`${isOpen ? 'rotate-180' : ''} duration-500 transition-all`}
            alt="Dropdown arrow"
          />
        )}
      </button>
      <div
        ref={contentRef}
        className={`absolute bottom-full w-full mb-2 z-50 transition-all p-2 duration-500 overflow-hidden rounded-[6px] bg-white border border-solid ${isOpen ? 'border-blueVeryLight visible' : 'border-[#00ffff00] invisible'}`}
        style={{ height: isOpen ? `${contentRef.current.scrollHeight}px` : '0' }}
      >
        <div className="flex flex-col text-xs">
          {languages.sort((a, b) => a.name.localeCompare(b.name)).map((lang) => (
            <button
              type="button"
              key={lang.name}
              className={`p-2 flex w-full gap-2 border-0 rounded-md text-greyThree bg-white ${isOpen && isSidebarOpen ? 'hover:bg-blueVeryLight' : ''} ${isSidebarOpen ? '' : 'justify-center'}`}
              onClick={() => handleClickLanguage(lang.shorthand)}
            >
              <img src={lang.flag} alt={`Flag ${lang.name}`} />
              {isSidebarOpen && lang.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

import pen from '../../../../../assets/ui/pen.svg';
import trashBin from '../../../../../assets/ui/trash-bin-m.svg';
import plus from '../../../../../assets/ui/cta-plus.svg';

import NoAnswer from '../../NoAnswer';
import useModal from '../../../../../hooks/useModal';

function QuestionTypeActions({ isPublished, hasWriteAccess, answer, noAnswerText, setData, question, setItemToDelete }) {
  const { open } = useModal();
  const { t } = useTranslation();

  // en bas type 2, a droite type 3
  return (
    <div>
      {((isPublished && (answer && answer.answer)) || hasWriteAccess) ? (
        <span className="custom-list">
          {parse(DOMPurify.sanitize(answer?.answer))}
        </span>
      ) : (
        <div className="mt-2">
          <NoAnswer text={noAnswerText} />
        </div>
      )}
      {(answer && isPublished) || hasWriteAccess ? (
        <>
          {(hasWriteAccess || (answer.contentListItems && answer.contentListItems.some((list) => list.type === 2))) && (
            <p className="mt-8 mb-6 font-medium text-greyOne">
              {t('ACTIONS_PLAN_CONCRETE_ACTIONS')}
            </p>
          )}
          <div className="flex flex-col gap-6">
            {answer?.contentListItems
              ?.sort((a) => (a.type === 9 ? 1 : -1)) // Trier les éléments pour que ceux avec les liens soient en bas
              .map((list) => {
                if (list.type === 2) {
                  return (
                    <div key={list.id} className="text-greyThree text-sm flex justify-between gap-5">
                      <span className="custom-list">
                        {parse(DOMPurify.sanitize(list.name))}
                      </span>
                      {hasWriteAccess ? (
                        <div className="flex justify-end gap-5">
                          <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                            <button
                              type="button"
                              onClick={() => {
                                setData({
                                  title: t('ACTIONS_PLAN_CONCRETE_ACTIONS_CTA_EDIT'),
                                  info: question.info,
                                  answerId: answer.id,
                                  id: list.id,
                                  name: list.name,
                                  type: 'TYPE_ACTION_IN_PROGRESS',
                                });
                                open('question-actions-items-modal');
                              }}
                            >
                              <img src={pen} alt="Pen" />
                            </button>
                          </div>
                          <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setItemToDelete({ id: list.id, type: 'contentListItem' });
                                open('delete-confirmation-modal-questions');
                              }}
                            >
                              <img src={trashBin} alt="Trash bin" />
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                }
                if (list.type === 9) {
                  return (
                    <div key={list.id} className="text-sm text-greyFour hover:text-greyThree underline flex justify-between gap-5 z-10">
                      <a href={list.url} target="_blank" rel="noreferrer">{list.name}</a>
                      {hasWriteAccess ? (
                        <div className="flex justify-end gap-5">
                          <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                            <button
                              type="button"
                              onClick={() => {
                                setData({
                                  title: t('ACTIONS_PLAN_LINK_EDIT'),
                                  info: question.info,
                                  answerId: answer?.id,
                                  id: list.id,
                                  name: list.name,
                                  url: list.url,
                                  type: 'TYPE_ACTION_LINKS',
                                });
                                open('question-actions-link-modal');
                              }}
                            >
                              <img src={pen} alt="Pen" />
                            </button>
                          </div>
                          <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setItemToDelete({ id: list.id, type: 'contentListItem' });
                                open('delete-confirmation-modal-questions');
                              }}
                            >
                              <img src={trashBin} alt="Trash bin" />
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                }
                return null;
              })}
            {hasWriteAccess && answer?.answer ? (
              <div className="flex flex-col items-start z-10">
                <button
                  type="button"
                  className="flex m-3 gap-2 bg-sky-blue rounded-[56px] py-[10px] px-7 text-sm text-white items-center"
                  onClick={async () => {
                    setData({
                      title: t('ACTIONS_PLAN_CONCRETE_ACTIONS_CTA'),
                      info: question.info,
                      answerId: answer?.id,
                      type: 'TYPE_ACTION_IN_PROGRESS',
                    });
                    open('question-actions-items-modal');
                  }}
                >
                  <img src={plus} alt="Plus" />
                  <span>
                    {t('ACTIONS_PLAN_CONCRETE_ACTIONS_CTA')}
                  </span>
                </button>
                <button
                  type="button"
                  className="flex  m-3 gap-2 bg-sky-blue rounded-[56px] py-[10px] px-7 text-sm text-white items-center"
                  onClick={async () => {
                    setData({
                      title: t('ACTIONS_PLAN_LINK'),
                      info: question.info,
                      answerId: answer?.id,
                      type: 'TYPE_ACTION_LINKS',
                      name: '',
                      url: '',
                    });
                    open('question-actions-link-modal');
                  }}
                >
                  <img src={plus} alt="Plus" />
                  <span>
                    {t('ACTIONS_PLAN_LINK')}
                  </span>
                </button>
              </div>
            ) : null}
            {hasWriteAccess && !answer?.answer ? (
              <p>
                {t('ACTIONS_PLAN_EMPTY_STATE_A')}
              </p>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default QuestionTypeActions;

import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import crossModal from '../../../../assets/ui/cross-modal.svg';
import linkIcon from '../../../../assets/ui/link-icon.svg';
import check from '../../../../assets/ui/check-thin-dark-blue.svg';
import exclamationMark from '../../../../assets/ui/exclamation-mark.svg';

function ResourceManager({
  resources,
  setResources,
}) {
  const { t } = useTranslation();
  const [resourceData, setResourceData] = useState({ name: '', link: '' });
  const [showResourceInputs, setShowResourceInputs] = useState(false);
  const [nameError, setNameError] = useState('');
  const [linkError, setLinkError] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');
  const resourceNameRef = useRef(null);
  const resourceLinkRef = useRef(null);

  const isValidUrl = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
    + '((\\d{1,3}\\.){3}\\d{1,3}))'
    + '(\\:\\d+)?'
    + '(\\/[-a-z\\d%_.~+]*)*'
    + '(\\?[;&a-z\\d%_.~+=-]*)?'
    + '(\\#[-a-z\\d_]*)?$', 'i');
    return !!pattern.test(url);
  };

  const addResource = () => {
    setNameError('');
    setLinkError('');

    if (!resourceData.name.trim()) {
      setNameError(t('PARTNER_DASHBOARD.CATEGORIES.FORM.ERROR.MISSING_NAME'));
      resourceNameRef.current.focus();
      return;
    }

    if (!isValidUrl(resourceData.link)) {
      setLinkError(t('PARTNER_DASHBOARD.CATEGORIES.FORM.ERROR.INVALID_LINK'));
      resourceLinkRef.current.focus();
      return;
    }

    setResources((prev) => [...prev, { ...resourceData }]);
    setResourceData({ name: '', link: '' });
    setShowResourceInputs(false);
  };

  const removeResource = (index) => {
    setResources((prev) => prev.filter((_, i) => i !== index));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResourceData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTooltip = (show) => {
    let message = '';
    if (show) {
      if (resources.length >= 3) {
        message = t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCEs_TOOLTIP_MAX_RESSOURCES');
      } else if (showResourceInputs) {
        message = t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCEs_TOOLTIP_VALIDATE');
      }
      setTooltipMessage(message);
      setShowTooltip(message !== '');
    } else {
      setShowTooltip(false);
    }
  };

  return (
    <div>
      <h2 className="text-base mb-2 mt-9">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_B')}
      </h2>
      <span className="text-sm text-greyDark font-moskauGrotesk font-normal mb-2">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCES_INFO')}
      </span>
      {resources.length > 0 || showResourceInputs ? (
        <ul className="flex items-center gap-x-2 list-none p-0 mt-4">
          {resources.map((resource, index) => (
            <li
              key={index}
              className="py-1 px-4 bg-greyLight border border-solid border-primaryNewBlue/30 text-sm text-greyHeavy font-moskauGrotesk font-normal rounded-full "
            >
              {resource.name}
              <button
                type="button"
                onClick={() => removeResource(index)}
                className="border-none p-0 ml-2"
              >
                <img
                  src={crossModal}
                  alt="delete resource"
                  width="16px"
                  className="mb-[1px]"
                />
              </button>
            </li>
          ))}
        </ul>
      ) : ('')}
      {showResourceInputs && (
        <div className="flex flex-col">
          <div className="w-full flex items-center gap-x-3 justify-between">
            <div className="w-1/3  min-[450px]:w-2/3    flex flex-col">
              <label
                className="text-sm font-moskauGrotesk font-normal"
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.NAME')}
              </label>
              <input
                id="resource-name"
                name="name"
                ref={resourceNameRef}
                value={resourceData.name}
                onChange={handleInputChange}
                placeholder={t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCE_NAME_PLACEHOLDER')}
                className={`h-11 text-sm font-moskauGrotesk font-normal text-greyHeavy border border-solid ${
                  nameError
                    ? ' border-2 border-red bg-red/5 focus:border-red focus:border'
                    : 'border-greyLight'
                } focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-md p-2 mb-4`}
              />
            </div>
            <div className="w-full flex flex-col">
              <label
                htmlFor="resource-link"
                className="text-sm font-moskauGrotesk font-normal"
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.LINK')}
              </label>
              <div className="w-full flex items-center mb-4 relative">
                <div className="pointer-events-none absolute top-3 left-0 flex items-center pl-3">
                  <img src={linkIcon} alt="Link" className="w-5 h-5" />
                </div>
                <input
                  id="resource-link"
                  name="link"
                  ref={resourceLinkRef}
                  value={resourceData.link}
                  onChange={handleInputChange}
                  placeholder={t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.RESSOURCE_LINK_PLACEHOLDER')}
                  className={`w-full h-11 text-sm font-moskauGrotesk font-normal text-greyHeavy border border-solid ${
                    linkError
                      ? ' border-2 border-red bg-red/5 focus:border-red focus:border'
                      : 'border-greyLight'
                  }  focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-md p-2 pl-10`}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={addResource}
              className="min-w-11 h-11 bg-white flex items-center justify-center border border-solid border-greyLight rounded-md mt-[3px]"
            >
              <img src={check} alt="add resource icon" />
            </button>
          </div>
          {nameError && (
            <span className="flex text-sm font-moskauGrotesk font-normal text-red mt-1">
              <img
                src={exclamationMark}
                alt="exclamation mark"
                className="mr-1 -mt-[3px]"
              />
              {nameError}
            </span>
          )}
          {linkError && (
            <span className="flex text-sm font-moskauGrotesk font-normal text-red mt-1">
              <img
                src={exclamationMark}
                alt="exclamation mark"
                className="mr-1 -mt-[3px]"
              />
              {linkError}
            </span>
          )}
        </div>
      )}
      {showResourceInputs && <hr className="my-4  border-greyFive" />}
      <div className="relative">
        <button
          type="button"
          onMouseOver={() => handleTooltip(true)}
          onMouseOut={() => handleTooltip(false)}
          onFocus={() => handleTooltip(true)}
          onBlur={() => handleTooltip(false)}
          onClick={setShowResourceInputs}
          disabled={resources.length >= 3 || showResourceInputs}
          className="inline bg-white text-sm font-moskauGrotesk font-normal text-secondarySkyBlue underline bg-transparent border-none p-0 m-0 hover:text-blue-700 disabled:text-greyDark disabled:cursor-not-allowed mt-4 mb-11"
        >
          {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.ADD_A_RESSOURCE')}
        </button>
        {showTooltip && (
          <div className="w-2/3 sm:w-auto absolute left-12 z-10 p-2 text-xs text-white font-moskauGrotesk font-normal bg-greyTwo rounded-sm shadow-lg -m-10">
            {tooltipMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default ResourceManager;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useFetch from 'use-http';
import getLocalLink from '../../../../utils/getLocalLink';
import checkPasswordStrenght from '../../../../utils/checkPasswordStrenght';
import handleResponse from '../../../../utils/handleResponse';

function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const [submited, setSubmited] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const { post, response, loading } = useFetch(
    process.env.REACT_APP_PARTNER_API,
  );

  const onSubmit = async (formData) => {
    try {
      await post('/login/reset', {
        token: searchParams.get('token'),
        email: searchParams.get('email'),
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      });
      if (response.ok) {
        setSubmited(true);
      }

      if (response.status === 401) {
        setTokenExpired(true);
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  return (
    <>
      <h1 className="text-3xl text-greyOne text-center mt-10">
        {t('RESET_PASSWORD.TITLE')}
      </h1>
      <div>
        {submited ? (
          <>
            <p className="mt-4">{t('RESET_PASSWORD.SUBMITED')}</p>
            <div className="mt-5">
              <a href={getLocalLink('/login')} className="text-secondarySkyBlue">
                {t('RESET_PASSWORD.LOGIN_LINK')}
              </a>
            </div>
          </>
        ) : (
          <form
            id="partner-forgot-form"
            className="mt-[30px]"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div>
              <label className="mb-2.5 font-medium" htmlFor="password">
                {t('RESET_PASSWORD.NEW_PASSWORD')}*
              </label>
              <input
                type="password"
                {...register('password', {
                  required: t('PASSWORD_REQUIRED'),
                  validate: (value) => {
                    if (!checkPasswordStrenght(value)) {
                      return t('RESET_PASSWORD.VALIDATION');
                    }
                    return true;
                  },
                })}
                name="password"
                id="password"
                className={`flex w-full border-greySix border border-solid rounded-md py-3 px-5 text-greyFour ${
                  errors.password
                    ? 'focus:outline-redError'
                    : 'focus:outline-secondarySkyBlue'
                }`}
              />
              {errors.password && (
                <div className="mt-1 text-sm text-redError">
                  {errors.password.message}
                </div>
              )}
            </div>
            <div className="mt-4">
              <label
                className="mb-2.5 font-medium"
                htmlFor="confirmPassword"
              >
                {t('RESET_PASSWORD.CONFIRM')}*
              </label>
              <input
                type="password"
                {...register('confirmPassword', {
                  required: t('PASSWORD_REQUIRED'),
                  validate: (value) => {
                    if (watch('password') !== value) {
                      return t('RESET_PASSWORD.NO_MATCH');
                    }
                    return true;
                  },
                })}
                name="confirmPassword"
                id="confirmPassword"
                className={`flex w-full border-greySix border border-solid rounded-md py-3 px-5 text-greyFour ${
                  errors.confirmPassword
                    ? 'focus:outline-redError'
                    : 'focus:outline-secondarySkyBlue'
                }`}
              />
              {errors.confirmPassword && (
                <div className="mt-1 text-sm text-redError">
                  {errors.confirmPassword.message}
                </div>
              )}
            </div>
            {tokenExpired && <p className="mt-1.5 text-sm text-redError">{t('RESET_PASSWORD.TOKEN_EXPIRED')}</p>}
            <button
              type="submit"
              className="w-full mt-[30px] min-h-[48px] py-3 bg-primaryNewBlue border-0 rounded text-white"
              disabled={loading}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                t('RESET_PASSWORD.RESET')
              )}
            </button>
          </form>
        )}
      </div>
    </>
  );
}

export default ResetPasswordForm;

import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { useUserLocale } from '../../../../../utils/getUserLocal';

import Header from '../../Header/Header';
import getQuestions from '../../../../../utils/getQuestions';
import TableOfContents from './TableOfContents';
import PublishingSwitch from '../PublishingSwitch';
import Category from '../Template/Category';
import getVisibility from '../../../../../utils/getVisibility';
import handleResponse from '../../../../../utils/handleResponse';
import QuestionQualitativeFormModal from '../../Modal/Forms/QuestionQualitativeFormModal';
import QuestionQuantitativeFormModal from '../../Modal/Forms/QuestionQuantitativeFormModal';
import DeleteConfirmationModal from '../../Modal/Forms/DeleteConfirmationModal';

function Approach({ view }) {
  const [categories, setCategories] = useState([]);
  const [optionalCategories, setOptionalCategories] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0);
  const [progressionBar, setProgressionBar] = useState(0);
  const [summary, setSummary] = useState([]);
  const [advancedSummary, setAdvancedSummary] = useState([]);
  const [isPublished, setIsPublished] = useState();
  const [showOptional, setShowOptional] = useState(false);
  const [data, setData] = useState();
  const [itemToDelete, setItemToDelete] = useState();

  const locale = useUserLocale();

  const { t } = useTranslation();

  const { profile, partner, hasWriteAccess } = useOutletContext();

  const api = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const TYPE_QUESTION = 1;
  const TYPE_ADVANCED = 3;

  const getAnsweredQuestionsCount = (categories) => {
    let answeredQuestions = 0;
    categories.forEach((category) => {
      category.individualQuestions.forEach((question) => {
        if (question.answer) {
          answeredQuestions += 1;
        }
      });
    });
    return answeredQuestions;
  };

  const fetchData = async () => {
    let answeredNormal = 0;
    let answeredAdvanced = 0;
    let totalQuestions = 0;

    try {
      const normalQuestionsResponse = await getQuestions(api, profile.id, partner.domain, view, locale, TYPE_QUESTION, profile.typology_id, partner.id, hasWriteAccess);

      if (api.response.ok) {
        setCategories(normalQuestionsResponse.categories);
        setIsPublished(normalQuestionsResponse.is_published_tab);
        setSummary(normalQuestionsResponse.table_contents);
        answeredNormal = getAnsweredQuestionsCount(normalQuestionsResponse.table_contents);
        normalQuestionsResponse.categories.forEach((category) => {
          totalQuestions += category.category[0].questions.length;
        });
      }
    } catch (error) {
      throw new Error(error);
    }

    try {
      const advancedQuestionsResponse = await getQuestions(api, profile.id, partner.domain, view, locale, TYPE_ADVANCED, profile.typology_id, partner.id);

      if (api.response.ok) {
        setOptionalCategories(advancedQuestionsResponse.categories);
        setAdvancedSummary(advancedQuestionsResponse.table_contents);
        answeredAdvanced = getAnsweredQuestionsCount(advancedQuestionsResponse.table_contents);
        advancedQuestionsResponse.categories.forEach((category) => {
          totalQuestions += category.category[0].questions.length;
        });
      }
    } catch (error) {
      throw new Error(error);
    }

    setNumberOfAnsweredQuestions(answeredNormal + answeredAdvanced);
    setNumberOfQuestions(totalQuestions);

    const progressionBar = (totalQuestions > 0) ? ((answeredNormal + answeredAdvanced) * 100) / totalQuestions : 0;
    setProgressionBar(parseInt(progressionBar, 10));
  };

  useEffect(() => {
    fetchData();
  }, [locale]);

  const handleDelete = async () => {
    try {
      await api.del(`/profiles/${profile.id}/content-value/${itemToDelete}`);
      if (api.response.ok) {
        fetchData();
      }
    } catch (error) {
      handleResponse(api.response);
      throw new Error(error);
    }
  };

  return (
    <div id="profile-page w-full">
      <Header view={view} />
      {hasWriteAccess ? (
        <PublishingSwitch
          isViewPublishedInThisLocale={isPublished}
          view={view}
          publishing={profile.publishing}
          locale={locale}
          profilId={profile.id}
        />
      ) : ('')}
      <div className="container py-10 mx-auto px-5">
        <div className="lg:grid grid-cols-12 gap-6">
          <div className="col-span-4 col-start-1">
            <TableOfContents
              hasWriteAccess={hasWriteAccess}
              progressionBar={progressionBar}
              numberOfQuestions={numberOfQuestions}
              numberOfAnsweredQuestions={numberOfAnsweredQuestions}
              summary={summary}
              advancedSummary={advancedSummary}
              isPublished={isPublished}
              showOptional={showOptional}
            />
          </div>
          <div className="col-span-8 col-start-5">
            <h2 className="fw-600 font-24 mb-4 mt-5 lg:mt-0">
              {t('TABLE_OF_CONTENTS_TEXT_2')}
            </h2>
            <div className="flex flex-col gap-6">
              {categories && categories.map((category, index) => {
                const show = getVisibility(category, hasWriteAccess, isPublished);

                return (
                  show
                  && (
                  <Category
                    key={index}
                    category={category.category[0]}
                    profile={profile}
                    isPublished={isPublished}
                    partner={partner}
                    view={view}
                    hasWriteAccess={hasWriteAccess}
                    fetchData={fetchData}
                    setData={setData}
                    setItemToDelete={setItemToDelete}
                  />
                  )
                );
              })}
            </div>
            {optionalCategories.length > 0 && showOptional === true && (
            <h3 className="mb-4 mt-5 font-24 fw-6tw00">
              {t('TO_GO_FURTHER')}
            </h3>
            )}
            {optionalCategories && optionalCategories.length > 0 && optionalCategories.map((category, index) => {
              const show = getVisibility(category, hasWriteAccess, isPublished);

              return (
                show
              && (
              <Category
                key={index}
                category={category.category[0]}
                profile={profile}
                isPublished={isPublished}
                partner={partner}
                view={view}
                hasWriteAccess={hasWriteAccess}
                setShowOptional={setShowOptional}
                fetchData={fetchData}
                setData={setData}
                setItemToDelete={setItemToDelete}
              />
              )
              );
            })}
          </div>
        </div>
        {hasWriteAccess ? (
          <>
            <QuestionQualitativeFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <QuestionQuantitativeFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <DeleteConfirmationModal modalId="delete-confirmation-modal-questions" handleDeleteFunction={handleDelete} title={t('DELETE_COMFIRMATION_MODAL.TITLE_A')} message={t('DELETE_COMFIRMATION_MODAL.TITLE_B')} />
          </>
        ) : ('')}
      </div>
    </div>
  );
}

export default Approach;

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{html,js,jsx}'],
  safelist: [
    'bg-greenLight',
    'bg-yellowLight',
    'bg-backgroundCleanBlue',
    'text-greenDark',
    'text-greenMedium',
    'text-yellowMedium',
    'text-greyDark',
    'text-greyTwo',
    'text-noteTresForte',
    'text-noteForte',
    'text-noteIntermediaire',
    'text-noteEncoreInsufissante',
    'text-noteTresInsuffisante',
    'text-notePasDengagement',
    'bg-noteTresForte',
    'bg-noteForte',
    'bg-noteIntermediaire',
    'bg-noteEncoreInsufissante',
    'bg-noteTresInsuffisante',
    'bg-notePasDengagement',
    'bg-noteTresForte/10',
    'bg-noteForte/10',
    'bg-noteEncoreInsufissante/10',
    'bg-greyLightFive',
    'bg-greenLightTwo',
    'border-noteTresForte',
    'border-noteForte',
    'border-noteEncoreInsufissante',
  ],
  theme: {
    extend: {
      screens: {
        sm: '600px',
        md: '728px',
        lg: '984px',
        xl: '1240px',
        '2xl': '1440px',
      },
      fontFamily: {
        moskauGrotesk: ['MoskauGrotesk', 'sans-serif'],
        inter: ['Inter', 'sans-serif'],
        poppins: ['Poppins', 'sans-serif'],
      },
      keyframes: {
        slideIn: {
          '0%': { transform: 'translateX(-100%)' },
          '100%': { transform: 'translateX(0)' },
        },
        fadeIn: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
      },
      animation: {
        'slide-in': 'slideIn 0.3s ease-out forwards',
        fadeIn: 'fadeIn 0.5s ease-in-out',
      },
      transitionProperty: {
        'max-height': 'max-height',
      },
      boxShadow: {
        customDropdown: '0px 1px 4px 0px rgba(216, 216, 221, 0.3)',
        tagShadow: '0px 2px 2px 0px #CDCDCD40',
        tooltipShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        resourcePdfShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      },
      backgroundImage: {
        'gradient-openclimat':
            'linear-gradient(180deg, #0B1241 0%, #4277C7 95%)',
      },
    },
    colors: {
      white: '#ffffff',
      whiteGrey: '#FCFCFC',
      black: '#f',
      blackTitle: '#28282D',
      blueSky: '#F5FAFF',
      blueVeryLight: '#F1F5F9',
      blueFlashLight: '#21AFFF1A',
      blueBorder: '#EEEFFE',
      yellowLight: '#FEFAEF',
      yellowMedium: '#EDCC5B',
      greyBlur: '#2121211A',
      greyMediumLight: '#DEE2E6',
      greyLight: '#ECEEF4',
      greyLightFive: '#9E9E9E33',
      greyDark: '#746F7C',
      greyHeavy: '#30263F',
      greyBorderLight: '#E0E0E0',
      greyDarkHeavy: '#150234',
      greenMedium: '#ADDB6D',
      greenLight: '#F1F8ED',
      greenLightTwo: '#0EBF7626',
      greenDark: '#518960',
      greyMuted: '#7B7B7B',
      orange: '#FF9780',
      red: '#E63756',
      redError: '#F23030',
      oldColors: {
        'white-LL': '#F6F6F6',
      },
      primaryNewBlue: '#031356',
      primaryNewBlueHover: '#000C41',
      primaryCorail: '#FF5530',
      primaryCorailHover: '#F1502C',
      secondarySkyBlue: '#21AFFF',
      secondarySkyBlueHover: '#20A3ED',
      secondaryBlueBg: '#D1EBFA',
      secondaryCreamBg: '#FFF5E2',
      secondaryGreen: '#B8FFE5',
      greyOne: '#00051b',
      greyTwo: '#44444f',
      greyThree: '#636676',
      greyFour: '#9398AC',
      greyFive: '#9E9E9E',
      greySix: '#E1E3EB',
      greySeven: '#F0F1F5',
      greyEight: '#FAFAFB',
      backgroundCleanBlue: '#F8FAFD',
      backgroundGrey: '#F4F4F4',
      noteTresForte: '#0EBF76',
      noteForte: '#6CB14A',
      noteIntermediaire: '#F8D55B',
      noteEncoreInsufissante: '#F68F57',
      noteTresInsuffisante: '#E63756',
      notePasDengagement: '#B41A35',
      tagsGrey: '#E6E6E6',
      backgroundBlur: '#2121211A',
      leclercPrimary: '#225AB5',
      leclercOrange: '#EF6926',
      leclercYellow: '#FF9519',
      tagsLightPink: '#FFEBEB',
      tagsYellow: '#FFFDD0',
      tagsGreen: '#DFFFF3',
      tagsBlue: '#CFFFFF',
      tagdBlueGrey: '#D8E7ED',
      tagsPurple: '#F3D8FF',
      tagsPink: '#FFE4E4',
      tagsOrange: '#FFEBD8',
      transparent: 'transparent',
      'sky-blue': '#21AFFF',
    },
  },
  variants: {
    extend: {
      display: ['group-hover'],
    },
  },
  plugins: [],
  important: true,
};

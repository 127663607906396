import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import NoAnswer from '../../../NoAnswer';

function QuestiontTypeQuantitative({ isPublished, hasWriteAccess, answer, noAnswerText, question }) {
  const [quantitativeData, setQuantitativeData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (answer && answer.answer) {
      setQuantitativeData(JSON.parse(answer.answer));
    }
  }, [answer]);

  return (
    <>
      {((isPublished && (answer && answer.answer)) || hasWriteAccess) ? (
        (answer && answer.answer)
          && (
            <div>
              <div>
                <span>{quantitativeData && quantitativeData.amount}</span> {quantitativeData && quantitativeData.amount && question.unit_name} {quantitativeData && quantitativeData.year && (`en ${quantitativeData.year}`)}
              </div>
              <div className="mt-2">
                <span
                  dangerouslySetInnerHTML={{ __html: sanitize(quantitativeData && quantitativeData.more) }}
                  className="custom-list"
                />
              </div>
            </div>
          )
      ) : (
        <div className="mt-2">
          <NoAnswer text={t(noAnswerText)} />
        </div>
      )}
      {answer && (isPublished || hasWriteAccess) ? (
        answer.contentListItems && answer.contentListItems[0] && (
          <div className="text-sm mt-6 text-greyFour hover:text-greyThree underline">
            <a href={answer.contentListItems[0].url} target="_blank" rel="noreferrer">{answer.contentListItems[0].name}</a>
          </div>
        )
      ) : ('')}
    </>
  );
}

export default QuestiontTypeQuantitative;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getLocalLink from '../../../utils/getLocalLink';

import spin from '../../../assets/ui/spin.svg';
import logoLeclerc from '../../../assets/ui/landingPartner/logo-partner-leclerc.svg';
import logoOcLeclerc from '../../../assets/ui/landingPartner/logo-oc-x-leclerc.svg';

import GetRecontactedForm from './GetRecontactedForm';

function Form() {
  const [firstFormData, setFirstFormData] = useState({});
  const [isFirstFormSubmitted, setIsFirstFormSubmitted] = useState(false);
  const [isSecondFormSubmitted, setIsSecondFormSubmitted] = useState(false);
  const [isGetRecontacted, setGetRecontacted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const { partner } = useLoaderData();

  const { post, response, loading } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/contact`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      await post('landing', data);

      if (response.ok) {
        const finalFirstFormData = { ...response.data, ...data };
        setFirstFormData(finalFirstFormData);
        setIsFirstFormSubmitted(true);
        searchParams.set('email', finalFirstFormData.email);
        setSearchParams(searchParams);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const onSubmitSecondForm = async (data) => {
    const finalData = { ...firstFormData, ...data };
    try {
      await post('landing-two', finalData);

      if (response.ok) {
        setIsSecondFormSubmitted(true);
        setFirstFormData({});
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div id="contact-form" className="parent-header bg-white bg-gradient-openclimat">
      <div id="content" className="container mx-auto flex flex-col xl:flex-row bg-white text-white xl:h-[720px] relative px-4 xl:px-0">
        <div className="xl:w-8/12 clip-65 xl:bg-gradient-openclimat flex items-center xl:pr-80 py-[60px] px-[14px] xl:px-[80px]">
          {isSecondFormSubmitted && (
            <div>
              <h5 className="text-3xl">
                {t('LANDING_LECLERC.FORM.TEXT_C')}
              </h5>
              <div className="mt-[30px] text-base">
                {t('LANDING_LECLERC.FORM.TEXT_D')}
              </div>
            </div>
          )}
          {!isSecondFormSubmitted && (
            <div className="text-center xl:text-left">
              <div className="flex justify-center xl:justify-normal">
                <img src={logoOcLeclerc} alt="OpenClimat X Leclerc" className="w-full h-full md:w-96 object-contain xl:object-none xl:w-max xl:h-max" />
              </div>
              <h5 className="text-3xl mt-9">
                {t('LANDING_LECLERC.FORM.TEXT_A')}
              </h5>
              <p className="mt-[30px] text-base mb-0">
                {t('LANDING_LECLERC.FORM.TEXT_T')}
              </p>
            </div>
          )}
        </div>
        <div className="xl:bg-white xl:w-4/12 xl:relative xl:flex xl:justify-center xl:items-center">
          {!isFirstFormSubmitted && !isSecondFormSubmitted && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white pt-[30px] xl:w-[640px] xl:right-[80px] xl:absolute rounded-lg xl:rounded-xl xl:border xl:border-solid xl:border-greySix xl:px-10 xl:pt-[30px] xl:pb-[30px] text-greyOne px-[30px] pb-[30px]"
          >
            <div className="flex gap-6 xl:gap-5 w-full flex-col xl:flex-row">
              <div className="xl:w-full text-sm">
                <label htmlFor="email" className="text-greyThree">
                  {t('LANDING_LECLERC.FORM.INPUT.EMAIL.LABEL')}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`w-full rounded-md border border-solid  px-3.5 py-3 mt-1 text-greyFour ${errors.email ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
                  {...register('email', {
                    required: t('LANDING_LECLERC.FORM.INPUT.EMAIL.REQUIRED'),
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('LANDING_LECLERC.FORM.INPUT.EMAIL.INVALID'),
                    },
                  })}
                />
                {errors && errors.email && (
                <div className="mt-1.5 text-sm text-redError">{errors.email?.message}</div>
                )}
              </div>
            </div>
            <div className="flex gap-2.5 mt-4 items-center">
              <input
                type="checkbox"
                name="accept"
                id="accept"
                {...register('accept', {
                  required: t('LANDING_LECLERC.FORM.INPUT.ACCEPT.REQUIRED'),
                })}
              />
              <label htmlFor="accept" className="text-sm text-primaryNewBlue mt-1">
                {t('LANDING_LECLERC.FORM.TEXT_F')}
                <a href={`${getLocalLink('/invitation-adhesion-fournisseurs/politique-de-confidentialite')}`} className="underline text-primaryNewBlue" rel="nofollow noreferrer" target="blank">
                  {t('LANDING_LECLERC.FORM.TEXT_G')}
                </a>
              </label>
            </div>
            {errors && errors.accept && (
            <div className="mt-1.5 text-sm text-redError">{errors.accept?.message}</div>
            )}
            <div className="mt-10 xl:mt-[18px]">
              <button type="submit" disabled={loading} className={`w-full py-3 font-medium bg-primaryNewBlue text-white border-0 rounded-md items-center justify-center flex ${loading ? 'disabled' : ''}`}>
                {loading ? <img src={spin} alt="spin" /> : t('LANDING_LECLERC.FORM.CONTINUE')}
              </button>
            </div>
          </form>
          )}
          {isFirstFormSubmitted && !isSecondFormSubmitted && !isGetRecontacted && (
            <div className="bg-white py-[30px] xl:w-[640px] xl:right-[80px] xl:absolute rounded-lg xl:rounded-xl xl:border xl:border-solid xl:border-greySix xl:px-10 xl:py-[50px] text-greyOne px-[30px] mb-[60px] xl:mb-0">
              <div className="">
                <h5 className="font-normal text-center text-xl text-greyOne mb-0">
                  {t('LANDING_LECLERC.FORM.TEXT_M')}
                </h5>
                <p className="mt-2.5 mb-0 text-sm text-greyThree text-center">
                  {t('LANDING_LECLERC.FORM.TEXT_N')}
                </p>
                <div className="flex justify-center mt-5">
                  <a
                    href="https://meetings-eu1.hubspot.com/meetings/elsachai/meet-with-openclimat"
                    className="bg-secondarySkyBlue hover:bg-secondarySkyBlueHover py-2.5 px-[50px] border-0 rounded text-white font-medium no-underline"
                    target="blank"
                  >
                    {t('LANDING_LECLERC.FORM.TEXT_Q')}
                  </a>
                </div>
                <div className="my-[50px] font-bold text-greyFour text-xs flex w-full gap-2.5 xl:px-10">
                  <div className="w-full border-greySix border-solid border-t-0 border-x-0 h-[1px] self-center border-[1px]" />
                  <div>
                    {t('LANDING_LECLERC.FORM.OR')}
                  </div>
                  <div className="w-full border-greySix border-solid border-t-0 border-x-0 h-[1px] self-center border-[1px]" />
                </div>
                <h5 className="text-xl text-greyOne text-center font-normal">
                  {t('LANDING_LECLERC.FORM.TEXT_O')}
                </h5>
                <p className="mt-2.5 mb-0 text-sm text-greyThree text-center">
                  {t('LANDING_LECLERC.FORM.TEXT_S')}
                </p>
                <div className="flex justify-center mt-5">
                  <button
                    type="button"
                    className="bg-primaryNewBlue hover:bg-primaryNewBlueHover p-2.5 border-0 w-[250px] rounded text-white font-medium no-underline"
                    onClick={() => setGetRecontacted(true)}
                  >
                    {t('LANDING_LECLERC.FORM.SEND')}
                  </button>
                </div>
              </div>
            </div>
          )}
          {isFirstFormSubmitted && !isSecondFormSubmitted && isGetRecontacted && (
            <GetRecontactedForm
              handleSubmit={handleSubmit}
              onSubmitSecondForm={onSubmitSecondForm}
              register={register}
              errors={errors}
              loading={loading}
              setGetRecontacted={setGetRecontacted}
            />
          )}
          {isFirstFormSubmitted && isSecondFormSubmitted && (
            <div className="xl:relative bg-white p-10 xl:p-0 flex justify-center xl:justify-normal">
              <img src={logoLeclerc} alt="Logo Leclerc" className="xl:-left-[320px] xl:-top-32 h-full w-full md:w-96 xl:h-max xl:w-max object-contain" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Form;

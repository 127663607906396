/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Content from './Content';

function TableOfContents({ hasWriteAccess, progressionBar, numberOfQuestions, numberOfAnsweredQuestions, summary, isPublished }) {
  const { t } = useTranslation();

  return (
    <div className="top-6 sticky rounded border border-solid border-greySix px-7 py-6 mb-6 sm:mb-0">
      <h3 className="text-base pb-3 border-b border-greySix">
        {t('TABLE_OF_CONTENTS_TITLE')}
      </h3>
      <div className="mt-4">
        {hasWriteAccess && (
          <div className="flex justify-between">
            <div className="h-2 w-3/4 relative self-center rounded">
              <div className="w-full absolute rounded h-2 bg-backgroundCleanBlue" />
              <div className="bg-primaryNewBlue h-2 rounded absolute" style={{ width: `${progressionBar && progressionBar}%` }} />
            </div>
            <div className="fw-400 text-xs">
              {numberOfAnsweredQuestions} / {numberOfQuestions} {t('TABLE_OF_CONTENTS_TEXT_1')}
            </div>
          </div>
        )}
        <div className="flex flex-col mt-4">
          {summary && summary.map((category, index) => (
            <Content
              key={index}
              category={category}
              hasWriteAccess={hasWriteAccess}
              isPublished={isPublished}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default TableOfContents;

import React from 'react';

/**
 * MethodPrecisionScale component renders a visual representation of a scale with a specified number of total steps and validated steps.
 *
 * @param {Object} props - The component props.
 * @param {number} [props.total=5] - The total number of steps in the scale.
 * @param {number} [props.validated=2] - The number of validated steps in the scale.
 * @returns {JSX.Element} The rendered MethodPrecisionScale component.
 */
function MethodPrecisionScale({ total = 5, validated }) {
  const getBorderClass = (index) => {
    if (total === 1) {
      return 'rounded-full';
    }
    if (index === 0) {
      return 'rounded-l-full';
    }
    if (index === total - 1) {
      return 'rounded-r-full';
    }
    return '';
  };

  const renderDivs = () => {
    return Array(total).fill(null).map((_, index) => {
      const isValidated = index < validated;
      const borderClass = getBorderClass(index);

      return (
        <div
          key={index}
          className={`flex-1 h-[7px] ${isValidated ? 'bg-noteTresForte' : 'bg-greySix'} ${borderClass}`}
        />
      );
    });
  };

  return (
    <div className="flex items-center">
      <div className="w-full flex items-center gap-[2px]">
        {renderDivs()}
      </div>
      <span className="font-inter text-xs text-primaryNewBlue font-bold ml-4">
        {validated}/{total}
      </span>
    </div>
  );
}

export default MethodPrecisionScale;

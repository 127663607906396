import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import cross from '../../assets/ui/cross-modal.svg';

import useModal from '../../hooks/useModal';

/**
 * TemplateFormModal component
 * @component
 * @param {Object} props - The props object for TemplateFormModal component
 * @param {string} props.modalId - The ID of the modal, used for handling its visibility and animations
 * @param {string} props.formId - The ID of the form that the modal contain
 * @param {string} props.title - The title of the modal
 * @param {string} [props.submitButtonText] - Optional. The text on the submit button
 * @param {string} [props.cancelButtonText] - Optional. The text on the cancel button
 * @returns {JSX.Element} - A modal component that can be controlled through a context, supports dynamic content, title, and a form submission
 * @example
 * <TemplateFormModal
 *  modalId="audit-modal"
 *  formId="audit-form"
 *  title="Éditer les informations d'audit"
 * >
 *  <form
 *   id="audit-form"
 *   onSubmit={handleSubmit(onSubmit)}
 *   noValidate
 *  >
 *  </form>
 * </TemplateFormModal>
 *
 * To use it you can wrap your form inside the TemplateFormModal component and pass the modalId, formId, title, submitButtonText, and cancelButtonText as props
 * You can open the modal by calling the open function from the useModal hook and passing the modalId as an argument
 * You can close the modal by calling the close function from the useModal hook
 * To handle the form submission you need to pass the formId to the form template
 */
export default function TemplateFormModal({ children, modalId, formId, title, submitButtonText, cancelButtonText }) {
  const { openModal, close } = useModal();
  const isModalOpen = openModal === modalId;
  const modalRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        const modalContent = document.getElementById(`modal-content-${modalId}`);
        if (modalContent) {
          modalContent.style.opacity = '1';
          modalContent.style.transform = 'translateY(0)';
          modalContent.style.transition = 'transform 0.5s ease-out, opacity 0.5s ease-out';
        }
      }, 50);
    } else {
      const modalContent = document.getElementById(`modal-content-${modalId}`);
      if (modalContent) {
        modalContent.style.opacity = '0';
        modalContent.style.transform = 'translateY(-20px)';
        modalContent.style.transition = 'none';
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        close();
      }
    };

    const handleBodyScroll = () => {
      if (isModalOpen) {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.style.paddingRight = '';
        document.body.classList.remove('overflow-hidden');
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      handleBodyScroll();
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      handleBodyScroll();
    }

    return () => {
      document.body.style.paddingRight = '';
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  return (
    <div
      id={modalId}
      tabIndex="-1"
      aria-hidden={!isModalOpen ? 'true' : undefined}
      inert={!isModalOpen ? 'true' : undefined}
      className={`flex ${isModalOpen ? 'visible' : 'hidden'} overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-[50000] justify-center items-center w-full h-full max-h-full bg-greyOne/50`}
    >
      <div
        id={`modal-content-${modalId}`}
        ref={modalRef}
        className={`relative my-4 w-full max-w-2xl h-auto transition-all transform ${isModalOpen ? '' : ''} duration-1000 ease-in-out mx-5`}
      >
        <div className="relative bg-white rounded-lg shadow overflow-y-auto max-h-screen">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-greySix sticky top-0 bg-white border-solid border-t-0 border-x-0">
            <h3 className="text-xl font-semibold text-gray-900 mb-0">
              {title}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center border-0"
              data-modal-hide="default-modal"
              onClick={() => close()}
            >
              <img src={cross} alt="cross" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {children}
          <div className="flex items-center justify-end p-4 md:p-5 border-t border-greySix gap-3 rounded-b border-solid border-x-0 border-b-0">
            <button
              data-modal-hide={modalId}
              type="button"
              className="py-2.5 px-7 text-sm font-poppins text-primaryNewBlue border-0 bg-white"
              onClick={() => close()}
            >
              {cancelButtonText || t('CANCEL')}
            </button>
            <button
              form={formId}
              data-modal-hide={modalId}
              type="submit"
              className="text-white bg-primaryNewBlue font-poppins hover:bg-white hover:text-primaryNewBlue text-sm rounded-md px-7 py-2.5 border border-primaryNewBlue"
            >
              {submitButtonText || t('SAVE')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

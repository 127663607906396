import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@Openclimat/openclimat-component-library';

import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function Tags({ tags }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const options = tags?.map((tag) => ({
      name: tag.name,
      value: tag.id,
    }));
    setOptions(options);
  }, []);

  const handleTagsFilter = (value) => {
    setLoading(true);
    if (value === null) {
      searchParams.delete('tags_filter');
      searchParams.set('p', 0);
    } else {
      searchParams.set('tags_filter', value);
      searchParams.set('p', 0);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('tags_filter')) {
      setValue(searchParams.get('tags_filter'));
    } else {
      setValue('');
    }
  }, [searchParams]);

  return (
    <Dropdown
      name={t('TAGS')}
      handleChange={handleTagsFilter}
      options={options}
      value={value}
    />
  );
}

export default Tags;

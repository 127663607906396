import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import exclamationMark from '../../../../assets/ui/exclamation-mark.svg';
import searchIcon from '../../../../assets/ui/search-icon.svg';

function ProfileSelector({ profiles, selectedProfiles, setSelectedProfiles, profileError, setProfileError, profileSearchRef }) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedSelectedProfiles, setSortedSelectedProfiles] = useState([]);
  const [sortedUnselectedProfiles, setSortedUnselectedProfiles] = useState([]);

  const handleProfileChange = (profileId) => {
    setProfileError('');

    setSelectedProfiles((prev) => {
      if (prev.includes(profileId)) {
        return prev.filter((id) => id !== profileId);
      }
      return [...prev, profileId];
    });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const sortedProfiles = profiles.sort((a, b) => a.name.localeCompare(b.name));

    const filteredProfiles = sortedProfiles.filter((profile) => profile.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const selected = filteredProfiles.filter((profile) => selectedProfiles.includes(profile.id));
    const unselected = filteredProfiles.filter((profile) => !selectedProfiles.includes(profile.id));
    setSortedSelectedProfiles(selected);
    setSortedUnselectedProfiles(unselected);
  }, [profiles, selectedProfiles, searchTerm]);

  const handleKeyPress = (event, profileId) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      handleProfileChange(profileId);
    }
  };

  return (
    <div>
      <h2 className=" text-base mb-2">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_C')}
      </h2>
      <div className=" flex flex-col">
        <div className="w-full flex items-center relative mb-4">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <img src={searchIcon} alt="Search" className="w-5 h-5" />
          </div>
          <input
            type="text"
            placeholder={t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.PROFILE_PLACEHOLDER')}
            value={searchTerm}
            onChange={handleSearchChange}
            ref={profileSearchRef}
            className={`w-full h-11 ${
              profileError
                ? ' border-2 border-red bg-red/5 focus:border-red focus:border'
                : 'bg-backgroundCleanBlue'
            }  text-sm font-moskauGrotesk font-normal text-greyHeavy border border-solid border-greyLight focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-full p-2 pl-10`}
          />
        </div>
        {profileError && (
          <span className=" text-sm font-moskauGrotesk font-normal text-red mb-4">
            <img
              src={exclamationMark}
              alt="exclamation mark"
              className=" mr-1 -mt-[3px]"
            />
            {profileError}
          </span>
        )}
      </div>

      <div className=" w-full max-h-80 overflow-y-scroll">
        {sortedSelectedProfiles.length !== 0 && (
          <span className=" text-xs font-normal font-moskauGrotesk text-greyDark">
            {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.PROFILE_SELECTED')}
          </span>
        )}
        {sortedSelectedProfiles.map((profile) => (
          <div
            key={profile.id}
            className="flex items-center gap-x-2 py-3 cursor-pointer hover:bg-backgroundCleanBlue"
            onClick={() => handleProfileChange(profile.id)}
            onKeyDown={(event) => handleKeyPress(event, profile.id)}
            tabIndex={0}
            role="checkbox"
            aria-checked
            style={{ outline: 'none' }}
          >
            <input
              type="checkbox"
              checked
              readOnly
              className="w-4 h-4 accent-primaryNewBlue border border-solid border-greyLight checked:bg-primaryNewBlue rounded-sm ml-2"
            />
            <span className="text-xs text-greyHeavy">{profile.name}</span>
          </div>
        ))}
        {sortedSelectedProfiles.length !== 0 && (
          <hr className="my-2  border-greyFive" />
        )}
        {sortedUnselectedProfiles.map((profile) => (
          <div
            key={profile.id}
            className="flex items-center gap-x-2 py-3 cursor-pointer hover:bg-backgroundCleanBlue"
            onClick={() => handleProfileChange(profile.id)}
            onKeyDown={(event) => handleKeyPress(event, profile.id)}
            tabIndex={0}
            role="checkbox"
            aria-checked={false}
            style={{ outline: 'none' }}
          >
            <input
              type="checkbox"
              checked={false}
              readOnly
              className="w-4 h-4 accent-primaryNewBlue border border-solid border-greyLight rounded-sm ml-2"
            />
            <span className="text-xs text-greyHeavy">{profile.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfileSelector;

const getAnsweredQuestionsCount = (questions, privateQuestions = false) => {
  let answeredQuestions = 0;
  questions.forEach((question) => {
    if (!privateQuestions) {
      if (question.individualQuestions[0].answer) {
        answeredQuestions += 1;
      }
    } else if (question.answer) {
      answeredQuestions += 1;
    }
  });
  return answeredQuestions;
};

export default getAnsweredQuestionsCount;

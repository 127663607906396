import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserLocale } from '../../../../utils/getUserLocal';
import Features from '../../../../features.json';

import Tabs from '../Tabs/Tabs';
import ocStomp from '../../../../assets/ui/open-climat-stomp.svg';
import bug from '../../../../assets/ui/bug-new-blue.svg';
import Certified from '../../../../assets/ui/managed.svg';
import buildings from '../../../../assets/ui/buildings.svg';

import './Header.scss';
import SectorInformations from './SectorInformations';
import EmployeesInformations from './EmployeesInformations';
import GroupInformations from './GroupInformations';

function Header({ view }) {
  const [brands, setBrands] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [profileLogoSrc, setProfileLogoSrc] = useState(buildings);

  const { profile, features } = useOutletContext();
  const { t } = useTranslation();

  const MAX_BRANDS = 4; // 0, 1, 2, 4 (4 valeurs)

  const locale = useUserLocale();

  const tooltipRef = useRef(null);

  const handleMouseEnter = (tooltip) => {
    setActiveTooltip(tooltip);
  };

  const handleMouseLeave = (e) => {
    if (tooltipRef.current && !tooltipRef.current.contains(e.relatedTarget)) {
      setActiveTooltip(null);
    }
  };

  useEffect(() => {
    if (profile?.brands) {
      setBrands(profile.brands);
    }
    if (profile?.logo_url) {
      setProfileLogoSrc(process.env.REACT_APP_FILES_DOMAIN + profile.logo_url);
    }
  }, [profile]);

  return (
    <>
      <div className="w-full bg-backgroundCleanBlue">
        <div className="container mx-auto ">
          <div className="w-full max-[983px]:flex-col flex items-center justify-between p-5">
            <div className="w-full h-full flex max-[768px]:flex-col items-center gap-x-9 ">
              <div
                className="min-w-[180px] min-h-[180px] w-[180px] h-[180px]  rounded-full bg-white bg-center bg-no-repeat max-[768px]:mb-6 border border-solid border-greySeven"
                style={{
                  backgroundImage: `url(${profileLogoSrc})`,
                  ...(profile?.logo_url ? { backgroundSize: 'contain' } : {}),
                }}
              />
              <div className="w-full flex flex-col">
                <div className="flex items-center gap-x-3 mb-4">
                  <h1 className="text-[28px] text-black m-0">{ profile?.name }</h1>
                  {profile?.is_managed &&
                  <img src={Certified} alt="certified logo" className="-mt-2" />}
                </div>
                <div className="flex items-stretch gap-x-5">
                  <SectorInformations profile={profile} />
                  {profile.employees_count ? <EmployeesInformations profile={profile} /> : null}
                  <GroupInformations profile={profile} />
                </div>
                {brands?.length > 0 && (
                  <div className="flex flex-col items-stretch gap-x-7 mt-4">
                    <div className="text-sm text-greyFour">
                      {t('BRANDS')}
                    </div>
                    <div className="flex gap-1.5 mt-0.5 items-center">
                      {brands?.slice(0, MAX_BRANDS).map((brand) => (
                        <div
                          ref={tooltipRef}
                          className="w-[40px] h-[40px] rounded-full bg-white bg-center bg-no-repeat border border-greySeven border-solid justify-center items-center flex relative"
                          onMouseEnter={() => handleMouseEnter(brand.name)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img src={`${process.env.REACT_APP_FILES_DOMAIN}${brand.attachment_path}${brand.attachment_name}`} alt={brand.name} width={36} height={36} className="max-h-9 max-w-9 object-contain p-1.5" />
                          {activeTooltip === brand.name && (
                            <ul
                              ref={tooltipRef}
                              className="absolute left-full ml-3 top-1/2 transform -translate-y-1/2 bg-greyThree py-2.5 px-3 rounded-sm text-sm text-white z-30 list-none"
                              onMouseEnter={() => handleMouseEnter(brand.name)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <li key={brand.name} className="whitespace-nowrap">{`${brand.name}`}</li>
                            </ul>
                          )}
                        </div>
                      ))}
                      {(brands.length > MAX_BRANDS && brands.length > 0) && (
                        <span
                          className="underline relative"
                          onMouseEnter={() => handleMouseEnter('moreBrands')}
                          onMouseLeave={handleMouseLeave}
                        >
                          +&nbsp;{parseInt(brands.length, 10) - MAX_BRANDS}
                          {activeTooltip === 'moreBrands' && (
                            <ul
                              ref={tooltipRef}
                              className="absolute left-full ml-3 top-1/2 transform -translate-y-1/2 bg-greyThree py-2.5 px-3 rounded-sm text-sm text-white z-30 list-none"
                              onMouseEnter={() => handleMouseEnter('moreBrands')}
                              onMouseLeave={handleMouseLeave}
                            >
                              {brands.slice(MAX_BRANDS, brands.length).map((brand) => (
                                <li key={brand.name} className="whitespace-nowrap">{` ${brand.name}`}</li>
                              ))}
                            </ul>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={`${profile.type === 1 ? 'min-[1230px]:w-80 min-[1150px]:w-60 w-40' : 'w-80'}  hidden min-[984px]:flex flex-col items-center justify-center`}>
              {profile.type !== 2 && (
                profile?.is_managed ? (
                  <>
                    <img src={ocStomp} alt="open climat logo" />
                    <div className="text-sm font-moskauGrotesk font-semibold text-primaryNewBlue my-3 text-center">
                      {t('HEADER_COMPLETED_PROFILE_A')}
                      <br />
                      {t('HEADER_COMPLETED_PROFILE_B')}
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col items-center gap-y-2">
                    <img src={bug} alt="warning" />
                    <div className="text-sm font-semibold font-moskauGrotesk text-primaryNewBlue">{t('HEADER_PARTIAL_PROFILE')}</div>
                  </div>
                )
              )}
              {features.includes(Features.ctaOpenClimat) && (
              <button type="button" className=" py-3 px-7 bg-primaryNewBlue rounded-md text-white text-sm font-medium border-none">
                <a href={`https://www.openclimat.com/${locale}/profile/${profile.slug}`} className="no-underline text-white">{t('GO_TO_OPENCLIMAT_PAGE')}</a>
              </button>
              )}
            </div>
            {(profile.type !== 2 || features.includes(Features.ctaOpenClimat)) && (
            <div className="flex flex-col min-[984px]:hidden p-6 border border-solid border-greySix rounded-sm mt-10">
              {profile.type !== 2 && (
                profile.is_managed ? (
                  <div className="flex items-center gap-x-2 justify-center text-center">
                    <img src={ocStomp} alt="open climat logo" width="30px" height="auto" />
                    <div className="text-sm font-moskauGrotesk font-semibold text-primaryNewBlue my-3">
                      {t('HEADER_COMPLETED_PROFILE_A')}
                      <br />
                      {t('HEADER_COMPLETED_PROFILE_B')}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center gap-x-2 justify-center">
                    <img src={bug} alt="warning" width="30px" height="auto" />
                    <div className="text-sm font-moskauGrotesk font-semibold text-primaryNewBlue my-3">{t('HEADER_PARTIAL_PROFILE')}</div>
                  </div>
                )
              )}
              {features.includes(Features.ctaOpenClimat) && (
              <div className="w-full flex items-center justify-center">
                <button type="button" className=" py-3 px-7 bg-primaryNewBlue rounded-md text-white text-sm font-medium border-none">
                  <a href={`https://www.openclimat.com/${locale}/profile/${profile.slug}`} className="no-underline text-white">{t('GO_TO_OPENCLIMAT_PAGE')}</a>
                </button>
              </div>
              )}
            </div>
            )}
          </div>
        </div>
      </div>
      <Tabs view={view} />
    </>
  );
}

export default Header;

import React from 'react';
import { useTranslation } from 'react-i18next';

import logoOcLeclerc from '../../../assets/ui/landingPartner/logo-oc-x-leclerc.svg';
import logoLeclerc from '../../../assets/ui/landingPartner/logo-partner-leclerc.svg';

function Header() {
  const { t } = useTranslation();

  return (
    <header className="parent-header bg-white xl:bg-gradient-openclimat">
      <div id="content" className="container mx-auto flex flex-col xl:flex-row bg-white text-white">
        <div className="xl:w-3/5 clip-45 px-[30px] pt-11 pb-14 xl:pl-20 xl:pr-44 xl:pt-[60px] xl:pb-52 bg-gradient-openclimat">
          <div className="flex justify-center xl:justify-normal">
            <img src={logoOcLeclerc} alt="OpenClimat X Leclerc" className="w-full h-full md:w-96 object-contain xl:object-none xl:w-max xl:h-max" />
          </div>
          <h1 className="text-[40px] xl:text-4xl mt-[70px] xl:mt-16 font-medium 2xl:pr-20">
            {t('LANDING_LECLERC.HEADER.TITLE')}
          </h1>
          <h2 className="text-lg mt-[40px] xl:mt-9 font-normal mb-16">
            {t('LANDING_LECLERC.HEADER.TEXT_A')}
            <br />
            <br />
            <span>{t('LANDING_LECLERC.HEADER.TEXT_B_A')}</span> {t('LANDING_LECLERC.HEADER.TEXT_B_B')}
            <br />
            {t('LANDING_LECLERC.HEADER.TEXT_C')}
          </h2>
          <a href="#contact-form" className="bg-white w-full xl:w-fit text-center block rounded-md px-7 py-2.5 text-primaryNewBlue no-underline text-sm hover:bg-backgroundCleanBlue">
            {t('LANDING_LECLERC.HEADER.CTA_A')}
          </a>
        </div>
        <div className="bg-white xl:w-2/5 xl:relative xl:flex xl:justify-center xl:items-center">
          <a href="#contact-form" className="top-8 right-12 hidden xl:block absolute bg-secondarySkyBlue hover:bg-secondarySkyBlueHover rounded-md px-7 py-2.5 text-white no-underline text-sm">
            {t('LANDING_LECLERC.HEADER.CTA_B')}
          </a>
          <div className="xl:relative p-10 xl:p-0 flex justify-center xl:justify-normal">
            <img src={logoLeclerc} alt="Logo Leclerc" className="xl:-left-[320px] xl:-top-32 h-full w-full md:w-96 xl:h-max xl:w-max object-contain" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';

import toggleSort from '../../../../utils/toggleSort';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function ClimatActionHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'climate_action';

  useEffect(() => {
    if (searchParams.get('climate_action')) {
      setSort(searchParams.get('climate_action'));
    }
  }, []);

  return (
    <th className="font-12 px-3 font-medium cursor-pointer border-solid border-greySix border border-b-0" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="flex justify-center">
        <div className="flex items-center">
          <div className="flex flex-col justify-center">
            <span>
              {t('CLIMATE_ACTION')}
            </span>
            <span id="climate-action-subtitle" className="font-10 text-muted">
              {t('CURRENT_PARIS_AGREEMENT_ALIGNMENT')}
            </span>
          </div>
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end ml-2.5 self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
            </span>
          )}
        </div>
      </div>
    </th>
  );
}

function ClimatActionBody({ profile, cellStyle, onClick }) {
  const { t } = useTranslation();
  const styleScore = ['score-one', 'score-two', 'score-three', 'score-four', 'score-five', 'score-six'];

  return (
    <td className="h-[60px] border-solid border-greySix border" style={cellStyle}>
      <Link to={onClick(profile)} className="px-3 w-full h-full flex no-underline text-greyOne items-center">
        <div className="flex">
          <div className={`elipse self-center ${styleScore[profile.score - 1]}`} />
          <div className="ps-1 climate-action-text">
            {t(profile.score_label)}
          </div>
        </div>
      </Link>
    </td>
  );
}

export { ClimatActionHead, ClimatActionBody };

import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

import pen from '../../../../../assets/ui/pen.svg';
import trashBin from '../../../../../assets/ui/trash-bin-m.svg';
import plus from '../../../../../assets/ui/cta-plus.svg';

import Question from './Question';
import useModal from '../../../../../hooks/useModal';

function Category({ profile, category, hasWriteAccess, isPublished, setShowOptional, fetchData, setData, setItemToDelete }) {
  const { open } = useModal();
  const { t } = useTranslation();

  return (
    <>
      {category.questions.map((question, index) => (
        <div key={index} className="lg:grid grid-cols-12">
          <div className="col-span-7 col-start-1 h-full border border-greySix border-solid mb-6">
            <section id={category.id} className="category-test -mt-24 pt-24">
              <div className="rounded">
                <div className="p-6">
                  <h3 className="flex justify-between">
                    {category.name}
                  </h3>
                  <div className="flex flex-col gap-6">
                    <Question
                      key={index}
                      question={question}
                      isPublished={isPublished}
                      setShowOptional={setShowOptional}
                      hasWriteAccess={hasWriteAccess}
                      fetchData={fetchData}
                      profile={profile}
                      setData={setData}
                      setItemToDelete={setItemToDelete}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="col-span-5 col-start-8 px-6 pb-10 pt-6 h-full bg-backgroundCleanBlue -mx-6 lg:mx-0">
            {((hasWriteAccess || isPublished) || (question.answer?.contentListItems && question.answer.contentListItems.some((list) => list.type === 3))) && (
              <h4 className="font-semibold text-base">
                {t('TO_REMEMBER')}
              </h4>
            )}
            <div className="flex flex-col gap-3.5 mt-6">
              {question.answer?.contentListItems && (isPublished || hasWriteAccess) && question.answer.contentListItems.map((list, index) => (
                list.type === 3 ? (
                  <div key={index} className="border border-greySix p-2.5 text-primaryNewBlue bg-[#21AFFF0F] rounded-sm text-sm flex gap-6 justify-between">
                    <span className="self-center custom-list">
                      {parse(DOMPurify.sanitize(list.name))}
                    </span>
                    {hasWriteAccess ? (
                      <div className="flex gap-5">
                        <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                          <button
                            type="button"
                            onClick={() => {
                              setData({
                                info: question.info,
                                answerId: question.answer.id,
                                id: list.id,
                                name: list.name,
                                type: 'TYPE_ACTION_KEYS',
                              });
                              open('question-actions-items-modal');
                            }}
                          >
                            <img src={pen} alt="Pen" />
                          </button>
                        </div>
                        <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setItemToDelete({ id: list.id, type: 'contentListItem' });
                              open('delete-confirmation-modal-questions');
                            }}
                          >
                            <img src={trashBin} alt="Trash bin" />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null
              ))}
              {hasWriteAccess && question.answer?.answer ? (
                <div className="flex">
                  <button
                    type="button"
                    className="flex gap-2 bg-sky-blue rounded-[56px] py-[10px] px-7 text-sm text-white items-center"
                    onClick={async () => {
                      setData({
                        title: t('ACTIONS_PLAN_KEY_TAKEAWAYS_CTA'),
                        info: question.info,
                        answerId: question.answer?.id,
                        type: 'TYPE_ACTION_KEYS',
                      });
                      open('question-actions-items-modal');
                    }}
                  >
                    <img src={plus} alt="Plus" />
                    <span>
                      {t('ACTIONS_PLAN_KEY_TAKEAWAYS_CTA')}
                    </span>
                  </button>
                </div>
              ) : null}
              {hasWriteAccess && !question.answer?.answer ? (
                <p className="text-sm text-greyOne">
                  {t('ACTIONS_PLAN_EMPTY_STATE_B')}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Category;

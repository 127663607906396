/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';
import { useUserLocale } from '../../../../../utils/getUserLocal';
import handleResponse from '../../../../../utils/handleResponse';

export default function QuestionsCarbonModelFormModal({ data, profileId, fetchData }) {
  const [parsedAnswer, setParsedAnswer] = useState();
  const [total, setTotal] = useState(0);
  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({});
  const { close } = useModal();

  const locale = useUserLocale();

  const { t } = useTranslation();

  const { post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions/${data?.id}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const updateTotal = (value) => {
    const newTotal = Object.entries(value)
      .filter((entry) => entry[0].includes('percent') && entry[1] !== '' && entry[1] != null)
      .map((entry) => parseFloat(entry[1], 10))
      .reduce((acc, value) => acc + value, 0);
    setTotal(Number(newTotal.toFixed(1)));
  };

  useEffect(() => {
    if (data) {
      reset(data);
      if (data.type === 3 && data.answer) {
        setParsedAnswer(data.answer.answer && JSON.parse(data.answer.answer));
      }
    }
    register('answer', {
      required: false,
    });
  }, [data]);

  const onSubmit = async (formData) => {
    try {
      let data = formData;
      data = Object.keys(formData).filter((property) => !property.toString().includes('-')).reduce((obj, key) => {
        obj[key] = formData[key];
        return obj;
      }, {});

      const formDataDistributions = Object.keys(formData).filter((property) => property.toString().includes('-')).reduce((obj, key) => {
        obj[key] = formData[key];
        return obj;
      }, {});

      let reduceDataById = Object.keys(formDataDistributions).map((property) => ({
        id: property.split('-')[0],
      }));

      reduceDataById = reduceDataById.filter((value, index, self) => index === self.findIndex((t) => (
        t.id === value.id
      )));

      reduceDataById = reduceDataById.map(((data) => ({
        id: data.id,
        percent: formData[`${data.id}-percent`],
      })));

      const finalData = {};
      finalData.profile_id = profileId;
      finalData.question_type = data.type;
      finalData.locale = locale;
      finalData.distributions = reduceDataById;
      finalData.answer = {
        emissions_scope_12: formData.emissionsScope12,
        emissions_scope_3: formData.emissionsScope3,
        carbon_mix_balance_year: formData.carbonMixBalanceYear,
      };

      if (data.answer && data.answer.id) {
        await put(`answer/${data.answer.id}`, finalData);
      } else {
        await post('answer', finalData);
      }
      if (response.ok) {
        fetchData();
        close();
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  useEffect(() => {
    watch((value) => {
      updateTotal(value);
    });
  }, [watch]);

  return (
    <TemplateFormModal
      modalId="question-carbon-distribution-modal"
      formId="question-carbon-distribution-form"
      title="Éditer une réponse"
    >
      <form
        id="question-carbon-distribution-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="p-4 md:p-5 space-y-4">
          <p>
            Renseigner le montant total des émissions de CO2e
          </p>
          <div className="grid grid-cols-12 mb-3 gap-4">
            <div className="sm:col-span-6">
              <label htmlFor="emissionsScope12" className="form-label font-13 equired-field-form">
                Scope 1&2, en <abbr title="kilotonnes de CO2">ktCO2e</abbr>
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  step=".01"
                  className={`border border-greySix rounded-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors.amount ? 'focus:outline-redError' : 'focus:outline-secondarySkyBlue'}`}
                  id="emissionsScope12"
                  {...register('emissionsScope12', {
                    required: true,
                    value: parsedAnswer && parsedAnswer.emissionsScope12,
                  })}
                />
                {errors.emissionsScope12 && (
                <div className="text-sm text-redError">Champ requis</div>
                )}
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="emissionsScope3" className="form-label font-13 equired-field-form">
                Scope 3, en <abbr title="kilotonnes de CO2">ktCO2e</abbr>
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  step=".01"
                  className={`border border-greySix rounded-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors.amount ? 'focus:outline-redError' : 'focus:outline-secondarySkyBlue'}`}
                  id="emissionsScope3"
                  {...register('emissionsScope3', {
                    required: true,
                    value: parsedAnswer && parsedAnswer.emissionsScope3,
                  })}
                />
                {errors.emissionsScope3 && (
                <div className="text-sm text-redError">Champ requis</div>
                )}
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="carbonMixBalanceYear" className="text-sm">
                Année de mesure
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  className={`border border-greySix rounded-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors.amount ? 'focus:outline-redError' : 'focus:outline-secondarySkyBlue'}`}
                  id="carbonMixBalanceYear"
                  {...register('carbonMixBalanceYear', {
                    required: true,
                    value: parsedAnswer && parsedAnswer.carbonMixBalanceYear,
                  })}
                />
                {errors.carbonMixBalanceYear && (
                <div className="text-sm text-redError">Champ requis</div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div htmlFor="answer" className="form-label required-field-form mb-3">
              {data && data.info}
            </div>
            <div className="grid grid-cols-12 gap-4 fw-500 mb-2">
              <div className="sm:col-span-6">
                <span>Nom</span>
              </div>
              <div className="sm:col-span-4">
                <span>Scope</span>
              </div>
              <div className="sm:col-span-2">
                <span className={`${total > 100 ? 'text-redError' : ''}`}>{total === 0 ? data?.distributions && data.distributions.reduce((acc, val) => acc + Number(val.percent), 0).toFixed(1) : total.toFixed(1)}%</span>
              </div>
            </div>
            {data?.carbonModel && data.carbonModel.map((item) => (
              <div className="grid grid-cols-12 gap-4 mb-2" key={item.id}>
                <div className="sm:col-span-6">
                  {item.name}
                </div>
                <div className="sm:col-span-4">
                  {item.scope === 12 ? '1 & 2' : '3'}
                </div>
                <div className="sm:col-span-2">
                  <div className="has-validation">
                    <input
                      type="number"
                      step=".1"
                      min={0}
                      className={`border border-greySix rounded-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors[`${item.id}-percent`] ? 'is-invalid' : ''} ${(total > 100) ? 'focus:outline-redError' : 'focus:outline-secondarySkyBlue'}`}
                      {...register(`${item.id}-percent`, {
                        required: false,
                        value: item.percent,
                        pattern: {
                          value: /^\d+(\.\d{0,1})?$/,
                          message: t('CARBON_FOOTPRINT_ERROR_1'),
                        },
                      })}
                    />
                    {errors[`${item.id}-percent`] && (
                      <div className="text-sm text-redError">{errors[`${item.id}-percent`].message}</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}

import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useFetch } from 'use-http';

import eyeSlash from '../../../../assets/ui/eye-slash-md.svg';
import eye from '../../../../assets/ui/eye-md.svg';
import getSession from '../../../../utils/getSession';
import storeContext from '../../../../context/StoreContext';
import spin from '../../../../assets/ui/spin.svg';
import getLocalLink from '../../../../utils/getLocalLink';

function Password() {
  const [showPassword, setShowPassword] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const { partner } = useOutletContext();
  const { store, setStore } = useContext(storeContext);
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();
  const { post, response, loading } = useFetch(process.env.REACT_APP_PARTNER_API, {
    credentials: 'include',
  });

  const { t } = useTranslation();

  const onSubmit = async (data) => {
    try {
      setWrongCredentials(false);
      await post('/login/password', {
        email: data.email,
        password: data.password,
        domain: partner.domain,
      });

      if (response.status === 200) {
        const params = new URLSearchParams(window.location.search);
        const session = await getSession(params.get('partner'));

        if (session) {
          setStore({
            ...store,
            email: session.email,
          });

          navigate(getLocalLink('/dashboard/home'));
        }
      } else if (response.status === 401) {
        setWrongCredentials(true);
      } else if (response.status === 500) {
        navigate(getLocalLink('/login'));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleEyeSlashClick = () => {
    const inputPassword = document.getElementById('inputPassword');
    if (inputPassword.type === 'password') {
      inputPassword.type = 'text';
      setShowPassword(true);
    } else {
      inputPassword.type = 'password';
      setShowPassword(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="partner-login-form" className="mt-[30px]">
      <div>
        <label htmlFor="inputEmail" className="mb-2.5 font-medium">{t('PARTNER_LOGIN_TEXT_2')}</label>
        <input
          type="email"
          {...register('email', {
            required: t('EMAIL_REQUIRED'),
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('FORM_CONTACT_COMPANY_EMAIL_BAD_FORMAT'),
            },
          })}
          name="email"
          id="inputEmail"
          className={`flex w-full border-greySix border border-solid rounded-md py-3 px-5 text-greyFour ${errors.email ? 'border-redError focus:outline-redError text-redError' : 'border-greySix focus:outline-secondarySkyBlue text-greyFour'}`}
          autoComplete="email"
          placeholder={t('LOGIN_FORM.PASSWORD.PLACEHOLDER_EMAIL')}
        />
        {errors.email && <div className="text-redError mt-2 text-sm">{errors.email.message}</div>}
      </div>
      <div className="mt-5">
        <label htmlFor="inputPassword" className="mb-2.5 font-medium">{t('PARTNER_LOGIN_TEXT_13')}</label>
        <div className="relative flex items-center">
          <input
            type="password"
            {...register('password', {
              required: t('PASSWORD_REQUIRED'),
            })}
            name="password"
            id="inputPassword"
            className={`flex w-full border-greySix border border-solid rounded-md py-3 px-5 ${errors.password ? 'border-redError focus:outline-redError text-redError' : 'border-greySix focus:outline-secondarySkyBlue text-greyFour'}`}
            autoComplete="password"
            placeholder={t('LOGIN_FORM.PASSWORD.PLACEHOLDER_PASSWORD')}
            style={{ paddingRight: '2.5rem' }}
          />
          <button type="button" onClick={() => handleEyeSlashClick()} className="absolute pointer right-4 cursor-pointer border-0 bg-transparent p-0">
            <img id="eye-slash" src={showPassword ? eye : eyeSlash} alt="Eye slash" />
          </button>
        </div>
        {errors.password && <div className="text-redError mt-2 text-sm">{errors.password.message}</div>}
        {wrongCredentials && <div className="text-redError mt-2 text-sm">{t('WRONG_CREDENTIALS')}</div>}
        <button type="submit" className="w-full mt-[30px] min-h-[48px] py-3 bg-primaryNewBlue border-0 rounded text-white flex items-center justify-center" disabled={loading}>
          {loading ? <img src={spin} alt="spin" /> : t('PARTNER_LOGIN_TEXT_14', { name: partner.name })}
        </button>
        <div className="mt-5">
          <a href={getLocalLink('/login/reset')} className="text-secondarySkyBlue">
            {t('LOGIN_FORM.FORGOTTEN_PASSWORD')}
          </a>
        </div>
      </div>
    </form>
  );
}

export default Password;

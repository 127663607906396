import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import tailwindConfig from '../../../tailwind.config';
import packageJson from '../../../../package.json';

import powerIcon from '../../../assets/ui/sidebar/power.svg';
import powerIconActive from '../../../assets/ui/sidebar/power-active.svg';
import doubleArrow from '../../../assets/ui/left-right-arrow.svg';
import homeIcon from '../../../assets/ui/sidebar/home-icon2.svg';
import homeIconActive from '../../../assets/ui/sidebar/home-icon-active2.svg';
import tagIcon from '../../../assets/ui/sidebar/tag-icon2.svg';
import tagIconActive from '../../../assets/ui/sidebar/tag-icon-active2.svg';
import filesIcon from '../../../assets/ui/sidebar/files-icon2.svg';
import filesIconActive from '../../../assets/ui/sidebar/files-icon-active2.svg';
import storeIcon from '../../../assets/ui/sidebar/store-logo2.svg';
import storeIconActive from '../../../assets/ui/sidebar/store-logo-active2.svg';
import exitIcon from '../../../assets/ui/sidebar/exit-icon.svg';
import chartBarActive from '../../../assets/ui/sidebar/chart-bar-active2.svg';
import chartBar from '../../../assets/ui/sidebar/chart-bar2.svg';
import stackIcon from '../../../assets/ui/sidebar/stack2.svg';
import stackIconActive from '../../../assets/ui/sidebar/stack-active2.svg';
import barcodeIcon from '../../../assets/ui/sidebar/barcode2.svg';
import barcodeIconActive from '../../../assets/ui/sidebar/barcode-active2.svg';
import fileCodeActive from '../../../assets/ui/sidebar/file-code-active2.svg';
import fileCode from '../../../assets/ui/sidebar/file-code2.svg';
import questionIcon from '../../../assets/ui/sidebar/question-mark.svg';
import questionIconActive from '../../../assets/ui/sidebar/question-mark-active.svg';
import flagFr from '../../../assets/ui/flag/flag-fr.svg';
import flagUk from '../../../assets/ui/flag/flag-uk.svg';

import getLocalLink from '../../../utils/getLocalLink';
import getLogout from '../../../utils/getLogout';
import verifyAuthorization from '../../../utils/verifyAuthorization';
import Suppliers from './Suppliers/Suppliers';
import Features from '../../../features.json';

import { TableLoadingContext } from '../../../context/TableLoadingProvider';
import LanguageSwitcher from './LanguageSwitcher';

export default function Sidebar({ logo, session, partner, isSidebarOpen, setIsSidebarOpen, features }) {
  const [isOpen, setIsOpen] = useState(false);
  const { setNavigationLoading } = useContext(TableLoadingContext);

  const { t } = useTranslation();
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const isPrivatePartner = partner.private === 1;

  const isDemoScreen = features.includes(Features.mockedDemoScreens);

  const handleButton = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMouseEnter = (icon) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const getIconStyle = (icon, activeIcon, isActive, iconName) => {
    let backgroundColor = `${tailwindConfig.theme.colors.greyThree}`; // Default color
    let maskImage = `url(${icon})`;

    if (isActive || hoveredIcon === iconName) {
      backgroundColor = `${tailwindConfig.theme.colors.greyOne}`; // Active color
      maskImage = `url(${activeIcon})`;
    }

    if (iconName === 'logout') {
      maskImage = `url(${icon})`;
    }

    return {
      display: 'inline-block',
      width: '24px',
      height: '24px',
      backgroundColor,
      WebkitMaskImage: maskImage,
      WebkitMaskSize: 'cover',
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskPosition: 'center',
      transition: 'background-color 50ms',
    };
  };

  const languages = [
    {
      name: 'Français',
      shorthand: 'fr',
      flag: flagFr,
    },
    {
      name: 'English',
      shorthand: 'en',
      flag: flagUk,
    },
  ];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex min-h-full font-inter border-y-0 border-l-0 border-r border-solid border-greyLight bg-white relative transition-all duration-300 z-20 shadow-[0_4px_4px_0px_rgba(215,215,214,0.25)] ${isSidebarOpen ? 'w-[300px]' : 'min-w-[80px]'}`}>
      <button type="button" className="sticky left-[300px] top-[64px] min-w-7 h-7 bg-white z-30 flex items-center justify-center rounded-lg border border-solid border-greySeven -mr-4" onClick={handleButton}>
        <img src={doubleArrow} alt="open sidebar button" />
      </button>
      <div className={`w-full h-screen flex flex-col justify-between sticky top-0 overflow-y-auto ${isSidebarOpen ? '' : ' overflow-x-visible'}`} style={{ overflow: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none', WebkitScrollbar: 'none' }}>
        <div className=" w-full flex flex-col text-primaryNewBlue text-sm items-center">
          <div className="py-10 pr-[12px]">
            <img src={`${process.env.REACT_APP_FILES_DOMAIN}${logo}`} className="transition-all duration-300" alt="Logo" width={`${isSidebarOpen ? '145px' : '52px'}`} height="auto" />
          </div>
          <div className="pl-2.5 pr-[22px] mb-7 w-full tex-greyThree ">
            {verifyAuthorization(session, ['ROLE_PARTNER', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']) && (
              <NavLink
                to={getLocalLink('/dashboard/home')}
                className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
                onMouseEnter={() => handleMouseEnter('home')}
                onMouseLeave={handleMouseLeave}
              >
                {({ isActive }) => (
                  <>
                    {isSidebarOpen && (
                    <div className={`w-full h-full flex gap-x-2 ${isActive ? 'bg-greyEight' : ''} group-hover:bg-greyEight transition-all delay-50`}>
                      <div className=" flex items-center justify-center">
                        {isPrivatePartner && (
                        <span style={getIconStyle(homeIcon, homeIconActive, isActive, 'home')} className="ml-3" />
                        )}
                      </div>
                      <div className={`w-full h-full p-3 ${isActive ? 'text-greyOne font-medium' : 'text-greyThree hover:text-greyOne hover:font-medium'} transition-all delay-50`}>
                        {t('PARTNER_DASHBOARD.SIDEBAR.HOME')}
                      </div>
                    </div>
                    )}
                    {!isSidebarOpen && (
                    <span style={getIconStyle(homeIcon, homeIconActive, isActive, 'home')} />
                    )}
                  </>
                )}
              </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_PARTNER', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']) && (
              <NavLink
                to={getLocalLink('/dashboard/suppliers')}
                className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
                onMouseEnter={() => handleMouseEnter('suppliers')}
                onMouseLeave={handleMouseLeave}
                onClick={() => setNavigationLoading(true)}
              >
                {({ isActive }) => (
                  <>
                    {isSidebarOpen && <Suppliers isActive={isActive} partner={partner} isSidebarOpen={isSidebarOpen} isPrivatePartner={isPrivatePartner} getIconStyle={getIconStyle} storeIcon={storeIcon} storeIconActive={storeIconActive} /> }
                    {!isSidebarOpen && <span style={getIconStyle(storeIcon, storeIconActive, isActive, 'suppliers')} />}
                  </>
                )}
              </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_PARTNER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']) && (
            <NavLink
              to={getLocalLink('/dashboard/statistics')}
              className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
              onMouseEnter={() => handleMouseEnter('statistics')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`w-full h-full flex gap-x-2 ${isActive ? 'bg-greyEight' : ''} group-hover:bg-greyEight transition-all delay-50`}>
                    <div className=" flex items-center justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(chartBar, chartBarActive, isActive, 'statistics')} className="ml-3" />
                      )}
                    </div>
                    <div className={`w-full h-full p-3 ${isActive ? 'text-greyOne font-medium' : 'text-greyThree hover:text-greyOne hover:font-medium'} transition-all delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.OVERVIEW')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(chartBar, chartBarActive, isActive, 'statistics')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_ADMIN']) && (
            <NavLink
              to={getLocalLink('/dashboard/category')}
              className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
              onMouseEnter={() => handleMouseEnter('category')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`w-full h-full flex gap-x-2 ${isActive ? 'bg-greyEight' : ''} group-hover:bg-greyEight transition-all delay-50`}>
                    <div className=" flex items-center justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(tagIcon, tagIconActive, isActive, 'category')} className="ml-3" />
                      )}
                    </div>
                    <div className={`w-full h-full p-3 ${isActive ? 'text-greyOne font-medium' : 'text-greyThree hover:text-greyOne hover:font-medium'} transition-all delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.CATEGORIES')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(tagIcon, tagIconActive, isActive, 'category')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_PARTNER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']) && (
            <NavLink
              to={getLocalLink('/dashboard/resources')}
              className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
              onMouseEnter={() => handleMouseEnter('ressources')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`w-full h-full flex gap-x-2 ${isActive ? 'bg-greyEight' : ''} group-hover:bg-greyEight transition-all delay-50`}>
                    <div className=" flex items-center justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(filesIcon, filesIconActive, isActive, 'ressources')} className="ml-3" />
                      )}
                    </div>
                    <div className={`w-full h-full p-3 ${isActive ? 'text-greyOne font-medium' : 'text-greyThree hover:text-greyOne hover:font-medium'} transition-all delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.RESSOURCES')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(filesIcon, filesIconActive, isActive, 'ressources')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {(isDemoScreen && verifyAuthorization(session, ['ROLE_ADMIN'])) && (
            <NavLink
              to={getLocalLink('/dashboard/campaign')}
              className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
              onMouseEnter={() => handleMouseEnter('campaign')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`w-full h-full flex gap-x-2 ${isActive ? 'bg-greyEight' : ''} group-hover:bg-greyEight transition-all delay-50`}>
                    <div className=" flex items-center justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(stackIcon, stackIconActive, isActive, 'campaign')} className="ml-3" />
                      )}
                    </div>
                    <div className={`w-full h-full p-3 ${isActive ? 'text-greyOne font-medium' : 'text-greyThree hover:text-greyOne hover:font-medium'} transition-all delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.CAMPAIGN')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(stackIcon, stackIconActive, isActive, 'campaign')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {(isDemoScreen && verifyAuthorization(session, ['ROLE_ADMIN'])) && (
            <div
              className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
              onMouseEnter={() => handleMouseEnter('product')}
              onMouseLeave={handleMouseLeave}
            >
              {isSidebarOpen && (
              <div className="w-full h-full flex gap-x-2 group-hover:bg-greyEight transition-all delay-50">
                <div className=" flex items-center justify-center">
                  {isPrivatePartner && (
                  <span style={getIconStyle(barcodeIcon, barcodeIconActive, false, 'product')} className="ml-3" />
                  )}
                </div>
                <div className="w-full h-full p-3 text-greyThree hover:text-greyOne hover:font-medium transition-all delay-50">
                  {t('PARTNER_DASHBOARD.SIDEBAR.PRODUCT')}
                </div>
              </div>
              )}
              {!isSidebarOpen && (
              <span style={getIconStyle(barcodeIcon, barcodeIconActive, false, 'product')} />
              )}
            </div>
            )}
            {verifyAuthorization(session, ['ROLE_ADMIN']) && (
            <div
              className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
              onMouseEnter={() => handleMouseEnter('api')}
              onMouseLeave={handleMouseLeave}
            >
              {isSidebarOpen && (
              <div className="w-full h-full flex gap-x-2 group-hover:bg-greyEight transition-all delay-50">
                <div className=" flex items-center justify-center">
                  {isPrivatePartner && (
                  <span style={getIconStyle(fileCode, fileCodeActive, false, 'api')} className="ml-3" />
                  )}
                </div>
                <div className="w-full h-full p-3 text-greyThree hover:text-greyOne hover:font-medium transition-all delay-50">
                  {t('PARTNER_DASHBOARD.SIDEBAR.API')}
                </div>
              </div>
              )}
              {!isSidebarOpen && (
              <span style={getIconStyle(fileCode, fileCodeActive, false, 'api')} />
              )}
            </div>
            )}
            {(isDemoScreen && verifyAuthorization(session, ['ROLE_ADMIN'])) && (
            <div
              className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center my-10'}`}
              onMouseEnter={() => handleMouseEnter('faq')}
              onMouseLeave={handleMouseLeave}
            >
              {isSidebarOpen && (
              <div className="w-full h-full flex gap-x-2 group-hover:bg-greyEight transition-all delay-50">
                <div className=" flex items-center justify-center">
                  {isPrivatePartner && (
                  <span style={getIconStyle(questionIcon, questionIconActive, false, 'faq')} className="ml-3" />
                  )}
                </div>
                <div className="w-full h-full p-3 text-greyThree hover:text-greyOne hover:font-medium transition-all delay-50">
                  FAQ
                </div>
              </div>
              )}
              {!isSidebarOpen && (
              <span style={getIconStyle(questionIcon, questionIconActive, false, 'faq')} />
              )}
            </div>
            )}
          </div>
        </div>
        <div className="w-full flex flex-col items-center pl-2.5 pr-[22px]">
          {!partner.private ? (
            <div className={`${isSidebarOpen ? 'px-3' : ''} py-7 w-full tex-greyThree  border border-solid border-x-0 border-b-0 border-greySeven text-sm`}>
              <NavLink
                to={getLocalLink('/')}
                className={`group relative w-full flex gap-x-2 no-underline items-center ${isSidebarOpen ? '' : 'justify-center'}`}
                onMouseEnter={() => handleMouseEnter('public')}
                onMouseLeave={handleMouseLeave}
              >
                <>
                  <div className={`w-full h-full text-greyThree hover:text-greyOne hover:font-medium text-nowrap ${isSidebarOpen ? '' : 'hidden'} transition-all delay-50`}>
                    {t('PARTNER_DASHBOARD.SIDEBAR.PUBLIC_SPACE_LINK')}
                  </div>
                  {!isSidebarOpen && <span style={getIconStyle(exitIcon, exitIcon, false, 'public')} />}
                </>
              </NavLink>
            </div>
          ) : ('')}
          <button
            type="button"
            className={`group relative ${isSidebarOpen ? 'p-3' : 'py-3'} bg-white text-greyThree  mb-7 pt-7 flex items-center ${isSidebarOpen ? '' : 'justify-center'} gap-x-2.5 w-full text-sm border-0 hover:text-greyOne hover:font-medium whitespace-nowrap transition-all delay-50`}
            onClick={getLogout}
            onMouseEnter={() => handleMouseEnter('logout')}
            onMouseLeave={handleMouseLeave}
          >
            <span style={getIconStyle(powerIcon, powerIconActive, false, 'logout')} />
            <span className={`${isSidebarOpen ? '' : 'hidden'}`}>
              {t('PARTNER_DASHBOARD.SIDEBAR.LOGOUT')}
            </span>
          </button>
          <LanguageSwitcher
            isSidebarOpen={isSidebarOpen}
            languages={languages}
            dropdownRef={dropdownRef}
            contentRef={contentRef}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          <p className="text-xs text-greyThree mt-5">
            v{packageJson.version}
          </p>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import tailwindConfig from '../../../../../tailwind.config';
import handleResponse from '../../../../../utils/handleResponse';

import arrowLeft from '../../../../../assets/ui/arrow-left.svg';

const tagsSelector = [
  {
    tailwindClassColor: 'bg-tagsGrey',
    colorValue: tailwindConfig.theme.colors.tagsGrey,
  },
  {
    tailwindClassColor: 'bg-tagsLightPink',
    colorValue: tailwindConfig.theme.colors.tagsLightPink,
  },
  {
    tailwindClassColor: 'bg-tagsYellow',
    colorValue: tailwindConfig.theme.colors.tagsYellow,
  },
  {
    tailwindClassColor: 'bg-tagsGreen',
    colorValue: tailwindConfig.theme.colors.tagsGreen,
  },
  {
    tailwindClassColor: 'bg-tagsBlue',
    colorValue: tailwindConfig.theme.colors.tagsBlue,
  },
  {
    tailwindClassColor: 'bg-tagdBlueGrey',
    colorValue: tailwindConfig.theme.colors.tagdBlueGrey,
  },
  {
    tailwindClassColor: 'bg-tagsPurple',
    colorValue: tailwindConfig.theme.colors.tagsPurple,
  },
  {
    tailwindClassColor: 'bg-tagsPink',
    colorValue: tailwindConfig.theme.colors.tagsPink,
  },
  {
    tailwindClassColor: 'bg-tagsOrange',
    colorValue: tailwindConfig.theme.colors.tagsOrange,
  },
];

function CreateTagForm({ partner, onCancel, onCreate, partnerTags, getPartnerTags, tagToEdit }) {
  const [tagName, setTagName] = useState(tagToEdit ? tagToEdit.name : '');
  const [error, setError] = useState('');
  const [selectedColor, setSelectedColor] = useState(tagToEdit ? tagToEdit.color : tagsSelector[0].colorValue);
  const { t } = useTranslation();

  const { post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/tags`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  useEffect(() => {
    setError('');
  }, [tagName]);

  const handleInputChange = (event) => {
    setTagName(event.target.value);
  };

  const isTagNameExists = (name) => {
    return partnerTags.some((tag) => tag.name.toLowerCase() === name.toLowerCase() && tag.id !== (tagToEdit?.id));
  };

  const handleSubmit = async () => {
    if (isTagNameExists(tagName)) {
      setError(t('TAG_NAME_EXISTS'));
      return;
    }

    const body = {
      name: tagName,
      color: selectedColor,
    };

    try {
      let result;
      if (tagToEdit) {
        result = await put(`/${tagToEdit.id}`, body);
      } else {
        result = await post(body);
      }

      if (response.ok && result?.id) {
        onCreate({ id: result.id, name: tagName, color: selectedColor });
        setTagName('');
        setError('');
        if (tagToEdit) {
          getPartnerTags();
        }
      }
    } catch (error) {
      handleResponse(response);
    }
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  return (
    <div className="w-full flex flex-col border border-solid border-greySix rounded-[4px] p-4 mt-5">
      <div className="flex items-center mb-4">
        <button
          type="button"
          onClick={onCancel}
          className="border-none bg-white p-0"
        >
          <img src={arrowLeft} alt="back" className="w-6 h-6 mr-3" />
        </button>
        <span className="font-medium text-greyOne font-moskauGrotesk">{tagToEdit ? t('EDIT_TAG_A') : t('CREATE_TAG')}</span>
      </div>
      <input
        id="tagName"
        type="text"
        placeholder="Nom de l'étiquette"
        className={`rounded-t-md rounded-b-none placeholder:text-greyFour text-greyOne font-moskauGrotesk px-5 py-2.5 font-normal text-base w-full border border-solid border-greySix ${error ? 'border-red bg-red/5 focus:border-red focus:border' : 'border-greySix'} focus:border-2 focus:outline-none focus:border-secondarySkyBlue`}
        value={tagName}
        onChange={handleInputChange}
      />
      <div className="flex flex-col p-[14px] border-solid border-x border-b border-y-0 border-greySix ">
        <span className="text-sm font-moskauGrotesk text-greyThree">{t('SELECT_TAG_COLOR')}</span>
        <div className="flex items-center gap-2.5 flew-wrap mt-2.5">
          {tagsSelector.map((tag) => (
            <button
              key={tag.colorValue}
              type="button"
              onClick={() => handleColorSelect(tag.colorValue)}
              className={`${tag.tailwindClassColor} w-10 h-5 rounded-sm border-none ${selectedColor === tag.colorValue ? ' ring-2 ring-secondarySkyBlue' : ''}`}
            />
          ))}
        </div>
      </div>
      <button
        type="button"
        disabled={tagName === ''}
        className="flex items-center bg-greyEight border border-x border-y-0 border-b border-solid border-greySix rounded-t-none rounded-b-md p-[14px]"
        onClick={handleSubmit}
      >
        <span
          className="border-none bg-greyEight text-base text-primaryNewBlue font-moskauGrotesk font-medium -mb-1"
        >
          {tagToEdit ? t('SAVE_TAG') : t('CREATE')}
        </span>
        {tagName && (
          <div className="ml-4 flex items-center rounded py-[6px] px-3 shadow-tagShadow" style={{ backgroundColor: selectedColor }}>
            <span className="text-sm font-moskauGrotesk font-medium text-greyOne -mb-[4px]">{tagName}</span>
          </div>
        )}
      </button>
      {error && <span className="text-sm text-redError font-moskauGrotesk mt-1">{error}</span>}
    </div>
  );
}

export default CreateTagForm;

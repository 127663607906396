import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';

import toggleSort from '../../../../utils/toggleSort';
import formatTargetSBT from '../../../../utils/formatTargetSbt';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function SbtTargetHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'sbt';

  useEffect(() => {
    if (searchParams.get('sbt')) {
      setSort(searchParams.get('sbt'));
    }
  }, []);

  return (
    <th className="font-12 px-3 text-center font-medium cursor-pointer border-solid border-greySix border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="flex justify-center">
        {t('SBT_TARGET_DETAILED')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end ml-2.5 self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function SbtTargetBody({ profile, cellStyle, onClick }) {
  const { t } = useTranslation();
  return (
    <td className="h-[60px] text-center border-solid border-greySix border" style={cellStyle}>
      <Link to={onClick(profile)} className="px-3 h-full w-full no-underline text-greyOne flex items-center justify-center">
        {t(formatTargetSBT(profile.target_sbt))}
      </Link>
    </td>
  );
}

export { SbtTargetHead, SbtTargetBody };

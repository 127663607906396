export const totalCarbonFootprintValue = 95;
export const formattedTotalCarbonFootprintValue = '95 kt';
export const carbonFootprintYear = 2024;
export const isFlagData = false;
export const isControledPmeWithoutCarbonFootprint = false;
export const isPmeWithCommitment = false;
export const isPlannedPublicationDateValid = false;
export const plannedPublicationDate = 'janvier 1970';
export const commitmentDate = '1 janvier 1970';
export const carbonFootprint = [
  5,
  90,
];
export const currentCharData = [
  {
    scope: 12,
    value: 4,
    color: '#21AFFF',
    id: "Opérations directes de l'entreprise",
  },
  {
    scope: 3,
    value: 62,
    color: '#031356',
    id: 'Matières premières',
  },
  {
    scope: 3,
    value: 10,
    color: '#112787',
    id: 'Transport amont',
  },
  {
    scope: 3,
    value: 7,
    color: '#5675C3',
    id: 'Transport aval',
  },
  {
    scope: 3,
    value: 20,
    color: '#B3B6DA',
    id: 'Autres',
  },
];
export const chartDataFiltered = [
  {
    scope: 12,
    value: 4,
    color: '#21AFFF',
    id: "Opérations directes de l'entreprise",
  },
  {
    scope: 3,
    value: 62,
    color: '#031356',
    id: 'Matières premières',
  },
  {
    scope: 3,
    value: 10,
    color: '#112787',
    id: 'Transport amont',
  },
  {
    scope: 3,
    value: 7,
    color: '#5675C3',
    id: 'Transport aval',
  },
  {
    scope: 3,
    value: 20,
    color: '#B3B6DA',
    id: 'Autres',
  },
];
export const carbonFootprintDistribution = [
  {
    name: 'Matières premières',
    scope: 3,
    percent: 61.8,
  },
  {
    name: 'Transport amont',
    scope: 3,
    percent: 9.3,
  },
  {
    name: 'Transport aval',
    scope: 3,
    percent: 6.7,
  },
  {
    name: 'Achat emballages',
    scope: 3,
    percent: 5.9,
  },
  {
    name: 'Production sous-traitée',
    scope: 3,
    percent: 5.4,
  },
  {
    name: 'Achat autres biens et services',
    scope: 3,
    percent: 3.9,
  },
  {
    name: 'Scope 1 & 2 (usines)',
    scope: 12,
    percent: 3.2,
  },
  {
    name: 'Achat biens équipement',
    scope: 3,
    percent: 2.2,
  },
];
export const chartData = [
  {
    scope: 3,
    value: 61.8,
    color: '#031356',
    id: 'Matières premières',
  },
  {
    scope: 3,
    value: 9.3,
    color: '#112787',
    id: 'Transport amont',
  },
  {
    scope: 3,
    value: 6.7,
    color: '#5675C3',
    id: 'Transport aval',
  },
  {
    scope: 3,
    value: 5.9,
    color: '#B3B6DA',
    id: 'Achat emballages',
  },
  {
    scope: 3,
    value: 5.4,
    color: '#B3B6DA',
    id: 'Production sous-traitée',
  },
  {
    scope: 3,
    value: 3.9,
    color: '#B3B6DA',
    id: 'Achat autres biens et services',
  },
  {
    scope: 12,
    value: 3.2,
    color: '#21AFFF',
    id: 'Scope 1 & 2 (usines)',
  },
  {
    scope: 3,
    value: 2.2,
    color: '#B3B6DA',
    id: 'Achat biens équipement',
  },
  {
    color: '#F4F4F4',
    id: 'Non communiqué',
    scope: 3,
    value: '1.6',
  },
];

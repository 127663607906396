import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import useFetch from 'use-http';

import spin from '../../../../assets/ui/spin.svg';

import '../Form.scss';

function Mail() {
  const { partner, mailRequested, setMailRequested } = useOutletContext();
  const { t } = useTranslation();
  const { post, loading } = useFetch(process.env.REACT_APP_PARTNER_API);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      await post('/login', {
        domain: partner.domain,
        email: data.email,
      });

      setMailRequested(data.email);
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div>
      {mailRequested ? (
        <>
          <h3 className="login-title mb-3 mt-9">{t('PARTNER_LOGIN_TEXT_7')}</h3>
          <p>{t('PARTNER_LOGIN_TEXT_8')} <b>{ mailRequested }</b> {t('PARTNER_LOGIN_TEXT_9')}</p>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id="partner-login-form">
          <p className="mt-9 text-base text-greyTwo mb-9">
            {t('LOGIN_FORM.TEXT_D')}
          </p>
          <label htmlFor="inputEmail" className="mb-2.5 font-medium">{t('PARTNER_LOGIN_TEXT_2')}</label>
          <input
            type="email"
            {...register('email', {
              required: t('FORM_CONTACT_COMPANY_EMAIL_MISSING'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('FORM_CONTACT_COMPANY_EMAIL_BAD_FORMAT'),
              },
            })}
            name="email"
            id="inputEmail"
            className={`flex w-full border-greySix border border-solid rounded-md py-3 px-5 text-greyFour ${
              errors.email
                ? 'focus:outline-redError'
                : 'focus:outline-secondarySkyBlue'
            }`}
            autoComplete="email"
            placeholder={t('LOGIN_FORM.PASSWORD.PLACEHOLDER_EMAIL')}
          />
          {errors.email && <div className="mt-1 text-sm text-redError">{errors.email.message}</div>}
          <button type="submit" className="w-full mt-[30px] min-h-[48px] py-3 bg-primaryNewBlue border-0 rounded text-white flex items-center justify-center" disabled={loading}>
            {loading ? <img src={spin} alt="spin" /> : t('PARTNER_LOGIN_TEXT_3')}
          </button>
        </form>
      )}
    </div>
  );
}

export default Mail;

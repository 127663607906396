import React from 'react';
import { Link } from 'react-router-dom';
import getLocalLink from '../../../../utils/getLocalLink';
import CompanyLogo from './CompanyLogo';
import CompanyInfo from './CompanyInfo';

function CompanyCard({ company, isDashboard, searchParams, climateAction, status, t }) {
  return (
    <Link
      to={`${getLocalLink(`${window.origin}${isDashboard ? '/dashboard/suppliers' : '/profile'}/${company.slug}`)}${searchParams.get('cf') ? `${searchParams.get('partner') ? '&' : '?'}cf=${searchParams.get('cf')}` : ''}`}
      className="flex flex-col no-underline pt-3 px-0 hover:bg-backgroundCleanBlue"
      onClick={() => window.scrollTo(0, 0)}
    >
      <div className="flex">
        <CompanyLogo company={company} />
        <CompanyInfo
          company={company}
          climateAction={climateAction}
          status={status}
          t={t}
        />
      </div>
      <div className="border border-solid border-b border-t-0 border-x-0 border-greySix mt-4" />
    </Link>
  );
}

export default CompanyCard;

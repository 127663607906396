import React, { useEffect, useState } from 'react';
import { useLoaderData, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function Login() {
  const [mailRequested, setMailRequested] = useState(null);
  const { partner, features } = useLoaderData();
  let { state } = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (mailRequested !== null) {
      state = null;
    }
  }, [mailRequested]);

  return (
    <>
      <Header partner={partner} showHeader={false} />
      <div id="main-content" className="flex flex-col justify-center align-middle min-h-screen font-moskauGrotesk">
        {state?.message && !mailRequested && <div className="mt-5 relative py-4 px-3 text-red bg-red/20 text-center">{t(state.message)}</div>}
        <div className="rounded-lg bg-white p-14 h-auto self-center max-w-[660px] sm:my-24">
          <div className="flex justify-center">
            {partner.logo_header ? (
              <img
                src={`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_header}`}
                id="partner-logo-header"
                alt={partner.name}
                className="my-0 py-0 h-[82px] w-auto object-contain"
                height="82"
                width="auto"
              />
            ) : (
              partner.name
            )}
          </div>
          <Outlet context={{ partner, mailRequested, setMailRequested }} />
          <div className="border-t border-b-0 border-x-0 border-solid border-greySeven mt-[50px] mb-[30px]" />
          <p className="text-center text-greyTwo mb-0">
            {t('LOGIN_FORM.TEXT_B')} <a href="mailto:support@openclimat.com" className="font-normal text-greyFour hover:text-greyThree underline">support@openclimat.com</a>
          </p>
        </div>
      </div>
      <Footer partner={partner} features={features} />
    </>
  );
}

export default Login;

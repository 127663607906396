import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import tailwindConfig from '../../../../tailwind.config';

import ResourceManager from './ResourceManager';
import IconSelector from './IconSelector';
import ProfileSelector from './ProfileSelector';
import SubCategorySelector from './SubCategorySelector';

import crossModal from '../../../../assets/ui/cross-modal.svg';
import exclamationMark from '../../../../assets/ui/exclamation-mark.svg';
import trashRed from '../../../../assets/ui/trash-red.svg';
import handleResponse from '../../../../utils/handleResponse';
import ToastMessage from '../ToastMessage';
import categoryIcons from '../../../../utils/categoryIcons';

import 'react-toastify/dist/ReactToastify.css';

function CategoryForm({ action, showForm, setShowForm, level, getCategories, setDeleteInfo, toggleModal, setDeleteModalFromCategory, deleteModalRef, backgroundDeleteModalRef }) {
  const { partner, session } = useOutletContext();
  const [resources, setResources] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [profileError, setProfileError] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');

  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [subCategoriesError, setSubCategoriesError] = useState('');

  const modalRef = useRef(null);
  const profileSearchRef = useRef(null);
  const subCategoriesRef = useRef(null);

  const isLevelPositionZero = level?.position === 0;
  const isPrivatePartner = partner.private === 1;

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  //--------------------------------------------------------
  // BLOC ENVOI RECEPTION DE DONNEES
  const { get, post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  // récupération des entreprises du partenaire
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const profiles = await get(`${partner.id}/profiles`);
        if (response.ok) {
          setProfiles(profiles);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        handleResponse(response);
      }
    };
    fetchProfiles();
  }, []);

  // récupération des entreprises sélectionnées
  useEffect(() => {
    if (level && level.category && level.category.profiles) {
      const selectedIds = level.category.profiles.map((profile) => profile.id);
      setSelectedProfiles(selectedIds);
    }
  }, []);

  // récupération des catégories
  useEffect(() => {
    setAllSubCategories(level.subCategories);
    if (action === 'edit' && level) {
      const { category } = level;
      reset({
        name: category.name,
        description: category.description,
        icon: category.icon,
      });
      setSelectedProfiles(category.profiles ? category.profiles.map((profile) => profile.id) : []);
      setResources(category.resources || []);
      const iconDetail = category.icon && categoryIcons.find((icon) => icon.value === category.icon);
      setSelectedIcon(iconDetail);
      setSelectedSubCategories(level.category.sub_categories || []);
    }
  }, []);

  // Submit Data
  const onSubmit = async (data) => {
    setProfileError('');
    setSubCategoriesError('');

    if (isLevelPositionZero) {
      if (selectedProfiles.length === 0) {
        setProfileError(t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.PROFILE_ERROR'));
        profileSearchRef.current.focus();
        return;
      }
    }

    if (!isLevelPositionZero) {
      if (selectedSubCategories.length === 0) {
        setSubCategoriesError(t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.SUBCATEGORIES_ERROR'));
        subCategoriesRef.current.focus();
        return;
      }
    }

    let subCategoriesData = null;
    if (!isLevelPositionZero) {
      subCategoriesData = selectedSubCategories.map((subCategory) => (
        subCategory.id
      ));
    }

    const finalData = {
      ...data,
      resources,
      profiles: selectedProfiles,
      icon: selectedIcon ? selectedIcon.value : '',
      level_position: 0,
      user_id: session.id,
      level_id: level ? level.levelId : null,
      sub_categories: subCategoriesData,
      parent_category: null,
    };

    try {
      if (action === 'new') {
        const result = await post(`${partner.id}/custom-categories`, finalData);

        if (result === 'Conflict') {
          setError('name', {
            type: 'manual',
            message: t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.CREATE.CONFLICT'),
          });
          toast(<ToastMessage text={t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.CREATE.CONFLICT')} warning />, {
            style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
          });
        }

        if (result === 'OK') {
          toast(<ToastMessage text={<>{t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.CREATE.PART_A')} <span className=" font-moskauGrotesk font-semibold">{data.name}</span> {t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.CREATE.PART_B')}</>} />, {
            style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
          });
          getCategories();
          setShowForm(false);
        }
      } else if (action === 'edit') {
        const result = await put(`${partner.id}/custom-categories/${level.category.id}`, finalData);

        if (result === 'Conflict') {
          setError('name', {
            type: 'manual',
            message: t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.UPDATE.CONFLICT'),
          });
          toast(<ToastMessage text={t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.UPDATE.CONFLICT')} warning />, {
            style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
          });
        }

        if (result === 'OK') {
          toast(<ToastMessage text={<>{t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.UPDATE.PART_A')} <span className=" font-moskauGrotesk font-semibold">{data.name}</span> {t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.UPDATE.PART_B')}</>} />, {
            style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
          });

          getCategories();
          setShowForm(false);
        }
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  //--------------------------------------------------------
  // BLOC SUPPRESSION CATEGORIE
  const handleDelete = async () => {
    setDeleteInfo({ id: level.category.id, name: level.category.name, number: level.category.profiles ? level.category.profiles.length : 0 });
    toggleModal(false);
    setDeleteModalFromCategory(true);
  };

  //--------------------------------------------------------
  // FERMER LE FORMULAIRE
  const closeModal = () => {
    setShowForm(false);
  };

  const handleClickOutside = (event) => {
    if ((modalRef.current && !modalRef.current.contains(event.target)) && (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) && (backgroundDeleteModalRef.current && !backgroundDeleteModalRef.current.contains(event.target))) {
      if (showForm) {
        setShowForm(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {showForm && (
        <div
          className="fixed top-0 left-0 w-full h-full backdrop-blur-sm bg-black/30 z-40"
        />
      )}
      <div
        id="category-modal"
        className="h-full w-full left-0 fixed flex z-40 top-0 overflow-y-auto"
      >
        <div className=" hidden lg:block w-1/2" />
        <div ref={modalRef} className=" w-full lg:w-1/2 bg-white relative">
          <div className="h-20 flex justify-between items-center py-7 px-6 border-solid border-0 border-b border-greyBorderLight sticky top-0 bg-white z-40">
            <div>
              <div className="text-xl font-moskauGrotesk font-semibold">
                {action === 'new'
                  ? t('PARTNER_DASHBOARD.CATEGORIES.FORM.TITLE_ADD')
                  : t('PARTNER_DASHBOARD.CATEGORIES.FORM.TITLE_EDIT')}
              </div>
              <div className="text-sm text-greyDark font-moskauGrotesk font-normal">
                {isLevelPositionZero ? t('PARTNER_DASHBOARD.CATEGORIES.FORM.LEVEL_NAME_PLACEHOLDER_NULL') : `${t('PARTNER_DASHBOARD.CATEGORIES.FORM.LEVEL_NAME_PLACEHOLDER')} ${level?.position}`}
              </div>
            </div>
            <button
              type="button"
              className="border-0 bg-white z-50"
              onClick={closeModal}
            >
              <img src={crossModal} alt="Cross" />
            </button>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" flex flex-col pb-12  bg-white"
          >
            <div className=" flex flex-col  px-10 py-11">
              <h2 className=" text-base font-moskauGrotesk font-semibold mb-11">{t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_A')}</h2>
              <label htmlFor="name" className=" text-sm font-moskauGrotesk font-normal">{t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.NAME')}*</label>
              <input
                id="name"
                {...register(
                  'name',
                  { required: t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.NAME_REQUIRED') },
                )}
                placeholder="Fromages"
                className={` h-11 text-sm font-moskauGroteskNormal text-greyHeavy placeholder:text-greyDark border border-solid ${errors.name ? 'border-red bg-red/5 focus:border-red focus:border' : 'border-greyLight'} border-greyLight focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-md p-2 `}
              />
              {errors.name && (
                <span className=" text-sm font-moskauGrotesk font-normal text-red mt-1">
                  <img src={exclamationMark} alt="exclamation mark" className=" mr-1 -mt-[3px]" />
                  {errors.name.message}
                </span>
              )}

              <label htmlFor="description" className=" text-sm font-moskauGrotesk font-normal mt-9">{t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.DESCRIPTION')}</label>
              <textarea
                id="description"
                {...register('description', {
                  maxLength: 600,
                })}
                placeholder={t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.DESCRIPTION_PLACEHOLDER')}
                className=" h-24 text-sm font-moskauGrotesk font-normal text-greyHeavy border border-solid border-greyLight focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-md p-2"
              />
              {errors.description && errors.description.type === 'maxLength' && (
                <span className=" text-sm font-moskauGrotesk font-normal text-red mt-1">
                  <img src={exclamationMark} alt="exclamation mark" className=" mr-1 -mt-[3px]" />
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.DESCRIPTION_MAX_LENGTH')}
                </span>
              )}

              {!isPrivatePartner &&
              <IconSelector selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />}

              <ResourceManager resources={resources} setResources={setResources} />
              {isLevelPositionZero ? (
                <ProfileSelector
                  profiles={profiles}
                  selectedProfiles={selectedProfiles}
                  setSelectedProfiles={setSelectedProfiles}
                  profileError={profileError}
                  setProfileError={setProfileError}
                  profileSearchRef={profileSearchRef}
                />
              ) : (
                <SubCategorySelector
                  subCategories={allSubCategories}
                  selectedSubCategories={selectedSubCategories}
                  setSelectedSubCategories={setSelectedSubCategories}
                  subCategoriesError={subCategoriesError}
                  setSubCategoriesError={setSubCategoriesError}
                  subCategoriesRef={subCategoriesRef}
                  subCategoriesLevelName={level.subCategoriesLevelName || `Niveau ${level.position - 1}`}
                  editedCategoryId={level.category.id}
                />
              )}

              {action === 'edit' ? (
                <div className=" mt-11 mb-8">
                  <h2 className="text-base font-moskauGrotesk font-semibold mb-4">{t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_D')}</h2>
                  <button type="button" className="flex items-center gap-x-2 px-0 bg-white border-0" onClick={() => handleDelete()}>
                    <img src={trashRed} alt="trash icon" />
                    <span
                      className="text-base font-moskauGrotesk font-normal text-red"
                      style={{ outline: 'none' }}
                    >
                      {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.DELETE_CATEGORY')}
                    </span>
                  </button>

                </div>
              ) : ('')}
            </div>
            <div className=" w-full lg:w-1/2 h-20 px-6 border-solid border-0 border-t border-greyBorderLight flex justify-end fixed  bottom-0 bg-white py-4">
              <button
                type="button"
                className="py-3 px-12 text-primaryNewBlue bg-white border-solid border border-primaryNewBlue rounded-[100px]"
                onClick={closeModal}
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.CANCEL')}
              </button>
              <button
                type="submit"
                className="py-3 px-12 text-white bg-primaryNewBlue border-0 rounded-[100px] ml-6"
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBMIT')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CategoryForm;

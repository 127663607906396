import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import closeIcon from '../../../../../assets/ui/cross-modal.svg';

import useModal from '../../../../../hooks/useModal';

export default function DeleteConfirmationModal({ modalId, handleDeleteFunction, title, message }) {
  const { openModal, close } = useModal();
  const isModalOpen = openModal === modalId;
  const modalRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        const modalContent = document.getElementById(`modal-content-${modalId}`);
        if (modalContent) {
          modalContent.style.opacity = '1';
          modalContent.style.transform = 'translateY(0)';
          modalContent.style.transition = 'transform 0.5s ease-out, opacity 0.5s ease-out';
        }
      }, 50);
    } else {
      const modalContent = document.getElementById(`modal-content-${modalId}`);
      if (modalContent) {
        modalContent.style.opacity = '0';
        modalContent.style.transform = 'translateY(-20px)';
        modalContent.style.transition = 'none';
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        close();
      }
    };

    if (isModalOpen) {
      document.body.classList.add('overflow-hidden');
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  return (
    <div
      id={modalId}
      tabIndex="-1"
      aria-hidden="true"
      className={`flex ${isModalOpen ? 'visible' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-screen bg-greyOne/50`}
    >
      <div
        id={`modal-content-${modalId}`}
        ref={modalRef}
        className={`relative p-4 w-full max-w-md h-auto transition-all transform ${isModalOpen ? '' : ''} duration-1000 ease-in-out`}
      >
        <div className="relative bg-white rounded-lg shadow-lg p-7">
          <div className="flex justify-between mb-4">
            <h2 className="text-xl text-greyOne font-normal">{title}</h2>
            <button type="button" className="flex flex-col justify-start" onClick={() => close()}>
              <img src={closeIcon} width={14} height={14} alt="close icon" className="ml-8 mt-1" />
            </button>
          </div>
          {message
          && <p className="text-sm text-greyFour mt-4">{message}</p>}
          <div className="flex items-center gap-x-3 mt-9">
            <button type="button" className="py-2.5 px-6 bg-white hover:bg-backgroundCleanBlue text-primaryNewBlue font-medium border border-primaryNewBlue rounded-full" onClick={() => close()}>
              {t('CONFIRM_MODAL.DECLINE')}
            </button>
            <button
              type="button"
              className="py-2.5 px-6 bg-primaryNewBlue hover:bg-primaryNewBlueHover text-white font-medium border border-primaryNewBlue rounded-full"
              onClick={() => {
                handleDeleteFunction();
                close();
              }}
            >
              {t('CONFIRM_MODAL.CONFIRM')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function SuppliersList({ profilesList }) {
  const { t } = useTranslation();

  const profilesSortedList = useMemo(() => {
    return profilesList ? profilesList.sort((a, b) => a.name.localeCompare(b.name)) : [];
  }, [profilesList]);

  return (
    <div className="flex-1 bg-greyEight py-3 px-5 flex flex-col max-h-[272px]">
      <div className="text-xs text-greyFour font-moskauGrotesk font-medium">
        {t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.SUPPLIERS_LIST')}
      </div>
      <div id="suppliers-list" className="flex flex-col gap-y-2 overflow-y-auto mt-2 h-[calc(100%-35px)]">
        {profilesSortedList && profilesSortedList.map((item) => (
          <div key={item.id} className="text-xs text-primaryNewBlue">{item?.name}</div>
        ))}
      </div>
    </div>
  );
}

export default SuppliersList;

import React from 'react';
import headphones from '../../../assets/temp/campaign-suppliers/headphones.svg';

function HeadphonesButton() {
  return (
    <button type="button" className="min-w-12 min-h-12 flex items-center justify-center bg-primaryCorail rounded-full shadow-md">
      <img src={headphones} alt="headphones" />
    </button>
  );
}

export default HeadphonesButton;

import React from 'react';

import './Landing.scss';

import Header from './Header/Header';
import Share from './Share/Share';
import About from './About/About';
import Footer from './Footer/Footer';
import Form from './Form/Form';

function Landing() {
  return (
    <div className="font-moskauGrotesk">
      <Header />
      <Share />
      <About />
      <Form />
      <Footer />
    </div>
  );
}

export default Landing;

/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';

import pen from '../../../../../assets/ui/pen.svg';

import Content from './Content';
import useModal from '../../../../../hooks/useModal';

function TableOfContents({ hasWriteAccess, progressionBar, numberOfQuestions, numberOfAnsweredQuestions, summary, summaryTransverse, isPublished, showOptional }) {
  const { t } = useTranslation();

  const { open } = useModal();

  const distributionEnum = {
    '': '--',
    0: '<1',
    2: '<5',
  };

  for (let value = 5; value <= 100; value += 5) {
    distributionEnum[value] = value;
  }

  return (
    <div className="top-6 sticky rounded border border-solid border-greySix px-7 py-6 mb-6 lg:mb-0">
      <h3 className="text-base pb-3 border-b border-greySix">
        {hasWriteAccess ? (
          <div className="flex justify-between">
            <span>
              {t('TABLE_OF_CONTENTS_TITLE')}
            </span>
            <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
              <button
                type="button"
                onClick={() => {
                  open('question-actions-distributions-modal');
                }}
              >
                <img src={pen} alt="Pen" />
              </button>
            </div>
          </div>
        ) : (
          t('TABLE_OF_CONTENTS.TITLE')
        )}
      </h3>
      <div className="mt-4">
        {hasWriteAccess && (
          <div className="flex justify-between">
            <div className="h-2 w-3/4 relative self-center rounded">
              <div className="w-full absolute rounded h-2 bg-backgroundCleanBlue" />
              <div className="bg-primaryNewBlue h-2 rounded absolute" style={{ width: `${progressionBar && progressionBar}%` }} />
            </div>
            <div className="fw-400 text-xs">
              {numberOfAnsweredQuestions} / {numberOfQuestions} {t('TABLE_OF_CONTENTS_TEXT_1')}
            </div>
          </div>
        )}
        <div className="flex mt-4">
          <div className="rounded-2xl bg-primaryNewBlue/[0.05] px-2 pt-0.5 text-[13px] font-medium text-primaryNewBlue">
            {t('TABLE_OF_CONTENTS_TEXT_7')}
          </div>
        </div>
        <h4 className="text-sm font-bold mt-4 mb-3">
          {t('TABLE_OF_CONTENTS_TEXT_4')}
        </h4>
        <div className="flex flex-col">
          {summary && summary.map((category, index) => (
            <Content
              key={index}
              category={category}
              hasWriteAccess={hasWriteAccess}
              isPublished={isPublished}
              isMainQuestions
            />
          ))}
        </div>
      </div>
      {summaryTransverse && summaryTransverse.length > 0 && summaryTransverse && showOptional === true ? (
        <div className="mt-3">
          <h4 className="text-sm font-bold mt-4 mb-3 flex justify-between items-center">
            <span>{t('TABLE_OF_CONTENTS_TEXT_8')}</span>
            {hasWriteAccess && <span className="text-xs font-normal">{t('TABLE_OF_CONTENTS_TEXT_6')}</span>}
          </h4>
          <div className="flex flex-col">
            {summaryTransverse && summaryTransverse.map((category, index) => (
              <Content
                key={index}
                category={category}
                hasWriteAccess={hasWriteAccess}
                isPublished={isPublished}
              />
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default TableOfContents;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import BarChartLegend from '../BarChartLegend';

import chartImage from '../../../../assets/ui/tmp-emissions-evolution-graph.svg';

function EmissionsEvolution({
  selectedCategoryId,
  selectedStatus,
  setSelectedStatus,
  selectedTagId,
}) {
  const [legend, setLegend] = useState();

  const { t } = useTranslation();
  const { partner } = useOutletContext();

  const getStats = async () => {
    setLegend({
      [t('PARTNER_DASHBOARD.STATISTICS.EMISSIONS_EVOLUTION.LEGEND.HIGH')]: '#f31400',
      [t('PARTNER_DASHBOARD.STATISTICS.EMISSIONS_EVOLUTION.LEGEND.LIGHT_LOW')]: '#ffd000',
      [t('PARTNER_DASHBOARD.STATISTICS.EMISSIONS_EVOLUTION.LEGEND.LOW')]: '#37b003',
    });
  };

  useEffect(() => {
    getStats();
  }, [selectedCategoryId, selectedTagId]);

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  return (
    <div className="w-full flex flex-col items-start bg-white border border-solid border-greySix rounded-md py-[15px] px-5">
      <div className="text-base font-inter font-bold text-greyOne">
        {t('PARTNER_DASHBOARD.STATISTICS.EMISSIONS_EVOLUTION.TITLE', { partner: partner?.name })}
      </div>
      <div className="w-full flex items-center gap-x-5 mt-1">
        <div className="w-[159px] text-base text-greyThree">
          {t('PARTNER_DASHBOARD.STATISTICS.EMISSIONS_EVOLUTION.UNIT')}
        </div>
      </div>

      <div className="flex lg:gap-10 flex-col items-center justify-center lg:flex-row">
        <div className="w-full max-w-[500px] flex max-[903px]:flex-col max-[903px]:items-center items-stretch gap-x-5 max-[903px]:gap-y-5 mt-[14px]">
          <div className="w-full h-full">
            <img
              src={chartImage}
              alt="Illustrative Chart"
              className="w-full h-full min-w-[200px]"
            />
            {/* <MainChart
              emissionsStatusBySupplier={emissionsStatusBySupplier}
              profileCountsBySbtiStatus={profileCountsBySbtiStatus}
              colorsObjects={colorsObjects}
              onStatusSelect={handleStatusSelect}
              selectedCategoryId={selectedCategoryId}
              selectedStatus={selectedStatus}
              isOpen={isOpen}
            /> */}
          </div>
        </div>
        <div className="pb-5 hidden lg:flex">
          <div className="w-0.5 h-[258px] border-r border-greySix " />
        </div>
        <div className="min-w-[300px] lg:mt-[-50px]">
          <BarChartLegend
            legend={legend}
            selectedStatus={selectedStatus}
            onStatusSelect={handleStatusSelect}
            isVertical
            isDisable
          />
          <div className="flex items-center mt-[14px]">
            <svg width="15" height="1" viewBox="0 0 15 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="15" y2="0.5" stroke="#FF5530" strokeDasharray="2 2" />
            </svg>
            <span className="text-xs text-greyOne font-moskauGrotesk ml-1">
              {t('PARTNER_DASHBOARD.STATISTICS.EMISSIONS_EVOLUTION.LEGEND.OBJECTIVE')}
            </span>
          </div>
          <div className="flex items-center mt-[14px]">
            <svg width="15" height="1" viewBox="0 0 15 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="15" y2="0.5" stroke="#FF5530" />
            </svg>
            <span className="text-xs text-greyOne font-moskauGrotesk ml-1">
              {t('PARTNER_DASHBOARD.STATISTICS.EMISSIONS_EVOLUTION.LEGEND.TRAJECTORY')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmissionsEvolution;

import React from 'react';
import { useTranslation } from 'react-i18next';

import './InternalError.scss';

import getLocalLink from '../../utils/getLocalLink';

function InternalError() {
  const { t } = useTranslation();
  return (
    <section className="flex w-full justify-center items-center text-center h-screen" id="error_page">
      <div>
        <h1>{t('INTERNAL_ERROR')}</h1>
        <p>
          {t('CHECK_URL')} <a href={getLocalLink('/')}>{t('GO_TO_HOMEPAGE')}</a>.
        </p>
      </div>
    </section>
  );
}

export default InternalError;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import pen from '../../../../../assets/ui/pen.svg';
import trashBin from '../../../../../assets/ui/trash-bin-m.svg';

import QuestionTypeCarbonDistribution from '../Template/QuestionTypeCarbonDistribution/QuestionTypeCarbonDistribution';
import QuestiontTypeQuantitative from '../Template/QuestionTypeQuantitative/QuestiontTypeQuantitative';
import QuestionTypeQualitative from '../Template/QuestionTypeQualitative/QuestionTypeQualitative';
import useModal from '../../../../../hooks/useModal';

function Question({ question, hasWriteAccess, setChecked, fetchData, setData, setItemToDelete }) {
  const { t } = useTranslation();

  const { open } = useModal();

  useEffect(() => {
    if (question.question.length > 0 && question.question[0].answer) {
      if (question.question[0].answer.is_partner_published) {
        setChecked(question.question[0].answer.is_partner_published);
      }
    }
  }, [fetchData]);

  return (
    <div className="relative">
      <div className="border border-solid border-greySix p-3.5 bg-backgroundCleanBlue mt-7 rounded-sm text-greyOne font-medium text-sm flex justify-between gap-6">
        <span className="self-center">
          {question.question[0].info} {!question.question[0].is_required && hasWriteAccess ? <span className="text-greyFour text-sm">(optionnel)</span> : ''}
        </span>
        {hasWriteAccess ? (
          <div className="flex gap-5">
            <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
              <button
                type="button"
                onClick={() => {
                  setData(question.question[0]);
                  if (question.question[0].type === 1 || question.question[0].type === null) {
                    open('question-qualitative-modal');
                  } else if (question.question[0].type === 2) {
                    open('question-quantitative-modal');
                  } else if (question.question[0].type === 3) {
                    open('question-carbon-distribution-modal');
                  }
                }}
              >
                <img src={pen} alt="Pen" />
              </button>
            </div>
            {question.question[0].answer?.answer ? (
              <div className="min-h-[26px] min-w-[26px] h-[26px] w-[26px]">
                <button
                  type="button"
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setItemToDelete(question.question[0].answer?.id);
                    open('delete-confirmation-modal-questions');
                  }}
                >
                  <img src={trashBin} alt="Trash bin" />
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="mt-6 text-sm text-greyTwo">
        {question.question[0].type === 1 || question.question[0].type === null ? (
          <QuestionTypeQualitative
            isPublished={question.question[0].answer && question.question[0].answer.is_partner_published}
            hasWriteAccess={hasWriteAccess}
            answer={question.question[0].answer}
            noAnswerText={t('PRIVATE_ANSWERS.QUESTIONS.NO_ANSWER')}
          />
        ) : null}
        {question.question[0].type === 2 ? (
          <QuestiontTypeQuantitative
            isPublished={question.question[0].answer && question.question[0].answer.is_partner_published}
            hasWriteAccess={hasWriteAccess}
            answer={question.question[0].answer}
            noAnswerText={t('PRIVATE_ANSWERS.QUESTIONS.NO_ANSWER')}
            question={question}
          />
        ) : null}
        {question.question[0].type === 3 ? (
          <QuestionTypeCarbonDistribution
            isPublished={question.question[0].answer && question.question[0].answer.is_partner_published}
            hasWriteAccess={hasWriteAccess}
            answer={question.question[0].answer}
            noAnswerText={t('PRIVATE_ANSWERS.QUESTIONS.NO_ANSWER')}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Question;

import React from 'react';
import { Link } from 'react-router-dom';
import getLocalLink from '../../../../utils/getLocalLink';

function SeeAllLink({ isDashboard, selectedCategory, searchParams, t }) {
  return (
    <div className="pt-4">
      <Link
        to={`${getLocalLink(window.origin + (isDashboard ? '/dashboard/suppliers' : ''))}${
          selectedCategory
            ? `${searchParams.get('partner') ? '&' : '?'}cf=${selectedCategory.id}`
            : ''
        }`}
        className="text-sm font-medium font-moskauGrotesk text-primaryNewBlue"
        loading="lazy"
      >
        {t('SEE_ALL_COMPANIES_BY_SECTOR')}
      </Link>
    </div>
  );
}

export default SeeAllLink;

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function PartnerProfileCarbonWeightAndTurnover({ profile, partner, featuresCarbonWeight, featuresTurnover }) {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [titleCard, setTitleCard] = useState('');
  const contentRef = useRef(null);

  useEffect(() => {
    if (featuresCarbonWeight && featuresTurnover) {
      setTitleCard(t('P_CWT.TITLE_FULL'));
    } else if (featuresCarbonWeight && !featuresTurnover) {
      setTitleCard(t('P_CWT.TITLE_WEIGHT'));
    } else if (featuresTurnover && !featuresCarbonWeight) {
      setTitleCard(t('P_CWT.TITLE_TURNOVER'));
    }
  }, [featuresCarbonWeight, featuresTurnover]);

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  return (
    <div className="p-5 white-bg mb-4 border border-solid border-greySix rounded relative">
      <h1 className="text-greyOne text-base mb-3.5">
        {titleCard}
      </h1>
      <div className="flex flex-col">
        {featuresCarbonWeight && (
          <div className={`flex flex-col mb-3 ${featuresTurnover ? 'border-b border-t-0 border-r-0 border-l-0 border-greyMediumLight border-solid' : ''}`}>
            <div className={`flex w-full justify-between items-center text-sm text-greyOne font-medium mt-[5px] ${featuresTurnover ? ' mb-[14px]' : ''} `}>
              <span className="text-greyOne text-sm">{t('P_CWT.CARBON_WEIGHT_WITH', { partner: partner?.name })}</span>
              <span className="font-semibold">
                {profile.carbon_weight_total ? (
                  `${(profile.carbon_weight_total / 1000).toFixed(2)}kt`
                ) : (
                  t('P_CWT.UNKNOWN')
                )}
              </span>
            </div>
          </div>
        )}
        {featuresTurnover && (
          <div className="flex flex-col">
            <div className="flex w-full justify-between items-center text-sm text-greyOne font-medium mt-[5px]">
              <span className="text-greyOne text-sm">{t('P_CWT.TURNOVER_WITH', { partner: partner?.name })}</span>
              <span className="font-semibold">
                {profile.turnover_amount_total ? (
                  `${(profile.turnover_amount_total / 1000000).toFixed(2)}M€`
                ) : (
                  t('P_CWT.UNKNOWN')
                )}
              </span>
            </div>
          </div>
        )}
        {profile?.categories && profile?.categories.length > 0 && (
          <button type="button" className="flex border-0 bg-white text-sm font-poppins my-[14px] ps-0 text-greyFour underline" onClick={toggleShowDetail}>
            {showDetail ? t('P_CWT.HIDE_DETAILS', { name: profile?.partner_category_level0 }) : t('P_CWT.SHOW_DETAILS', { name: profile?.partner_category_level0 })}
          </button>
        )}
        <div ref={contentRef} style={{ maxHeight: (showDetail) ? contentRef.current.scrollHeight : '0px', overflow: 'hidden' }} className="transition-all duration-300 overflow-hidden">
          <div className="grid grid-cols-4 bg-greyEight text-greyFour border-b border-greySix border-solid border-b-1 border-t-0 border-l-0 border-r-0 text-xs w-full">
            <span className={`py-2 ${featuresCarbonWeight && featuresTurnover ? 'col-span-2' : 'col-span-3'} col-span-2   pl-[10px]`}>{profile?.partner_category_level0}</span>
            {featuresCarbonWeight && (<span className="py-2 col-span-1">CO2e</span>)}
            {featuresTurnover && (<span className="py-2 col-span-1 pr-[10px] text-right">CA</span>)}
          </div>
          {profile?.categories_unfiltered && profile?.categories_unfiltered.map((item) => (
            <div key={item.id} className="grid grid-cols-4 py-[5px] items-center">
              <div className={`${featuresCarbonWeight && featuresTurnover ? 'col-span-2' : 'col-span-3'} group pr-2 pl-[10px] text-left text-greyOne`}>
                {item.name.length > 20 && showDetail ? (
                  <div className="w-auto absolute left-14 p-2 mb-1 text-xs text-white font-moskauGrotesk font-normal bg-greyTwo rounded-sm shadow-lg group-hover:opacity-100 opacity-0 transition-opacity duration-100 z-20">
                    {item.name}
                  </div>
                ) : null}
                <div className="text-xs text-ellipsis overflow-hidden line-clamp-2 pr-2">
                  {item.name}
                </div>
              </div>
              {featuresCarbonWeight && (
                <span className="col-span-1 text-left font-medium text-greyOne text-xs z-10">
                  {item.carbon_weight ? (
                    `${(item.carbon_weight / 1000).toFixed(2)}kt`
                  ) : (
                    '-'
                  )}
                </span>
              )}
              {featuresTurnover && (
                <span className="col-span-1 pr-[10px] font-medium text-greyOne text-xs z-10 text-right">
                  {item.turnover_amount ? (
                    `${(item.turnover_amount / 1000000).toFixed(2)}M€`
                  ) : (
                    '-'
                  )}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PartnerProfileCarbonWeightAndTurnover;

import React from 'react';
import { useTranslation } from 'react-i18next';

import threeDots from '../../../../../assets/ui/three-dots.svg';

function RenderTagOptions({ tag, handleToggleMenu, openMenuTagId, handleEditTag, handleDeleteTag }) {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => handleToggleMenu(tag.id)}
        className="w-[26px] h-[26px] rounded-full bg-greyEight border-none flex items-center justify-center ml-3 cursor-pointer"
      >
        <img src={threeDots} alt="edit tag" />
      </button>
      {openMenuTagId === tag.id && (
      <>
        <div className="absolute left-12 top-0 flex flex-col p-[3px] rounded shadow-tooltipShadow bg-white">
          <button
            type="button"
            className="text-greyThree text-sm font-medium font-moskauGrotesk bg-white hover:bg-greyEight border-none text-left px-[7px] py-[2px] rounded-sm transition-transform duration-150 z-10"
            onClick={() => handleEditTag(tag)}
          >
            {t('EDIT_TAG_B')}
          </button>
          <button
            type="button"
            className="text-greyThree text-sm font-medium font-moskauGrotesk mt-2 bg-white hover:bg-greyEight border-none text-left px-[7px] py-[2px] rounded-sm transition-transform duration-150"
            onClick={() => handleDeleteTag(tag)}
          >
            {t('DELETE_TAG')}
          </button>
        </div>
        <div className="absolute left-[42px] top-2 w-3 h-3 rotate-45 bg-white shadow-tooltipShadow" />
      </>

      )}
    </div>
  );
}

export default RenderTagOptions;

import React, { useEffect } from 'react';

import blueCheckmark from '../../../../../../assets/ui/blue-checkmark.svg';
import emptyCheckmark from '../../../../../../assets/ui/empty-checkmark.svg';

function Content({ category, hasWriteAccess, isPublished }) {
  let allAnswered = true;
  let show = false;
  category.individualQuestions.forEach((question) => {
    if (!question.answer) {
      allAnswered = false;
    }
    if (question.answer || question.isRequired || hasWriteAccess) {
      show = true;
    }
  });

  useEffect(() => {
    const targets = document.getElementsByClassName('category-test');
    if (targets.length === 0) return undefined;

    const observer = new IntersectionObserver((entries) => {
      let closestId = null;
      let smallestDistance = Infinity;

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const distance = Math.abs(entry.boundingClientRect.top - window.innerHeight / 2);
          if (distance < smallestDistance) {
            smallestDistance = distance;
            closestId = entry.target.id;
          }
        }
      });

      if (closestId !== null) {
        const test = document.getElementById(`table-contents-${closestId}`);
        // Reset background color for all targets
        Array.from(targets).forEach((target) => {
          const potentialTest = document.getElementById(`table-contents-${target.id}`);
          if (potentialTest) potentialTest.style.backgroundColor = ''; // Reset background color
        });
        // Highlight the closest element
        if (test) {
          test.style.backgroundColor = '#FAFAFB';
        }
      }
    }, { threshold: 0.1 });

    Array.from(targets).forEach((target) => observer.observe(target));

    return () => observer.disconnect();
  }, []);

  return (
    show && (
      hasWriteAccess ? (
        <a id={`table-contents-${category.id}`} href={`#${category.id}`} className={`flex hover:bg-greyEight rounded-sm justify-between no-underline ${allAnswered ? 'font-medium text-greyOne' : 'font-normal text-greyFour'}`}>
          <div className="p-2 text-sm font-medium">
            {category.name}
          </div>
          {allAnswered ? (
            <div className="flex content-center min-h-5 min-w-5 mr-2">
              <img className="flex self-center" src={blueCheckmark} alt="blue checkmark" />
            </div>
          ) : (
            <div className="flex content-center min-h-5 min-w-5 mr-2">
              <img className="flex self-center" src={emptyCheckmark} alt="empty checkmark" />
            </div>
          )}
        </a>
      ) : (
        <a id={`table-contents-${category.id}`} href={`#${category.id}`} className={`p-2 hover:bg-greyEight rounded-sm text-sm no-underline ${!allAnswered || !isPublished ? 'font-normal text-greyFour' : 'font-medium text-greyOne'}`}>
          {category.name}
        </a>
      )
    )
  );
}

export default Content;

import React from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import useViewport from '../../../../../hooks/useViewport';

import Features from '../../../../../features.json';
import Header from '../../Header/Header';
import ClimateActions from '../../ClimateActions/ClimateActions';
import Sources from '../../Sources/Sources';
import Audit from '../../Audit/Audit';
import LabelsClimat from '../../LabelsClimat/LabelsClimat';
import CompetingCompanies from '../../CompetingCompanies/CompetingCompanies';
import LabelsRse from '../../LabelsRse/LabelsRse';
import CarbonFootprintDistribution from '../../CarbonFootprintDistribution/CarbonFootprintDistribution';
import PartnerProfileCarbonWeightAndTurnover from '../../PartnerProfileCarbonWeightAndTurnover/PartnerProfileCarbonWeightAndTurnover';
import Status from '../../Status/Status';
import PartnerProfileRating from '../../PartnerProfileRating/PartnerProfileRating';
import ClimateCommitments from '../../ClimateCommitments/ClimateCommitments';
import Notes from '../../Note/Notes';

import './Preview.scss';

function Preview({ view }) {
  const [searchParams] = useSearchParams();

  const { isMobile } = useViewport(984);

  const { profile, partner, features, hasWriteAccess } = useOutletContext();

  let selectedCategory;

  if (profile?.categories?.length === 1) {
    selectedCategory = profile?.categories[0];
  }

  if (searchParams.get('cf')) {
    selectedCategory = profile?.categories.find((category) => category.id === searchParams.get('cf'));
  }

  const climateActions = features.includes(Features.climateActions) && <ClimateActions profile={profile} />;
  const sources = features.includes(Features.sources) && profile?.type !== 2 && <Sources profile={profile} />;
  const carbonWeightTurnover = (features.includes(Features.profilePartnerTurnover) || features.includes(Features.profilePartnerCarbonWeight)) && <PartnerProfileCarbonWeightAndTurnover profile={profile} partner={partner} featuresTurnover={features.includes(Features.profilePartnerTurnover)} featuresCarbonWeight={features.includes(Features.profilePartnerCarbonWeight)} />;
  const audit = features.includes(Features.audit) && <Audit profile={profile} />;
  const labelsClimat = features.includes(Features.labelsClimat) && <LabelsClimat profile={profile} />;
  const labelsRse = features.includes(Features.labelsRse) && <LabelsRse profile={profile} />;
  const competingCompaniesClimateAction = features.includes(Features.competingCompanies) && <CompetingCompanies profile={profile} partnerId={partner.id} climateAction selectedCategory={selectedCategory} />;
  const competingCompaniesStatus = features.includes(Features.competingCompaniesStatus) && <CompetingCompanies profile={profile} partnerId={partner.id} status selectedCategory={selectedCategory} />;
  const carbonFootprintDistribution = features.includes(Features.carbonFootprintDistribution) && <CarbonFootprintDistribution profile={profile} />;
  const profileStatus = features.includes(Features.profileStatus) && <Status profile={profile} partner={partner} hasWriteAccess={hasWriteAccess} />;
  const partnerProfileRating = features.includes(Features.partnerProfileRating) && <PartnerProfileRating profile={profile} partner={partner} hasWriteAccess={hasWriteAccess} />;
  const climateCommitments = features.includes(Features.climateObjectives) && <ClimateCommitments profile={profile} />;

  return (
    <div id="profile-page">
      <Header view={view} />
      <div className="container pb-6 px-3 relative mx-auto">
        {!isMobile ? (
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-8 col-start-1">
              {profileStatus}
              {carbonFootprintDistribution}
              {climateCommitments}
              {climateActions}
            </div>
            <div className="col-span-4 col-start-9">
              {partnerProfileRating}
              {sources}
              {carbonWeightTurnover}
              {audit}
              {labelsClimat}
              {labelsRse}
              {competingCompaniesClimateAction}
              {competingCompaniesStatus}
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center flex-wrap">
            {profileStatus}
            {partnerProfileRating}
            {carbonFootprintDistribution}
            {climateCommitments}
            {climateActions}
            {sources}
            {carbonWeightTurnover}
            {audit}
            {labelsClimat}
            {labelsRse}
            {competingCompaniesClimateAction}
            {competingCompaniesStatus}
          </div>
        )}
        <Notes partnerId={partner.id} profileId={profile.id} />
      </div>
    </div>
  );
}

export default Preview;

import React from 'react';
import { useTranslation } from 'react-i18next';
import MethodPrecisionScale from './MethodPrecisionScale';

import check from '../../../../../assets/ui/check-greyOne.svg';

function AllocationMethod() {
  const { t } = useTranslation();

  const validatedData = [
    {
      id: 1,
      name: 'Méthode GHG Protocol',
    },
    {
      id: 2,
      name: 'Vérification externe : Intensité physique auditée',
    },
  ];

  return (
    <div className="flex flex-col gap-y-[14px] bg-white text-greyOne p-6 mb-4 min-[984px]:mt-6 border border-solid border-greySix rounded">
      <h2 className="font-moskauGrotesk text-base">
        {t('ALLOCATION_METHOD.TITLE')}
      </h2>
      <span className="text-sm font-poppins">{t('ALLOCATION_METHOD.PHYSICAL_INTENSITY')}</span>
      <span className="text-sm font-moskauGrotesk font-semibold">{t('ALLOCATION_METHOD.ACCURACY_METHOD')}</span>
      <MethodPrecisionScale validated={validatedData.length} />
      {validatedData.map((data) => (
        <div key={data.id} className="flex items-center gap-x-2">
          <img src={check} alt="check" />
          <span className="text-sm font-poppins font-semibold">{data.name}</span>
        </div>
      ))}
    </div>
  );
}

export default AllocationMethod;

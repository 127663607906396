import React from 'react';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';

import caretLeftPrimaryBlue from '../../../../../assets/ui/caret-left-primary-blue.svg';

function NoteDetails({ setSelectedNote, selectedNote }) {
  const { t } = useTranslation();

  return (
    <div className="p-6 border border-solid border-greySeven rounded">
      <button
        type="button"
        className="text-greyFour text-sm font-moskauGrotesk flex gap-3 items-center bg-white border-0 px-0 hover:text-greyThree"
        onClick={() => {
          setSelectedNote();
        }}
      >
        <img src={caretLeftPrimaryBlue} alt="Caret left" />
        <span className="text-primaryNewBlue pt-0.5">
          {selectedNote?.title}
        </span>
      </button>
      <div className="mt-[22px] text-greyFour text-sm">
        {t('NOTES.PUBLISHED_ON')} {selectedNote ? new Date(selectedNote.created_at).toLocaleDateString() : null} {t('NOTES.BY')} {selectedNote?.firstname} {selectedNote?.lastname}
      </div>
      <div className="mt-[20px] text-greyTwo text-sm break-words">
        <Markdown>{selectedNote?.note}</Markdown>
      </div>
    </div>
  );
}

export default NoteDetails;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import NoAnswer from '../../../NoAnswer';

function QuestionTypeQualitative({ isPublished, hasWriteAccess, answer, noAnswerText }) {
  const { t } = useTranslation();

  return (
    <>
      {((isPublished && (answer && answer.answer)) || hasWriteAccess) ? (
        <span
          dangerouslySetInnerHTML={{ __html: sanitize(answer && answer.answer) }}
          className="custom-list"
        />
      ) : (
        <div className="mt-2">
          <NoAnswer text={t(noAnswerText)} />
        </div>
      )}
      {answer && (isPublished || hasWriteAccess) ? (
        answer.contentListItems && answer.contentListItems[0] && (
          <div className="text-sm mt-6 text-greyFour hover:text-greyThree underline">
            <a href={answer.contentListItems[0].url} target="_blank" rel="noreferrer">{answer.contentListItems[0].name}</a>
          </div>
        )
      ) : ('')}
    </>
  );
}

export default QuestionTypeQualitative;

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import upload from '../../../assets/ui/upload.svg';
import chevronDown from '../../../assets/ui/chevron-down-grey.svg';

function AddResourceForm({ partnerCategories, register, errors, watch, setValue, editingResource }) {
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDragActive, setIsDragActive] = useState(false);

  const dropdownRef = useRef(null);

  const { t } = useTranslation();

  const file = watch('resource');
  const category = watch('category');

  const sortedCategories = [...partnerCategories].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (editingResource) {
      setValue('category', editingResource.partner_category_id);
    }
  }, [editingResource]);

  useEffect(() => {
    if (category) {
      const selectedCat = sortedCategories.find((cat) => cat.id === category);
      setSelectedCategory(selectedCat || null);
    } else {
      setSelectedCategory(null);
    }
  }, [category, sortedCategories]);

  const handleCategoryChange = (categoryItem) => {
    setValue('category', categoryItem.id, { shouldValidate: true });
    setShowCategoryDropdown(false);
  };

  const getDisplayFileName = () => {
    if (file && file[0]) {
      return file[0].name;
    }
    if (editingResource) {
      return editingResource.file_name;
    }
    return t('PARTNER_DASHBOARD.RESOURCES.FORM.UPLOAD_RESOURCE');
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setValue('resource', droppedFiles);
    }
  };

  const getDropzoneClasses = () => {
    const baseClasses = 'flex flex-col items-center justify-center gap-y-[6px] w-full border border-dashed rounded-lg cursor-pointer hover:bg-greyEight transition-all duration-150 p-4';

    if (errors.resource) {
      return `${baseClasses} border-redError bg-red/5`;
    }
    if (isDragActive) {
      return `${baseClasses} border-secondarySkyBlue bg-secondarySkyBlue/10`;
    }
    return `${baseClasses} border-greySix`;
  };

  return (
    <div>
      <label htmlFor="resource" className="text-sm text-greyOne font-medium">
        {t('PARTNER_DASHBOARD.RESOURCES.FORM.RESOURCES')}
      </label>
      <div
        className="mt-2.5"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <label
          htmlFor="resource"
          className={getDropzoneClasses()}
        >
          <div className="flex flex-col items-center justify-center gap-y-[6px] p-">
            <img src={upload} alt="upload" />
            <p className="text-sm text-greyFour m-0 text-center">
              {getDisplayFileName()}
            </p>
            <p className="text-[10px] text-greyThree m-0">{t('PARTNER_DASHBOARD.RESOURCES.FORM.PDF_MAX_SIZE')}</p>
          </div>
          <input
            id="resource"
            type="file"
            className="hidden"
            accept=".pdf"
            {...register('resource', {
              required: editingResource ? false : t('PARTNER_DASHBOARD.RESOURCES.FORM.RESOURCE_REQUIRED'),
              validate: {
                lessThan20MB: (files) => !files[0] || files[0].size <= 20 * 1024 * 1024 || t('PARTNER_DASHBOARD.RESOURCES.FORM.FILE_TOO_LARGE'),
                isPDF: (files) => !files[0] || files[0].type === 'application/pdf' || t('PARTNER_DASHBOARD.RESOURCES.FORM.MUST_BE_PDF'),
              },
            })}
          />
        </label>
      </div>
      {errors.resource && (
        <p className="mt-1 text-sm text-redError" role="alert">{errors.resource.message}</p>
      )}

      <div className="mt-[30px]">
        <label htmlFor="category" className="block text-sm text-greyOne font-medium mb-2.5">
          {t('PARTNER_DASHBOARD.RESOURCES.FORM.CATEGORY')}
        </label>
        <div className="relative" ref={dropdownRef}>
          <button
            type="button"
            className={`h-11 w-full text-left text-sm font-moskauGrotesk font-normal ${selectedCategory ? 'text-greyOne' : 'text-greyFour'} border border-solid ${errors.category ? 'border-redError' : 'border-greySix'} bg-white focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-md py-3 px-4 flex items-center justify-between`}
            onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
          >
            <span>{selectedCategory ? selectedCategory.name : t('PARTNER_DASHBOARD.RESOURCES.FORM.CATEGORY')}</span>
            <img src={chevronDown} alt="Toggle dropdown" className="ml-4 h-5 w-5" />
          </button>
          {showCategoryDropdown && (
          <div className="absolute z-10 w-full mt-1 bg-white rounded-md max-h-96 overflow-auto border border-greySix border-solid py-3">
            {sortedCategories.map((categoryItem) => (
              <label key={categoryItem.id} className="flex items-center px-4 py-3 hover:bg-backgroundCleanBlue cursor-pointer text-sm text-greyOne">
                <input
                  type="radio"
                  value={categoryItem.id}
                  checked={category === categoryItem.id}
                  onChange={() => handleCategoryChange(categoryItem)}
                  className="hidden"
                  {...register('category', { required: t('PARTNER_DASHBOARD.RESOURCES.FORM.CATEGORY_REQUIRED') })}
                />
                <span className="relative inline-block w-[14px] h-[14px] mr-2 rounded-full border border-solid border-greyFour">
                  <span className={`absolute inset-[2px] rounded-full ${category === categoryItem.id ? 'bg-primaryNewBlue' : ''}`} />
                </span>
                <span className={`${category === categoryItem.id ? 'font-medium' : ''}`}>{categoryItem.name}</span>
              </label>
            ))}
          </div>
          )}
        </div>
        {errors.category && (
        <p className="mt-1 text-sm text-redError" role="alert">{errors.category.message}</p>
        )}
      </div>
    </div>
  );
}

export default AddResourceForm;

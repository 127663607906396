import React, { createContext, useState, useEffect, useContext } from 'react';
import i18n from 'i18next';

const LocaleContext = createContext();

export function LocaleProvider({ children }) {
  const [locale, setLocale] = useState(i18n.language);

  const changeLocale = (lng) => {
    i18n.changeLanguage(lng);
    setLocale(lng);
  };

  // Synchronisation initiale
  useEffect(() => {
    document.documentElement.lang = locale;
  }, []);

  // Écouter les changements externes de langue dans i18n
  useEffect(() => {
    const handleLanguageChanged = (lng) => {
      setLocale(lng);
      document.documentElement.lang = lng;
    };

    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, []);

  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      {children}
    </LocaleContext.Provider>
  );
}

export const useLocale = () => useContext(LocaleContext);

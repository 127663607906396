import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useOutletContext } from 'react-router-dom';
import handleResponse from '../../../utils/handleResponse';

import ResourceCard from './ResourceCard';
import Drawer from '../../Features/Database/Drawer/DrawerTemplate';
import AddResourceForm from './AddResourceForm';
import DeleteConfirmationModal from './DeleteConfirmationModal';

import chevronDown from '../../../assets/ui/chevron-down-grey.svg';
import verifyAuthorization from '../../../utils/verifyAuthorization';

function Ressources() {
  const [partnerCategories, setPartnerCategories] = useState([]);
  const [partnerResources, setPartnerResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [editingResource, setEditingResource] = useState(null);

  const dropdownRef = useRef(null);
  const { partner, session } = useOutletContext();
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, watch, setValue, reset } = useForm({
    resolver: (values) => {
      const errors = {};
      if (!values.category) {
        errors.category = { type: 'required', message: t('PARTNER_DASHBOARD.RESOURCES.FORM.CATEGORY_REQUIRED') };
      }
      if ((!values.resource || !values.resource[0]) && !editingResource) {
        errors.resource = { type: 'required', message: t('PARTNER_DASHBOARD.RESOURCES.FORM.RESOURCE_REQUIRED') };
      }
      return {
        values,
        errors,
      };
    },
  });

  // APPELS API
  const { get, put, post, del, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const getCategories = async () => {
    try {
      await get('/categories');

      if (response.ok) {
        // If there are categories
        if (response.status === 200) {
          const allCategories = response.data;
          if (Array.isArray(allCategories) && allCategories.length > 0) {
            const hasMoreThanOneLevel = allCategories.length > 1;

            if (hasMoreThanOneLevel) {
              setPartnerCategories(allCategories[1].level[0].categories);
            } else {
              setPartnerCategories(allCategories[0].level[0].categories);
            }
          }
        }

        // If there are no categories
        if (response.status === 204) {
          setPartnerCategories([]);
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const sortedCategories = useMemo(() => {
    return [...partnerCategories].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  }, [partnerCategories]);

  const getResources = async () => {
    try {
      await get('/resources');
      if (response.ok && Array.isArray(response.data)) {
        const sortedResources = response.data.sort((a, b) => a.file_name.localeCompare(b.file_name, undefined, { sensitivity: 'base' }));
        setPartnerResources(sortedResources);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    getResources();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setFilteredResources(partnerResources.filter((resource) => resource.partner_category_id === selectedCategory.id));
    } else {
      setFilteredResources(partnerResources);
    }
  }, [selectedCategory, partnerResources]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setShowCategoryDropdown(false);
  };

  const onSubmit = async (formData) => {
    try {
      const form = new FormData();
      form.append('category_id', formData.category);

      if (editingResource) {
        // Mode édition
        form.append('attachment_id', editingResource.attachment_id);

        if (formData.resource && formData.resource[0]) {
          form.append('file', formData.resource[0]);
        }
        await put(`/resource/${editingResource.id}`, form);
      } else {
        // Mode ajout
        form.append('file', formData.resource[0]);
        await post('/resource', form);
      }

      if (response.ok) {
        reset();
        setDrawerVisibility(false);
        setEditingResource(null);
        getResources();
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const openDeleteModal = (resourceId, attachmentId) => {
    setResourceToDelete({ resourceId, attachmentId });
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setResourceToDelete(null);
  };

  const confirmDelete = async () => {
    if (resourceToDelete) {
      try {
        await del(`/resource/${resourceToDelete.resourceId}/${resourceToDelete.attachmentId}`);

        if (response.ok) {
          setPartnerResources((prevResources) => prevResources.filter((resource) => resource.id !== resourceToDelete.resourceId));
          closeDeleteModal();
        }
      } catch (error) {
        handleResponse(response);
        throw new Error(error);
      }
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setDrawerVisibility(true);
    setOpenMenuId(null);
  };

  const handleEdit = (resource) => {
    setEditingResource(resource);
    setValue('category', resource.partner_category_id);
    setDrawerVisibility(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    setEditingResource(null);
    reset();
  };

  return (
    <>
      <div className="w-full min-h-[calc(100vh-60px)] p-7 xl:p-12 bg-backgroundCleanBlue">
        <div className="w-full flex justify-between items-center">
          <div className="flex flex-col">
            <h1 className="text-2xl text-primaryNewBlue font-semibold mb-[14px]">{t('PARTNER_DASHBOARD.SIDEBAR.RESSOURCES')}</h1>
            <span className="text-sm text-greyTwo mb-6">{t('PARTNER_DASHBOARD.RESOURCES.PAGE_DESCRIPTION')}</span>
            <div className="relative" ref={dropdownRef}>
              <button
                type="button"
                className="min-w-[370px] h-11 text-left text-sm font-moskauGrotesk font-normal border border-solid border-greySix bg-white focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-md py-3 px-4 flex items-center justify-between"
                onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
              >
                <span className={`${selectedCategory ? 'text-greyOne' : 'text-greyThree'}`}>{selectedCategory ? selectedCategory.name : t('PARTNER_DASHBOARD.RESOURCES.CATEGORY')}</span>
                <img src={chevronDown} alt="Toggle dropdown" className="ml-4 h-5 w-5" />
              </button>
              {showCategoryDropdown && (
                <div className="min-w-[370px] absolute z-10 mt-1 bg-white rounded-md max-h-96 overflow-auto border border-greySix border-solid py-3">
                  <label className="flex items-center px-4 py-3 hover:bg-backgroundCleanBlue cursor-pointer text-sm text-greyOne">
                    <input
                      type="radio"
                      checked={!selectedCategory}
                      onChange={() => handleCategoryChange(null)}
                      className="hidden"
                    />
                    <span className={`${!selectedCategory ? 'font-medium' : ''}`}>{t('PARTNER_DASHBOARD.RESOURCES.ALL_CATEGORIES')}</span>
                  </label>
                  {sortedCategories.map((category) => (
                    <label key={category.id} className="flex items-center px-4 py-3 hover:bg-backgroundCleanBlue cursor-pointer text-sm text-greyOne">
                      <input
                        type="radio"
                        checked={selectedCategory && selectedCategory.id === category.id}
                        onChange={() => handleCategoryChange(category)}
                        className="hidden"
                      />
                      <span className={`${selectedCategory && selectedCategory.id === category.id ? 'font-medium' : ''}`}>{category.name}</span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
          {verifyAuthorization(session, ['ROLE_ADMIN']) && (
          <div className="flex items-center gap-4">
            <button
              type="button"
              className="flex items-center justify-center bg-primaryNewBlue text-white text-sm rounded-full border-none py-3 px-7"
              onClick={(e) => handleClick(e)}
            >
              {t('PARTNER_DASHBOARD.RESOURCES.ADD_RESOURCE')}
            </button>
          </div>
          )}
        </div>
        <div className="w-full flex flex-wrap gap-[14px] mt-[30px]">
          {filteredResources.map((resource) => (
            <ResourceCard
              key={resource.id}
              resource={resource}
              onDelete={() => openDeleteModal(resource.id, resource.attachment_id)}
              onEdit={() => handleEdit(resource)}
              openMenuId={openMenuId}
              setOpenMenuId={setOpenMenuId}
            />
          ))}
        </div>
      </div>
      <Drawer
        title={editingResource ? t('PARTNER_DASHBOARD.RESOURCES.FORM.EDIT_TITLE') : t('PARTNER_DASHBOARD.RESOURCES.FORM.ADD_TITLE')}
        drawerVisibility={drawerVisibility}
        setDrawerVisibility={handleCloseDrawer}
        className={drawerVisibility ? '' : 'hidden'}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      >
        <AddResourceForm
          partnerCategories={partnerCategories}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          editingResource={editingResource}
        />
      </Drawer>
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        title={t('PARTNER_DASHBOARD.RESOURCES.DELETE_MODAL_TITLE')}
        message={t('PARTNER_DASHBOARD.RESOURCES.DELETE_MODAL_MESSAGE')}
      />
    </>

  );
}

export default Ressources;

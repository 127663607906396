import React from 'react';
import { useOutletContext } from 'react-router-dom';

import Features from '../../../features.json';

import verifyAuthorization from '../../../utils/verifyAuthorization';
import Database from '../../Features/Database/Database';

import './Suppliers.scss';

function Suppliers() {
  const { partner, isDashboard, hasWriteAccess, features, session } = useOutletContext();
  const isDemoScreen = features.includes(Features.mockedDemoScreens) && verifyAuthorization(session, ['ROLE_ADMIN']);

  return (
    <div className="w-full pt-[20px] px-[20px] py-2 2xl:px-12 2xl:py-10 flex flex-col max-w-full max-h-full overflow-hidden font-moskauGrotesk">
      <Database hasWriteAccess={hasWriteAccess} partner={partner} isDashboard={isDashboard} isDemoScreen={isDemoScreen} />
    </div>
  );
}

export default Suppliers;

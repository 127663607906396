import abinbev from '../../../assets/temp/campaign-suppliers/abinbev.png';
import bacardi from '../../../assets/temp/campaign-suppliers/bacardi.png';
import britvic from '../../../assets/temp/campaign-suppliers/britvic.png';
import coca from '../../../assets/temp/campaign-suppliers/coca.png';
import danone from '../../../assets/temp/campaign-suppliers/danone.png';
import diageo from '../../../assets/temp/campaign-suppliers/diageo.png';
import ebro from '../../../assets/temp/campaign-suppliers/ebro-foods.png';
import ecotone from '../../../assets/temp/campaign-suppliers/ecotone.png';
import elivia from '../../../assets/temp/campaign-suppliers/elivia.png';
import epidis from '../../../assets/temp/campaign-suppliers/epidis.png';

const suppliersList = [
  {
    id: 1,
    name: 'AB InBev',
    connexion: 1,
    statut: 1,
    last_relaunch_date: null,
    actions: 1,
    image: abinbev,
  },
  {
    id: 2,
    name: 'Bacardi',
    connexion: 1,
    statut: 1,
    last_relaunch_date: null,
    actions: 1,
    image: bacardi,
  },
  {
    id: 3,
    name: 'Britvic',
    connexion: 1,
    statut: 2,
    last_relaunch_date: '15/04/2024',
    actions: null,
    image: britvic,
  },
  {
    id: 4,
    name: 'Coca-Cola Europe',
    connexion: 1,
    statut: 2,
    last_relaunch_date: '15/04/2024',
    actions: null,
    image: coca,
  },
  {
    id: 5,
    name: 'Danone',
    connexion: 2,
    statut: 3,
    last_relaunch_date: '15/04/2024',
    actions: null,
    image: danone,
  },
  {
    id: 6,
    name: 'Diageo',
    connexion: 2,
    statut: 3,
    last_relaunch_date: '15/04/2024',
    actions: null,
    image: diageo,
  },
  {
    id: 7,
    name: 'Ebro foods',
    connexion: 2,
    statut: 3,
    last_relaunch_date: '15/04/2024',
    actions: null,
    image: ebro,
  },
  {
    id: 8,
    name: 'Ecotone',
    connexion: 2,
    statut: 3,
    last_relaunch_date: '15/04/2024',
    actions: null,
    image: ecotone,
  },
  {
    id: 9,
    name: 'Elivia',
    connexion: 2,
    statut: 3,
    last_relaunch_date: '15/04/2024',
    actions: null,
    image: elivia,
  },
  {
    id: 10,
    name: 'Epidis',
    connexion: 2,
    statut: 3,
    last_relaunch_date: '15/04/2024',
    actions: null,
    image: epidis,
  },
];

export default suppliersList;

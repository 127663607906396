import React from 'react';
import Markdown from 'markdown-to-jsx';

function StatusTemplate({ title, description, color, backgroundColor, additionalClass }) {
  return (
    <div className={`rounded-lg ${additionalClass || ''}`} style={{ padding: '16px', backgroundColor, color }}>
      <h4 className="font-semibold text-sm mb-2">
        {title}
      </h4>
      <p className="font-normal text-sm mb-0 text-greyOne">
        <Markdown>{description}</Markdown>
      </p>
    </div>
  );
}

export default StatusTemplate;

/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';

import blueCheckmark from '../../../../../assets/ui/blue-checkmark.svg';
import emptyCheckmark from '../../../../../assets/ui/empty-checkmark.svg';
import informationIcon from '../../../../../assets/ui/i-rounded-full.svg';

function TableOfContents({ hasWriteAccess, numberOfQuestions, numberOfAnsweredQuestions, progressionBar, partner, summary, isPublished }) {
  const { t } = useTranslation();

  return (
    <div className="top-6 sticky mb-6 lg:mb-0">
      <div className="rounded border border-solid border-greySix px-7 py-6">
        <h3 className="text-base pb-3 border-b border-greySix">
          {t('PRIVATE_ANSWERS.TABLE_OF_CONTENTS.TITLE')}
        </h3>
        <div className="mt-4">
          {hasWriteAccess && (
          <div className="flex justify-between pb-4">
            <div className="h-2 w-3/4 relative self-center rounded">
              <div className="w-full absolute rounded h-2 bg-backgroundCleanBlue" />
              <div className="bg-primaryNewBlue h-2 rounded absolute" style={{ width: `${progressionBar && progressionBar}%` }} />
            </div>
            <div className="fw-400 text-xs">
              {numberOfAnsweredQuestions} / {numberOfQuestions} {t('TABLE_OF_CONTENTS_TEXT_1')}
            </div>
          </div>
          )}
          {summary && summary.map((question, index) => (
            hasWriteAccess ? (
              <a href={`#${question.question}`} key={index} className={`flex justify-between no-underline ${question.answer ? 'summary-question' : 'summary-question-empty'}`}>
                <div className="p-2 text-sm">
                  {question.question}
                </div>
                {question.answer ? (
                  <div className="flex items-center min-h-5 min-w-5 mr-2">
                    <img className="flex self-center" src={blueCheckmark} alt="blue checkmark" />
                  </div>
                ) : (
                  <div className="flex items-center min-h-5 min-w-5 mr-2">
                    <img className="flex self-center" src={emptyCheckmark} alt="empty checkmark" />
                  </div>
                )}
              </a>
            ) : (
              question.isRequired ? (
                <a href={`#${question.question}`} key={index} className={`p-2 text-sm ${!question.answer || !isPublished ? 'summary-question-empty' : 'summary-question'} no-underline block`}>
                  {question.question}
                </a>
              ) : (
                (question.answer || hasWriteAccess)
                    && (
                    <a href={`#${question.question}`} key={index} className={`p-2 text-sm ${!question.answer || !isPublished ? 'summary-question-empty' : 'summary-question'} no-underline block`}>
                      {question.question}
                    </a>
                    )
              )
            )
          ))}
        </div>
      </div>
      {partner ? (
        <div className="flex flex-col gap-y-4 rounded border border-solid border-greySix mt-6 p-6 font-moskauGrotesk text-greyOne mb-6">
          <div className="flex items-center gap-x-3 mb-[2px]">
            <img src={informationIcon} width={20} height={20} alt="informations" />
            <span className="font-semibold text-greyOne mt-1">{t('PRIVATE_ANSWERS.DISCLAIMER.TITLE')}</span>
          </div>
          <span className="text-sm font-normal">{t('PRIVATE_ANSWERS.DISCLAIMER.TEXT', { name: partner?.name })}</span>
        </div>
      ) : (
        ''
      )}

    </div>
  );
}

export default TableOfContents;

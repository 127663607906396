import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import resolveConfig from 'tailwindcss/resolveConfig';
import { useUserLocale } from '../../../../utils/getUserLocal';
import questionMarkCircled from '../../../../assets/ui/question-mark-circled.svg';

import tailwindConfig from '../../../../tailwind.config';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';
import NoCimateActions from './NoClimateActions';
import Details from './Details';
import DetailsMobile from './DetailsMobile';
import getCompleteColorByScore from '../../../../utils/getCompleteColorByScore';
import AdvancementCircle from '../MaturityScale/AdvancementCircle';
import useClimateActions from '../../../../hooks/useClimateActions';

function ClimateActions({ profile }) {
  const {
    openClimatComment,
    scope12,
    scope3,
    flag,
    scope12Color,
    scope3Color,
    flagColor,
    note,
    flagPerimeter,
    levelInfo,
    reductionColPosition,
    speedColPosition,
    showClimateAlignment,
  } = useClimateActions(profile);

  const fullConfig = resolveConfig(tailwindConfig);

  const locale = useUserLocale();

  const { t } = useTranslation();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${fullConfig.theme.screens.lg})`,
  });

  return (
    <div className="px-6 lg:px-7 py-6 mb-4 rounded bg-white border border-solid border-greySix">
      <h3 className="text-greyOne text-base">
        {t('CLIMATE_ACTIONS.DECARBONISATION_RESULTS')}
      </h3>
      <div className="mt-4 p-4 font-medium text-sm text-primaryNewBlue border border-solid border-greySix bg-backgroundCleanBlue rounded-sm flex items-center gap-2">
        <AdvancementCircle level={levelInfo?.level} bgColor={fullConfig.theme.colors.backgroundCleanBlue} />
        {t(openClimatComment)}
      </div>
      {isDesktop ? (
        <>
          <div className="mt-5 grid grid-cols-[4fr_1fr_3fr_1fr_3fr_1fr_3fr] text-greyFour text-xs border-b border-solid border-x-0 border-t-0 border-greySix py-3">
            <div className={`${reductionColPosition} text-center flex self-center mx-auto`}>
              {t('CLIMATE_ACTIONS.ACTUAL_REDUCTION')}
            </div>
            <div className={`${speedColPosition} text-center flex self-center mx-auto`}>
              {t('CLIMATE_ACTIONS.REDUCTION_SPEED')}
            </div>
            {showClimateAlignment ? (
              <div className="col-span-1 col-start-7 text-center flex self-center mx-auto">
                {t('CLIMATE_ACTIONS.CLIMATE_ALIGNEMENT')}
              </div>
            ) : null}
          </div>
          <div className="py-3 grid grid-cols-[4fr_1fr_3fr_1fr_3fr_1fr_3fr] border-b border-solid border-x-0 border-t-0 border-greySix">
            <div className="text-primaryNewBlue col-span-1 flex items-center">
              <div className="flex">
                {scope12Color && showClimateAlignment && (
                  <div key={scope12Color} className={`w-4 h-4 min-h-4 min-w-4 self-center mr-2.5 bg-${getCompleteColorByScore(scope12?.degreeScore)} rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope12Color, 0.1)}`, backgroundClip: 'padding-box' }} />
                )}
                <div className="self-center font-moskauGrotesk font-semibold text-sm mt-0.5">
                  {t('CLIMATE_ACTIONS.RESULTS')} Scope 1&2 {flag && t('CLIMATE_ACTIONS.NOT_FLAG')}
                </div>
              </div>
            </div>
            {(scope12?.dataUsable || scope12?.dataNotComparable) ? (
              <NoCimateActions dataUsable={scope12?.dataUsable} dataNotComparable={scope12?.dataNotComparable} />
            ) : (
              <Details
                scope={scope12}
                scopeColor={scope12Color}
                getColorByScore={getCompleteColorByScore}
                getHexColorWithOpacity={getHexColorWithOpacity}
                reductionColPosition={reductionColPosition}
                speedColPosition={speedColPosition}
                showClimateAlignment={showClimateAlignment}
              />
            )}
          </div>
          <div className="py-3 grid grid-cols-[4fr_1fr_3fr_1fr_3fr_1fr_3fr] border-b border-solid border-x-0 border-t-0 border-greySix">
            <div className="text-primaryNewBlue col-span-1 flex items-center">
              <div className="flex">
                {scope3Color && showClimateAlignment && (
                  <div key={scope3Color} className={`w-4 h-4 min-h-4 min-w-4 mr-2.5 ${note ? 'self-start' : 'self-center'} bg-${getCompleteColorByScore(scope3?.degreeScore)} rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope3Color, 0.1)}`, backgroundClip: 'padding-box' }} />
                )}
                <div className={`self-center font-moskauGrotesk font-semibold text-sm align-center ${note ? '' : 'mt-0.5'}`}>
                  <div>
                    {t('CLIMATE_ACTIONS.RESULTS')} Scope 3 {flag && t('CLIMATE_ACTIONS.NOT_FLAG')}
                  </div>
                  {note && (
                    <div className="text-[10px] text-greyFour font-normal inline-block relative mr-1">
                      {note}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(scope3?.dataUsable || scope3?.dataNotComparable) ? (
              <NoCimateActions dataUsable={scope3?.dataUsable} dataNotComparable={scope3?.dataNotComparable} />
            ) : (
              <Details
                scope={scope3}
                scopeColor={scope3Color}
                getColorByScore={getCompleteColorByScore}
                getHexColorWithOpacity={getHexColorWithOpacity}
                reductionColPosition={reductionColPosition}
                speedColPosition={speedColPosition}
                showClimateAlignment={showClimateAlignment}
              />
            )}
          </div>
          {flag && (
            <div className="py-3 grid grid-cols-[4fr_1fr_3fr_1fr_3fr_1fr_3fr] border-b border-solid border-x-0 border-t-0 border-greySix">
              <div className="text-primaryNewBlue col-span-1 flex items-center">
                <div className="flex">
                  {flagColor && showClimateAlignment && (
                  <div key={flagColor} className={`w-4 h-4 min-h-4 min-w-4 mr-2.5 ${flagPerimeter ? 'self-start' : 'self-center'} bg-${getCompleteColorByScore(flag?.degreeScore)} rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(flagColor, 0.1)}`, backgroundClip: 'padding-box' }} />
                  )}
                  <div className={`self-center font-moskauGrotesk font-semibold text-sm align-center ${flagPerimeter ? '' : 'mt-0.5'} relative`}>
                    <div>
                      <span className="mr-1">
                        {t('CLIMATE_ACTIONS.RESULTS')} FLAG
                      </span>
                      <div className="inline-block align-middle group">
                        <img src={questionMarkCircled} alt="Question Mark" />
                        <div className="absolute top-full invisible group-hover:visible max-w-44 min-w-44 z-10 py-2 px-3 text-xs text-white font-normal font-moskauGrotesk bg-greyTwo rounded-sm shadow-lg ml-6 -my-[49px]">
                          <div className="absolute -left-[4px] top-[10px] w-3 h-3 bg-greyTwo rotate-45 rounded-sm" />
                          {t('CLIMATE_ACTIONS.FLAG_DEFINITION')}
                        </div>
                      </div>
                    </div>
                    {flagPerimeter && (
                    <div className="text-[10px] text-greyFour font-normal inline-block relative">
                      <span>
                        {flagPerimeter}
                      </span>
                    </div>
                    )}
                  </div>
                </div>
              </div>
              {(flag?.dataUsable || flag?.dataNotComparable) ? (
                <NoCimateActions dataUsable={flag?.dataUsable} dataNotComparable={flag?.dataNotComparable} />
              ) : (
                <Details
                  flag
                  scope={flag}
                  scopeColor={flagColor}
                  getColorByScore={getCompleteColorByScore}
                  getHexColorWithOpacity={getHexColorWithOpacity}
                  reductionColPosition={reductionColPosition}
                  speedColPosition={speedColPosition}
                  showClimateAlignment={showClimateAlignment}
                />
              )}
            </div>
          )}
          <div className="mt-6">
            <a href={`https://www.openclimat.com/${locale}/methodology`} className="text-sm text-greyFour underline">
              {t('CLIMATE_ACTIONS.SBTI_METHODOLOGY')}
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="py-7 border-b border-solid border-x-0 border-t-0 border-greySix">
            <div className="flex">
              {scope12Color && showClimateAlignment && (
                <div key={scope12Color} className={`w-4 h-4 min-h-4 min-w-4 self-center mr-2.5 bg-${getCompleteColorByScore(scope12?.degreeScore)} rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope12Color, 0.1)}`, backgroundClip: 'padding-box' }} />
              )}
              <div className="self-center font-moskauGrotesk font-semibold text-sm">
                {t('CLIMATE_ACTIONS.RESULTS')} Scope 1&2 {flag && t('CLIMATE_ACTIONS.NOT_FLAG')}
              </div>
            </div>
            <DetailsMobile
              scope={scope12}
              scopeColor={scope12Color}
              dataUsable={scope12?.dataUsable}
              dataNotComparable={scope12?.dataNotComparable}
              getColorByScore={getCompleteColorByScore}
              getHexColorWithOpacity={getHexColorWithOpacity}
              showClimateAlignment={showClimateAlignment}
            />
          </div>
          <div className="py-7 border-b border-solid border-x-0 border-t-0 border-greySix">
            <div className="flex">
              {scope3Color && showClimateAlignment && (
                <div key={scope3Color} className={`w-4 h-4 min-h-4 min-w-4 self-start mr-2.5 bg-${getCompleteColorByScore(scope3?.degreeScore)} rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope3Color, 0.1)}`, backgroundClip: 'padding-box' }} />
              )}
              <div className="self-center font-moskauGrotesk font-semibold text-sm">
                <div>
                  {t('CLIMATE_ACTIONS.RESULTS')} Scope 3 {flag && t('CLIMATE_ACTIONS.NOT_FLAG')}
                </div>
                {note && (
                  <div className="text-[10px] text-greyFour font-normal inline-block relative">
                    <span className="mr-1">
                      {t('CLIMATE_ACTIONS.PERFORMANCE_KPI_TRACKED')}
                    </span>
                    <div className="inline-block align-middle group">
                      <img src={questionMarkCircled} alt="Question Mark" />
                      <div className="absolute top-full -right-8 sm:-right-[115%] invisible group-hover:visible md:top-full min-w-44 max-w-44 z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -mb-10 sm:-my-10 sm:-ml-10">
                        {note}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <DetailsMobile
              scope={scope3}
              scopeColor={scope3Color}
              dataUsable={scope3?.dataUsable}
              dataNotComparable={scope3?.dataNotComparable}
              getColorByScore={getCompleteColorByScore}
              getHexColorWithOpacity={getHexColorWithOpacity}
              showClimateAlignment={showClimateAlignment}
            />
          </div>
          {flag && (
            <div className="py-7 border-b border-solid border-x-0 border-t-0 border-greySix">
              <div className="flex">
                {flagColor && showClimateAlignment && (
                <div key={flagColor} className={`w-4 h-4 min-h-4 min-w-4 self-start mr-2.5 bg-${getCompleteColorByScore(flag?.degreeScore)} rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(flagColor, 0.1)}`, backgroundClip: 'padding-box' }} />
                )}
                <div className="self-center font-moskauGrotesk font-semibold text-sm relative">
                  <div>
                    <span className="mr-1">
                      {t('CLIMATE_ACTIONS.RESULTS')} FLAG
                    </span>
                    <div className="inline-block align-middle group">
                      <img src={questionMarkCircled} alt="Question Mark" />
                      <div className="absolute top-[100%] sm:top-full -right-8 sm:-right-[130%] invisible group-hover:visible md:top-full min-w-44 max-w-44 z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyTwo rounded-sm shadow-lg -mb-[48px] sm:-my-[48px] sm:ml-10">
                        {t('CLIMATE_ACTIONS.FLAG_DEFINITION')}
                      </div>
                    </div>
                  </div>
                  {flagPerimeter && (
                  <div className="text-[10px] text-greyFour font-normal inline-block relative">
                    <span className="mr-1">
                      {flagPerimeter}
                    </span>
                  </div>
                  )}
                </div>
              </div>
              <DetailsMobile
                flag
                scope={flag}
                scopeColor={flagColor}
                dataUsable={flag?.dataUsable}
                dataNotComparable={flag?.dataNotComparable}
                getColorByScore={getCompleteColorByScore}
                getHexColorWithOpacity={getHexColorWithOpacity}
                showClimateAlignment={showClimateAlignment}
              />
            </div>
          )}
          <div className="mt-6">
            <a href={`https://www.openclimat.com/${locale}/methodology`} className="text-sm text-greyFour underline">
              {t('CLIMATE_ACTIONS.SBTI_METHODOLOGY')}
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default ClimateActions;

import React from 'react';
import { useTranslation } from 'react-i18next';

function Status({ profileStatusList, register, errors }) {
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <label htmlFor="status" className="block text-base font-medium text-greyOne mb-2.5">
        {t('STATUS_SUPPLIER')}
      </label>
      <select
        className={`border rounded-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors.status ? 'border-redError focus:outline-redError' : 'border-greySix focus:outline-secondarySkyBlue'}`}
        {...register('status')}
      >
        <option value="default" disabled>{t('CHOOSE_STATUS')}</option>
        {profileStatusList?.sort((a, b) => a.position - b.position).map((status, index) => (
          <option key={index} value={status.id}>{t(status.name)}</option>
        ), [])}
      </select>
    </div>
  );
}

export default Status;

const getClimateAlignementInfos = (level) => {
  const roundedLevel = Math.floor(level);
  switch (roundedLevel) {
    case 6:
      return {
        text: '1.5°C',
        textColor: 'noteTresForte',
        bgColor: 'noteTresForte',
        border: true,
      };
    case 5:
      return {
        text: 'WB 2°C',
        textColor: 'noteForte',
        bgColor: 'noteForte',
        border: true,
      };
    case 4:
      return {
        text: '2°C',
        textColor: 'noteIntermediaire',
        bgColor: 'noteIntermediaire',
        border: true,
      };
    case 3:
      return {
        text: 'CLIMATE_ACTIONS.NOT_SUFFICIENT',
        textColor: 'noteEncoreInsufissante',
        bgColor: 'noteEncoreInsufissante',
        border: false,
      };
    case 2:
      return {
        text: 'CLIMATE_ACTIONS.RISING',
        textColor: 'noteTresInsuffisante',
        bgColor: 'noteTresInsuffisante',
        border: false,
      };
    case 1:
      return {
        text: 'CLIMATE_ACTIONS.NOT_MEASURED',
        textColor: 'notePasDengagement',
        bgColor: 'notePasDengagement',
        border: false,
      };
    default:
      return {
        text: 'CLIMATE_ACTIONS.NOT_MEASURED',
        textColor: 'notePasDengagement',
        bgColor: 'notePasDengagement',
        border: false,
      };
  }
};

export default getClimateAlignementInfos;

import { useLocale } from '../context/LocaleContext';
import isSupportedLocal from './isSupportedLocal';

// Version Hook (pour les composants)
export function useUserLocale() {
  const { locale } = useLocale();
  return isSupportedLocal(locale) ? locale : 'fr';
}

// Version traditionnelle (pour les fonctions non-composant)
export default function getUserLocal() {
  const language = document.documentElement.lang || 'fr';
  return isSupportedLocal(language) ? language : 'fr';
}

/**
 * An object that maps unit identifiers to their corresponding text representations.
 * @type {Object.<number, string>}
 * @property {string} 1 - Text representation for absolute value climate actions.
 * @property {string} 2 - Text representation for per volume climate actions.
 * @property {string} 3 - Text representation for per euros climate actions.
 */
const unitText = {
  1: 'IN_ABSOLUTE_VALUE',
  2: 'PER_VOLUME',
  3: 'PER_EUROS',
};

export default unitText;

import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function SectorInformations({ profile }) {
  const [categories, setCategories] = useState([]);
  const [firstLineCategories, setFirstLineCategories] = useState([]);
  const [secondLineCategories, setSecondLineCategories] = useState([]);
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [remainingSpace, setRemainingSpace] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const containerRef = useRef(null);
  const firstCategoryRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (profile?.categories_unfiltered) {
      const sortedCategories = [...profile.categories_unfiltered].sort((a, b) => a.name.localeCompare(b.name));
      setCategories(sortedCategories);
    }
  }, [profile]);

  useEffect(() => {
    if (categories.length === 0) return;

    // Mettre la première catégorie sur la première ligne
    setFirstLineCategories([categories[0]]);

    // setTimeout pour s'assurer que le DOM est mis à jour et que la ref est disponible
    setTimeout(() => {
      if (firstCategoryRef.current && categories.length > 1) {
        const containerWidth = 200;
        const gap = 6;
        const firstCatWidth = firstCategoryRef.current.offsetWidth;
        const space = containerWidth - firstCatWidth - gap;

        if (space >= 40) {
          // On peut ajouter la deuxième catégorie sur la première ligne
          setFirstLineCategories([categories[0], categories[1]]);
          setSecondLineCategories(categories.slice(2, 3));
          setHiddenCategories(categories.slice(3));
        } else {
          // La deuxième catégorie va sur la deuxième ligne
          setSecondLineCategories([categories[1]]);
          setHiddenCategories(categories.slice(2));
        }
        setRemainingSpace(space);
      } else {
        setSecondLineCategories([]);
        setHiddenCategories([]);
      }
    }, 0);
  }, [categories]);

  if (categories.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col w-[200px]" ref={containerRef}>
      <div className="text-xs text-greyFour mb-1">{t('HEADER_LABEL_SECTOR')}</div>
      <div className="flex flex-col gap-1.5">
        {/* Première ligne */}
        <div className="flex gap-1.5 w-[200px]">
          {firstLineCategories.map((category, index) => (
            <div className="relative" key={category.id}>
              <div
                ref={index === 0 ? firstCategoryRef : null}
                className="bg-white py-1.5 px-2 rounded text-xs text-greyOne truncate whitespace-nowrap border-[1px] border-solid border-greySeven"
                style={{
                  maxWidth: index === 0 ? '200px' : `${remainingSpace}px`,
                  width: 'auto',
                }}
                onMouseEnter={() => setActiveTooltip(category.id)}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                {category.name}
              </div>
              {activeTooltip === category.id && (
                <div className="absolute left-0 top-full mt-2 bg-greyDark text-white p-2 rounded shadow-lg z-50">
                  <div className="absolute left-3 -top-1 w-2 h-2 bg-greyDark rotate-45" />
                  <div className="whitespace-nowrap text-xs">
                    {category.name}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Deuxième ligne */}
        {(secondLineCategories.length > 0 || hiddenCategories.length > 0) && (
          <div className="flex flex-wrap gap-1.5 items-center w-[200px]">
            {secondLineCategories.map((category) => (
              <div className="relative" key={category.id}>
                <div
                  className="bg-white py-1.5 px-2 rounded text-xs text-greyOne truncate border-[1px] border-solid border-greySeven"
                  style={{
                    maxWidth: hiddenCategories.length > 0 ? '154px' : '200px',
                  }}
                  onMouseEnter={() => setActiveTooltip(category.id)}
                  onMouseLeave={() => setActiveTooltip(null)}
                >
                  {category.name}
                </div>
                {activeTooltip === category.id && (
                  <div className="absolute left-0 top-full mt-2 bg-greyDark text-white p-2 rounded shadow-lg z-50">
                    <div className="absolute left-3 -top-1 w-2 h-2 bg-greyDark rotate-45" />
                    <div className="whitespace-nowrap text-xs">
                      {category.name}
                    </div>
                  </div>
                )}
              </div>
            ))}

            {/* hiddenCategories */}
            {hiddenCategories.length > 0 && (
              <div className="relative shrink-0">
                <div
                  className="bg-white py-1.5 px-2 rounded text-xs text-greyOne cursor-pointer border-[1px] border-solid border-greySeven"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  +{hiddenCategories.length}
                </div>
                {showTooltip && (
                  <div className="absolute left-full ml-2 top-0 bg-greyDark text-white p-2 rounded shadow-lg z-50 min-w-[150px]">
                    <div className="absolute -left-1 top-3 w-2 h-2 bg-greyDark rotate-45" />
                    {hiddenCategories.map((category) => (
                      <div key={category.id} className="whitespace-nowrap text-xs py-0.5">
                        {category.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SectorInformations;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { useOutletContext } from 'react-router-dom';

import pen from '../../../../assets/ui/pen.svg';

import ProfileStatusForm from '../Modal/Forms/ProfileStatusForm';
import ProfileTags from './ProfileTags';
import StatusListModal from './StatusListModal';
import StatusTemplate from './StatusTemplate';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';
import Features from '../../../../features.json';
import useModal from '../../../../hooks/useModal';

function Status({ partner, profile, hasWriteAccess }) {
  const [status, setStatus] = useState({
    id: profile.current_status_id,
    status_id: profile.status_id,
    name: profile.status_name,
    description: profile.status_description,
    color: profile.status_color,
  });
  const [profileStatusList, setProfileStatusList] = useState([]);
  const [formContext, setFormContext] = useState({});
  const { features } = useOutletContext();

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });
  const { t } = useTranslation();

  const { open } = useModal();

  const getProfileStatusList = async () => {
    await get(`/partner/${partner.id}/profile-status`);
    if (response.ok) {
      setProfileStatusList(response.data);
    }
  };

  useEffect(() => {
    try {
      getProfileStatusList();
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  useEffect(() => {
    setStatus({
      id: profile?.current_status_id,
      status_id: profile?.status_id,
      name: profile?.status_name,
      description: profile?.status_description,
      color: profile?.status_color,
    });
  }, [profile]);

  return (
    <>
      <div className="bg-white p-6 mb-4 border border-solid border-greySix rounded">
        <div className="mb-3 flex justify-between">
          <h2 className="text-greyOne font-moskauGrotesk text-base">
            {t('STATUS_SUPPLIER')}
          </h2>
          <div className="flex items-center gap-x-2.5">
            {features.includes(Features.profileTags) && <ProfileTags partnerId={partner.id} profileId={profile.id} />}
            {hasWriteAccess ? (
              <button
                type="button"
                className="border-0 bg-white cursor-pointer"
                onClick={() => {
                  setFormContext({
                    action: status.id ? 'edit' : 'create',
                    modalTitle: t('EDIT_STATUS'),
                    profileId: profile.id,
                    partnerId: partner.id,
                    currentStatusId: status.id,
                    statusList: profileStatusList,
                    setStatus,
                    data: {
                      status: status.status_id,
                    },
                  });
                  open('profile-status-modal');
                }}
              >
                <img src={pen} width={22} height={22} alt="edit statut" />
              </button>
            ) : ('')}
          </div>

        </div>
        {status.id && status.color && status.description ? (
          <StatusTemplate
            title={status.name}
            description={status.description}
            color={status.color}
            backgroundColor={getHexColorWithOpacity(status.color, 0.1)}
          />
        ) : (
          <div className="bg-greyEight border-dashed border-[1px] p-4 text-greyDark rounded">
            <h4 className="font-moskauGrotesk font-semibold text-sm">
              {t('NO_STATUS_SELECTED')}
            </h4>
            <p className="font-poppins font-normal text-sm mb-0">
              {t('NO_STATUS')}
            </p>
          </div>
        )}
        <button type="button" className="font-poppins text-greyFour text-sm block mt-6 bg-white border-0 underline" onClick={() => open('status-list-modal')}>
          {t('SHOW_ALL_STATUS')}
        </button>
      </div>
      <StatusListModal statusList={profileStatusList} />
      {hasWriteAccess ? (
        <ProfileStatusForm
          formContext={formContext}
        />
      ) : ('')}
    </>
  );
}

export default Status;

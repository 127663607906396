import React from 'react';

function Card({ card }) {
  return (
    <div className="bg-white w-[300px] h-[300px] max-w-[300px] max-h-[300px] px-[22px] py-[50px] border border-solid border-greySeven rounded-[20px]">
      <div className="flex justify-center">
        <img src={card.icon} alt={card.iconName} />
      </div>
      <div className="text-lg font-medium mt-6 text-center">
        <span className="text-leclercOrange">
          {card.textOne}
        </span>
        <span className="text-primaryNewBlue">
          {card.textTwo}
        </span>
      </div>
    </div>
  );
}

export default Card;

import React from 'react';
import { Link } from 'react-router-dom';

import getLocalLink from '../../../utils/getLocalLink';
import rightArrow from '../../../assets/ui/arrow-right-blueSky.svg';

function Card({ cardName, cardText, cardCta, cardLink }) {
  return (
    <Link to={getLocalLink(cardLink)} className="w-full lg:w-[300px] xl:w-[408px] h-[155px] xl:h-[164px] bg-white flex flex-col justify-between p-6 rounded border border-solid border-greySeven hover:border-secondarySkyBlue no-underline transition-all">
      <h3 className=" text-base font-semibold text-greyOne">
        {cardName}
      </h3>
      <p className="text-xs text-greyThree">
        {cardText}
      </p>
      <div className="w-full flex items-center gap-x-2">
        <span className=" text-sm text-primaryNewBlue">{cardCta}</span>
        <img src={rightArrow} alt="right arrow" />
      </div>
    </Link>
  );
}

export default Card;

import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import chevronDown from '../../../../../assets/ui/chevron-down-grey.svg';
import closeIcon from '../../../../../assets/ui/cross-modal.svg';

import CreateTagForm from './CreateTagForm';
import RenderTagDropdown from './RenderTagDropdown';

function RenderTagSection({ partner, handleCancelCreateTag, handleTagCreated, handleCancelEditTag, handleTagEdited, partnerTags, getPartnerTags, tagToEdit, control, selectedTags, handleTagRemoval, isTagDropdownOpen, toggleTagDropdown, tagDropdownRef, tagTriggerRef, handleTagKeyDown, isCreatingTag, isEditingTag, tailwindConfig, hoveredTagId, setHoveredTagId, handleTagSelection, handleCreateTag, handleToggleMenu, openMenuTagId, handleEditTag, handleDeleteTag }) {
  const { t } = useTranslation();

  if (isCreatingTag) {
    return (
      <CreateTagForm
        partner={partner}
        onCancel={handleCancelCreateTag}
        onCreate={handleTagCreated}
        partnerTags={partnerTags}
      />
    );
  }

  if (isEditingTag) {
    return (
      <CreateTagForm
        partner={partner}
        onCancel={handleCancelEditTag}
        onCreate={handleTagEdited}
        partnerTags={partnerTags}
        getPartnerTags={getPartnerTags}
        tagToEdit={tagToEdit}
      />
    );
  }

  return (
    <div className="w-full flex flex-col border border-solid border-greySix rounded-[4px] p-4 mt-5">
      <span className="text-xl font-medium text-greyTwo font-moskauGrotesk">{t('TAGS')}</span>
      <span className="text-greyTwo font-moskauGrotesk mt-5">{t('TAGS_DESCRIPTION')}</span>

      <Controller
        name="tags"
        control={control}
        render={() => (
          <div className="flex flex-col mt-[30px]" ref={tagDropdownRef}>
            <label htmlFor="tags" className="block text-base font-moskauGrotesk font-medium text-greyOne mb-2.5">
              {t('TAGS')}
            </label>
            <div
              ref={tagTriggerRef}
              tabIndex="0"
              className={`min-h-[46px] rounded-t-md ${isTagDropdownOpen ? 'rounded-b-none' : 'rounded-b'} rounded-b-none p-2 flex justify-between items-center cursor-pointer border border-solid border-greySix focus:border-secondarySkyBlue focus:border-2 focus:outline-none`}
              onClick={toggleTagDropdown}
              onKeyDown={handleTagKeyDown}
              role="button"
              aria-haspopup="listbox"
              aria-expanded={isTagDropdownOpen}
            >
              <div className="flex flex-wrap gap-2">
                {selectedTags.length > 0 ? (
                  selectedTags.map((tag, index) => (
                    <div key={`selected-${tag.id}-${index}`} className="flex items-center rounded py-[6px] px-3" style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}>
                      <span className="text-sm font-moskauGrotesk font-medium text-greyOne -mb-[4px]">{tag.name}</span>
                      <button
                        type="button"
                        className="border-none p-0"
                        style={{ backgroundColor: tag.color ? tag.color : tailwindConfig.theme.colors.tagsGrey }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTagRemoval(tag);
                        }}
                      >
                        <img
                          src={closeIcon}
                          alt="remove"
                          className="w-[18px] h-[18px] ml-1 cursor-pointer"
                        />
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="text-greyFour font-moskauGrotesk">{t('TAGS_SELECT_OR_CREATE')}</span>
                )}
              </div>
              <img
                src={chevronDown}
                alt="chevron"
                className={`transition-transform duration-300 ${isTagDropdownOpen ? 'transform rotate-180' : ''}`}
              />
            </div>
            {isTagDropdownOpen && (
              <RenderTagDropdown
                availableTags={partnerTags}
                hoveredTagId={hoveredTagId}
                setHoveredTagId={setHoveredTagId}
                handleTagSelection={handleTagSelection}
                handleCreateTag={handleCreateTag}
                tailwindConfig={tailwindConfig}
                handleToggleMenu={handleToggleMenu}
                openMenuTagId={openMenuTagId}
                handleEditTag={handleEditTag}
                handleDeleteTag={handleDeleteTag}
              />
            )}
          </div>
        )}
      />
    </div>
  );
}

export default RenderTagSection;

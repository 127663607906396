import React, { useState, useEffect, useContext } from 'react';

import { TableLoadingContext } from '../../../context/TableLoadingProvider';

import caretLeft from '../../../assets/ui/database/caret-left.svg';
import caretRight from '../../../assets/ui/database/caret-right.svg';

function Pagination({ page, togglePage, totalPages }) {
  const NUM_BUTTONS = 4;

  const [pagesToShow, setPagesToShow] = useState([]);

  const { setNavigationLoading, navigationLoading } =
    useContext(TableLoadingContext);

  useEffect(() => {
    const pagesToShowPlaceholder = [];

    if (page > totalPages) togglePage(0);

    if (page < NUM_BUTTONS - 1) {
      for (let i = 1; i <= NUM_BUTTONS; i += 1) {
        if (totalPages >= i) {
          pagesToShowPlaceholder.push(i);
        }
      }
    } else if (page < totalPages - 1) {
      for (let i = page - 1; i <= page + 2; i += 1) {
        if (totalPages >= i) {
          pagesToShowPlaceholder.push(i);
        }
      }
    } else {
      for (let i = page - 2; i <= page + 1; i += 1) {
        if (totalPages >= i) {
          pagesToShowPlaceholder.push(i);
        }
      }
    }
    setPagesToShow(pagesToShowPlaceholder);
    setNavigationLoading(false);
  }, [totalPages, page]);

  return navigationLoading && pagesToShow.length === 0 ? (
    <div className="text-center flex justify-end">
      <div className="bg-greySeven animate-pulse h-7 rounded mt-3 flex w-60" />
    </div>
  ) : (
    <nav className="flex justify-end w-full pt-3 gap-2">
      <button
        type="button"
        className={`w-[34px] h-[34px] flex justify-center items-center text-xs text-greyFour bg-transparent border border-greySix rounded-md hover:bg-greySeven disabled:bg-transparent duration-75 ${
          page === 0 ? 'opacity-50' : 'hover:text-greyOne active:scale-90'
        }`}
        {...(page === 0 ? { disabled: true } : {})}
        onClick={() => {
          if (page > 0) {
            togglePage(page - 1);
          }
        }}
      >
        <img src={caretLeft} alt="Caret left" className="w-[18px] h-[18px]" />
      </button>

      {pagesToShow.map((item, index) => (
        <button
          type="button"
          key={index}
          onClick={() => togglePage(item - 1)}
          className={`w-[34px] h-[34px] flex justify-center items-center rounded-md duration-75 active:scale-90 ${
            item - 1 === page
              ? 'bg-primaryNewBlue text-white'
              : 'px-3 bg-transparent text-greyFour border border-greySix hover:bg-greySeven'
          }`}
        >
          <span className="text-sm flex justify-center items-end">{item}</span>
        </button>
      ))}

      <button
        type="button"
        className={`w-[34px] h-[34px] flex justify-center items-center text-xs text-greyFour bg-transparent border border-greySix rounded-md hover:bg-greySeven disabled:bg-transparent duration-75 ${
          page === totalPages - 1
            ? 'opacity-50'
            : 'hover:text-greyOne active:scale-90'
        }`}
        {...(page === totalPages - 1 ? { disabled: true } : {})}
        onClick={() => {
          if (page < totalPages - 1) {
            togglePage(page + 1);
          }
        }}
      >
        <img src={caretRight} alt="Caret right" className="w-[18px] h-[18px]" />
      </button>
    </nav>
  );
}

export default Pagination;

import React from 'react';
import { useTranslation } from 'react-i18next';

function ProfileCategoryData({ currentProfile, register, errors, tonnageGlobal, turnoverGlobal, partnerName, hasTurnoverEditAccess, hasCarbonWeightEditAccess }) {
  const { t } = useTranslation();
  const hasFullEditAccess = (hasCarbonWeightEditAccess && hasTurnoverEditAccess);

  return (
    <>
      <div className="mt-8 p-4 border border-solid border-greySix rounded-t font-moskauGrotesk">
        <div className="text-lg font-medium text-greyThree mb-6">
          {hasFullEditAccess && (
            t('CARBON_WEIGHT_AND_TURNOVER', { partner: partnerName })
          )}
          {hasCarbonWeightEditAccess && !hasTurnoverEditAccess && (
            t('TITLE_CARBON_WEIGHT', { partner: partnerName })
          )}
          {hasTurnoverEditAccess && !hasCarbonWeightEditAccess && (
            t('TITLE_TURNOVER', { partner: partnerName })
          )}
        </div>
        <div className="grid grid-cols-3 gap-2.5 text-greyOne max-h-96 overflow-y-auto text-sm">
          <div className="col-span-1 font-medium text-greyFour">
            {t('CATEGORY')}
          </div>
          <div className="col-span-1 font-medium text-greyFour">
            {(hasFullEditAccess) && (
              t('TONNAGE')
            )}
          </div>
          <div className="col-span-1 font-medium text-greyFour">
            {hasTurnoverEditAccess && (
              t('CA')
            )}
            {hasCarbonWeightEditAccess && !hasFullEditAccess && (
              t('TONNAGE')
            )}
          </div>
          {currentProfile && currentProfile.categories?.map((category, index) => (
            <React.Fragment key={category.id + category.carbon_weight + index}>
              <div className={`${hasFullEditAccess ? 'col-span-1' : 'col-span-2'} items-center flex text-greyOne`}>
                {category.name}
              </div>
              {(hasCarbonWeightEditAccess) && (
                <div className={`flex col-span-1 rounded-md mb-2.5 mr-[1px] ${errors.carbon_weight ? 'parent-outline-red-error' : 'parent-outline'}`}>
                  <input
                    id="carbon_weight"
                    type="number"
                    step=".001"
                    min={0}
                    className={`custom-number-input border-l border-r-0 border-t border-b border-solid rounded-l-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors.carbon_weight ? 'border-redError focus:outline-none focus:border-l focus:border-t focus:border-b focus:border-redError' : 'border-greySix focus:outline-none focus:border-l focus:border-t focus:border-b focus:border-secondarySkyBlue'}`}
                    {...register(`carbon_weight_${category.id}`)}
                  />
                  <span className={`pr-4 flex items-center h-full self-center border-solid border-l-0 border-r border-t border-b ${errors.carbon_weight ? 'border-redError' : 'border-greySix'} rounded-r-md text-greyFour text-sm`}>
                    ktCO2e
                  </span>
                </div>
              )}
              {(hasTurnoverEditAccess) && (
                <div className={`flex col-span-1 rounded-md mb-2.5 mr-[1px] ${errors.carbon_weight ? 'parent-outline-red-error' : 'parent-outline'}`}>
                  <input
                    id="turnover"
                    type="number"
                    step=".001"
                    min={0}
                    className={`custom-number-input border-l border-r-0 border-t border-b border-solid rounded-l-md text-greyFour px-5 py-2.5 font-normal text-base w-full ${errors.turnover ? 'border-redError focus:outline-none focus:border-l focus:border-t focus:border-b focus:border-redError' : 'border-greySix focus:outline-none focus:border-l focus:border-t focus:border-b focus:border-secondarySkyBlue'}`}
                    {...register(`turnover_${category.id}`)}
                  />
                  <span className={`pr-4 flex items-center h-full self-center border-solid border-l-0 border-r border-t border-b ${errors.turnover ? 'border-redError' : 'border-greySix'} rounded-r-md text-greyFour text-sm`}>
                    M€
                  </span>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      {(hasCarbonWeightEditAccess || hasFullEditAccess) && (
      <div className="flex justify-between bg-greyEight border-t-0 border border-solid border-x-greySix border-b-greySix px-4 py-3.5 text-greyTwo text-sm items-center">
        <div className="text-greyFour font-medium">
          {t('TONNAGE_GLOBALE')}
        </div>
        <div className="text-secondarySkyBlue font-medium text-base font-">
          {tonnageGlobal.toFixed(2)} ktCO2e
        </div>
      </div>
      )}
      {(hasTurnoverEditAccess || hasFullEditAccess) && (
      <div className="flex justify-between bg-greyEight border-t-0 border border-solid border-x-greySix border-b-greySix px-4 py-3.5 rounded-b text-greyTwo text-sm items-center">
        <div className="text-greyFour font-medium">
          {t('TURNOVER_GLOBALE')}
        </div>
        <div className="text-secondarySkyBlue font-medium text-base font-">
          {turnoverGlobal.toFixed(2)} M€
        </div>
      </div>
      )}
    </>
  );
}

export default ProfileCategoryData;

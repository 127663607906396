import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import chevronDown from '../../../../assets/ui/chevron-down-grey.svg';
import categoryIcons from '../../../../utils/categoryIcons';

function IconSelector({ selectedIcon, setSelectedIcon }) {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event, icon) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      setSelectedIcon(icon);
      setShowDropdown(false);
    }
  };

  const selectIcon = (icon) => {
    setSelectedIcon(icon);
    setShowDropdown(false);
  };

  return (
    <div ref={dropdownRef}>
      <label className="text-sm font-moskauGrotesk font-normal mt-9">
        {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.ICON')}
      </label>
      <div className="relative">
        <button
          type="button"
          className=" w-h-11 w-full bg-white text-left text-sm font-moskauGrotesk font-normal text-greyHeavy border border-solid border-greyLight focus:border-secondarySkyBlue focus:border-2 focus:outline-none rounded-md p-2 flex items-center justify-between"
          onClick={toggleDropdown}
          aria-haspopup="listbox"
          aria-expanded={showDropdown}
        >
          <span className="flex items-center grow">
            {selectedIcon && selectedIcon.icon ? (
              <img
                src={selectedIcon.icon}
                alt=""
                className="h-5 w-5 flex-shrink-0 mr-2"
              />
            ) : (
              <span
                className="block truncate text-greyDark font-moskauGrotesk font-normal"
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.ICON_INFO')}
              </span>
            )}
          </span>
          <img
            src={chevronDown}
            alt="chevron down icon"
            className="ml-4 h-5 w-5"
          />
        </button>
        {showDropdown && (
          <div
            className="absolute z-10 w-full max-h-72 flex flex-col overflow-auto rounded-md bg-white py-4 pl-0 shadow-customDropdown ring-1 ring-black ring-opacity-5 focus:outline-none text-sm border border-solid border-greyLight"
            tabIndex="-1"
            role="listbox"
          >
            <p className="text-sm text-greyFive font-moskauGrotesk font-normal pl-4 mb-2">
              {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.ICON')}s
            </p>
            <div className="w-full flex flex-wrap p-2">
              <div className=" w-16 h-16 flex flex-col items-center justify-center p-2 hover:bg-secondaryBlueBg rounded">
                <button
                  type="button"
                  tabIndex="0"
                  onClick={() => selectIcon(null)}
                  onKeyDown={handleKeyDown}
                  className=" text-sm font-normal font-moskauGrotesk text-greyFive truncate whitespace-normal text-center border-none bg-white/0"
                >
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.NO_ICON')}
                </button>
              </div>
              {categoryIcons.map((icon) => (
                <div
                  key={icon.value}
                  className="w-16 h-16 flex items-center justify-center p-2 hover:bg-secondaryBlueBg rounded cursor-pointer text-greyFive font-moskauGroteskNormal hover:text-greyTwo hover:font-moskauGrotesk hover:font-semibold"
                  role="option"
                  onClick={() => selectIcon(icon)}
                  onKeyDown={(event) => handleKeyDown(event, icon)}
                  tabIndex="0"
                  aria-selected={
                    selectedIcon && selectedIcon.value === icon.value
                  }
                >
                  <img
                    src={icon.icon}
                    alt=""
                    className="h-5 w-5 flex-shrink-0"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default IconSelector;

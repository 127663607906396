import React from 'react';
import { useTranslation } from 'react-i18next';

import questionMarkCircled from '../../../../assets/ui/question-mark-circled.svg';

import getClimateAlignementInfos from '../../../../utils/getClimateAlignementInfos';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

export default function Flag({ data, flagColor, unitText, showClimateAlignment }) {
  const { t } = useTranslation();

  return (
    <>
      {/* Desktop FLAG */}
      <div className="hidden w-full lg:flex items-center py-3 border border-solid border-x-0 border-t-0 border-greySix">
        <div className="w-2/5 flex items-center gap-x-2">
          {showClimateAlignment ? (
            <div key={flagColor} className={`w-4 h-4 min-h-4 min-w-4 self-start mt-[1px] bg-${data?.percent_reduction_target ? getClimateAlignementInfos(data?.manual_degree_position).bgColor : 'notePasDengagement'} rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(flagColor, 0.1)}`, backgroundClip: 'padding-box' }} />
          ) : null}
          <div className="text-sm text-greyOne font-moskauGrotesk font-semibold inline-block relative">
            <div>
              <span className="mr-1">
                {t('CLIMATE_ACTIONS.FLAG')}
              </span>
              <div className="inline-block align-middle group">
                <img src={questionMarkCircled} alt="Question Mark" />
                <div className={`absolute top-full invisible group-hover:visible max-w-44 min-w-44 z-10 py-2 px-3 text-xs text-white font-normal font-moskauGrotesk bg-greyTwo rounded-sm shadow-lg ml-6 ${data?.perimeter ? '-my-[49px]' : '-my-[27px]'}`}>
                  <div className="absolute -left-[4px] top-[10px] w-3 h-3 bg-greyTwo rotate-45 rounded-sm" />
                  {t('CLIMATE_ACTIONS.FLAG_DEFINITION')}
                </div>
              </div>
            </div>
            {data?.perimeter && (
              <div className="text-[10px] text-greyFour font-normal inline-block relative">
                {data?.perimeter}
              </div>
            )}
          </div>
        </div>
        <div className={`w-3/5 flex items-center ${!showClimateAlignment ? 'justify-end' : ''}`}>
          <div className={`${!showClimateAlignment ? '' : 'w-1/2'} flex flex-col items-center`}>
            {data?.percent_reduction_target ? (
              <>
                <div className="font-moskauGrotesk font-semibold text-xl">
                  {
                    data?.percent_reduction_target
                  }
                  %
                </div>
                <div className="text-xs text-greyTwo">
                  {t(unitText[data?.direct_value_type])}
                </div>
                <div className="text-xs text-greyTwo">
                  {t('CLIMATE_ACTIONS.BETWEEN')}{' '}
                  {
                    data?.flag_initial_year
                  }{' '}
                  et{' '}
                  {data?.flag_final_year}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          {showClimateAlignment ? (
            <div className="w-1/2 flex flex-col items-center">
              {data?.percent_reduction_target && data?.manual_degree_position >= 4 && data?.percent_reduction_target !== 1.5 ? (
                <div
                  className={`w-24 text-sm font-medium text-${
                    getClimateAlignementInfos(
                      data?.manual_degree_position,
                    ).textColor
                  } text-center py-1 bg-${
                    getClimateAlignementInfos(
                      data?.manual_degree_position,
                    ).bgColor
                  }/10 rounded-2xl border-${
                    getClimateAlignementInfos(
                      data?.manual_degree_position,
                    ).textColor
                  } border border-solid`}
                >
                  <span className="relative top-[1px]">
                    {
                      getClimateAlignementInfos(
                        data?.manual_degree_position,
                      ).text
                    }
                  </span>
                </div>
              ) : (
                <>
                  <div className="text-xs text-greyThree font-moskauGrotesk font-normal">
                    {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
                  </div>
                  { data?.percent_reduction_target ? (
                    <div className="text-xs text-noteEncoreInsufissante font-moskauGrotesk font-semibold mt-1">
                      {t('CLIMATE_ACTIONS.INSUFFICIENT_COMMITMENT')}
                    </div>
                  ) : (
                    <div className="text-xs text-notePasDengagement font-moskauGrotesk font-semibold mt-1">
                      {t('CLIMATE_ACTIONS.NO_COMMITMENT_2')}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
      {/* Mobile FLAG */}
      <div className="w-full flex lg:hidden flex-col gap-y-6 border border-solid border-x-0 border-t-0 border-greySix py-6">
        <div className="w-full flex items-center gap-x-2">
          {showClimateAlignment ? (
            <div key={flagColor} className={`w-4 h-4 min-h-4 min-w-4 self-start mt-[1px] bg-${data?.percent_reduction_target ? getClimateAlignementInfos(data?.manual_degree_position).bgColor : 'notePasDengagement'} rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(flagColor, 0.1)}`, backgroundClip: 'padding-box' }} />
          ) : null}
          <div className="text-sm text-greyOne font-moskauGrotesk font-semibold relative">
            <div>
              <span className="mr-1">
                {t('CLIMATE_ACTIONS.FLAG')}
              </span>
              <div className="inline-block align-middle group">
                <img src={questionMarkCircled} alt="Question Mark" />
                <div className="absolute top-[100%] sm:top-full -right-8 sm:-right-[130%] invisible group-hover:visible md:top-full min-w-44 max-w-44 z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyTwo rounded-sm shadow-lg -mb-[48px] sm:-my-[48px] sm:ml-10">
                  {t('CLIMATE_ACTIONS.FLAG_DEFINITION')}
                </div>
              </div>
            </div>
            {data?.perimeter && (
              <div className="text-[10px] text-greyFour font-normal inline-block relative">
                {data?.perimeter}
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="w-1/2 text-xs text-greyFour leading-[18px]">
            {t('CLIMATE_ACTIONS.TARGET_REDUCTION')}
          </div>
          <div className="w-1/2 flex flex-col items-center">
            {data?.percent_reduction_target ? (
              <>
                <div className="font-moskauGrotesk font-semibold text-xl">
                  {
                    data?.percent_reduction_target
                  }
                  %
                </div>
                <div className="text-xs text-greyTwo">
                  {t(unitText[data?.direct_value_type])}
                </div>
                <div className="text-xs text-greyTwo">
                  {t('CLIMATE_ACTIONS.BETWEEN')}{' '}
                  {
                    data?.flag_initial_year
                  }{' '}
                  et{' '}
                  {data?.flag_final_year}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { CKEditor } from 'ckeditor4-react';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';
import { useUserLocale } from '../../../../../utils/getUserLocal';
import handleResponse from '../../../../../utils/handleResponse';

export default function QuestionsActionsItemFormModal({ data, profileId, fetchData }) {
  const { register, handleSubmit, setValue } = useForm({});
  const { close } = useModal();
  const locale = useUserLocale();
  const [editor, setEditor] = useState(null);

  const { post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/content-list-item`, {
    credentials: 'include',
  });

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  useEffect(() => {
    if (editor) {
      editor.setData(data ? data.name : null);
    }
    register('name', {
      required: true,
    });
  }, [data]);

  const onSubmit = async (formData) => {
    try {
      const finalData = {
        answer_id: data.answerId,
        name: formData.name,
        type: data.type,
        locale,
      };
      if (data?.id) {
        await put(data.id, finalData);
      } else {
        await post('', finalData);
      }
      if (response.ok) {
        close();
        fetchData();
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  return (
    <TemplateFormModal
      modalId="question-actions-items-modal"
      formId="question-actions-items-form"
      title={data?.title}
    >
      <form
        id="question-actions-items-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="p-4 md:p-5 space-y-4">
          <div className="mb-4">
            <label htmlFor="answer" className="block text-base font-medium text-greyOne mb-2.5">
              {data?.info}*
            </label>
            <CKEditor
              initData={data ? data.name : null}
              onLoaded={onBeforeLoad}
              onChange={(value) => setValue('name', value.editor.getData().replace(/(\r\n|\n|\r)/gm, ''))}
              required={false}
              config={{
                toolbar: [
                  ['Bold', 'Italic', 'Underline', 'Strike'],
                  ['NumberedList', 'BulletedList'],
                ],
                versionCheck: false,
              }}
            />
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CarbonFootprintDistributionPie from '../../CarbonFootprintDistribution/Chart/CarbonFootprintDistributionPie';
import { totalCarbonFootprintValue, formattedTotalCarbonFootprintValue, carbonFootprintYear, isFlagData, isControledPmeWithoutCarbonFootprint, isPmeWithCommitment, isPlannedPublicationDateValid, plannedPublicationDate, commitmentDate, carbonFootprint, currentCharData, chartDataFiltered, carbonFootprintDistribution, chartData } from './fakeData';

import circleQuestion from '../../../../../assets/ui/circle-question.svg';
import circleQuestionScope3 from '../../../../../assets/ui/circle-question-scope-3.svg';
import circleQuestionGrey from '../../../../../assets/ui/circle-question-grey.svg';
import crossIconGrey from '../../../../../assets/ui/cross-icon-grey.svg';
import calendarCrossBlue from '../../../../../assets/ui/calendar-cross-blue.svg';
import calendarCheckBlue from '../../../../../assets/ui/calendar-check-blue.svg';
import questionMarkCircled from '../../../../../assets/ui/question-mark-circled.svg';

function SupplierPartnerCarbonFootprint({ partner, profile }) {
  const [displayTable, setDisplayTable] = useState(false);
  const { t } = useTranslation();

  const toggleTable = (e) => {
    e.preventDefault();
    setDisplayTable(!displayTable);
  };

  return (
    <div className="flex flex-col bg-white p-6 mb-4 border border-solid border-greySix rounded">
      <h2 className="text-greyOne font-moskauGrotesk text-base mb-6">
        {t('SUPPLIER_PARTNER_CARBON_FOOTPRINT.TITLE', { profileName: profile.name, partnerName: partner.name })}
      </h2>
      <span className="text-sm text-greyFour font-poppins mb-6">{t('SUPPLIER_PARTNER_CARBON_FOOTPRINT.SUB_TITLE')}</span>
      <div className="xl:grid grid-cols-12 mb-6">
        <div className="col-span-4">
          <div className="carbon-footprint-chart-container h-64 overflow-visible py-3">
            <CarbonFootprintDistributionPie
              totalCarbonFootprintValue={totalCarbonFootprintValue}
              formattedTotalCarbonFootprintValue={formattedTotalCarbonFootprintValue}
              carbonFootprintYear={carbonFootprintYear}
              data={currentCharData}
              scope12={carbonFootprint && carbonFootprint[0]}
              scope3={carbonFootprint && carbonFootprint[1]}
            />
          </div>
        </div>
        {((carbonFootprint && !isFlagData && (carbonFootprint[0] && carbonFootprint[1])) || (isControledPmeWithoutCarbonFootprint && !isFlagData)) ? (
          <div className="col-span-8 my-auto xl:grid grid-cols-12 xl:ps-5 pe-0 text-sm">
            <div className="flex mt-6 xl:hidden gap-1">
              <div className="xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 flex-nowrap bg-[#21AFFF1A] text-[#21AFFF] rounded-full relative">
                <div>
                  Scope 1&2
                </div>
                <div className="group">
                  <img src={circleQuestion} width="auto" height="auto" alt="circle question" className="mt-0.5" />
                  <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-[160px] z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                  </div>
                </div>
              </div>
              <div className="xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 bg-[#000D850D] text-[#000D85AD] rounded-full relative">
                <div>
                  Scope 3
                </div>
                <div className="group">
                  <img src={circleQuestionScope3} width="auto" height="auto" alt="circle question" className="mt-0.5" />
                  <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 right-0 md:left-[160px] z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-8 xl:px-0 flex mt-6 xl:mt-0">
              <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: '#21AFFF' }} />
              <div className="self-center ms-3 pt-1 text-greyOne">
                {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10')}
              </div>
            </div>
            <div className="col-span-4 xl:flex hidden border-solid border-r-0 border-y-0 border-l border-greySix relative">
              <div className="ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex flex-nowrap bg-[#21AFFF1A] rounded-full">
                <div className="text-[#21AFFF] text-center">
                  Scope 1&2
                </div>
                <div className="group self-center">
                  <img className="ms-1 mb-0.5" src={circleQuestion} width="auto" height="auto" alt="circle question" />
                  <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-full z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-8 pe-0 md:ps-0">
              {(chartDataFiltered) ? (
                chartDataFiltered.filter((item) => parseInt(item.scope, 10) === 3).map((item, index) => (
                  <div key={index} className="flex mt-6">
                    <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: item.color }} />
                    <div className="self-center ms-3 pt-1 text-greyOne">
                      {t(item.id)}
                    </div>
                  </div>
                ))
              ) : (
                ''
              )}
            </div>
            <div className="col-span-4 xl:flex hidden mt-6 pe-0 border-solid border-r-0 border-y-0 border-l border-greySix">
              <div className="ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex bg-[#000D850D] text-[#000D85AD] rounded-full relative">
                <div className="text-center">
                  Scope 3
                </div>
                <div className="group self-center">
                  <img className="ms-1 mb-0.5" width="auto" height="auto" src={circleQuestionScope3} alt="circle question scope 3" />
                  <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-[140px] z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {((carbonFootprint && isFlagData && (carbonFootprint[0] && carbonFootprint[1])) || (isControledPmeWithoutCarbonFootprint && isFlagData)) ? (
          <div className="col-span-8 xl:my-auto xl:ps-5 mt-6 text-sm">
            <div className="flex">
              <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex bg-greyFour" />
              <div className="self-center ms-3 pt-1 text-greyOne flex gap-1 relative items-center">
                <div className="pt-0.5">
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_25')}
                </div>
                <div className="inline-block align-middle group">
                  <img src={questionMarkCircled} alt="Question Mark" />
                  <div className="absolute top-full invisible group-hover:visible max-w-44 min-w-44 z-10 py-2 px-3 text-xs text-white font-normal font-moskauGrotesk bg-greyTwo rounded-sm shadow-lg ml-6 -my-[27px]">
                    <div className="absolute -left-[4px] top-[10px] w-3 h-3 bg-greyTwo rotate-45 rounded-sm" />
                    {t('CLIMATE_ACTIONS.FLAG_DEFINITION')}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-6 xl:mt-7">
              <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex bg-greySix" />
              <div className="self-center ms-3 pt-1 text-greyOne">
                {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_26')}
              </div>
            </div>
          </div>
        ) : null}
        {(carbonFootprint && (!carbonFootprint[0] && !carbonFootprint[1]) && carbonFootprintDistribution?.length === 0) ? (
          <div className="xl:col-span-8 flex xl:ps-5 mt-4 xl:mt-0 items-center mr-6">
            <div className="w-full flex items-center justify-center bg-greyEight py-6 px-2 rounded">
              <div className="text-center flex items-center justify-center gap-3">
                {isPmeWithCommitment ? (
                  <>
                    <div className={`${isPlannedPublicationDateValid ? 'bg-greenLightTwo' : 'bg-greyLightFive'} p-3 rounded-full`}>
                      <img
                        src={isPlannedPublicationDateValid ? calendarCheckBlue : calendarCrossBlue}
                        alt={isPlannedPublicationDateValid ? 'calendar check blue' : 'calendar cross blue'}
                        height={32}
                        width={32}
                      />
                    </div>
                    {isPmeWithCommitment && (
                    <div className="flex flex-col gap-1 text-left">
                      <span className="text-sm text-greyThree">{t('CARBON_FOORPRINT_DISTRIBUTION_EXPECTED_P_DATE')}</span>
                      <span className="text-sm font-semibold text-greyOne">
                        {isPlannedPublicationDateValid ? (
                          t('CARBON_FOORPRINT_DISTRIBUTION_BEFORE').concat(plannedPublicationDate)
                        ) : (
                          plannedPublicationDate.charAt(0).toUpperCase() + plannedPublicationDate.slice(1)
                        )}
                      </span>
                      <span className="text-xs text-greyFour">
                        {t('CARBON_FOORPRINT_COMMITMENT').concat(commitmentDate)}
                      </span>
                    </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="bg-greyLightFive p-3 rounded-full">
                      <img src={crossIconGrey} alt="cross icon grey" height={32} width={32} />
                    </div>
                    <div className="text-sm text-greyThree mt-2">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null }
        {(carbonFootprint && !isControledPmeWithoutCarbonFootprint && !isFlagData && ((carbonFootprint[0] || carbonFootprint[1]) && !(carbonFootprint[0] && carbonFootprint[1]))) ? (
          <div className="col-span-8 my-auto xl:grid grid-cols-12 xl:ps-5 pe-0 text-sm">
            <div className="flex mt-6 xl:hidden gap-1">
              <div className={`xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 flex-nowrap rounded-full ${carbonFootprint[0] ? 'bg-[#21AFFF1A] text-[#21AFFF]' : 'bg-[#9E9E9E0D] text-greyFive'}`}>
                <div>
                  Scope 1&2
                </div>
                <img src={carbonFootprint[0] ? circleQuestion : circleQuestionGrey} width="auto" height="auto" alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
              </div>
              <div className={`xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 rounded-full ${carbonFootprint[1] ? 'bg-[#000D850D] text-[#000D85AD]' : 'bg-[#9E9E9E0D] text-greyFive'}`}>
                <div>
                  Scope 3
                </div>
                <img src={carbonFootprint[1] ? circleQuestionScope3 : circleQuestionGrey} width="auto" height="auto" alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
              </div>
            </div>
            <div className="col-span-8 xl:px-0 flex mt-6 xl:mt-0">
              <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: carbonFootprint[0] ? '#21AFFF' : '#F0F1F5' }} />
              <div className={`self-center ms-3 pt-1 ${carbonFootprint[0] ? 'text-greyOne' : 'text-greyFive'}`}>
                {carbonFootprint[0] ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
              </div>
            </div>
            <div className="col-span-4 xl:flex hidden border-solid border-r-0 border-y-0 border-l border-greySix relative">
              <div className={`ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex flex-nowrap rounded-full ${carbonFootprint[0] ? 'bg-[#21AFFF1A] text-[#21AFFF]' : 'bg-[#9E9E9E0D] text-greyFive'}`}>
                <div>
                  Scope 1&2
                </div>
                <div className="group self-center">
                  <img className="ms-1 mb-0.5" src={circleQuestion} width="auto" height="auto" alt="circle question" />
                  <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-full z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-8 pe-0 md:ps-0">
              <div className="flex mt-6">
                <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: carbonFootprint[1] ? '#000D85' : '#F0F1F5' }} />
                <div className={`self-center ms-3 pt-1 ${carbonFootprint[1] ? 'text-greyOne' : 'text-greyFive'}`}>
                  {carbonFootprint[1] ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_12') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                </div>
              </div>
            </div>
            <div className="col-span-4 xl:flex hidden mt-6 pe-0 border-solid border-r-0 border-y-0 border-l border-greySix">
              <div className={`ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex rounded-full relative ${carbonFootprint[1] ? 'bg-[#000D850D] text-[#000D85AD]' : 'bg-[#9E9E9E0D] text-greyFive'}`}>
                <div>
                  Scope 3
                </div>
                <div className="group self-center">
                  <img className="ms-1 mb-0.5" width="auto" height="auto" src={circleQuestionScope3} alt="circle question scope 3" />
                  <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-[140px] z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null }
      </div>
      {carbonFootprintDistribution?.length > 0 ? (
        <>
          <button type="button" onClick={toggleTable} className="border-0 bg-white text-sm text-primaryNewBlue hover:text-primaryNewBlueHover font-moskauGrotesk font-medium underline cursor-pointer w-fit">
            {displayTable ? t('SUPPLIER_PARTNER_CARBON_FOOTPRINT.SUB_CATEGORY_DETAILS_HIDE') : t('SUPPLIER_PARTNER_CARBON_FOOTPRINT.SUB_CATEGORY_DETAILS')}
          </button>
          <div style={{ maxHeight: (displayTable) ? '999px' : '0px', overflow: 'hidden' }} className="transition-all duration-1000 ease-in-out me-0 md:mr-4">
            {chartData
              .sort((a, b) => b.value - a.value)
              .map((item, index) => (
                <div key={index} className="mt-3">
                  {/* Container qui passe de vertical à horizontal selon breakpoint */}
                  <div className="flex flex-col md:grid md:grid-cols-12">
                    {/* Label */}
                    <div className="text-sm font-normal md:col-span-6">
                      {t(item.id)}
                    </div>
                    {/* Barre de progression et pourcentage */}
                    <div className="flex md:mt-0 md:col-span-6">
                      <div
                        className="h-4 rounded-md mt-1"
                        style={{
                          width: `${item.value}%`,
                          backgroundColor: item.color,
                        }}
                      />
                      <div className="ml-2 text-[14px] font-semibold">
                        {parseFloat(item.value).toFixed(1)}%
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default SupplierPartnerCarbonFootprint;

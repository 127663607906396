import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { useUserLocale } from '../../../../../utils/getUserLocal';

import Header from '../../Header/Header';
import getQuestions from '../../../../../utils/getQuestions';
import getAnsweredQuestionsCount from '../../../../../utils/getAnsweredQuestionsCount';
import TableOfContents from './TableOfContents';
import PublishingSwitch from '../PublishingSwitch';
import Category from './Category';
import getVisibility from '../../../../../utils/getVisibility';
import QuestionActionsFormModal from '../../Modal/Forms/QuestionActionsFormModal';
import QuestionsActionsItemFormModal from '../../Modal/Forms/QuestionsActionsItemFormModal';
import DeleteConfirmationModal from '../../Modal/Forms/DeleteConfirmationModal';
import handleResponse from '../../../../../utils/handleResponse';
import QuestionsActionsLinkFormModal from '../../Modal/Forms/QuestionsActionsLinkFormModal';
import QuestionActionDistributionFormModal from '../../Modal/Forms/QuestionActionDistributionFormModal';

function ActionPlan({ view }) {
  const [questions, setQuestions] = useState([]);
  const [questionsTransverse, setQuestionsTransverse] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0);
  const [progressionBar, setProgressionBar] = useState(0);
  const [summary, setSummary] = useState([]);
  const [summaryTransverse, setSummaryTransverse] = useState([]);
  const [isPublished, setIsPublished] = useState();
  // const [profilePartners, setProfilePartners] = useState([]);
  const [showOptional, setShowOptional] = useState(false);
  const [data, setData] = useState();
  const [itemToDelete, setItemToDelete] = useState();

  const { profile, partner, hasWriteAccess } = useOutletContext();
  const locale = useUserLocale();

  const api = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { t } = useTranslation();

  const TYPE_ACTION = 2;
  const TYPE_ACTION_TRANSVERSE = 7;

  const fetchData = async () => {
    let answeredNormal = 0;
    let answeredAdvanced = 0;
    let totalQuestions = 0;

    try {
      const normalQuestionsResponse = await getQuestions(api, profile.id, partner.domain, view, locale, TYPE_ACTION, profile.typology_id, partner.id, hasWriteAccess);

      if (api.response.ok) {
        setQuestions(normalQuestionsResponse.categories);
        setIsPublished(normalQuestionsResponse.is_published_tab);
        setSummary(normalQuestionsResponse.table_contents);
        // setProfilePartners(normalQuestionsResponse.partners);
        answeredNormal = getAnsweredQuestionsCount(normalQuestionsResponse.table_contents);
        totalQuestions += normalQuestionsResponse.categories.length;
      }
    } catch (error) {
      throw new Error(error);
    }

    try {
      const advancedQuestionsResponse = await getQuestions(api, profile.id, partner.domain, view, locale, TYPE_ACTION_TRANSVERSE, profile.typology_id, partner.id);

      if (api.response.ok) {
        setQuestionsTransverse(advancedQuestionsResponse.categories);
        setSummaryTransverse(advancedQuestionsResponse.table_contents);
        answeredAdvanced = getAnsweredQuestionsCount(advancedQuestionsResponse.table_contents);
        totalQuestions += advancedQuestionsResponse.categories.length;
      }
    } catch (error) {
      throw new Error(error);
    }

    setNumberOfAnsweredQuestions(answeredNormal + answeredAdvanced);
    setNumberOfQuestions(totalQuestions);

    const progressionBar = (totalQuestions > 0) ? ((answeredNormal + answeredAdvanced) * 100) / totalQuestions : 0;
    setProgressionBar(parseInt(progressionBar, 10));
  };

  useEffect(() => {
    fetchData();
  }, [locale]);

  const handleDelete = async () => {
    try {
      if (itemToDelete.type === 'contentValue') {
        await api.del(`/profiles/${profile.id}/content-value/${itemToDelete.id}`);
      } else if (itemToDelete.type === 'contentListItem') {
        await api.del(`/profiles/${profile.id}/content-list-item/${itemToDelete.id}`);
      }

      if (api.response.ok) {
        fetchData();
      }
    } catch (error) {
      handleResponse(api.response);
      throw new Error(error);
    }
  };

  return (
    <div id="profile-page w-full">
      <Header view={view} />
      {hasWriteAccess ? (
        <PublishingSwitch
          isViewPublishedInThisLocale={isPublished}
          view={view}
          publishing={profile.publishing}
          locale={locale}
          profilId={profile.id}
        />
      ) : ('')}
      <div className="container py-10 mx-auto px-5">
        <div className="lg:grid grid-cols-12 gap-6">
          <div className="col-span-4 col-start-1">
            <TableOfContents
              hasWriteAccess={hasWriteAccess}
              profileId={profile.id}
              progressionBar={progressionBar}
              numberOfQuestions={numberOfQuestions}
              numberOfAnsweredQuestions={numberOfAnsweredQuestions}
              summary={summary}
              summaryTransverse={summaryTransverse}
              isPublished={isPublished}
              showOptional={showOptional}
            />
          </div>
          <div className="col-span-8 col-start-5">
            <h2 className="fw-600 font-24 mb-4 mt-5 lg:mt-0">
              {t('TABLE_OF_CONTENTS_TEXT_4')}
            </h2>
            <div className="flex flex-col gap-6">
              {questions && questions.map((category, index) => {
                const show = getVisibility(category, hasWriteAccess, isPublished);

                return (
                  show
                && (
                <Category
                  key={index}
                  category={category.category[0]}
                  profile={profile}
                  isPublished={isPublished}
                  // view={view}
                  hasWriteAccess={hasWriteAccess}
                  fetchData={fetchData}
                  setData={setData}
                  setItemToDelete={setItemToDelete}
                />
                )
                );
              })}
            </div>
            {questionsTransverse.length > 0 && showOptional === true && (
            <h3 className="mb-4 mt-5 font-24">
              {t('PROFILE_ACTIONS_TEXT_21')}
            </h3>
            )}
            <div className="flex flex-col gap-6">
              {questionsTransverse && questionsTransverse.length > 0 && questionsTransverse.map((category, index) => {
                const show = getVisibility(category, hasWriteAccess, isPublished);

                return (
                  show
                  && (
                  <Category
                    key={index}
                    category={category.category[0]}
                    profile={profile}
                    isPublished={isPublished}
                    // view={view}
                    hasWriteAccess={hasWriteAccess}
                    setShowOptional={setShowOptional}
                    fetchData={fetchData}
                    setData={setData}
                    setItemToDelete={setItemToDelete}
                  />
                  )
                );
              })}
            </div>
          </div>
        </div>
        {hasWriteAccess ? (
          <>
            <QuestionActionsFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <QuestionsActionsItemFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <QuestionsActionsLinkFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <QuestionActionDistributionFormModal data={summary} profileId={profile.id} fetchData={fetchData} />
            <DeleteConfirmationModal modalId="delete-confirmation-modal-questions" handleDeleteFunction={handleDelete} title={t('DELETE_COMFIRMATION_MODAL.TITLE_A')} message={t('DELETE_COMFIRMATION_MODAL.TITLE_B')} />
          </>
        ) : ('')}
      </div>
    </div>
  );
}

export default ActionPlan;

import React from 'react';
import { useTranslation } from 'react-i18next';

function SupplierPartnerEmissions({ partner, profile }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-3.5 bg-white p-6 mb-4 border border-solid border-greySix rounded">
      <h2 className="text-greyOne font-moskauGrotesk text-base">
        {t('SUPPLIER_PARTNER_EMISSIONS.TITLE', { profileName: profile.name, partnerName: partner.name })}
      </h2>
      <div className="flex flex-col">
        <span className="font-inter font-light text-greyTwo">{t('SUPPLIER_PARTNER_EMISSIONS.TOTAL_EMISSIONS_YEAR', { year: '2024' })}</span>
        <span className="font-inter font-semibold text-primaryNewBlue">95 kt CO2eq</span>
        <span className="font-inter font-light text-greyTwo mt-3.5">{t('SUPPLIER_PARTNER_EMISSIONS.TOTAL_EMISSIONS_YEAR', { year: '2019' })}</span>
        <span className="font-inter font-semibold text-primaryNewBlue">109,3 kt CO2eq</span>
      </div>
      <div className="flex flex-col mt-3.5">
        <span className="font-inter font-light text-greyTwo">{t('SUPPLIER_PARTNER_EMISSIONS.TOTAL_INTENSITY_YEAR', { year: '2024' })}</span>
        <span className="font-inter font-semibold text-primaryNewBlue">9,5 tCO2eq par t de produits vendus</span>
        <span className="font-inter font-light text-greyTwo mt-3.5">{t('SUPPLIER_PARTNER_EMISSIONS.TOTAL_INTENSITY_YEAR', { year: '2019' })}</span>
        <span className="font-inter font-semibold text-primaryNewBlue">10 tCO2eq par t de produits vendus</span>
      </div>
      <div className="flex flex-col mt-3.5">
        <span className="font-inter font-light text-greyTwo">{t('SUPPLIER_PARTNER_EMISSIONS.TOTAL_QUANTITY_YEAR', { year: '2024' })}</span>
        <span className="font-inter font-semibold text-primaryNewBlue">10 kt de produits vendus</span>
        <span className="font-inter font-light text-greyTwo mt-3.5">{t('SUPPLIER_PARTNER_EMISSIONS.TOTAL_QUANTITY_YEAR', { year: '2019' })}</span>
        <span className="font-inter font-semibold text-primaryNewBlue">11,5 kt de produits vendus</span>
      </div>
    </div>
  );
}

export default SupplierPartnerEmissions;

import React from 'react';
import parse from 'html-react-parser';
import { sanitize } from 'dompurify';

function Presentation({ text }) {
  return (
    <div className="p-5 text-greyThree text-sm bg-white rounded border border-solid border-greySeven">
      {parse(sanitize(text))}
    </div>
  );
}

export default Presentation;

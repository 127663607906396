import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { sanitize } from 'dompurify';

// import checkProfileCard from '../../../../assets/ui/check-profile-card.svg';
// import circleExclamation from '../../../../assets/ui/circle-exclamation.svg';
import doc from '../../../../assets/ui/doc.svg';
import bug from '../../../../assets/ui/bug.svg';
import managed from '../../../../assets/ui/managed.svg';
import filiale from '../../../../assets/ui/filiale.svg';

import getSources from '../../../../utils/getSources';

function Sources({ profile }) {
  const [data, setData] = useState({});
  const [furtherInformations, setFurtherInformations] = useState([]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getSources(profile.id).then((response) => {
      setData(response);
      if (response && response.further_information !== null) {
        const furtherInformations = response.further_information;
        let results = [];
        let titles = [];

        const regex = /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#/%?=~_|!:,.;]*[-a-z0-9+&@#/%=~_|]/ig;
        const furtherInformationsLinks = furtherInformations.match(regex) || [];
        titles = furtherInformations.split(';');
        results = titles.map((titles, index) => {
          return {
            title: titles.replace(/\(.*\)/g, ''),
            link: furtherInformationsLinks[index] ? furtherInformationsLinks[index] : null,
          };
        });
        setFurtherInformations(results);
      } else {
        setFurtherInformations();
      }
    });
  }, [profile]);

  const getLastYearDatas = () => {
    if (data?.min_year && data?.max_year) {
      return data?.min_year > data?.max_year ? data?.min_year : data?.max_year;
    }

    return null;
  };

  return (
    <div className="p-5 bg-white mb-4 border border-solid border-greySix rounded">
      <h1 className="text-greyOne text-base mb-3.5">{t('SOURCES_AND_FIABILITY_TITLE')}</h1>
      <div className="text-sm text-greyTwo ">
        {!profile.is_managed ? (
          <div className="flex items-center">
            <img src={bug} className="mr-2" alt="warrning" data-bs-toggle="tooltip" data-bs-placement="right" />
            <span className="text-sm">
              <span className="font-semibold">{t('LOW_RELIABILITY')}</span> {t('PARTIAL_ANALYSE')}
            </span>
          </div>
        ) : (
          <div className="flex items-center">
            <img src={managed} className="mr-2" alt="warrning" data-bs-toggle="tooltip" data-bs-placement="right" />
            <span className="text-sm">
              <span className="font-semibold">{t('HIGH_RELIABILITY')}</span> {t('COMPLETE_ANALYSE')}
            </span>
          </div>
        )}
        <div className="flex items-center my-3.5">
          <img src={doc} className="mr-2" alt="warrning" data-bs-toggle="tooltip" data-bs-placement="right" />
          <div className="flex flex-col">
            <span className="text-sm font-semibold">
              {getLastYearDatas() ? (
                <>
                  {t('DATA_SOURCES')}&nbsp;
                  <span>{getLastYearDatas()}</span>
                </>
              ) : t('NO_DATA')}
            </span>
            {data?.last_analysis && (
              <span className="text-sm">
                {t('LAST_ANALYSIS')}
                {new Date(data?.last_analysis).toLocaleDateString(i18n?.language)}
              </span>
            )}
          </div>
        </div>
        {profile?.type === 1 && (
          <div className="flex items-center my-3.5">
            <img src={filiale} className="mr-2" alt="warrning" data-bs-toggle="tooltip" data-bs-placement="right" />
            <div>
              <p className="mb-0 font-semibold">
                {t('SUBSIDIARY_OF_THE_GROUP', { group: profile?.parent_company_name })}
              </p>
              <p className="mb-0">
                {t('SUBSIDIARY_DISCLAIMER_TEXT')}
              </p>
            </div>
          </div>
        )}
        {data?.parent_company_name && furtherInformations && (furtherInformations.map((furtherInformation, index) => (
          <React.Fragment key={index}>
            {furtherInformation.link ? (
              <a target="_blank" rel="noreferrer" className="text-sm text-greyFour" href={furtherInformation.link}>
                {furtherInformation.title}
              </a>
            ) : (
              <span className="text-sm text-greyFour">
                {furtherInformation.title}
              </span>
            )}
            <br />
          </React.Fragment>
        ))
        )}
      </div>
    </div>
  );
}

export default Sources;

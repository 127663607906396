import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationFR from './translations/translation.fr.json';
import translationEN from './translations/translation.en.json';

const languageDetector = new LanguageDetector();
languageDetector.init({
  order: ['cookie', 'localStorage', 'navigator'],
  lookupCookie: 'i18next',
  caches: ['cookie'],
  cookieMinutes: 10080, // 7 days
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: translationEN,
      fr: translationFR,
    },
    debug: false,
    detection: {
      order: ['cookie', 'localStorage', 'navigator'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

// Synchroniser l'attribut HTML lang au démarrage
document.documentElement.lang = i18n.language;

export default i18n;
